import { ReactElement, useRef } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import { DeleteDialogInterface } from '../../interfaces/components.interfaces';
import useDeleteDialogStyle from '../../styles/delete_dialog';
import { useDeletBudgetItemMutation } from '../../types';
import { reducer } from '../../store/reducer';

function DeleteDialog(props: DeleteDialogInterface): ReactElement {
  const classes = useDeleteDialogStyle();
  const {
    open,
    onClose,
    setOpen,
    budget,
    setBudget,
    item,
    setTotalPrice,
    totalPrice,
    setHasChanged,
  } = props;
  const [deleteBudgetItem] = useDeletBudgetItemMutation({
    variables: {
      itemId: +item.id,
    },
    onCompleted: (data) => {
      reducer({ type: 'LOADING' });
      setTotalPrice(totalPrice - data.deleteBudgetItem.price);
      setBudget({
        ...budget,
        items: budget.items.filter((actualItem) => actualItem.id !== data.deleteBudgetItem.id),
      });
      setOpen(false);
      setHasChanged(true);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const dialogRef = useRef(null);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth={false}
      ref={dialogRef}
    >
      <DialogTitle className={classes.deleteMessage}>¿Esta seguro que desea eliminar?</DialogTitle>
      <div className={classes.buttonsContainer}>
        <div
          className={classes.leftButtonContainer}
          onClick={() => {
            reducer({ type: 'LOADING' });
            deleteBudgetItem();
          }}
        >
          <p className={classes.optionMessage}>Si</p>
        </div>
        <div
          className={classes.rightButtonContainer}
          onClick={() => setOpen(false)}
        >
          <p className={classes.optionMessage}>No</p>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteDialog;
