import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  grid: {
    padding: theme.spacing(6),
  },
  itemGrid: {
    alignContent: 'center',
    alignItems: 'center',
  },
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
    textAlign: 'center',
    alignItems: 'center',
  },
  formInput: {
    width: '60vw',
    padding: theme.spacing(2),
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: '30vw',
      padding: theme.spacing(2),
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  boxItem: {
    marginTop: '20vh',
  },
  table: {
    width: '90vw',
    padding: theme.spacing(2),
    textAlign: 'center',
    marginBottom: '5vh',
    [theme.breakpoints.up('md')]: {
      maxWidth: '45vw',
      maxHeight: '55vh',
      minHeight: '55vh',
      marginBottom: '0vh',
      alignItems: 'center',
    },
  },
  pageButtonActive: {
    color: '#FFFFFF',
    backgroundColor: '#126da6',
    '&:focus': {
      backgroundColor: '#126da6',
    },
  },
  pageButton: {
    color: '#FFFFFF',
    backgroundColor: 'rgba(18, 109, 166, 0.5)',
    '&:focus': {
      backgroundColor: 'rgba(18, 109, 166, 0.5)',
    },
    cursor: 'not-allowed',
  },
  secondBox: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  bloqued: {
    cursor: 'not-allowed',
  },
  dialog: {
    minHeight: '50vh',
    minWidth: '50vw',
  },
  secondBoxs: {
    margin: '3vw',
  },
  firstColumn: {
    width: '7vw',
  },
  secondColumn: {
    width: '15vw',
  },
  thirdColumn: {
    width: '10vw',
  },
  typography: {
    padding: '20px',
    color: 'black',
    fontSize: 36,
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
      marginBottom: '5vh',
      fontSize: 34,
    },
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export default useStyles;
