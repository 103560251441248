import {
  ReactElement,
  useState,
} from 'react';
import {
  Box,
  Button,
} from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import useStyles from '../../styles/register_form';
import RegisterWorkerData from '../../components/forms/registerWorker';
import { reducer } from '../../store/reducer';
import { useSignupWorkerMutation } from '../../types';

function WorkerRegister() : ReactElement {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [isNameValid, setIsNameValid] = useState(false);
  const [rut, setRut] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isRutValid, setIsRutValid] = useState(false);
  const [isRoleValid, setIsRoleValid] = useState(false);
  const [role, setRole] = useState('');

  const [execution] = useSignupWorkerMutation({
    variables: {
      rut,
      email,
      name,
      role,
    },
    onCompleted: () => {
      reducer({ type: 'LOADING' });
      window.location.href = '/admin/trabajadores';
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  function submit() {
    if (isNameValid && isRutValid && isEmailValid && isRoleValid) {
      execution();
    }
  }

  return (
    <Box className={classes.appContainer}>
      <Box className={classes.formBox}>
        <div className={classes.formTitle}>
          Registro de Trabajador
        </div>
        <RegisterWorkerData
          name={name}
          setName={setName}
          rut={rut}
          setRut={setRut}
          mail={email}
          setMail={setEmail}
          isNameValid={isNameValid}
          setIsNameValid={setIsNameValid}
          isMailValid={isEmailValid}
          setIsMailValid={setIsEmailValid}
          isRutValid={isRutValid}
          setIsRutValid={setIsRutValid}
          role={role}
          setRole={setRole}
          isRoleValid={isRoleValid}
          setIsRoleValid={setIsRoleValid}
        />
        <Box className={classes.pagesBox}>
          <Button
            variant="contained"
            onClick={() => submit()}
            className={(isNameValid && isRutValid && isEmailValid && isRoleValid)
              ? classes.pageButtonActive
              : classes.pageButton}
          >
            Registrar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default WorkerRegister;
