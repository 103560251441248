import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#115fa6',
    position: 'relative',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    [theme.breakpoints.up(1167)]: {
      display: 'none',
    },
    textAlign: 'left',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.up(1167)]: {
      display: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
    color: '#000000',
    background: '#ffffff',
  },
  iconStyle: {
    color: '#ffffff',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  button: {
    height: '100%',
    '&:hover': {
      TransitionEvent: '2s',
      backgroundColor: '#ffffff',
      color: '#3c52b2',
    },
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff',
  },
  adminLink: {
    textDecoration: 'none',
    color: '#115fa6',
    fontWeight: 'bold',
  },
  listItem: {
    marginTop: '100%',
  },
  signOutContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  listItemIcon: {
    color: '#000000',
  },
  listItemText: {
    color: '#000000',
  },
  iconButton: {
    color: '#000000',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    height: '100%',
  },
  children: {
    height: '100%',
  },
  buttonClass: {
    background: '#115fa6',
    color: '#f5f5f5',
    '&:hover': {
      background: '#f5f5f5',
      color: '#115fa6',
    },
    [theme.breakpoints.between('xs', 1167)]: {
      display: 'none',
    },
  },
  topBar: {
    background: '#fbfbfb',
    minHeight: 36,
    color: '#115fa6',
    [theme.breakpoints.between('xs', 1167)]: {
      display: 'none',
    },
  },
  textTop: {
    color: '#115fa6',
    textDecoration: 'underline',
  },
  toggle: {
    '&:hover': {
      backgroundColor: '#115fa6',
      color: '#f5f5f5',
    },
    border: 1,
  },
  rightSide: {
    textAlign: 'right',
  },
  hideLink: {
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
  },
  quelleLink: {
    textDecoration: 'none',
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'left',
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
