import {
  ReactElement,
  useEffect,
} from 'react';
import {
  TextField,
  Box,
  FormHelperText,
} from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import useBudgetAppointmentStyles from '../../styles/budget_appointment';
import ImageDialog from '../image_dialog/image_dialog';
import rutFormat from '../../helpers/rutFormat';
import mailRegex from '../../helpers/mailRegex';
import { PersonalDataInterface } from '../../interfaces/components.interfaces';
import validateRut from '../../helpers/validateRut';

function PersonalData(props: PersonalDataInterface) : ReactElement {
  const {
    name,
    setName,
    lastName,
    setLastName,
    rut,
    setRut,
    mail,
    setMail,
    isNameValid,
    setIsNameValid,
    isMailValid,
    setIsMailValid,
    isRutValid,
    setIsRutValid,
    idFiles,
    setIdFiles,
    openId,
    setOpenId,
    phone,
    setPhone,
    nextClicked,
  } = props;
  const classes = useBudgetAppointmentStyles();
  useEffect(() => {
    if (name.length > 0) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  }, [name]);

  useEffect(() => {
    if (mailRegex.test(mail)) {
      setIsMailValid(true);
    } else {
      setIsMailValid(false);
    }
  }, [mail]);

  useEffect(() => {
    if (rut.length >= 11 && validateRut(rut)) {
      setIsRutValid(true);
    } else {
      setIsRutValid(false);
    }
  }, [rut]);

  return (
    <Box className={classes.inputsBox}>
      <div className={classes.formTitle}>
        Ingresa tus datos personales
      </div>
      <TextField
        label="Nombre"
        helperText={(nextClicked && !isNameValid) ? 'Ingrese su nombre' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setName(e.target.value.toUpperCase())}
        value={name}
        className={classes.formInput}
      />
      <TextField
        label="Apellido"
        helperText={(nextClicked && lastName.length === 0) ? 'Ingrese su apellido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setLastName(e.target.value.toUpperCase())}
        value={lastName}
        className={classes.formInput}
      />
      <TextField
        label="Email"
        helperText={((!isMailValid && mail.length > 0)
          || (nextClicked && !isMailValid)) ? 'Ingrese un mail válido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setMail(e.target.value.toLowerCase())}
        value={mail}
        className={classes.formInput}
      />
      <TextField
        label="RUT"
        helperText={((!isRutValid && rut) || (nextClicked && !isRutValid)) ? 'Ingrese un RUT válido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setRut(rutFormat(e.target.value))}
        value={rut}
        className={classes.formInput}
      />
      <TextField
        label="Número"
        helperText={((nextClicked && phone.length !== 12)
          || (phone.length > 3 && phone.length !== 12)) ? 'Ingrese un número válido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setPhone(
          e.target.value.length > 3
            ? e.target.value.slice(0, 2) + e.target.value.slice(2).replace(/\D+/g, '') : '+56',
        )}
        value={phone}
        className={classes.formInput}
      />
      <div className={classes.uploadContainer}>
        <div
          onClick={() => setOpenId(true)}
          className={idFiles.length > 0 ? classes.uploadButtonActive : classes.uploadButton}
        >
          <p className={classes.uploadTitle}>
            Cédula (derecho y revés)
          </p>
          {(idFiles.length === 2)
            ? (
              <div className={classes.filesCount}>
                <div className={classes.count}>
                  {idFiles.length}
                </div>
              </div>
            ) : <CameraAltIcon className={classes.uploadIcon} />}
        </div>
        <FormHelperText className={classes.helperText}>
          {(idFiles.length === 2 || !nextClicked) ? '\u00a0' : `${idFiles.length > 2 ? 'Sobran' : 'Faltan'} ${Math.abs(
            2 - idFiles.length,
          )} archivos`}
        </FormHelperText>
        <ImageDialog
          files={idFiles}
          open={openId}
          setOpen={setOpenId}
          onClose={() => setOpenId(false)}
          setFiles={setIdFiles}
          title="Cédula Identidad"
        />
      </div>
    </Box>
  );
}
export default PersonalData;
