import { useState, ReactElement } from 'react';
import Alert from '@material-ui/lab/Alert';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import { Button } from '@material-ui/core';
import { SchedulerModalInterface } from '../../interfaces/components.interfaces';
import Scheduler from '../reschedule/reschedule';
import { useStyles } from '../../styles/reschedulerModal';

function RescheduleModal(props: SchedulerModalInterface): ReactElement {
  const {
    id,
    idService,
    plate,
    date,
    hour,
  } = props;

  const classes = useStyles();
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [severity, setSeverity] = useState('');
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const checkResult = () => {
    if (severity === 'error') {
      return <Alert className={classes.alert} severity="error">{alertContent}</Alert>;
    }
    return <Alert className={classes.alert} severity="success">{alertContent}</Alert>;
  };

  return (
    <div className={classes.rescheduleModal}>
      <Button
        onClick={handleOpen}
        className={classes.button}
      >
        Reagendar
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
      >
        <div className={classes.paper}>
          {alert ? checkResult() : <></> }
          <div className={classes.closeBox}>
            <CloseIcon className={classes.closeIcon} onClick={() => handleClose()} />
          </div>
          <h1>Reagendar Hora</h1>
          <h2>
            Fecha actual:
            {` ${date} ${hour} hrs.`}
          </h2>
          <h2>
            Patente Vehículo:
            {` ${plate}`}
          </h2>
          <Scheduler
            id={id}
            idService={idService}
            plate={plate}
            setAlert={setAlert}
            setAlertContent={setAlertContent}
            setSeverity={setSeverity}
          />
        </div>
      </Modal>
    </div>
  );
}

export default RescheduleModal;
