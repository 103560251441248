import { ReactElement } from 'react';
import { Grid, CardMedia, Typography } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { useStyles } from '../../styles/landing_style';
import quelle from '../../assets/images/quelle.png';

function LandingPage(): ReactElement {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      className={classes.image}
    >
      <Grid item>
        <Card className={classes.root} elevation={0}>
          <CardMedia
            component="img"
            image={quelle}
          />
          <CardContent>
            <Typography className={classes.typography} component="p">
              Casa matriz: Calle 9 norte 1040
            </Typography>
            <Typography className={classes.typography} component="p">
              Horario: 08:30hrs. a 19:30hrs.
            </Typography>
            <Typography className={classes.typography} component="p">
              Contacto: +569-78637360
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default LandingPage;
