import {
  ReactElement,
  useEffect,
} from 'react';
import {
  Box,
  Button,
  Grid,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { CalendarInterface } from '../../interfaces/components.interfaces';
import { useGetAvailabilityLazyQuery, useGetCarByPlateLazyQuery } from '../../types';
import useStyles from '../../styles/calendar_style';

function Calendar(props: CalendarInterface): ReactElement {
  const {
    day,
    hour,
    setDay,
    setHour,
    area,
    disponibility,
    setDisponibility,
    plate,
    carReservations,
    setCarReservations,
    reserve0,
    setReserve0,
  } = props;
  const classes = useStyles();
  const [executeAvailable] = useGetAvailabilityLazyQuery({
    variables: {
      serviceId: +area,
      date: day,
    },
    onCompleted: (data) => {
      setDisponibility(data.getAvailability);
    },
  });
  const [executeReservationByCar] = useGetCarByPlateLazyQuery({
    variables: {
      plate,
      gte: false,
    },
    onCompleted: (data) => {
      setCarReservations(data.getCarByPlate.reservations);
    },
  });
  function changeVal(text: string) {
    setDay(text);
    setReserve0([]);
    executeReservationByCar();
    executeAvailable();
    setHour('');
  }
  useEffect(() => {
    const lista:string[] = [];
    for (let i = 0; i < carReservations.length; i += 1) {
      const fecha = carReservations[i].date;
      if (day === `${fecha.substring(0, 4)}-${fecha.substring(5, 7)}-${fecha.substring(8, 10)}`) {
        let hora = fecha.substring(11, 19).toString();
        if (hora.substring(0, 1) === '0') {
          hora = hora.substring(1);
        }
        lista.push(hora);
      }
      setReserve0(lista);
    }
  }, [disponibility]);
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1;
  const today = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  dateObj.setDate(new Date().getDate() + 31);
  const futureMonth = dateObj.getUTCMonth() + 1;
  const futureYear = dateObj.getUTCFullYear();
  const futureToday = dateObj.getUTCDate();
  let newMonth = month.toString();
  let newFutureMonth = futureMonth.toString();
  if (month < 10) {
    newMonth = `0${month}`;
  }
  if (futureMonth < 10) {
    newFutureMonth = `0${futureMonth}`;
  }

  const newdate = `${year}-${newMonth}-${today}`;
  const futureDay = `${futureYear}-${newFutureMonth}-${futureToday}`;
  const dispoKeys = Object.keys(disponibility);
  const var0 = hour === dispoKeys[0] ? classes.hColor : classes.hNotColor;
  const var1 = hour === dispoKeys[1] ? classes.hColor : classes.hNotColor;
  const var2 = hour === dispoKeys[2] ? classes.hColor : classes.hNotColor;
  const var3 = hour === dispoKeys[3] ? classes.hColor : classes.hNotColor;
  const var4 = hour === dispoKeys[4] ? classes.hColor : classes.hNotColor;
  const var5 = hour === dispoKeys[5] ? classes.hColor : classes.hNotColor;
  const var6 = hour === dispoKeys[6] ? classes.hColor : classes.hNotColor;
  const var7 = hour === dispoKeys[7] ? classes.hColor : classes.hNotColor;
  const variable = [var0, var1, var2, var3, var4, var5, var6, var7];

  return (
    <Box
      className={classes.hours}
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        className={classes.dates}
      >
        <form>
          <TextField
            id="date"
            label="Seleccione fecha"
            type="date"
            value={day}
            onChange={(e) => changeVal(e.target.value)}
            className={classes.formInput}
            inputProps={{ min: newdate, max: futureDay }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </form>
      </Box>
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="center"
        justify="center"
        className={classes.gridHours}
      >
        {Object.keys(disponibility).map((key: string, index: number) => (
          <Grid
            item
            xs={3}
            spacing={0}
            alignItems="center"
            justify="center"
            className={classes.centered}
          >
            <Button
              id="0"
              onClick={Object.values(disponibility)[index] === '1' && !reserve0.includes(key)
                ? () => setHour(key) : null}
              className={Object.values(disponibility)[index] === '0' || reserve0.includes(key)
                ? classes.reserved : variable[index]}
            >
              {key.slice(0, -3)}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
export default Calendar;
