import {
  ReactElement,
  useState,
  useEffect,
} from 'react';
import Alert from '@material-ui/lab/Alert';
import {
  Box,
  Button,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  useSignS3Mutation,
  useCreateReservationMutation,
  useServicesLazyQuery,
  ServicesQuery,
  GetCarByPlateQuery,
} from '../../types';
import imagesUploadHandler from '../../helpers/imagesUploadHandler';
import Calendar from '../../components/calendar/calendar';
import useBudgetAppointmentStyles from '../../styles/budget_appointment';
import useLoaderStyles from '../../styles/loader';
import PersonalData from '../../components/forms/personalData';
import CarData from '../../components/forms/carData';
import AreaData from '../../components/forms/areaData';
import { twoLettersPlateRegex, fourLettersPlateRegex } from '../../helpers/plateRegex';
import Loader from '../../components/loader/loader';

function FwithoutInsurance() : ReactElement {
  const classes = useBudgetAppointmentStyles();
  const loaderStyle = useLoaderStyles();
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('+56');
  const [isNameValid, setIsNameValid] = useState(false);
  const [rut, setRut] = useState('');
  const [mail, setMail] = useState('');
  const [isMailValid, setIsMailValid] = useState(false);
  const [isRutValid, setIsRutValid] = useState(false);
  const [idFiles, setIdFiles] = useState<File[]>([]);
  const [circulationFiles, setCirculationFiles] = useState<File[]>([]);
  const [openCirculation, setOpenCirculation] = useState(false);
  const [openId, setOpenId] = useState(false);
  const [disponibility, setDisponibility] = useState<string[]>([]);
  const [allArea, setAllArea] = useState<ServicesQuery['services']>([]);
  const [initial, setInitial] = useState(0);
  const [area, setArea] = useState(-1);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [daySelected, setDaySelected] = useState('');
  const [hour, setHour] = useState('');
  const [reserve0, setReserve0] = useState<string[]>([]);
  const [reservations, setReservations] = useState<number[]>([]);
  const [carBrand, setCarBrand] = useState('');
  const [carModel, setCarModel] = useState('');
  const [carYear, setCarYear] = useState(0);
  const [chasisNumber, setChasisNumber] = useState('');
  const [carColor, setCarColor] = useState('');
  const [carPlate, setCarPlate] = useState('');
  const [isCarPlateValid, setIsCarPlateValid] = useState(false);
  const [executeSignS3] = useSignS3Mutation({});
  const [executeCreateReservation] = useCreateReservationMutation({});
  const [carReservations, setCarReservations] = useState<GetCarByPlateQuery['getCarByPlate']['reservations']>([]);
  const [alertContent, setAlertContent] = useState('');
  const [description, setDescription] = useState('');
  const [nextClicked, setNextClicked] = useState(false);
  const [severityError, setSeverityError] = useState(false);
  const [executeServices] = useServicesLazyQuery({
    onCompleted: (data) => setAllArea(data.services),
  });
  useEffect(() => {
    executeServices();
  }, []);

  useEffect(() => {
    setDaySelected('');
    setHour('');
    setDisponibility([]);
  }, [area]);

  function sendAlert(alertType: boolean): ReactElement {
    if (alertType) {
      return (
        <>
          <Alert className={classes.alert} severity="success">
            {alertContent}
          </Alert>
          <div className={classes.detailsAlert}>
            <Typography> Le hemos enviado la confirmación de la reserva a su correo electrónico </Typography>
          </div>
          <div className={classes.detailsAlert}>
            <Button href="/" className={classes.pageButtonActive}> Ir al inicio </Button>
          </div>
        </>
      );
    }
    return (
      <>
        <Alert className={classes.alert} severity="error">
          {alertContent}
        </Alert>
        <div className={classes.detailsAlert}>
          <Button href="/reserva" className={classes.pageButtonActive}> Volver a intentar </Button>
        </div>
      </>
    );
  }

  useEffect(() => {
    setDaySelected('');
    setHour('');
    setDisponibility([]);
    if (carPlate.length === 6 && fourLettersPlateRegex.test(carPlate)) {
      setIsCarPlateValid(true);
    } else if (carPlate.length === 6 && twoLettersPlateRegex.test(carPlate)) {
      setIsCarPlateValid(true);
    } else {
      setIsCarPlateValid(false);
    }
  }, [carPlate]);
  function formatPlate(plate: string): string {
    return plate.toUpperCase().replace('-', '').substring(0, 6);
  }

  async function handleSubmit() {
    setStep(3);
    const idUrls = await imagesUploadHandler('idImage', rut, idFiles, executeSignS3)
      .catch(() => {
        setAlertContent('Lo lamentamos, ha habido un error procesando su solicitud');
        setSeverityError(false);
        setStep(4);
      });
    const circulationUrls = await imagesUploadHandler('circulationImage', carPlate, circulationFiles, executeSignS3)
      .catch(() => {
        setAlertContent('Lo lamentamos, ha habido un error procesando su solicitud');
        setSeverityError(false);
        setStep(4);
      });
    executeCreateReservation({
      variables: {
        name,
        lastname: lastName,
        phone,
        year: carYear,
        rut,
        email: mail,
        plate: carPlate,
        chasis: chasisNumber,
        brand: carBrand,
        model: carModel,
        color: carColor,
        carnet: idUrls as string[],
        permisoCirculacion: circulationUrls as string[],
        declaracionJurada: [''],
        siniestro: '11111',
        serviceId: +area,
        date: `${daySelected}T${hour}.000Z`,
        description,
      },
    })
      .then(() => {
        setAlertContent('Su reserva ha sido realizada con éxito.');
        setSeverityError(true);
        setStep(4);
      })
      .catch(() => {
        setAlertContent('Lo lamentamos, ha habido un error procesando su solicitud');
        setSeverityError(false);
        setStep(4);
      });
  }

  function nextClick() {
    if (step === 0 && (hour !== '') && (description !== '')) {
      setStep(1);
      setNextClicked(false);
    } else if (
      step === 1
      && isNameValid
      && isRutValid
      && idFiles.length === 2
      && isMailValid
      && phone.length === 12) {
      setStep(2);
      setNextClicked(false);
      if (hour.length === 7) {
        setHour(`0${hour}`);
      }
    } else if (step === 2
      && carBrand.length > 0
      && carModel.length > 0
      && chasisNumber.length > 0
      && carColor.length > 0
      && carPlate.length > 0
      && circulationFiles.length > 0
      && carYear >= 1960
      && carYear <= new Date().getFullYear()) {
      setNextClicked(false);
      handleSubmit();
    } else {
      setNextClicked(true);
    }
  }
  function back() {
    setStep(step - 1);
  }

  if (step === 0) {
    return (
      <Box
        className={classes.boxsize}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="auto"
      >
        <Box
          className={classes.boxsize}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            className={classes.containerForm}
          >
            <Box className={classes.boxsize}>
              <AreaData
                open={open}
                setOpen={setOpen}
                area={area}
                setArea={setArea}
                allArea={allArea}
              />
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <TextField
                  label="Patente (ej. XXYY22)"
                  helperText={(!isCarPlateValid && carPlate.length > 0)
                    ? 'Ingrese una patente válida' : '\u00a0'}
                  onChange={(e) => setCarPlate(formatPlate(e.target.value))}
                  className={classes.formInput}
                  value={carPlate}
                />
                <TextField
                  id="standard-multiline-static"
                  label="Ingrese motivo de visita"
                  variant="filled"
                  helperText={(nextClicked && description.length <= 0) ? 'Ingrese una descripción' : '\u00a0'}
                  FormHelperTextProps={{ className: classes.helperText }}
                  className={classes.formInput}
                  multiline
                  rows={4}
                  onChange={(e) => setDescription(e.target.value)}
                  defaultValue={description}
                />
              </Box>
              {area > 0 && isCarPlateValid
                ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Calendar
                      day={daySelected}
                      hour={hour}
                      reserve0={reserve0}
                      setDay={setDaySelected}
                      setHour={setHour}
                      setReserve0={setReserve0}
                      disponibility={disponibility}
                      setDisponibility={setDisponibility}
                      area={area}
                      initial={initial}
                      setInitial={setInitial}
                      reservations={reservations}
                      setReservations={setReservations}
                      plate={carPlate}
                      setPlate={setCarPlate}
                      carReservations={carReservations}
                      setCarReservations={setCarReservations}
                    />
                    <Box className={classes.pagesBox}>
                      <Button
                        variant="contained"
                        onClick={() => nextClick()}
                        className={(step === 0 && (hour !== '') && (description !== ''))
                          ? classes.pageButtonActive : classes.pageButton}
                      >
                        Siguiente
                      </Button>
                    </Box>
                  </Box>
                ) : (null)}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  if (step === 1) {
    return (
      <Box className={classes.appContainer}>
        <Box className={classes.formBox}>
          <PersonalData
            name={name}
            setName={setName}
            lastName={lastName}
            setLastName={setLastName}
            rut={rut}
            setRut={setRut}
            mail={mail}
            setMail={setMail}
            isNameValid={isNameValid}
            setIsNameValid={setIsNameValid}
            isMailValid={isMailValid}
            setIsMailValid={setIsMailValid}
            nextClicked={nextClicked}
            isRutValid={isRutValid}
            setIsRutValid={setIsRutValid}
            idFiles={idFiles}
            setIdFiles={setIdFiles}
            openId={openId}
            setOpenId={setOpenId}
            phone={phone}
            setPhone={setPhone}
          />
          <Box className={classes.pagesBox}>
            <Button
              variant="contained"
              onClick={() => nextClick()}
              className={(isNameValid && isRutValid && idFiles.length === 2 && isMailValid && phone.length === 12)
                ? classes.pageButtonActive
                : classes.pageButton}
            >
              Siguiente
            </Button>
            <Button
              variant="contained"
              onClick={() => back()}
              className={classes.pageButtonActive}
            >
              Volver
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  if (step === 2) {
    return (
      <Box className={classes.appContainer}>
        <Box className={classes.formBox}>
          <Box className={classes.inputsBox}>
            <CarData
              carBrand={carBrand}
              setCarBrand={setCarBrand}
              carModel={carModel}
              setCarModel={setCarModel}
              carPlate={carPlate}
              setCarPlate={setCarPlate}
              nextClicked={nextClicked}
              isCarPlateValid={isCarPlateValid}
              setIsCarPlateValid={setIsCarPlateValid}
              carYear={carYear}
              setCarYear={setCarYear}
              chasisNumber={chasisNumber}
              setChasisNumber={setChasisNumber}
              carColor={carColor}
              setCarColor={setCarColor}
              openCirculation={openCirculation}
              setOpenCirculation={setOpenCirculation}
              circulationFiles={circulationFiles}
              setCirculationFiles={setCirculationFiles}
            />
            <Box className={classes.pagesBox}>
              <Button
                variant="contained"
                onClick={() => nextClick()}
                className={(
                  carBrand.length > 0
                  && carModel.length > 0
                  && carPlate.length > 0
                  && circulationFiles.length > 0
                  && chasisNumber.length > 0
                  && carYear >= 1960
                  && carYear <= new Date().getFullYear())
                  ? classes.pageButtonActive
                  : classes.pageButton}
              >
                Enviar
              </Button>
              <Button
                variant="contained"
                onClick={() => setStep(step - 1)}
                className={classes.pageButtonActive}
              >
                Volver
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }
  if (step === 3) {
    return (
      <Box className={loaderStyle.loaderDiv}>
        <Loader />
      </Box>
    );
  }
  return (
    <Box className={classes.appContainer}>
      {sendAlert(severityError)}
    </Box>
  );
}
export default FwithoutInsurance;
