import { Button, Box } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Alert from '@material-ui/lab/Alert';
import { GetReceptionsListQuery, useGetAllReceptionsListLazyQuery, useGetReceptionsListLazyQuery } from '../../types';
import { useStyles } from '../../styles/reservationTable_style';
import { ReceptionRowInterface } from '../../interfaces/components.interfaces';
import ReceptionFilter from '../filters/receptionFilters';

function dateFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  const year: number = date.getFullYear();
  const month:number = (date.getMonth());
  const day: number = date.getDate();
  return moment(new Date(year, month, day)).format('DD/MM/YYYY');
}

function timeFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  let hour: string = date.getUTCHours().toString();
  let min: string = date.getUTCMinutes().toString();
  if (min.length === 1) {
    min = `0${min}`;
  }
  if (hour.length === 1) {
    hour = `0${hour}`;
  }
  return `${hour}:${min}`;
}

function formatingData(data: GetReceptionsListQuery['receptions']) {
  const rows: ReceptionRowInterface[] = [];
  data.forEach((element) => {
    const reception: ReceptionRowInterface = {
      id: +element.id,
      name: `${element.budget.client.name} ${element.budget.client.lastname}`,
      receptionDate: dateFormat(element.receptionDate),
      receptionHour: timeFormat(element.receptionDate),
      plate: element.budget.car.plate,
      model: element.budget.car.model,
      color: element.budget.car.color,
    };
    rows.push(reception);
  });
  return rows;
}

function redirectToReceptionView(id: string) {
  window.location.href = `/admin/recepciones/${id}`;
}

const columns = [
  {
    name: 'Fecha', selector: 'receptionDate',
  },
  {
    name: 'Hora', selector: 'receptionHour',
  },
  {
    name: 'Cliente', selector: 'name',
  },
  {
    name: 'Patente', selector: 'plate',
  },
  {
    name: 'Modelo', selector: 'model',
  },
  {
    name: 'Color', selector: 'color',
  },
  {
    name: '',
    cell: (params: { id: string }) => {
      const onClick = () => {
        redirectToReceptionView(params.id);
      };
      return <Button onClick={onClick}>Ver</Button>;
    },
  },
];

function Today() {
  const date: Date = new Date();
  const year: number = date.getUTCFullYear();
  const month:number = (date.getUTCMonth());
  const day: number = date.getUTCDate();
  return moment(new Date(year, month, day)).format('YYYY-MM-DD');
}

function NextMonth() {
  const date: Date = new Date();
  const year: number = date.getUTCFullYear();
  const month:number = (date.getUTCMonth());
  const day: number = date.getUTCDate();
  return moment(new Date(year, month, day)).add(31, 'days').format('YYYY-MM-DD');
}

function ReceptionTable(): ReactElement {
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [allRows, setAllRows] = useState(0);
  const [page, setPage] = useState(1);
  const [name, setName] = useState('');
  const [dateIni, setDateIni] = useState(`${Today()}`);
  const [dateEnd, setDateEnd] = useState(`${NextMonth()}`);
  const [plate, setPlate] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const take = 6;
  const [executeAllReceptions] = useGetAllReceptionsListLazyQuery({
    variables:
    {
      name,
      plate,
      dateIni: `${dateIni}T00:00:00Z`,
      dateEnd: `${dateEnd}T22:00:00Z`,
    },
    onCompleted: (data) => {
      setAlert(false);
      setAllRows(data.allReceptions);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlertContent('Falló la solicitud, inténtelo nuevamente');
        setAlert(true);
      }
    },
  });
  const [executeReceptionsQuery] = useGetReceptionsListLazyQuery({
    variables: {
      page,
      take,
      name,
      plate,
      dateIni: `${dateIni}T00:00:00Z`,
      dateEnd: `${dateEnd}T22:00:00Z`,
    },
    onCompleted: (data) => {
      setAlert(false);
      executeAllReceptions();
      setRowData(formatingData(data.receptions));
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlertContent('Falló la solicitud, inténtelo nuevamente');
        setAlert(true);
      }
    },
  });

  useEffect(() => {
    executeReceptionsQuery();
  }, [page]);
  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.buttonsContainer}
      >
        <ReceptionFilter
          name={name}
          setName={setName}
          plate={plate}
          setPlate={setPlate}
          dateIni={dateIni}
          setDateIni={setDateIni}
          dateEnd={dateEnd}
          setDateEnd={setDateEnd}
        />
      </Box>
      { alert ? <Alert className={classes.alert} severity="error">{alertContent}</Alert> : <></>}
      <DataTable
        noHeader
        columns={columns}
        data={rowData}
        noDataComponent="No se ha encontrado información para mostrar"
        pagination
        paginationServer
        paginationTotalRows={allRows}
        paginationPerPage={take}
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: 'de',
        }}
        paginationRowsPerPageOptions={[6]}
        onChangePage={(pageNumber) => setPage(pageNumber)}
      />
    </div>
  );
}

export default ReceptionTable;
