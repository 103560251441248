import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useBudgetShowStyle = makeStyles((theme: Theme) => createStyles({
  desktopContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    position: 'relative',
    width: '80vw',
  },
  desktopAddButton: {
    position: 'absolute',
    backgroundColor: 'transparent',
    '&:focus, &:hover': {
      backgroundColor: 'transparent',
    },
    color: theme.palette.primary.main,
    bottom: '1vw',
    right: 0,
  },
  dypContainer: {
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  tableEditButton: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  tableHeader: {
    color: theme.palette.primary.main,
  },
  generalDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
    width: '80vw',
    marginBottom: '5vh',
  },
  personalDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '40vw',
    position: 'relative',
  },
  editGeneralButton: {
    position: 'absolute',
    top: '1.7vw',
    right: '1vw',
    backgroundColor: 'transparent',
    '&:focus, &:hover': {
      backgroundColor: 'transparent',
    },
    color: theme.palette.primary.main,
  },
  dataSeparator: {
    height: '30vh',
    borderRightColor: theme.palette.primary.main,
    borderRightStyle: 'solid',
    borderRightWidth: 2,
  },
  imageButton: {
    position: 'fixed',
    right: '2.5vw',
    top: 108,
    [theme.breakpoints.between('xs', 1167)]: {
      top: 75,
    },
  },
  sendContainer: {
    marginTop: '3vh',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '80vw',
  },
  sendButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    marginBottom: '3vh',
    marginLeft: '3vw',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  acceptButton: {
    backgroundColor: '#008000',
    color: '#FFFFFF',
    marginBottom: '3vh',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    margin: '3vh',
  },
  rejectButton: {
    backgroundColor: '#c81d11',
    color: '#FFFFFF',
    marginBottom: '3vh',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    margin: '3vh',
  },
  buttonArea: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  itemList: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 1vw',
    overflow: 'auto',
  },
  itemStat: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
  },
  desktopItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1vw',
    width: '20vw',
    position: 'relative',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    marginBottom: '1vw',
  },
  itemStatName: {
    color: theme.palette.primary.main,
    fontSize: '1.5vw',
    margin: 0,
  },
  itemStatValue: {
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '1.5vw',
    whiteSpace: 'pre',
    fontWeight: 'bold',
    margin: 0,
  },
  desktopItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderRadius: '2vw',
    width: '25vw',
    alignItems: 'center',
    position: 'relative',
    padding: '0 1vw 2vh',
    marginRight: '2vw',
    height: '60vh',
  },
  itemEdit: {
    color: theme.palette.primary.main,
    fontSize: '2vw',
    position: 'absolute',
    right: '2.2vw',
    bottom: 0,
  },
  itemDelete: {
    color: theme.palette.primary.main,
    fontSize: '2vw',
    position: 'absolute',
    right: 0,
    bottom: 0,
  },
  personalContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    borderRadius: '2vw',
    width: '25vw',
    alignItems: 'center',
    position: 'relative',
    padding: '0 1vw 2vh',
    height: '60vh',
    marginRight: '2vw',
  },
  personalTitle: {
    color: 'black',
    fontSize: '36px',
    marginTop: '1vw',
    marginBottom: '1vw',
    letterSpacing: 1,
    fontWeight: 'lighter',
  },
  desktopStatContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    maxWidth: '40vw',
  },
  desktopStatName: {
    color: theme.palette.primary.main,
    fontSize: '1.5vw',
    marginTop: '0.2vw',
    marginBottom: '0.2vw',
  },
  desktopStatValue: {
    color: theme.palette.primary.main,
    fontSize: '1.5vw',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'bold',
    whiteSpace: 'pre',
    marginTop: '0.2vw',
    marginBottom: '0.2vw',
  },
  budgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.main,
  },
  accordion: {
    width: '90vw',
  },
  accordionDetail: {
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  accordionIcon: {
    color: theme.palette.primary.main,
    marginRight: '4vw',
    fontSize: '10vw',
  },
  accordionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordionTitle: {
    fontSize: '7vw',
    margin: 0,
    color: theme.palette.primary.main,
  },
  transparentIcon: {
    color: 'transparent',
    fontSize: '10vw',
    marginRight: '4vw',
  },
  statsContainer: {
    position: 'relative',
    diplay: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  statContainer: {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'row',
    paddingRight: '2vh',
    with: '100%',
  },
  statName: {
    color: theme.palette.primary.main,
    fontSize: '5vw',
    margin: 0,
  },
  statValue: {
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'bold',
    fontSize: '5vw',
    margin: 0,
    width: '100%',
    whiteSpace: 'pre',
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-around',
    overflow: 'overlay',
    flexWrap: 'wrap',
  },
  budgetImageContainer: {
    paddingRight: '2vh',
    position: 'relative',
  },
  budgetImage: {
    width: '13vh',
    height: '13vh',
    [theme.breakpoints.up('md')]: {
      width: '9vw',
      height: '9vw',
    },
  },
  deleteImageIcon: {
    position: 'absolute',
    color: theme.palette.primary.main,
    right: 0,
    top: 0,
  },
  itemsContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  itemContainer: {
    padding: '2vh 5vw 2vh 5vw',
    position: 'relative',
    marginBottom: '2vh',
    borderWidth: 1,
    borderRadius: '3px',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
  },
  editBotton: {
    position: 'absolute',
    right: '8vw',
    bottom: 0,
    color: theme.palette.primary.main,
    fontSize: '7vw',
    cursor: 'pointer',
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    color: theme.palette.primary.main,
    fontSize: '7vw',
    cursor: 'pointer',
  },
  editClientButton: {
    position: 'absolute',
    right: 0,
    bottom: 0,
    color: theme.palette.primary.main,
    fontSize: '7vw',
    [theme.breakpoints.up('md')]: {
      fontSize: '3vw',
    },
  },
  addButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  addButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    marginBottom: '3vh',
    width: '30vw',
    [theme.breakpoints.up('sm')]: {
      width: '20vw',
    },
  },
  imageDiv: {
    overflowY: 'hidden',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10000,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000aa',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageDisplay: {
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
  imageCloseIconContainer: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100vw',
    height: '10vh',
  },
  closeIcon: {
    color: '#FFFFFF',
    fontSize: '9vh',
  },
  addIconContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  addIcon: {
    color: theme.palette.primary.main,
    fontSize: '13vw',
    [theme.breakpoints.up('md')]: {
      fontSize: '7vw',
    },
  },
  hiddenInput: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  receptionShow: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  dyplastContainer: {
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '5vh',
  },
  lastAccordion: {
    width: '90vw',
    marginBottom: '5vh',
  },
  button: {
    height: '4vh',
    backgroundColor: '#126da6',
    color: '#ffffff',
    '&:hover': {
      TransitionEvent: '2s',
      backgroundColor: '#ffffff',
      color: '#3c52b2',
    },
  },
  descriptionDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '40vw',
    position: 'relative',
    marginBottom: '5vh',
  },
  carContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '',
    marginBottom: '15vh',
  },
  grid:
  {
    backgroundColor: 'white',
    minHeight: '10vh',
    maxHeight: '12vh',
    marginBottom: '2vh',
    marginTop: '2vh',
  },
  table: {
    height: '20vh',
    marginBottom: '15vh',
    [theme.breakpoints.down('md')]: {
      height: '20vh',
      marginBottom: '45vh',
    },
  },
  responsiveStatContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    maxWidth: '40vw',
  },
  responsiveStatName: {
    color: theme.palette.primary.main,
    fontSize: '1.5vw',
    marginTop: '0.2vw',
    marginBottom: '0.2vw',
    [theme.breakpoints.down('md')]: {
      fontSize: '5vw',
    },
  },
  responsiveStatValue: {
    color: theme.palette.primary.main,
    fontSize: '1.5vw',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'bold',
    whiteSpace: 'pre',
    marginTop: '0.2vw',
    marginBottom: '0.2vw',
    [theme.breakpoints.down('md')]: {
      fontSize: '5vw',
    },
  },
}));

export default useBudgetShowStyle;
