import {
  ReactElement,
  useEffect,
  ChangeEvent,
} from 'react';
import {
  TextField,
  Box,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import useBudgetAppointmentStyles from '../../styles/budget_appointment';
import rutFormat from '../../helpers/rutFormat';
import mailRegex from '../../helpers/mailRegex';
import { RegisterWorkerDataInterface } from '../../interfaces/components.interfaces';
import validateRut from '../../helpers/validateRut';

function RegisterWorkerData(props: RegisterWorkerDataInterface) : ReactElement {
  const {
    name,
    setName,
    rut,
    setRut,
    mail,
    setMail,
    isNameValid,
    setIsNameValid,
    isMailValid,
    setIsMailValid,
    isRutValid,
    setIsRutValid,
    role,
    setRole,
    isRoleValid,
    setIsRoleValid,
  } = props;
  const classes = useBudgetAppointmentStyles();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRole(event.target.value);
  };

  const roles = [
    {
      value: 'worker',
      label: 'Técnico',
    },
    {
      value: 'boss',
      label: 'Jefe',
    },
  ];

  useEffect(() => {
    if (name.length > 0) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  }, [name]);

  useEffect(() => {
    if (mailRegex.test(mail)) {
      setIsMailValid(true);
    } else {
      setIsMailValid(false);
    }
  }, [mail]);

  useEffect(() => {
    if (rut.length >= 11 && validateRut(rut)) {
      setIsRutValid(true);
    } else {
      setIsRutValid(false);
    }
  }, [rut]);

  useEffect(() => {
    if (role.length >= 1) {
      setIsRoleValid(true);
    } else {
      setIsRoleValid(false);
    }
  }, [role]);

  return (
    <Box className={classes.inputsBox}>
      <TextField
        label="Nombre"
        helperText={!isNameValid ? 'Ingrese su nombre y apellido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setName(e.target.value)}
        value={name}
        className={classes.formInput}
      />
      <TextField
        label="Email"
        helperText={!isMailValid && mail.length > 0 ? 'Ingrese un mail válido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setMail(e.target.value)}
        value={mail}
        className={classes.formInput}
      />
      <TextField
        label="RUT"
        helperText={!isRutValid && rut ? 'Ingrese un RUT válido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setRut(rutFormat(e.target.value))}
        value={rut}
        className={classes.formInput}
      />
      <TextField
        select
        label="Selecciona un cargo"
        value={role}
        onChange={handleChange}
        helperText={!isRoleValid ? 'Selecciona un cargo' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        className={classes.formInput}
      >
        {roles.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
}
export default RegisterWorkerData;
