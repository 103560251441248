import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useBudgetImageDialogStyle = makeStyles((theme: Theme) => createStyles({
  closeBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    width: '42vw',
  },
  closeIcon: {
    fontSize: '4vw',
    color: theme.palette.primary.main,
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  dialogTitle: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '2vw',
    margin: 0,
  },
  imagesContainer: {
    width: '42vw',
    display: 'flex',
    padding: '2vw 0 0 2vw',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  budgetImageContainer: {
    position: 'relative',
  },
  budgetImage: {
    margin: '0 2vw 2vw 0',
    height: '8vw',
    width: '8vw',
    cursor: 'pointer',
  },
  deleteImageIcon: {
    position: 'absolute',
    cursor: 'pointer',
    color: theme.palette.primary.main,
    right: '1vw',
    top: '-1vw',
  },
  addIconContainer: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  addIcon: {
    color: theme.palette.primary.main,
    fontSize: '4vw',
    marginBottom: '2vw',
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  root:
  {
    color: 'black',
    fontSize: 36,
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
    },
  },
  footnote: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: '0vh',
  },
  ReservationImageContainer: {
    position: 'relative',
    marginTop: '1.5vh',
  },
  imagesReservationContainer: {
    width: '42vw',
    display: 'flex',
    padding: '2vw 0 0 2vw',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    [theme.breakpoints.down('md')]: {
      width: '85vw',
      padding: '2vw 0 0 2vw',
    },
  },
  addReservationImageIcon: {
    color: theme.palette.primary.main,
    fontSize: '4vw',
    marginBottom: '2vw',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      fontSize: '10vw',
      marginBottom: '1vw',
    },
  },
}));

export default useBudgetImageDialogStyle;
