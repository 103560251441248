import { ReactElement } from 'react';
import {
  Box,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from '../../styles/workOrderFilter_style';
import { WorkOrderFilterInterface } from '../../interfaces/components.interfaces';

function WorkOrderFilter(props: WorkOrderFilterInterface): ReactElement {
  const {
    deadLineSort,
    setDeadLineSort,
    nameSort,
    setNameSort,
    rutSort,
    setRutSort,
    currentProcess,
    setCurrentProcess,
    open,
    setOpen,
  } = props;
  const classes = useStyles();

  const handleChangeName = (event: React.ChangeEvent<{ value: unknown }>) => {
    setNameSort(event.target.value as string);
    setRutSort('');
    setDeadLineSort('');
  };
  const handleChangeRut = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRutSort(event.target.value as string);
    setNameSort('');
    setDeadLineSort('');
  };
  const handleChangeProcess = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrentProcess(event.target.value as string);
  };
  const handleChangeOpen = (event: React.ChangeEvent<{ value: unknown }>) => {
    setOpen(event.target.value as string);
  };
  const handleChangeDeadline = (event: React.ChangeEvent<{ value: unknown }>) => {
    setDeadLineSort(event.target.value as string);
    setNameSort('');
    setRutSort('');
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.buttonsContainer}
    >
      <Box>
        <FormControl className={classes.formControl}>
          <InputLabel id="name">Nombre</InputLabel>
          <Select
            labelId="name"
            id="demo-simple-select"
            value={nameSort}
            onChange={handleChangeName}
          >
            <MenuItem value="asc">Orden A - Z</MenuItem>
            <MenuItem value="desc">Orden Z - A</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel id="rut">RUT</InputLabel>
          <Select
            labelId="rut"
            id="demo-simple-select"
            value={rutSort}
            onChange={handleChangeRut}
          >
            <MenuItem value="asc">Orden ascendente</MenuItem>
            <MenuItem value="desc">Orden descendente</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl className={classes.formControl}>
          <InputLabel id="deadline">Plazo limite</InputLabel>
          <Select
            labelId="deadline"
            id="demo-simple-select"
            value={deadLineSort}
            onChange={handleChangeDeadline}
          >
            <MenuItem value="asc">Orden ascendente</MenuItem>
            <MenuItem value="desc">Orden descendente</MenuItem>
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel id="stage">Etapa actual</InputLabel>
          <Select
            labelId="stage"
            id="demo-simple-select"
            value={currentProcess}
            onChange={handleChangeProcess}
          >
            <MenuItem value="Todas">Todas</MenuItem>
            <MenuItem value="Desabolladura">Desabolladura</MenuItem>
            <MenuItem value="Pintura">Pintura</MenuItem>
            <MenuItem value="Armado">Armado</MenuItem>
            <MenuItem value="Pulido">Pulido</MenuItem>
            <MenuItem value="Lavado">Lavado</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <FormControl className={classes.formControl}>
          <InputLabel id="state">Estado</InputLabel>
          <Select
            labelId="state"
            id="demo-simple-select"
            value={open}
            onChange={handleChangeOpen}
          >
            <MenuItem value="Todas">Todas</MenuItem>
            <MenuItem value="true">En proceso</MenuItem>
            <MenuItem value="false">Finalizada</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </Box>

  );
}
export default WorkOrderFilter;
