import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useKPIStyles = makeStyles((theme: Theme) => createStyles({
  column: {
    width: '100%',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  row: {
    width: '100%',
    padding: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  typography: {
    padding: '20px',
    color: 'black',
    fontSize: 36,
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
      marginBottom: '5vh',
      fontSize: 34,
    },
  },
}));
export default useKPIStyles;
