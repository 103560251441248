import {
  createStyles,
  makeStyles,
} from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  table: {
    width: '80vw',
  },
}));

export default useStyles;
