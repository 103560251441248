import { ReactElement, useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { Button } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useAllBudgetsLazyQuery, BudgetsQuery, useBudgetsLazyQuery } from '../../types';
import { BudgetInterface } from '../../interfaces/components.interfaces';
import useButtonstyles from '../../styles/exportButton_style';
import { reducer } from '../../store/reducer';

function formatingData(data: BudgetsQuery['budgets']) {
  const rows: BudgetInterface[] = [];
  data.forEach((element) => {
    let price = 0;
    element.items.forEach((item) => {
      price += item.price;
    });
    element.replacements.forEach((replace) => {
      price += replace.price * replace.quantity;
    });
    const budget : BudgetInterface = {
      Id: +element.id,
      Patente: element.car.plate,
      RUT_usuario: element.user.rut,
      Nombre_usuario: element.user.name,
      RUT_cliente: element.client.rut,
      Nombre_cliente: element.client.name,
      Apellido_cliente: element.client.lastname,
      Status: element.status,
      Id_orden_trabajo: element.workOrder ? +element.workOrder.id : null,
      Id_reserva_entrega: element.entryReservation ? +element.entryReservation.id : null,
      Id_recepcion: element.reception ? +element.reception.id : null,
      Costo_total: price,
    };
    rows.push(budget);
  });
  return rows;
}

function ExportBudgets() : ReactElement {
  const classes = useButtonstyles();
  const fileName = 'Presupuestos';
  const [rowData, setRowData] = useState([]);
  const [amount, setAmount] = useState(-1);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (dataCSV: BudgetInterface[], NameFile: string) => {
    const ws = XLSX.utils.json_to_sheet(dataCSV);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, NameFile + fileExtension);
  };
  const [executeAllBudgets] = useAllBudgetsLazyQuery({
    onCompleted: (data) => {
      setAmount(data.allBudgets);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const [executeBudgets] = useBudgetsLazyQuery({
    onCompleted: (data) => {
      setRowData(formatingData(data.budgets));
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  useEffect(() => {
    if (rowData.length > 0) {
      exportToCSV(rowData, fileName);
    }
  }, [rowData]);
  useEffect(() => {
    if (amount > -1) {
      executeBudgets({
        variables: {
          page: 1,
          take: amount,
        },
      });
    }
  }, [amount]);

  return (
    <Button
      onClick={() => {
        reducer({ type: 'LOADING' });
        executeAllBudgets();
      }}
      className={classes.pageButtonActive}
    >
      Datos de los presupuestos
    </Button>
  );
}
export default ExportBudgets;
