import { ReactElement, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
} from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';
import { CarDialogInterface } from '../../interfaces/components.interfaces';
import useItemDialogStyles from '../../styles/item_dialog';
import { reducer } from '../../store/reducer';
import { useUpdateBudgetMutation } from '../../types';
import { twoLettersPlateRegex, fourLettersPlateRegex } from '../../helpers/plateRegex';

function CarDialog(props: CarDialogInterface): ReactElement {
  const classes = useItemDialogStyles();
  const {
    open,
    setOpen,
    car,
    setCar,
    budget,
    setBudget,
    onClose,
  } = props;
  const [isPlateValid, setIsPlateValid] = useState<boolean>(false);
  const [executeUpdateBudget] = useUpdateBudgetMutation({
    variables: {
      input: {
        budgetID: +budget.id,
        personalData: {
          phoneNumber: budget.client.phone,
          name: budget.client.name,
          lastname: budget.client.lastname,
          rut: budget.client.rut,
          email: budget.client.email,
          plate: car.plate,
          brand: car.brand,
          model: car.model,
          color: car.color,
          year: car.year,
        },
        sendAuto: false,
      },
    },
    onCompleted: (data) => {
      reducer({ type: 'LOADING' });
      setBudget({
        ...budget,
        ...data.updateBudget,
      });
      setOpen(false);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  useEffect(() => {
    if (car.plate && car.plate.length === 6 && fourLettersPlateRegex.test(car.plate)) {
      setIsPlateValid(true);
    } else if (car.plate && car.plate.length === 4 && twoLettersPlateRegex.test(car.plate)) {
      setIsPlateValid(true);
    } else {
      setIsPlateValid(false);
    }
  }, [car.plate]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <div className={classes.closeBox}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => {
            setCar(budget.car);
            setOpen(false);
          }}
        />
      </div>
      <DialogTitle className={classes.dialogTitle}>Editar</DialogTitle>
      <div className={classes.formContainer}>
        <TextField
          label="Marca"
          className={classes.formInput}
          helperText={car.brand ? '\u00a0' : 'Ingrese una marca'}
          FormHelperTextProps={{ className: classes.helperText }}
          value={car.brand}
          onChange={(e) => {
            setCar({
              ...car,
              brand: e.target.value.toUpperCase(),
            });
          }}
        />
        <TextField
          label="Modelo"
          className={classes.formInput}
          helperText={car.model ? '\u00a0' : 'Ingrese un modelo'}
          FormHelperTextProps={{ className: classes.helperText }}
          value={car.model}
          onChange={(e) => {
            setCar({
              ...car,
              model: e.target.value.toUpperCase(),
            });
          }}
        />
        <TextField
          label="Año"
          type="number"
          className={classes.formInput}
          value={car.year > 0 ? car.year : ''}
          helperText={car.year ? '\u00a0' : 'Ingrese un año'}
          FormHelperTextProps={{ className: classes.helperText }}
          onChange={(e) => {
            setCar({
              ...car,
              year: e.target.value ? +e.target.value : -1,
            });
          }}
        />
        <TextField
          label="Patente"
          className={classes.formInput}
          helperText={isPlateValid ? '\u00a0' : 'Ingrese una patente válida'}
          FormHelperTextProps={{ className: classes.helperText }}
          value={car.plate}
          onChange={(e) => {
            setCar({
              ...car,
              plate: e.target.value.toUpperCase(),
            });
          }}
        />
        <TextField
          label="Número de chasis"
          className={classes.formInput}
          helperText={car.chasis ? '\u00a0' : 'Ingrese un número de chasis'}
          FormHelperTextProps={{ className: classes.helperText }}
          value={car.chasis}
          onChange={(e) => {
            setCar({
              ...car,
              chasis: e.target.value,
            });
          }}
        />
        <Button
          variant="contained"
          className={(
            isPlateValid
            && car.brand
            && car.year > 0
            && car.model
            && car.chasis)
            ? classes.saveButton
            : classes.saveButtonDisabled}
          onClick={() => {
            if (
              isPlateValid
              && car.brand
              && car.year > 0
              && car.model
              && car.chasis) {
              reducer({ type: 'LOADING' });
              executeUpdateBudget();
            }
          }}
        >
          Guardar
        </Button>
      </div>
    </Dialog>
  );
}

export default CarDialog;
