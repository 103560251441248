import { ReactElement, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Alert from '@material-ui/lab/Alert';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Loader from '../../components/loader/loader';
import { useGetWorkOrderByFolioLazyQuery } from '../../types';
import useStyles from '../../styles/tracing_style';

function TracingToken() : ReactElement {
  const [deadLine, setDeadLine] = useState('');
  const [plate, setPlate] = useState('');
  const [currentProcess, setCurrentProcess] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [color, setColor] = useState('');
  const [year, setYear] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const { token }: { token: string } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [chasis, setChasis] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const classes = useStyles();
  const fases: { [fase: string]: string } = {
    dent: 'Desabolladura',
    wash: 'Lavado',
    assemble: 'Armado',
    polish: 'Pulido',
    paint: 'Pintura',
    thirdpartyservice: 'Servicios a terceros',
  };
  const fasesSteps : string[] = [
    'Desabolladura',
    'Pintura',
    'Armado',
    'Servicios a terceros',
    'Mecánica',
    'Pulido',
    'Lavado',
  ];
  const ids: { [id: string]: string } = {
    7: '1',
    6: '2',
    5: '3',
    4: '4',
    3: '5',
    2: '6',
    1: '7',
  };

  const [executeTracing] = useGetWorkOrderByFolioLazyQuery({
    onCompleted: (data) => {
      setPlate(data.getWorkOrderByFolio.budget.car.plate);
      setDeadLine(data.getWorkOrderByFolio.deadline);
      setBrand(data.getWorkOrderByFolio.budget.car.brand);
      setModel(data.getWorkOrderByFolio.budget.car.model);
      setColor(data.getWorkOrderByFolio.budget.car.color);
      setYear(data.getWorkOrderByFolio.budget.car.year);
      setChasis(data.getWorkOrderByFolio.budget.car.chasis);
      setCurrentProcess(data.getWorkOrderByFolio.currentProcess.name);
      if ((+ids[data.getWorkOrderByFolio.currentProcess.id]) > 1) {
        setActiveStep((+ids[data.getWorkOrderByFolio.currentProcess.id]) - 1);
      }
      setIsLoading(false);
    },
    onError: () => {
      setAlert(true);
      setIsLoading(false);
    },
  });

  useEffect(() => {
    try {
      const { folio, rut } = jwt_decode<{ folio: string, rut: string }>(token);
      if (folio && rut) {
        setIsLoading(true);
        executeTracing({
          variables: {
            rut,
            folio,
          },
        });
      } else {
        setIsLoading(false);
        setAlertContent('Enlace invalido');
        setAlert(true);
      }
    } catch {
      setAlertContent('Enlace invalido');
      setAlert(true);
    }
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (alert) {
    return <Alert severity="error">{alertContent}</Alert>;
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h2" className={classes.typography}>
        Seguimiento de tu vehículo
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.secondBox}
      >
        <form>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <TableContainer component={Paper} className={classes.table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Datos</TableCell>
                    <TableCell align="left">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Patente
                    </TableCell>
                    <TableCell>
                      {plate}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Marca
                    </TableCell>
                    <TableCell>
                      {brand}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Modelo
                    </TableCell>
                    <TableCell>
                      {model}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Color
                    </TableCell>
                    <TableCell>
                      {color}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Año
                    </TableCell>
                    <TableCell>
                      {year}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Chasis
                    </TableCell>
                    <TableCell>
                      {chasis}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

          </Box>
        </form>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.stepBox}
        >
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel className={classes.steps}>
              {fasesSteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className={classes.text}>
              <p>
                Tu vehículo de encuentra actualmente en &nbsp;
                <b><strong>{fases[currentProcess]}</strong></b>
              </p>
              <p>
                Fecha estimada de finalización &nbsp;
                {deadLine}
              </p>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
export default TracingToken;
