import { useState, ReactElement, useEffect } from 'react';
import {
  Button,
  Grid,
  Box,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useLoginStyles from '../../styles/login';
import useLocalStorage from '../../helpers/useLocalStorage';
import logo from '../../assets/images/quelle.png';
import { reducer, initialState } from '../../store/reducer';
import validateRut from '../../helpers/validateRut';
import { useLoginAdminMutation } from '../../types';
import LoginForm from '../../components/forms/login';

function Login(): ReactElement {
  const [rut, setRut] = useState('');
  const [isRutValid, setIsRutValid] = useState(false);
  const [password, setPassword] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [state, setState] = useLocalStorage('state', initialState);
  const classes = useLoginStyles();
  const [executeLogin] = useLoginAdminMutation({
    variables: {
      rut,
      password,
    },
    onCompleted: (data) => {
      setState({
        ...state,
        token: data.loginAdmin.token,
      });
      reducer({ type: 'LOADING' });
      reducer({ type: 'LOG_IN', data: { token: state.token } });
      if (!data.loginAdmin.user.changedPassword) {
        window.location.href = '/admin/cambio-clave';
      } else {
        window.location.href = '/';
      }
    },
    onError: () => {
      setAlertContent('No se pudo iniciar sesión.');
      setAlert(true);
    },
  });

  useEffect(() => {
    if (rut.length >= 11 && validateRut(rut)) {
      setIsRutValid(true);
    } else {
      setIsRutValid(false);
    }
  }, [rut]);

  function submit() {
    if (isPasswordValid && isRutValid) {
      executeLogin();
    }
  }

  return (
    <div className={classes.loginContainer}>
      {alert ? <Alert className={classes.alert} severity="error">{alertContent}</Alert> : <></> }
      <img alt="" src={logo} className={classes.logo} />
      <Grid
        container
        justify="center"
        alignItems="center"
        direction="column"
      >
        <Box
          className={classes.contentBox}
        >
          <LoginForm
            rut={rut}
            setRut={setRut}
            isRutValid={isRutValid}
            password={password}
            setPassword={setPassword}
            isPasswordValid={isPasswordValid}
            setIsPasswordValid={setIsPasswordValid}
          />
          <Button
            variant="contained"
            onClick={() => submit()}
            className={(isRutValid && isPasswordValid)
              ? classes.pageButtonActive
              : classes.pageButton}
          >
            Ingresar
          </Button>
        </Box>
      </Grid>
    </div>
  );
}

export default Login;
