import {
  Dialog,
  DialogTitle,
  Box,
  Button,
} from '@material-ui/core';
import {
  ReactElement,
  useRef,
  useState,
  useEffect,
} from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';
import Resizer from 'react-image-file-resizer';
import useImageDialogStyles from '../../styles/image_dialog';
import { ImageDialogInterface } from '../../interfaces/components.interfaces';

function ImageDialog(props: ImageDialogInterface): ReactElement {
  const {
    open,
    setOpen,
    onClose,
    files,
    setFiles,
    title,
  } = props;
  const classes = useImageDialogStyles();
  const hiddenInput = useRef(null);
  const dialogRef = useRef(null);
  const [badImages, setBadImages] = useState(0);

  const resizeImage = (image: File) => new Promise<File>((resolve) => {
    if (image.name.split('.').pop() !== 'pdf') {
      Resizer.imageFileResizer(
        image,
        600,
        600,
        'JPEG',
        70,
        0,
        (uri) => {
          resolve(uri as File);
        },
        'file',
      );
    } else {
      resolve(image);
    }
  });

  async function imagesResizer(images: File[]) {
    const resizePromises: Promise<File>[] = [];
    const filteredImages = images.filter(
      (image) => ['jpg', 'jpeg', 'pdf', 'png'].includes(image.name.split('.').pop()),
    );
    filteredImages.forEach((image) => {
      resizePromises.push(
        resizeImage(image),
      );
    });
    const resizedImages = await Promise.all(resizePromises);
    setBadImages(images.length - filteredImages.length);
    setFiles([...files, ...resizedImages]);
  }

  useEffect(() => {
    setBadImages(0);
  }, [open]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth={false}
      ref={dialogRef}
    >
      <Box
        className={classes.closeBox}
      >
        <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
      </Box>
      {badImages > 0 && (
        <Alert severity="error">
          {`${badImages} imagenes no fueron subidas por formato erroneo`}
        </Alert>
      )}
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <Box
        className={classes.imagesBox}
      >
        {(files.length === 0) && (
          <p className={classes.dialogDescription}>Agregue una imagen</p>
        )}
        {files.map((file, index) => (
          <Box
            className={classes.imageBox}
            key={String(index)}
          >
            {file.name.split('.').pop() === 'pdf' ? (
              <PictureAsPdfIcon className={classes.image} />
            ) : (
              <img alt="" src={URL.createObjectURL(file)} className={classes.image} />
            )}
            <p className={classes.fileName}>
              {file.name}
            </p>
            <DeleteOutlineIcon
              className={classes.deleteIcon}
              onClick={() => {
                files.splice(index, 1);
                setFiles([...files]);
              }}
            />
          </Box>
        ))}
      </Box>
      <Box
        className={classes.addBox}
      >
        <AddCircleOutlineIcon
          className={classes.addIcon}
          onClick={() => hiddenInput.current.click()}
        />
        <input
          ref={hiddenInput}
          accept="image/*"
          type="file"
          multiple
          onChange={(e) => imagesResizer([...e.target.files])}
          className={classes.hiddenInput}
        />
      </Box>
      <div className={classes.addBox}>
        <Button
          className={classes.readyButton}
          onClick={() => {
            setOpen(false);
          }}
        >
          Listo
        </Button>
      </div>
    </Dialog>
  );
}

export default ImageDialog;
