import { ReactElement, useEffect } from 'react';
import { TextField, Box, FormHelperText } from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ImageDialog from '../image_dialog/image_dialog';
import ChasisDialog from '../item_dialog/chasis_dialog';
import { CarDataInterface } from '../../interfaces/components.interfaces';
import useBudgetAppointmentStyles from '../../styles/budget_appointment';
import { twoLettersPlateRegex, fourLettersPlateRegex } from '../../helpers/plateRegex';

function CarData(props: CarDataInterface): ReactElement {
  const {
    carBrand,
    setCarBrand,
    carModel,
    setCarModel,
    carPlate,
    setCarPlate,
    isCarPlateValid,
    setIsCarPlateValid,
    chasisNumber,
    setChasisNumber,
    carColor,
    setCarColor,
    openCirculation,
    setOpenCirculation,
    circulationFiles,
    setCirculationFiles,
    carYear,
    setCarYear,
    nextClicked,
  } = props;
  const classes = useBudgetAppointmentStyles();

  function formatPlate(plate: string): string {
    return plate.toUpperCase().replace('-', '').substring(0, 6);
  }

  useEffect(() => {
    if (carPlate.length === 6 && fourLettersPlateRegex.test(carPlate)) {
      setIsCarPlateValid(true);
    } else if (carPlate.length === 4 && twoLettersPlateRegex.test(carPlate)) {
      setIsCarPlateValid(true);
    } else {
      setIsCarPlateValid(false);
    }
  }, [carPlate]);

  return (
    <Box className={classes.inputsBox}>
      <div className={classes.formTitle}>
        Ingrese los datos de su vehículo
      </div>
      <TextField
        label="Marca del auto"
        helperText={(carBrand.length === 0 && nextClicked) ? 'Ingrese la marca del auto' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setCarBrand(e.target.value.toUpperCase())}
        className={classes.formInput}
        value={carBrand}
      />
      <TextField
        label="Modelo del auto"
        helperText={(carModel.length === 0 && nextClicked) ? 'Ingrese el modelo del auto' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setCarModel(e.target.value.toUpperCase())}
        className={classes.formInput}
        value={carModel}
      />
      <TextField
        label="Patente (ej. XXYY22)"
        helperText={(!isCarPlateValid && carPlate.length > 0) ? 'Ingrese una patente válida' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setCarPlate(formatPlate(e.target.value))}
        className={classes.formInput}
        value={carPlate}
        disabled
      />
      <TextField
        label="Año"
        type="number"
        helperText={((carYear < 1960
          || carYear > new Date().getFullYear()) && nextClicked) ? 'Ingrese una año valido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setCarYear(+e.target.value)}
        className={classes.formInput}
        value={carYear || null}
      />
      <TextField
        label="Numero de chasis"
        helperText={(chasisNumber.length === 0 && nextClicked) ? 'Ingrese el numero de chasis del auto' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setChasisNumber(e.target.value)}
        className={classes.formInput}
        value={chasisNumber}
        InputProps={{
          endAdornment: (
            <ChasisDialog />
          ),
        }}
      />
      <TextField
        label="Color"
        helperText={(carColor.length === 0 && nextClicked) ? 'Ingrese el color del auto' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setCarColor(e.target.value.toUpperCase())}
        className={classes.formInput}
        value={carColor}
      />
      <div className={classes.uploadContainer}>
        <div
          onClick={() => setOpenCirculation(true)}
          className={circulationFiles.length > 0 ? classes.uploadButtonActive : classes.uploadButton}
        >
          <p className={classes.uploadTitle}>
            Permiso circulación
          </p>
          {(circulationFiles.length > 0)
            ? (
              <div className={classes.filesCount}>
                <div className={classes.count}>
                  {circulationFiles.length}
                </div>
              </div>
            ) : <CameraAltIcon className={classes.uploadIcon} />}
        </div>
        <FormHelperText className={classes.helperText}>
          {(circulationFiles.length > 0 || !nextClicked) ? '\u00a0' : 'Agregue una imagen de su permiso'}
        </FormHelperText>
        <ImageDialog
          files={circulationFiles}
          open={openCirculation}
          setOpen={setOpenCirculation}
          onClose={() => setOpenCirculation(false)}
          setFiles={setCirculationFiles}
          title="Permiso Circulación"
        />
      </div>
    </Box>
  );
}

export default CarData;
