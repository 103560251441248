import {
  makeStyles,
  createStyles,
  Theme,
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    height: '70vh',
    width: '80vw',

  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  secondBox: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  pageButtonActive: {
    display: 'inlineBlock',
    background: '#115fa6',
    color: '#f5f5f5',
    '&:hover': {
      background: '#f5f5f5',
      color: '#115fa6',
    },
  },
  buttonsContainer: {
    width: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

export default useStyles;
