import { ReactElement, useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { Button } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { useEntryReservationsLazyQuery, EntryReservationsQuery, useAllEntryReservationsLazyQuery } from '../../types';
import { EntryReservationsInterface } from '../../interfaces/components.interfaces';
import useButtonstyles from '../../styles/exportButton_style';
import { reducer } from '../../store/reducer';

function dateFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  const year: number = date.getFullYear();
  const month:number = (date.getMonth());
  const day: number = date.getDate();
  return moment(new Date(year, month, day)).format('DD/MM/YYYY');
}

function formatingData(data: EntryReservationsQuery['entryReservations']) {
  const rows: EntryReservationsInterface[] = [];
  data.forEach((element) => {
    const reservation: EntryReservationsInterface = {
      Id: +element.id,
      Día: dateFormat(element.date),
      Descripción: element.description,
    };
    rows.push(reservation);
  });
  return rows;
}

function ExportReservedEntry() : ReactElement {
  const classes = useButtonstyles();
  const fileName = 'Reserva entrega vehículo';
  const [rowData, setRowData] = useState([]);
  const [amount, setAmount] = useState(-1);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (dataCSV: EntryReservationsInterface[], NameFile: string) => {
    const ws = XLSX.utils.json_to_sheet(dataCSV);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, NameFile + fileExtension);
  };
  const [executeEntryReservation] = useEntryReservationsLazyQuery({
    onCompleted: (data) => {
      setRowData(formatingData(data.entryReservations));
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  const [executeAllEntry] = useAllEntryReservationsLazyQuery({
    onCompleted: (data) => {
      setAmount(data.allEntryReservations);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  useEffect(() => {
    if (rowData.length > 0) {
      exportToCSV(rowData, fileName);
    }
  }, [rowData]);
  useEffect(() => {
    if (amount > -1) {
      executeEntryReservation({
        variables: {
          page: 1,
          take: amount,
        },
      });
    }
  }, [amount]);

  return (
    <Button
      onClick={() => {
        reducer({ type: 'LOADING' });
        executeAllEntry();
      }}
      className={classes.pageButtonActive}
    >
      Datos de reserva de recepción
    </Button>
  );
}
export default ExportReservedEntry;
