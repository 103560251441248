import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useDeleteDialogStyle = makeStyles((theme: Theme) => createStyles({
  deleteMessage: {
    color: theme.palette.primary.main,
  },
  buttonsContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  leftButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    borderTopColor: theme.palette.primary.main,
    borderTopWidth: 0.5,
    borderTopStyle: 'solid',
    borderRightColor: theme.palette.primary.main,
    borderRightWidth: 0.5,
    borderRightStyle: 'solid',
  },
  rightButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50%',
    borderTopColor: theme.palette.primary.main,
    borderTopWidth: 0.5,
    borderTopStyle: 'solid',
  },
  optionMessage: {
    color: theme.palette.primary.main,
    margin: 0,
    fontSize: '7vw',
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
  },
}));

export default useDeleteDialogStyle;
