import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  above: {
    width: '360px',
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2vh',
    height: '300px',
    padding: '2vw',
    marginInline: '2vw',
  },
  lower: {
    width: '360px',
    display: 'inline-block',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2vh',
    height: '300px',
    padding: '2vw',
    marginInline: '2vw',
  },
  grid: {
    marginTop: '13vh',
    justifyContent: 'center',
    alignContent: 'center',
    display: 'flex',
  },
  headerCard: {
    justifyContent: 'center',
    display: 'flex',
  },
  budgetContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    color: theme.palette.primary.main,
  },
  accordion: {
    width: '90vw',
  },
  accordionDetail: {
    overflow: 'hidden',
  },
  accordionIcon: {
    color: theme.palette.primary.main,
    marginRight: '4vw',
    fontSize: '10vw',
  },
  accordionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  accordionTitle: {
    fontSize: '7vw',
    margin: 0,
    color: theme.palette.primary.main,
  },
  transparentIcon: {
    color: 'transparent',
    fontSize: '10vw',
    marginRight: '4vw',
  },
  statsContainer: {
    diplay: 'flex',
    flexDirection: 'column',
  },
  statContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    with: '100%',
  },
  statName: {
    color: theme.palette.primary.main,
    fontSize: '5vw',
    margin: 0,
  },
  statValue: {
    color: theme.palette.primary.main,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    maxWidth: '25vw',
    fontWeight: 'bold',
    fontSize: '5vw',
    margin: 0,
    width: '100%',
    whiteSpace: 'pre-wrap',
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  budgetImage: {
    width: '15vh',
    height: '15vh',
    marginRight: '2vw',
  },
  itemContainer: {
    paddingTop: '2vh',
    position: 'relative',
    width: '100%',
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  dataSeparator: {
    height: '30vh',
    borderRightColor: theme.palette.primary.main,
    borderRightStyle: 'solid',
    borderRightWidth: 2,
  },
  dataSeparatorHorizontal: {
    width: '30vw',
    height: '1vh',
    borderRightColor: theme.palette.primary.main,
    borderRightStyle: 'solid',
    borderRightWidth: 2,
  },
  personalDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '40vw',
    position: 'relative',
  },
  personalTitle: {
    color: theme.palette.primary.main,
    fontSize: '3vw',
    marginTop: '1vw',
    marginBottom: '1vw',
    fontWeight: 'bold',
  },
  desktopStatContainer: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    maxWidth: '40vw',
  },
  desktopStatName: {
    color: theme.palette.primary.main,
    fontSize: '1.5vw',
    marginTop: '0.2vw',
    marginBottom: '0.2vw',
  },
  desktopStatValue: {
    color: theme.palette.primary.main,
    fontSize: '1.5vw',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 'bold',
    whiteSpace: 'pre',
    marginTop: '0.2vw',
    marginBottom: '0.2vw',
  },
  desktopContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
  },
  generalDataContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    alignItems: 'center',
    width: '80vw',
    marginBottom: '5vh',
  },
  imageDiv: {
    overflowY: 'hidden',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10000,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000aa',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageCloseIconContainer: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100vw',
    height: '10vh',
  },
  imageDisplay: {
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
  hiddenInput: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  closeIcon: {
    color: '#FFFFFF',
    fontSize: '9vh',
  },
  imageButton: {
    position: 'fixed',
    right: '2.5vw',
    top: 108,
    [theme.breakpoints.between('xs', 1167)]: {
      top: 75,
    },
  },
  reservationImageContainer: {
    paddingRight: '2vh',
    position: 'relative',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export default useStyles;
