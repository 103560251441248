import { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarCrash, faUserCog, faTools } from '@fortawesome/free-solid-svg-icons';
import CarouselSlide from '../../components/carousel/Carousel';
import useStyles from '../../styles/home';

function Index(): ReactElement {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.grid}
    >
      <CarouselSlide />
      <Grid item className={classes.item}>
        <Typography variant="h2" className={classes.root}>
          ¿Quiénes somos?
        </Typography>
        <p className={classes.text}>
          QUELLE es una empresa de servicio automotriz integral, que inicia sus actividades en el mes de
          febrero de 1982. La empresa cuenta con una infraestructura de más de 3.000 metros cuadrados, en los
          cuales se distribuyen los servicios de Mecánica Multimarca, Lubricentro, Bosch Car Service,
          Desabolladura y Pintura, Venta de Repuestos, Venta de neumáticos, Servicio Rent a Car y Conversión a gas.
          Para lograr los objetivos y metas de la empresa, clientes satisfechos, se han realizado alianzas estratégicas
          con Bosch, PPG y Compañías de Seguros, entre otros. Además se ha invertido en infraestructura y maquinarias
          especializadas.
        </p>
      </Grid>
      <Grid item className={classes.item}>
        <Divider />
      </Grid>
      <Grid item className={classes.item}>
        <Typography variant="h2" className={classes.root}>
          Nuestros Servicios
        </Typography>
        <Box className={classes.box}>
          <Box className={classes.boxItem}>
            <FontAwesomeIcon icon={faTools} className={classes.icon} />
            <Typography className={classes.services}>
              Mecánica
            </Typography>
            <p className={classes.text}>
              Servicio integral de calidad y eficiencia Equipos y maquinarias especializadas y personal capacitado.
            </p>
          </Box>
          <Box className={classes.boxItem}>
            <FontAwesomeIcon icon={faCarCrash} className={classes.icon} />
            <Typography className={classes.services}>
              Desabolladura y pintura
            </Typography>
            <p className={classes.text}>
              Cabinas de pintura, laboratorio de pintura, maquinaria de estiraje de carrocería, máquina de
              desabolladura por punto, insumos PPG, entre otros.
            </p>
          </Box>
          <Box className={classes.boxItem}>
            <FontAwesomeIcon icon={faUserCog} className={classes.icon} />
            <Typography className={classes.services}>
              Lubricentro
            </Typography>
            <p className={classes.text}>
              Utilizamos aceites, insumos y repuestos de calidad de las marcas Mobil, Valvoline, Bosch y Wurth.
              Servicio en tiempo optimo.
            </p>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Index;
