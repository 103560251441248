import {
  ReactElement,
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import BuildIcon from '@material-ui/icons/Build';
import Table from '@material-ui/core/Table';
import Alert from '@material-ui/lab/Alert';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Fab from '@material-ui/core/Fab';
import { useParams } from 'react-router-dom';
import ReplacementDialog from '../../components/item_dialog/replacement_dialog';
import ItemDialog from '../../components/item_dialog/item_dialog';
import DeleteDialog from '../../components/item_dialog/delete_dialog';
import DeleteReplacementDialog from '../../components/item_dialog/delete_replacement_dialog';
import ClientDialog from '../../components/item_dialog/personal_dialog';
import CarDialog from '../../components/item_dialog/car_dialog';
import DeleteImageDialog from '../../components/item_dialog/delete_image_dialog';
import {
  useGetBudgetLazyQuery,
  GetBudgetQuery,
  useGetPiecesLazyQuery,
  GetPiecesQuery,
  useSignS3Mutation,
  useCreateBudgetImageMutation,
  useGetConceptsLazyQuery,
  useGetInsurancesLazyQuery,
  GetInsurancesQuery,
  GetConceptsQuery,
  BudgetStatusOption,
  useGetEntryReservationByBudgetLazyQuery,
  useSendBudgetMutation,
} from '../../types';
import ImageDialog from '../../components/item_dialog/image_dialog';
import useLoginStyles from '../../styles/folio_style';
import useBudgetShowStyles from '../../styles/budget_show';
import imagesUploadHandler from '../../helpers/imagesUploadHandler';
import { reducer } from '../../store/reducer';
import AcceptAdminDialog from '../../components/budget_dialog/acceptAdminBudget';
import RejectAdminDialog from '../../components/budget_dialog/rejectAdminBudget';
import PendingAdminDialog from '../../components/budget_dialog/pendingAdminBudget';
import DeleteAdminDialog from '../../components/budget_dialog/deleteAdminBudget';
import Loader from '../../components/loader/loader';

const initialBudget: GetBudgetQuery['getBudget'] = {
  id: '',
  client: {
    name: '',
    lastname: '',
    rut: '',
    email: '',
    phone: '',
  },
  car: {
    brand: '',
    plate: '',
    model: '',
    chasis: '',
    color: '',
    year: 0,
  },
  images: [],
  items: [],
  replacements: [],
  status: BudgetStatusOption.Pendiente,
};

const initialItem = {
  id: '',
  piece: {
    id: '',
  },
  concept: {
    id: '',
  },
  price: -1,
};

const initialReplacement = {
  id: '',
  piece: {
    id: '',
  },
  quantity: -1,
  price: -1,
};

function BudgetShow(): ReactElement {
  const classes = useBudgetShowStyles();
  const loginClasses = useLoginStyles();
  const { budgetId }: { budgetId:string } = useParams();
  const [budget, setBudget] = useState<GetBudgetQuery['getBudget']>(initialBudget);
  const [actualItem, setActualItem] = useState<GetBudgetQuery['getBudget']['items'][0]>(initialItem);
  const [
    actualReplacement,
    setActualReplacement,
  ] = useState<GetBudgetQuery['getBudget']['replacements'][0]>(initialReplacement);
  const [dentPieces, setDentPieces] = useState<GetPiecesQuery['getPieces']>([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paintPieces, setPaintPieces] = useState<GetPiecesQuery['getPieces']>([]);
  const [mechanicsPieces, setMechanicsPieces] = useState<GetPiecesQuery['getPieces']>([]);
  const [thirdPartyPieces, setThirdPartyPieces] = useState<GetPiecesQuery['getPieces']>([]);
  const [replacements, setReplacements] = useState<GetPiecesQuery['getPieces']>([]);
  const [openItemDialog, setOpenItemDialog] = useState<boolean>(false);
  const [openClientDialog, setOpenClientDialog] = useState<boolean>(false);
  const [openCarDialog, setOpenCarDialog] = useState<boolean>(false);
  const [actualImageId, setActualImageId] = useState<number>(0);
  const [openDeleteImageDialog, setOpenDeleteImageDialog] = useState<boolean>(false);
  const [editClient, setEditClient] = useState<GetBudgetQuery['getBudget']['client']>({});
  const [dentConcepts, setDentConcepts] = useState<GetConceptsQuery['getConcepts']>([]);
  const [paintConcepts, setPaintConcepts] = useState<GetConceptsQuery['getConcepts']>([]);
  const [mechanicsConcepts, setMechanicsConcepts] = useState<GetConceptsQuery['getConcepts']>([]);
  const [thirdPartyConcepts, setThirdPartyConcepts] = useState<GetConceptsQuery['getConcepts']>([]);
  const [editCar, setEditCar] = useState<GetBudgetQuery['getBudget']['car']>({});
  const [openReplacementDialog, setOpenReplacementDialog] = useState<boolean>(false);
  const [alert, setAlert] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);
  const [openDeleteReplacementDialog, setOpenDeleteReplacementDialog] = useState<boolean>(false);
  const [actualImage, setActualImage] = useState<string>('');
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openImageDisplay, setOpenImageDisplay] = useState<boolean>(false);
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [insurances, setInsurances] = useState<GetInsurancesQuery['insurances']>([]);
  const [mobile, setMobile] = useState<boolean>(window.innerWidth < 850);
  const [reserved, setReserved] = useState(0);
  const hiddenInputRef = useRef(null);

  const resizeView = () => {
    if (window.innerWidth < 870) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const [sendBudget] = useSendBudgetMutation({
    onCompleted: () => {
      setAlert(true);
      reducer({ type: 'LOADING' });
      setHasChanged(false);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const [executeGetInsurances] = useGetInsurancesLazyQuery({
    onCompleted: (data) => {
      setInsurances(data.insurances);
    },
  });
  const [executeEntryRecervation] = useGetEntryReservationByBudgetLazyQuery({
    variables: {
      budgetId: +budgetId,
    },
    onCompleted: () => {
      setReserved(1);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  const [executeSignS3] = useSignS3Mutation({
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  const [executeCreateBudgetImage] = useCreateBudgetImageMutation({
    onCompleted: (data) => {
      setBudget({
        ...budget,
        images: [
          ...budget.images,
          data.createBudgetImage,
        ],
      });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const [executeGetBudget, { loading }] = useGetBudgetLazyQuery({
    variables: {
      id: +budgetId,
    },
    onCompleted: (data) => {
      setBudget(data.getBudget);
      let total = 0;
      data.getBudget.items.forEach((item) => {
        total += item.price;
      });
      data.getBudget.replacements.forEach((replacement) => {
        total += replacement.price && replacement.quantity ? replacement.price * replacement.quantity : 0;
      });
      setTotalPrice(total);
      setEditClient(data.getBudget.client);
      setEditCar(data.getBudget.car);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const [executeGetDentConcepts] = useGetConceptsLazyQuery({
    variables: {
      stage: 'dent',
    },
    onCompleted: (data) => {
      setDentConcepts(data.getConcepts);
    },
  });

  const [executeGetPaintConcepts] = useGetConceptsLazyQuery({
    variables: {
      stage: 'paint',
    },
    onCompleted: (data) => {
      setPaintConcepts(data.getConcepts);
    },
  });

  const [executeGetMechanicsConcepts] = useGetConceptsLazyQuery({
    variables: {
      stage: 'mechanics',
    },
    onCompleted: (data) => {
      setMechanicsConcepts(data.getConcepts);
    },
  });

  const [executeGetThirdPartyConcepts] = useGetConceptsLazyQuery({
    variables: {
      stage: 'thirdPartyService',
    },
    onCompleted: (data) => {
      setThirdPartyConcepts(data.getConcepts);
    },
  });

  const [executeGetDentPieces] = useGetPiecesLazyQuery({
    variables: {
      stage: 'dent',
    },
    onCompleted: (data) => {
      setDentPieces(data.getPieces);
    },
  });

  const [executeGetPaintPieces] = useGetPiecesLazyQuery({
    variables: {
      stage: 'paint',
    },
    onCompleted: (data) => {
      setPaintPieces(data.getPieces);
    },
  });

  const [executeGetMechanicsPieces] = useGetPiecesLazyQuery({
    variables: {
      stage: 'mechanics',
    },
    onCompleted: (data) => {
      setMechanicsPieces(data.getPieces);
    },
  });

  const [executeGetThirdPartyPieces] = useGetPiecesLazyQuery({
    variables: {
      stage: 'thirdPartyService',
    },
    onCompleted: (data) => {
      setThirdPartyPieces(data.getPieces);
    },
  });

  const [executeGetReplacements] = useGetPiecesLazyQuery({
    variables: {
      stage: 'replacement',
    },
    onCompleted: (data) => {
      setReplacements(data.getPieces);
    },
  });

  useEffect(() => {
    executeGetBudget();
  }, []);

  useEffect(() => {
    executeGetDentPieces();
    executeGetPaintPieces();
    executeGetMechanicsPieces();
    executeGetThirdPartyPieces();
    executeGetReplacements();
    executeGetDentConcepts();
    executeGetPaintConcepts();
    executeGetMechanicsConcepts();
    executeGetThirdPartyConcepts();
    executeGetInsurances();
    executeEntryRecervation();
  }, []);

  useEffect(() => {
    resizeView();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeView);
  }, []);
  const handleReservation = () => {
    window.location.href = `/admin/agendar-recepcion/${budget.id}`;
  };

  if (mobile) {
    return (
      <div className={classes.budgetContainer}>
        {loading && <Loader />}
        {alert && <Alert className={loginClasses.alert} severity="success">Se ha enviado mail correctamente</Alert>}
        {hasChanged && (
          <Alert severity="warning">
            Has cambiado el presupuesto, recuerda volver a enviarlo.
          </Alert>
        )}
        {openImageDisplay && (
          <div className={classes.imageDiv}>
            <div className={classes.imageCloseIconContainer}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setOpenImageDisplay(false)}
              />
            </div>
            <img
              alt=""
              src={actualImage}
              className={classes.imageDisplay}
            />
          </div>
        )}
        <h1 className={classes.title}>Presupuesto</h1>
        <div className={classes.statusContainer}>
          <h1 className={classes.title}>{`Estado: ${budget.status}`}</h1>
          <h1 className={classes.title}>{`Total: $${totalPrice.toLocaleString('de-DE')}`}</h1>
          {(budget.insurance && budget.status === 'PENDIENTE') && (
            <div className={classes.budgetContainer}>
              <h1 className={classes.title}>¿Confirmar?</h1>
              <div className={classes.buttonArea}>
                <AcceptAdminDialog
                  id={parseInt(budget.id, 10)}
                />
                <RejectAdminDialog
                  id={parseInt(budget.id, 10)}
                />
              </div>
            </div>
          )}
          {(budget.insurance && budget.status !== 'PENDIENTE') && (
            <div className={classes.budgetContainer}>
              <h1 className={classes.title}>¿Cambiar a Pendiente?</h1>
              <div className={classes.buttonArea}>
                <PendingAdminDialog
                  id={parseInt(budget.id, 10)}
                />
              </div>
            </div>
          )}
        </div>
        {budget.status === 'APROBADO' && reserved === 0
          ? (
            <Button
              variant="outlined"
              className={classes.sendButton}
              onClick={handleReservation}
            >
              Agendar al cliente
            </Button>
          )
          : (
            null
          )}
        {budget.status !== 'APROBADO' && reserved === 0
          ? (
            <Button
              variant="outlined"
              className={classes.sendButton}
              onClick={() => {
                if (budget.id) {
                  reducer({ type: 'LOADING' });
                  sendBudget();
                }
              }}
            >
              {budget?.insurance ? 'Enviar al seguro' : 'Enviar al cliente'}
            </Button>
          ) : (
            null
          )}
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <AccountCircleIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Datos cliente</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <ClientDialog
              open={openClientDialog}
              setOpen={setOpenClientDialog}
              budget={budget}
              insurances={insurances}
              onClose={() => {
                setEditClient(budget.client);
                setOpenClientDialog(false);
              }}
              setBudget={setBudget}
              client={editClient}
              setClient={setEditClient}
            />
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Nombre:
                </p>
                <p className={classes.statValue}>
                  {` ${budget.client.name}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Apellido:
                </p>
                <p className={classes.statValue}>
                  {` ${budget.client.lastname}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Rut:
                </p>
                <p className={classes.statValue}>
                  {` ${budget.client.rut}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Email:
                </p>
                <p className={classes.statValue}>
                  {` ${budget.client.email}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Numero:
                </p>
                <p className={classes.statValue}>
                  {` ${budget.client.phone}`}
                </p>
              </div>
              {(budget.insurance) && (
                <div className={classes.statContainer}>
                  <AccountCircleIcon className={classes.transparentIcon} />
                  <p className={classes.statName}>
                    Seguro:
                  </p>
                  <p className={classes.statValue}>
                    {` ${budget.insurance.name}`}
                  </p>
                </div>
              )}
              <EditIcon
                className={classes.editClientButton}
                onClick={() => {
                  setOpenClientDialog(true);
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <DirectionsCarIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Datos auto</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <CarDialog
              open={openCarDialog}
              setOpen={setOpenCarDialog}
              budget={budget}
              onClose={() => {
                setEditCar(budget.car);
                setOpenCarDialog(false);
              }}
              setBudget={setBudget}
              car={editCar}
              setCar={setEditCar}
            />
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Marca:
                </p>
                <p className={classes.statValue}>
                  {` ${budget.car.brand}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Modelo:
                </p>
                <p className={classes.statValue}>
                  {` ${budget.car.model}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Año:
                </p>
                <p className={classes.statValue}>
                  {` ${budget.car.year}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Patente:
                </p>
                <p className={classes.statValue}>
                  {` ${budget.car.plate}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Chasis:
                </p>
                <p className={classes.statValue}>
                  {` ${budget.car.chasis}`}
                </p>
              </div>
              <EditIcon
                className={classes.editClientButton}
                onClick={() => setOpenCarDialog(true)}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <FormatPaintIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>DYP</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <ItemDialog
              open={openItemDialog}
              setItem={setActualItem}
              item={actualItem}
              dentPieces={dentPieces}
              paintPieces={paintPieces}
              mechanicsPieces={mechanicsPieces}
              thirdPartyPieces={thirdPartyPieces}
              setOpen={setOpenItemDialog}
              onClose={() => setOpenItemDialog(false)}
              budget={budget}
              setBudget={setBudget}
              dentConcepts={dentConcepts}
              paintConcepts={paintConcepts}
              mechanicsConcepts={mechanicsConcepts}
              thirdPartyConcepts={thirdPartyConcepts}
              setTotalPrice={setTotalPrice}
              totalPrice={totalPrice}
              setHasChanged={setHasChanged}
            />
            <DeleteDialog
              open={openDeleteDialog}
              onClose={() => setOpenDeleteDialog(false)}
              setOpen={setOpenDeleteDialog}
              item={actualItem}
              budget={budget}
              setBudget={setBudget}
              setTotalPrice={setTotalPrice}
              totalPrice={totalPrice}
              setHasChanged={setHasChanged}
            />
            <div className={classes.itemsContainer}>
              <div className={classes.addButtonContainer}>
                <Button
                  variant="outlined"
                  className={classes.addButton}
                  onClick={() => {
                    setActualItem(initialItem);
                    setOpenItemDialog(true);
                  }}
                >
                  Agregar
                </Button>
              </div>
              {budget.items.map((item) => (
                <div key={item.id} className={classes.itemContainer}>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Pieza:</p>
                    <p className={classes.statValue}>{` ${item.piece.name}`}</p>
                  </div>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Concepto:</p>
                    <p className={classes.statValue}>{` ${item.concept.name}`}</p>
                  </div>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Precio:</p>
                    <p className={classes.statValue}>{` $${item.price.toLocaleString('de-DE')}`}</p>
                  </div>
                  <DeleteIcon
                    className={classes.deleteButton}
                    onClick={() => {
                      setActualItem(item);
                      setOpenDeleteDialog(true);
                    }}
                  />
                  <EditIcon
                    className={classes.editBotton}
                    onClick={() => {
                      setActualItem(item);
                      setOpenItemDialog(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <BuildIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Repuestos</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <ReplacementDialog
              open={openReplacementDialog}
              setOpen={setOpenReplacementDialog}
              onClose={() => setOpenReplacementDialog(false)}
              replacement={actualReplacement}
              setReplacement={setActualReplacement}
              replacements={replacements}
              budget={budget}
              setBudget={setBudget}
              setTotalPrice={setTotalPrice}
              setHasChanged={setHasChanged}
              totalPrice={totalPrice}
            />
            <DeleteReplacementDialog
              budget={budget}
              setBudget={setBudget}
              open={openDeleteReplacementDialog}
              setOpen={setOpenDeleteReplacementDialog}
              onClose={() => setOpenDeleteReplacementDialog(false)}
              replacement={actualReplacement}
              setTotalPrice={setTotalPrice}
              totalPrice={totalPrice}
              setHasChanged={setHasChanged}
            />
            <div className={classes.itemsContainer}>
              <div className={classes.addButtonContainer}>
                <Button
                  variant="outlined"
                  className={classes.addButton}
                  onClick={() => {
                    setActualReplacement(initialReplacement);
                    setOpenReplacementDialog(true);
                  }}
                >
                  Agregar
                </Button>
              </div>
              {budget.replacements.map((replacement) => (
                <div key={replacement.id} className={classes.itemContainer}>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Descripción:</p>
                    <p className={classes.statValue}>{` ${replacement.piece.name}`}</p>
                  </div>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Cantidad:</p>
                    <p className={classes.statValue}>{` ${replacement.quantity}`}</p>
                  </div>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Precio:</p>
                    <p className={classes.statValue}>{` $${replacement.price.toLocaleString('de-DE')}`}</p>
                  </div>
                  <EditIcon
                    className={classes.editBotton}
                    onClick={() => {
                      setActualReplacement(replacement);
                      setOpenReplacementDialog(true);
                    }}
                  />
                  <DeleteIcon
                    className={classes.deleteButton}
                    onClick={() => {
                      setOpenDeleteReplacementDialog(true);
                      setActualReplacement(replacement);
                    }}
                  />
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <PermMediaIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Imágenes taller</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <DeleteImageDialog
              onClose={() => setOpenDeleteDialog(false)}
              budget={budget}
              setBudget={setBudget}
              imageId={actualImageId}
              open={openDeleteImageDialog}
              setOpen={setOpenDeleteImageDialog}
            />
            <div className={classes.imagesContainer}>
              {budget.images.map((image) => (
                <div
                  key={image.id}
                  className={classes.budgetImageContainer}
                >
                  <DeleteIcon
                    className={classes.deleteImageIcon}
                    onClick={() => {
                      setActualImageId(+image.id);
                      setOpenDeleteImageDialog(true);
                    }}
                  />
                  <img
                    className={classes.budgetImage}
                    alt=""
                    src={image.link}
                    onClick={() => {
                      setActualImage(image.link);
                      setOpenImageDisplay(true);
                    }}
                  />
                </div>
              ))}
            </div>
            <div className={classes.addIconContainer}>
              <AddCircleOutlineIcon
                className={classes.addIcon}
                onClick={() => hiddenInputRef.current.click()}
              />
              <input
                ref={hiddenInputRef}
                accept="image/*"
                type="file"
                multiple
                className={classes.hiddenInput}
                onChange={(e) => {
                  reducer({ type: 'LOADING' });
                  imagesUploadHandler('budget_image', budget.client.rut, [...e.target.files], executeSignS3)
                    .then((urls) => {
                      urls.forEach((url) => {
                        executeCreateBudgetImage({
                          variables: {
                            budgetID: +budget.id,
                            link: url,
                          },
                        });
                      });
                      reducer({ type: 'LOADING' });
                    });
                }}
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <DeleteAdminDialog
          id={parseInt(budget.id, 10)}
        />
      </div>
    );
  }
  return (
    <div className={classes.desktopContainer}>
      {loading && <Loader />}
      {alert && <Alert className={loginClasses.alert} severity="success">Se ha enviado mail correctamente</Alert>}
      {hasChanged && (
        <Alert severity="warning" className={loginClasses.alert}>
          Has cambiado el presupuesto, recuerda volver a enviarlo.
        </Alert>
      )}
      {openImageDisplay && (
        <div className={classes.imageDiv}>
          <div className={classes.imageCloseIconContainer}>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => setOpenImageDisplay(false)}
            />
          </div>
          <img
            alt=""
            src={actualImage}
            className={classes.imageDisplay}
          />
        </div>
      )}
      <DeleteImageDialog
        onClose={() => setOpenDeleteDialog(false)}
        budget={budget}
        setBudget={setBudget}
        imageId={actualImageId}
        open={openDeleteImageDialog}
        setOpen={setOpenDeleteImageDialog}
      />
      <ImageDialog
        open={openImageDialog}
        onClose={() => {
          setOpenImageDialog(false);
        }}
        setOpen={setOpenImageDialog}
        images={budget.images}
        setOpenDelete={setOpenDeleteImageDialog}
        setActualImageId={setActualImageId}
        setActualImage={setActualImage}
        setOpenDisplay={setOpenImageDisplay}
        inputRef={hiddenInputRef}
      />
      <Fab
        color="primary"
        className={classes.imageButton}
        onClick={() => setOpenImageDialog(true)}
      >
        <PermMediaIcon />
      </Fab>
      <div className={classes.sendContainer}>
        {budget.status === 'APROBADO' && reserved === 0
          ? (
            <Button
              variant="outlined"
              className={classes.sendButton}
              onClick={handleReservation}
            >
              Agendar al cliente
            </Button>
          )
          : (
            null
          )}
        {budget.status !== 'APROBADO' && reserved === 0
          ? (
            <Button
              variant="outlined"
              className={classes.sendButton}
              onClick={() => {
                if (budget.id) {
                  reducer({ type: 'LOADING' });
                  sendBudget({
                    variables: {
                      budgetId: +budget.id,
                    },
                  });
                }
              }}
            >
              {budget?.insurance ? 'Enviar al seguro' : 'Enviar al cliente'}
            </Button>
          ) : (
            null
          )}
      </div>
      <div className={classes.personalDataContainer}>
        <h1 className={classes.personalTitle}>{`Estado: ${budget.status}`}</h1>
        <h1 className={classes.personalTitle}>{`Total: $${totalPrice.toLocaleString('de-DE')}`}</h1>
        {(budget.insurance && budget.status === 'PENDIENTE') && (
          <div className={classes.budgetContainer}>
            <h1 className={classes.title}>¿Confirmar Presupuesto?</h1>
            <div className={classes.buttonArea}>
              <AcceptAdminDialog
                id={parseInt(budget.id, 10)}
              />
              <RejectAdminDialog
                id={parseInt(budget.id, 10)}
              />
            </div>
          </div>
        )}
        {(budget.insurance && budget.status !== 'PENDIENTE') && (
          <div className={classes.budgetContainer}>
            <h1 className={classes.title}>¿Cambiar a Pendiente?</h1>
            <div className={classes.buttonArea}>
              <PendingAdminDialog
                id={parseInt(budget.id, 10)}
              />
            </div>
          </div>
        )}
      </div>
      <div className={classes.generalDataContainer}>
        <div className={classes.personalDataContainer}>
          <ClientDialog
            open={openClientDialog}
            setOpen={setOpenClientDialog}
            budget={budget}
            insurances={insurances}
            onClose={() => {
              setEditClient(budget.client);
              setOpenClientDialog(false);
            }}
            setBudget={setBudget}
            client={editClient}
            setClient={setEditClient}
          />
          <Button
            className={classes.editGeneralButton}
            onClick={() => {
              setOpenClientDialog(true);
            }}
          >
            Editar
            <EditIcon />
          </Button>
          <h1 className={classes.personalTitle}>Cliente</h1>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Nombre:</p>
            <p className={classes.desktopStatValue}>{` ${budget.client.name}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Apellidos:</p>
            <p className={classes.desktopStatValue}>{` ${budget.client.lastname}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>RUT:</p>
            <p className={classes.desktopStatValue}>{` ${budget.client.rut}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Email:</p>
            <p className={classes.desktopStatValue}>{` ${budget.client.email}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Número:</p>
            <p className={classes.desktopStatValue}>{` ${budget.client.phone}`}</p>
          </div>
          {(budget.insurance) && (
            <div className={classes.desktopStatContainer}>
              <p className={classes.desktopStatName}>Seguro:</p>
              <p className={classes.desktopStatValue}>{` ${budget.insurance.name}`}</p>
            </div>
          )}
        </div>
        <div className={classes.dataSeparator} />
        <div className={classes.personalDataContainer}>
          <CarDialog
            open={openCarDialog}
            setOpen={setOpenCarDialog}
            budget={budget}
            onClose={() => {
              setEditCar(budget.car);
              setOpenCarDialog(false);
            }}
            setBudget={setBudget}
            car={editCar}
            setCar={setEditCar}
          />
          <h1 className={classes.personalTitle}>Auto</h1>
          <Button
            className={classes.editGeneralButton}
            onClick={() => {
              setOpenCarDialog(true);
            }}
          >
            Editar
            <EditIcon />
          </Button>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Marca:</p>
            <p className={classes.desktopStatValue}>{` ${budget.car.brand}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Modelo:</p>
            <p className={classes.desktopStatValue}>{` ${budget.car.model}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Año:</p>
            <p className={classes.desktopStatValue}>{` ${budget.car.year}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Patente:</p>
            <p className={classes.desktopStatValue}>{` ${budget.car.plate}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Chasis:</p>
            <p className={classes.desktopStatValue}>{` ${budget.car.chasis}`}</p>
          </div>
        </div>
      </div>
      <div className={classes.dypContainer}>
        <div className={classes.titleContainer}>
          <h1 className={classes.personalTitle}>Desabolladura y pintura</h1>
          <Button
            className={classes.desktopAddButton}
            onClick={() => {
              setActualItem(initialItem);
              setOpenItemDialog(true);
            }}
          >
            Agregar
            <AddCircleOutlineIcon />
          </Button>
        </div>
        <ItemDialog
          open={openItemDialog}
          setItem={setActualItem}
          item={actualItem}
          dentPieces={dentPieces}
          paintPieces={paintPieces}
          mechanicsPieces={mechanicsPieces}
          thirdPartyPieces={thirdPartyPieces}
          setOpen={setOpenItemDialog}
          onClose={() => setOpenItemDialog(false)}
          budget={budget}
          setBudget={setBudget}
          dentConcepts={dentConcepts}
          paintConcepts={paintConcepts}
          mechanicsConcepts={mechanicsConcepts}
          thirdPartyConcepts={thirdPartyConcepts}
          setTotalPrice={setTotalPrice}
          totalPrice={totalPrice}
          setHasChanged={setHasChanged}
        />
        <DeleteDialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
          setOpen={setOpenDeleteDialog}
          item={actualItem}
          budget={budget}
          setBudget={setBudget}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
          setHasChanged={setHasChanged}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} align="left">Pieza</TableCell>
                <TableCell className={classes.tableHeader} align="center">Concepto</TableCell>
                <TableCell className={classes.tableHeader} align="center">Precio</TableCell>
                <TableCell className={classes.tableHeader} align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {budget.items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item.piece.name}</TableCell>
                  <TableCell align="center">{item.concept.name}</TableCell>
                  <TableCell align="center">{`$${item.price.toLocaleString('de-DE')}`}</TableCell>
                  <TableCell
                    align="right"
                  >
                    <EditIcon
                      className={classes.tableEditButton}
                      onClick={() => {
                        setActualItem(item);
                        setOpenItemDialog(true);
                      }}
                    />
                    <DeleteIcon
                      className={classes.tableEditButton}
                      onClick={() => {
                        setActualItem(item);
                        setOpenDeleteDialog(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.dypContainer}>
        <div className={classes.titleContainer}>
          <h1 className={classes.personalTitle}>Repuestos</h1>
          <Button
            className={classes.desktopAddButton}
            onClick={() => {
              setActualReplacement(initialReplacement);
              setOpenReplacementDialog(true);
            }}
          >
            Agregar
            <AddCircleOutlineIcon />
          </Button>
        </div>
        <ReplacementDialog
          open={openReplacementDialog}
          setOpen={setOpenReplacementDialog}
          onClose={() => setOpenReplacementDialog(false)}
          replacement={actualReplacement}
          setReplacement={setActualReplacement}
          replacements={replacements}
          budget={budget}
          setBudget={setBudget}
          setTotalPrice={setTotalPrice}
          totalPrice={totalPrice}
          setHasChanged={setHasChanged}
        />
        <DeleteReplacementDialog
          budget={budget}
          setBudget={setBudget}
          open={openDeleteReplacementDialog}
          setOpen={setOpenDeleteReplacementDialog}
          onClose={() => setOpenDeleteReplacementDialog(false)}
          replacement={actualReplacement}
          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
          setHasChanged={setHasChanged}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} align="left">Pieza</TableCell>
                <TableCell className={classes.tableHeader} align="center">Cantidad</TableCell>
                <TableCell className={classes.tableHeader} align="center">Precio</TableCell>
                <TableCell className={classes.tableHeader} align="right">Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {budget.replacements.map((replacement) => (
                <TableRow key={replacement.id}>
                  <TableCell align="left">{replacement.piece.name}</TableCell>
                  <TableCell align="center">{replacement.quantity}</TableCell>
                  <TableCell align="center">{`$${replacement.price.toLocaleString('de-DE')}`}</TableCell>
                  <TableCell
                    align="right"
                  >
                    <EditIcon
                      className={classes.tableEditButton}
                      onClick={() => {
                        setActualReplacement(replacement);
                        setOpenReplacementDialog(true);
                      }}
                    />
                    <DeleteIcon
                      className={classes.tableEditButton}
                      onClick={() => {
                        setActualReplacement(replacement);
                        setOpenDeleteReplacementDialog(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <input
        ref={hiddenInputRef}
        accept="image/*"
        type="file"
        multiple
        className={classes.hiddenInput}
        onChange={(e) => {
          reducer({ type: 'LOADING' });
          imagesUploadHandler('budget_image', budget.client.rut, [...e.target.files], executeSignS3)
            .then((urls) => {
              urls.forEach((url) => {
                executeCreateBudgetImage({
                  variables: {
                    budgetID: +budget.id,
                    link: url,
                  },
                });
              });
              reducer({ type: 'LOADING' });
            });
        }}
      />
      <DeleteAdminDialog
        id={parseInt(budget.id, 10)}
      />
    </div>
  );
}

export default BudgetShow;
