import { makeStyles, createStyles } from '@material-ui/core/styles';

const useImageDialogStyles = makeStyles((theme) => createStyles({
  closeBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: '2vw',
    paddingRight: '2vw',
    paddingLeft: '2vw',
    width: '70vw',
    maxWidth: '400px',
    maxHeight: '400px',
  },
  closeIcon: {
    maxWidth: '50px',
    maxHeight: '50px',
    fontSize: '10vw',
    cursor: 'pointer',
    color: theme.palette.primary.main,
  },
  dialogTitle: {
    textAlign: 'center',
    paddingTop: 0,
    paddingBottom: 0,
  },
  addIcon: {
    fontSize: '15vw',
    color: theme.palette.primary.main,
    maxWidth: '70px',
    maxHeight: '70px',
    cursor: 'pointer',
  },
  imagesBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 2vw',
    overflow: 'overlay',
  },
  image: {
    width: '20vw',
    height: '20vw',
    maxWidth: '100px',
    maxHeight: '100px',
  },
  fileName: {
    width: '30vw',
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  deleteIcon: {
    fontSize: '10vw',
    color: theme.palette.primary.main,
    maxWidth: '50px',
    maxHeight: '50px',
  },
  imageBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '70vw',
    maxWidth: '400px',
    marginBottom: '2vh',
  },
  addBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '2vw',
  },
  readyButton: {
    maxWidth: 100,
    backgroundColor: theme.palette.primary.main,
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    color: '#FFFFFF',
    width: '30vw',
    marginBottom: '5vw',
  },
  hiddenInput: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  dialogDescription: {
    marginTop: '4vh',
    marginBottom: '4vh',
  },
}));

export default useImageDialogStyles;
