import { ReactElement } from 'react';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useBudgetImageDialogStyle from '../../styles/budget_image_dialog';
import { receptionImageDialogInterface } from '../../interfaces/components.interfaces';

function ReceptionImageDialog(props: receptionImageDialogInterface): ReactElement {
  const classes = useBudgetImageDialogStyle();
  const {
    open,
    images,
    setOpen,
    onClose,
    setActualImage,
    setOpenDisplay,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <div className={classes.closeBox}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={classes.titleContainer}>
        <h1 className={classes.dialogTitle}>Imágenes</h1>
      </div>
      <div className={classes.imagesContainer}>
        {images.map((image) => (
          <div
            className={classes.budgetImageContainer}
            key={image}
          >
            <img
              alt=""
              src={image}
              className={classes.budgetImage}
              onClick={() => {
                setActualImage(image);
                setOpenDisplay(true);
              }}
            />
          </div>
        ))}
      </div>
    </Dialog>
  );
}

export default ReceptionImageDialog;
