import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useBudgetAppointmentStyles = makeStyles((theme: Theme) => createStyles({
  formBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  appContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  formIcon: {
    fontSize: '40vw',
    color: '#126da6',
    marginTop: '2vh',
    [theme.breakpoints.up('md')]: {
      fontSize: '30vh',
    },
  },
  inputsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  formInput: {
    width: '60vw',
    marginButton: '2vh',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      width: '30vw',
    },
  },
  containerForm: {
    borderRadius: '20px',
    maxWidth: '100vw',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    [theme.breakpoints.up('md')]: {
      marginTop: '0vh',
    },
  },
  pagesBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-around',
    width: '60vw',
    marginBottom: '3vh',
  },
  pageButton: {
    color: '#FFFFFF',
    backgroundColor: 'rgba(18, 109, 166, 0.5)',
    '&:focus': {
      backgroundColor: 'rgba(18, 109, 166, 0.5)',
    },
    cursor: 'not-allowed',
  },
  pageButtonActive: {
    color: '#FFFFFF',
    backgroundColor: '#126da6',
    '&:focus': {
      backgroundColor: '#126da6',
    },
  },
  uploadContainer: {
    marginBottom: '3vh',
  },
  uploadButton: {
    width: '60vw',
    height: '5vh',
    backgroundColor: '#FFFFFF',
    borderBottom: '2px solid gray',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      maxWidth: '30vw',
    },
  },
  uploadButtonActive: {
    width: '58vw',
    height: '5vh',
    backgroundColor: '#FFFFFF',
    border: '2px solid #126da6',
    borderRadius: '5px',
    padding: '0 1vw',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      maxWidth: '30vw',
    },
  },
  lastButton: {
    color: '#FFFFFF',
    backgroundColor: '#126da6',
    cursor: 'pointer',
    margin: theme.spacing(2),
    float: 'left',
    '&:hover': {
      background: '#126da6',
      color: '#FFFFFF',
    },
  },
  daysF: {
    marginBottom: theme.spacing(2),
  },
  uploadTitleActive: {
    margin: 0,
    color: '#126da6',
    fontSize: '14px',
    textAlign: 'center',
    alignSelf: 'center',
  },
  uploadIconActive: {
    color: '#126da6',
  },
  uploadTitle: {
    margin: 0,
    color: 'gray',
    fontSize: '14px',
    textAlign: 'center',
    alignSelf: 'center',
  },
  uploadIcon: {
    color: 'gray',
  },
  filesCount: {
    backgroundColor: '#126da6',
    height: '3vh',
    width: '3vh',
    borderRadius: '1.5vh',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  count: {
    textAlign: 'center',
    alignContent: 'center',
    fontSize: '14px',
    lineHeight: '14px',
    color: '#FFFFFF',
  },
  mycount: {
    margin: 0,
    color: 'gray',
    fontSize: '12px',
    textAlign: 'center',
    alignSelf: 'center',
  },
  boxsize: {
    minHeight: '75vh',
    width: '100vw',
  },
  helperText: {
    color: 'red',
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  formTitle: {
    marginTop: '5vh',
    marginBottom: '5vh',
    color: 'black',
    fontSize: 24,
    fontWeight: 'lighter',
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
    },
  },
  alertText: {
    color: '#ff0000',
  },
  infoAlert: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '1vh',
    background: '#BBE7FF',
    marginBottom: '5vh',
    color: 'rgb(19 94 170)',
  },
  rowAlert: {
    marginLeft: '2vw',
  },
  detailsAlert: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10vh',
    paddingBottom: '10vh',
    paddingLeft: '2vh',
    paddingRight: '2vh',
  },
}));

export default useBudgetAppointmentStyles;
