import { ReactElement, useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { Button } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useExportServicesLazyQuery, ExportServicesQuery } from '../../types';
import { ExportServicesInterface } from '../../interfaces/components.interfaces';
import useButtonstyles from '../../styles/exportButton_style';
import { reducer } from '../../store/reducer';

function formatingData(data: ExportServicesQuery['exportServices']) {
  const rows: ExportServicesInterface[] = [];
  data.forEach((element) => {
    const service : ExportServicesInterface = {
      Id: +element.id,
      Nombre: element.name,
      Descripción: element.description,
      Lugares: element.places,
      Último_lugar_bloqueado: element.lastBlockPlaces,
    };
    rows.push(service);
  });
  return rows;
}

function ExportServices() : ReactElement {
  const classes = useButtonstyles();
  const fileName = 'Servicios';
  const [rowData, setRowData] = useState([]);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (dataCSV: ExportServicesInterface[], NameFile: string) => {
    const ws = XLSX.utils.json_to_sheet(dataCSV);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, NameFile + fileExtension);
  };

  const [executeServices] = useExportServicesLazyQuery({
    onCompleted: (data) => {
      setRowData(formatingData(data.exportServices));
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  useEffect(() => {
    if (rowData.length > 0) {
      exportToCSV(rowData, fileName);
    }
  });

  return (
    <Button
      onClick={() => {
        reducer({ type: 'LOADING' });
        executeServices();
      }}
      className={classes.pageButtonActive}
    >
      Datos de los servicios
    </Button>
  );
}
export default ExportServices;
