import {
  ReactElement,
  useState,
} from 'react';
import {
  Box,
  Button,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useStyles from '../../styles/change_password_form';
import ChangePassword from '../../components/forms/changePassword';
import Loader from '../../components/loader/loader';
import { useChangeAdminPasswordMutation } from '../../types';
import PASSWORD_LENGTH from '../../helpers/constants';

function ChangePasswordView() : ReactElement {
  const classes = useStyles();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [validationPassword, setValidationPassword] = useState('');
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [isValidationPasswordValid, setIsValidationPasswordValid] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [severity, setSeverity] = useState('');
  const [executionChangePassword] = useChangeAdminPasswordMutation({
    variables: {
      currentPassword,
      newPassword,
      validationPassword,
    },
    onCompleted: () => {
      setIsLoading(false);
      setSeverity('success');
      setAlertContent('Cambio de contraseña realizado con éxito');
      setAlert(true);
      setNewPassword('');
      setValidationPassword('');
      setIsNewPasswordValid(false);
      setIsValidationPasswordValid(false);
      setCurrentPassword('');
    },
    onError: () => {
      setIsLoading(false);
      setSeverity('error');
      setAlertContent('Falló cambio de contraseña, inténtelo nuevamente');
      setAlert(true);
    },
  });

  const handleSubmit = () => {
    if (isValidationPasswordValid && isNewPasswordValid && currentPassword.length >= PASSWORD_LENGTH) {
      setIsLoading(true);
      executionChangePassword();
    }
  };

  const checkResult = () => {
    if (severity === 'error') {
      return <Alert className={classes.alert} severity="error">{alertContent}</Alert>;
    }
    return <Alert className={classes.alert} severity="success">{alertContent}</Alert>;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Box className={classes.appContainer}>
      {alert ? checkResult() : <></> }
      <Box
        className={classes.formBox}
      >
        <Box
          className={classes.contentBox}
        >
          <div className={classes.formTitle}>
            Cambiar Contraseña
          </div>
          <ChangePassword
            currentPassword={currentPassword}
            setCurrentPassword={setCurrentPassword}
            newPassword={newPassword}
            setNewPassword={setNewPassword}
            validationPassword={validationPassword}
            setValidationPassword={setValidationPassword}
            isNewPasswordValid={isNewPasswordValid}
            setIsNewPasswordValid={setIsNewPasswordValid}
            isValidationPasswordValid={isValidationPasswordValid}
            setIsValidationPasswordValid={setIsValidationPasswordValid}
          />
          <Box className={classes.pagesBox}>
            <Button
              variant="contained"
              onClick={handleSubmit}
              className={(
                isNewPasswordValid
                && isValidationPasswordValid)
                ? classes.pageButtonActive
                : classes.pageButton}
            >
              Confirmar
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ChangePasswordView;
