import MaterialTable from 'material-table';
import { ReactElement, useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { useStyles } from '../../styles/workers_table_style';
import { useWorkersListLazyQuery, WorkersListQuery } from '../../types';

function WorkersTable() : ReactElement {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const changeName = (role: string) => {
    if (role === 'worker') {
      return 'Técnico';
    }
    return 'Jefe';
  };

  const [executeWorkersListQuery] = useWorkersListLazyQuery({
    onCompleted: (dataWorkers) => {
      const allWorkersInfo: WorkersListQuery['workers'] = [];
      dataWorkers.workers.forEach((worker) => {
        const workerInfo = {
          name: worker.name,
          email: worker.email,
          rut: worker.rut,
          role: changeName(worker.role),
        };
        allWorkersInfo.push(workerInfo);
      });
      setData(allWorkersInfo);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  useEffect(() => {
    executeWorkersListQuery();
  }, []);

  const columns = [
    {
      title: 'Nombre',
      field: 'name',
      sorting: false,
    },
    {
      title: 'Correo',
      field: 'email',
      sorting: false,
    },
    {
      title: 'Rut',
      field: 'rut',
      sorting: false,
    },
    {
      title: 'Cargo',
      field: 'role',
      sorting: false,
    },
  ];

  return (
    <div className={classes.table}>
      <MaterialTable
        title=""
        columns={columns}
        data={data}
        localization={{
          body: {
            emptyDataSourceMessage: 'No se encuentra información',
          },
          toolbar: {
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar',
            exportPDFName: 'Exportar PDF',
            exportCSVName: 'Exportar CSV',
          },
          pagination: {
            labelRowsSelect: 'filas',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primera Página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página',
          },
        }}
        options={{
          pageSize: 4,
          pageSizeOptions: [4],
          exportButton: true,
          exportAllData: true,
        }}
      />
    </div>
  );
}

export default WorkersTable;
