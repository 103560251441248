import { ReactElement, useState, useEffect } from 'react';
import {
  Box, TextField, Select, MenuItem,
} from '@material-ui/core';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { useStyles } from '../../styles/workOrderFilter_style';
import { ReservationFilterInterface, ServiceFilterInterface } from '../../interfaces/components.interfaces';
import { useAllServicesInformationLazyQuery, AllServicesInformationQuery } from '../../types';

function ReservationFilter(props: ReservationFilterInterface): ReactElement {
  const {
    name,
    setName,
    plate,
    setPlate,
    dateIni,
    setDateIni,
    dateEnd,
    setDateEnd,
    serviceId,
    setServiceId,
  } = props;

  const classes = useStyles();
  const [services, setServices] = useState([]);
  function formatServiceInformation(data: AllServicesInformationQuery) {
    const servicesInformation: ServiceFilterInterface[] = [];
    data.services.forEach((element) => {
      const service: ServiceFilterInterface = {
        id: +element.id,
        nameService: element.name,
      };
      servicesInformation.push(service);
    });
    return servicesInformation;
  }
  const [executeAllServiceInformation] = useAllServicesInformationLazyQuery({
    onCompleted: (data) => {
      setServices(formatServiceInformation(data));
    },
  });

  useEffect(() => {
    executeAllServiceInformation();
  }, []);

  const handleService = (event: React.ChangeEvent<{ value: unknown }>) => {
    setServiceId(event.target.value as number);
  };

  function dateFormat(dateTime: string) {
    const date: Date = new Date(dateTime);
    const year: number = date.getUTCFullYear();
    const month:number = (date.getUTCMonth());
    const day: number = date.getUTCDate();
    return moment(new Date(year, month, day)).format('YYYY-MM-DD');
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.buttonsContainer}
    >
      <FormControl className={classes.formControl}>
        <InputLabel>Nombre</InputLabel>
        <Input
          id="name"
          value={name}
          aria-describedby="component-helper-text"
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>Patente</InputLabel>
        <Input
          id="plate"
          value={plate}
          aria-describedby="component-helper-text"
          onChange={(e) => setPlate(e.target.value)}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>Área</InputLabel>
        <Select
          id="serviceId"
          value={serviceId}
          onChange={handleService}
        >
          <MenuItem value={0}>Todas</MenuItem>
          {services.map((serviceInfo) => {
            const { id, nameService } = serviceInfo;
            return (
              <MenuItem value={id}>{nameService}</MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label="Desde"
          id="dateIni"
          type="date"
          value={dateIni}
          onChange={(e) => setDateIni(`${dateFormat(e.target.value)}`)}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label="Hasta"
          id="dateEnd"
          type="date"
          value={dateEnd}
          onChange={(e) => setDateEnd(`${dateFormat(e.target.value)}`)}
        />
      </FormControl>
    </Box>

  );
}
export default ReservationFilter;
