import { makeStyles, createStyles } from '@material-ui/core/styles';

const useWorkOrderShowStyles = makeStyles((theme) => createStyles({
  orderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    width: '100vw',
  },
  backContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '3vh',
    width: '100vw',
  },
  mobileClick: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  clickableRow: {
    cursor: 'pointer',
  },
  deliverButton: {
    width: '30vw',
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    marginRight: '3vw',
    [theme.breakpoints.up('sm')]: {
      width: '10vw',
    },
  },
  backIcon: {
    fontSize: '4vh',
    marginLeft: '3vw',
  },
  backButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  backText: {
    fontSize: '3vh',
  },
  generalContainer: {
    display: 'flex',
    width: '80vw',
    marginTop: '2vh',
    marginBottom: '4vh',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      alignItems: 'center',
    },
  },
  generalStatContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '80vw',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'center',
    },
  },
  generalStatName: {
    fontWeight: 'bold',
    fontSize: '2.5vh',
    marginRight: '1vh',
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
  generalStatValue: {
    fontSize: '2.5vh',
    width: '100%',
    minWidth: 0,
    color: theme.palette.primary.main,
    overflowWrap: 'break-word',
    [theme.breakpoints.up('sm')]: {
      width: 'auto',
    },
  },
  tablesContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '80vw',
  },
  tableContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  tasksContainer: {
    marginTop: '4vh',
    width: '80vw',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: '35vw',
    },
  },
  tablePaper: {
    padding: '2vw',
  },
  tableCell: {
    overflow: 'hidden',
    width: '30vw',
    [theme.breakpoints.up('sm')]: {
      width: '10vw',
    },
  },
  formInput: {
    width: '80vw',
    marginTop: '3vh',
    [theme.breakpoints.up('sm')]: {
      width: '35vw',
    },
  },
  assignButton: {
    width: '80vw',
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    margin: '3vh 0',
    [theme.breakpoints.up('sm')]: {
      width: '35vw',
    },
  },
  assignButtonDisabled: {
    backgroundColor: 'rgba(18, 109, 166, 0.5)',
    width: '80vw',
    color: '#ffffff',
    '&:focus': {
      backgroundColor: 'rgba(18, 109, 166, 0.5)',
    },
    margin: '3vh 0',
    [theme.breakpoints.up('sm')]: {
      width: '35vw',
    },
  },
  notIniciated: {
    backgroundColor: 'red',
    width: '1.5vh',
    height: '1.5vh',
    marginLeft: 'auto',
    marginRight: '1.1rem',
    textAlign: 'center',
    borderRadius: 20,
  },
  inProcess: {
    backgroundColor: '#f5c907',
    width: '1.5vh',
    height: '1.5vh',
    marginLeft: 'auto',
    marginRight: '1.1rem',
    textAlign: 'center',
    borderRadius: 20,
  },
  finished: {
    backgroundColor: 'green',
    width: '1.5vh',
    height: '1.5vh',
    marginLeft: 'auto',
    marginRight: '1.1rem',
    textAlign: 'center',
    borderRadius: 20,
  },
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1vh',
  },
  statusInProcess: {
    color: '#f5c907',
    fontSize: '2.5vh',
  },
  statusNotStarted: {
    color: 'red',
    fontSize: '2.5vh',
  },
  statusFinished: {
    color: 'green',
    fontSize: '2.5vh',
  },
  taskStats: {
    display: 'flex',
    width: '75vw',
    marginTop: '2vh',
    marginLeft: '5vw',
    flexDirection: 'column',
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
      width: '35vw',
      alignItems: 'center',
      margin: 0,
    },
  },
  taskStatName: {
    fontWeight: 'bold',
    fontSize: '2.5vh',
    marginRight: '1vw',
  },
  taskStatValue: {
    fontSize: '2.5vh',
    marginRight: '1vw',
  },
}));

export default useWorkOrderShowStyles;
