import { ReactElement, useRef } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import { DeleteReplacementDialogInterface } from '../../interfaces/components.interfaces';
import useDeleteDialogStyle from '../../styles/delete_dialog';
import { useDeleteReplacementMutation } from '../../types';
import { reducer } from '../../store/reducer';

function DeleteReplacementDialog(props: DeleteReplacementDialogInterface): ReactElement {
  const classes = useDeleteDialogStyle();
  const {
    open,
    onClose,
    setOpen,
    budget,
    setBudget,
    replacement,
    setTotalPrice,
    totalPrice,
    setHasChanged,
  } = props;
  const [deleteReplacement] = useDeleteReplacementMutation({
    variables: {
      id: +replacement.id,
    },
    onCompleted: (data) => {
      reducer({ type: 'LOADING' });
      setBudget({
        ...budget,
        replacements: budget.replacements.filter(
          (actualReplacement) => actualReplacement.id !== data.deleteReplacement.id,
        ),
      });
      setTotalPrice(totalPrice - data.deleteReplacement.price * data.deleteReplacement.quantity);
      setOpen(false);
      setHasChanged(true);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const dialogRef = useRef(null);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth={false}
      ref={dialogRef}
    >
      <DialogTitle className={classes.deleteMessage}>¿Esta seguro que desea eliminar?</DialogTitle>
      <div className={classes.buttonsContainer}>
        <div
          className={classes.leftButtonContainer}
          onClick={() => {
            reducer({ type: 'LOADING' });
            deleteReplacement();
          }}
        >
          <p className={classes.optionMessage}>Si</p>
        </div>
        <div
          className={classes.rightButtonContainer}
          onClick={() => setOpen(false)}
        >
          <p className={classes.optionMessage}>No</p>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteReplacementDialog;
