import { ReactElement, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
} from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ReplacementDialogInterface } from '../../interfaces/components.interfaces';
import useItemDialogStyles from '../../styles/item_dialog';
import { useUpdateReplacementMutation, GetPiecesQuery, useAddReplacementToBudgetMutation } from '../../types';
import { reducer } from '../../store/reducer';

function ReplacementDialog(props: ReplacementDialogInterface): ReactElement {
  const classes = useItemDialogStyles();
  const {
    onClose,
    open,
    setOpen,
    replacement,
    setReplacement,
    replacements,
    budget,
    setBudget,
    setTotalPrice,
    totalPrice,
    setHasChanged,
  } = props;
  const [selectedReplacement, setSelectedReplacement] = useState<GetPiecesQuery['getPieces'][0]>(null);
  const [inputValue, setInputValue] = useState('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [executeUpdateBudget] = useUpdateReplacementMutation({
    variables: {
      ...replacement,
      description: selectedReplacement?.name,
      id: +replacement.id,
    },
    onCompleted: (data) => {
      setBudget({
        ...budget,
        replacements: budget.replacements.map((actualReplacement) => {
          if (replacement.id === actualReplacement.id) {
            return data.updateReplacement;
          }
          return actualReplacement;
        }),
      });
      setTotalPrice(totalPrice + data.updateReplacement.price);
      reducer({ type: 'LOADING' });
      setHasChanged(true);
      setOpen(false);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  const [executeAddReplacement] = useAddReplacementToBudgetMutation({
    variables: {
      input: {
        quantity: replacement.quantity,
        description: selectedReplacement?.name,
        unitPrice: replacement.price,
      },
      budgetId: +budget.id,
    },
    onCompleted: (data) => {
      reducer({ type: 'LOADING' });
      setBudget({
        ...budget,
        replacements: [
          ...budget.replacements,
          data.addReplacementToBudget,
        ],
      });
      setTotalPrice(totalPrice + data.addReplacementToBudget.price);
      setHasChanged(true);
      setOpen(false);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  useEffect(() => {
    setSelectedReplacement(replacement.piece);
  }, [open]);

  useEffect(() => {
    setOriginalPrice(replacement.price);
  }, [open]);

  useEffect(() => {
    if (replacement.price >= 0 && replacement.quantity >= 0 && selectedReplacement) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [replacement, selectedReplacement]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <div className={classes.closeBox}>
        <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
      </div>
      <DialogTitle className={classes.dialogTitle}>Editar</DialogTitle>
      <div className={classes.formContainer}>
        <Autocomplete
          value={selectedReplacement}
          onChange={(_e, newValue: string | GetPiecesQuery['getPieces'][0] | null) => {
            setSelectedReplacement(newValue as GetPiecesQuery['getPieces'][0]);
          }}
          getOptionSelected={(option, value) => (value as GetPiecesQuery['getPieces'][0]).name
          === (option as GetPiecesQuery['getPieces'][0]).name}
          className={classes.formInput}
          getOptionLabel={(option) => (option as GetPiecesQuery['getPieces'][0]).name}
          options={replacements}
          inputValue={inputValue}
          id="pieces-select"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Pieza"
              FormHelperTextProps={{ className: classes.helperText }}
              helperText={selectedReplacement ? '\u00a0' : 'Selecciona un repuesto'}
            />
          )}
          onInputChange={(_e, value) => {
            setInputValue(value);
          }}
        />
        <TextField
          label="Cantidad"
          type="number"
          className={classes.formInput}
          value={replacement.quantity >= 0 ? replacement.quantity : ''}
          FormHelperTextProps={{ className: classes.helperText }}
          helperText={replacement.quantity >= 0 ? '\u00a0' : 'Indique la cantidad'}
          onChange={(e) => {
            setReplacement({
              ...replacement,
              quantity: e.target.value ? +e.target.value : -1,
            });
          }}
        />
        <TextField
          label="Precio"
          className={classes.formInput}
          FormHelperTextProps={{ className: classes.helperText }}
          helperText={replacement.price >= 0 ? '\u00a0' : 'Agrega un precio'}
          value={replacement.price >= 0 ? `$${replacement.price.toLocaleString('de-DE')}` : ''}
          onChange={(e) => {
            setReplacement({
              ...replacement,
              price: e.target.value.replace('$', '').replace(/\./g, '')
                ? +e.target.value.replace('$', '').replace(/\./g, '') : -1,
            });
          }}
        />
        <Button
          variant="contained"
          className={isFormValid ? classes.saveButton : classes.saveButtonDisabled}
          onClick={() => {
            if (replacement.id && isFormValid) {
              reducer({ type: 'LOADING' });
              setTotalPrice(totalPrice - originalPrice);
              executeUpdateBudget();
            } else if (isFormValid) {
              reducer({ type: 'LOADING' });
              executeAddReplacement();
            }
          }}
        >
          {replacement.id ? 'Guardar' : 'Agregar'}
        </Button>
      </div>
    </Dialog>
  );
}

export default ReplacementDialog;
