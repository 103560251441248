import { ReactElement } from 'react';
import {
  Box,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import { ServicesQuery } from '../../types';
import { AreaDataInterface } from '../../interfaces/components.interfaces';
import useBudgetAppointmentStyles from '../../styles/budget_appointment';

function AreaData(props: AreaDataInterface) : ReactElement {
  const {
    open,
    setOpen,
    area,
    setArea,
    allArea,
  } = props;

  const classes = useBudgetAppointmentStyles();
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setArea(event.target.value as number);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      className={classes.daysF}
    >
      <Box className={classes.inputsBox}>
        <div className={classes.formTitle}>
          Razón de visita
        </div>
        <FormControl className={classes.formInput}>
          <InputLabel id="demo-controlled-open-select-label">
            Selecciona área
          </InputLabel>
          <Select
            labelId="demo-controlled-open-select-label"
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={area}
            onChange={handleChange}
          >
            {allArea.map((areas: ServicesQuery['services'][0]) => (
              <MenuItem
                key={areas.id}
                value={areas.id}
              >
                {areas.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
}

export default AreaData;
