import { ReducerActionInterface, StateInterface } from '../interfaces';
import state from './getGlobalState';

export const initialState: StateInterface = {
  isLoading: false,
  token: '',
  name: '',
};

export const reducer = (action: ReducerActionInterface): void => {
  const { type } = action;
  if (type === 'INITIALIZE') {
    state({
      ...state(),
      token: action.data.token,
    });
  } else if (type === 'LOG_IN') {
    state({
      ...state(),
      token: action.data.token,
      isLoading: false,
    });
  } else if (type === 'SIGN_UP') {
    state({
      ...state(),
      token: action.data.token,
      isLoading: false,
    });
  } else if (type === 'SIGN_OUT') {
    state(initialState);
  } else if (type === 'NAME') {
    state({
      ...state(),
      name: action.data.name,
    });
  } else if (type === 'LOADING') {
    state({
      ...state(),
      isLoading: !state().isLoading,
    });
  } else {
    state(state());
  }
};
