import { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, Button } from '@material-ui/core';
import WorkersTable from '../../components/table/workers';
import { useStyles } from '../../styles/workers_view';

function WorkersList(): ReactElement {
  const classes = useStyles();
  const registerView = () => {
    window.location.href = '/admin/trabajadores/registro';
  };
  return (
    <Grid
      container
      className={classes.grid}
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Grid item>
        <Typography variant="h2" className={classes.typography}>
          Trabajador
        </Typography>
      </Grid>
      <Grid item>
        <Button
          className={classes.registrationButton}
          variant="contained"
          onClick={() => registerView()}
        >
          Registrar
        </Button>
      </Grid>
      <Grid item>
        <WorkersTable />
      </Grid>
    </Grid>
  );
}

export default WorkersList;
