import { makeStyles, createStyles } from '@material-ui/core/styles';

const useLoaderStyles = makeStyles(() => createStyles({
  circularProgress: {
    color: '#ffffff',
  },
  loaderDiv: {
    overflowY: 'hidden',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10000,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#000000aa',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  message: {
    color: '#ffffff',
  },
}));

export default useLoaderStyles;
