import { makeStyles, createStyles } from '@material-ui/core/styles';

const useItemDialogStyles = makeStyles((theme) => createStyles({
  closeBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: '2vw',
    paddingRight: '2vw',
    paddingLeft: '2vw',
    width: '80vw',
    maxWidth: '400px',
    maxHeight: '400px',
  },
  closeIcon: {
    maxWidth: '50px',
    maxHeight: '50px',
    fontSize: '10vw',
    color: theme.palette.primary.main,
  },
  dialogTitle: {
    color: theme.palette.primary.main,
    textAlign: 'center',
    paddingTop: 0,
    paddingBottom: 0,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '2vh',
  },
  formInput: {
    width: '70vw',
    maxWidth: 300,
  },
  saveButton: {
    maxWidth: 300,
    backgroundColor: theme.palette.primary.main,
    '&:focus': {
      backgroundColor: 'rgba(18, 109, 166, 0.5)',
    },
    color: '#FFFFFF',
    width: '50vw',
    marginBottom: '10vw',
    [theme.breakpoints.up('md')]: {
      marginBottom: 50,
    },
  },
  saveButtonDisabled: {
    maxWidth: 300,
    backgroundColor: 'rgba(18, 109, 166, 0.5)',
    '&:focus': {
      backgroundColor: 'rgba(18, 109, 166, 0.5)',
    },
    color: '#FFFFFF',
    width: '50vw',
    marginBottom: '10vw',
  },
  helperText: {
    color: 'red',
  },
}));

export default useItemDialogStyles;
