import { ReactElement, useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { Button } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useExportWorkPocessesLazyQuery, ExportWorkPocessesQuery } from '../../types';
import { ExportWorkProcessesInterface } from '../../interfaces/components.interfaces';
import useButtonstyles from '../../styles/exportButton_style';
import { reducer } from '../../store/reducer';

function formatingData(data: ExportWorkPocessesQuery['exportWorkPocesses']) {
  const rows: ExportWorkProcessesInterface[] = [];
  data.forEach((element) => {
    const workProcesse : ExportWorkProcessesInterface = {
      Id: +element.id,
      Id_orden_trabajo: +element.workOrder.id,
      Id_proceso: +element.process.id,
    };
    rows.push(workProcesse);
  });
  return rows;
}

function ExportWorkProcesess() : ReactElement {
  const classes = useButtonstyles();
  const fileName = 'Procesos';
  const [rowData, setRowData] = useState([]);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (dataCSV: ExportWorkProcessesInterface[], NameFile: string) => {
    const ws = XLSX.utils.json_to_sheet(dataCSV);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, NameFile + fileExtension);
  };
  const [executeWorkProcesess] = useExportWorkPocessesLazyQuery({
    onCompleted: (data) => {
      setRowData(formatingData(data.exportWorkPocesses));
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  useEffect(() => {
    if (rowData.length > 0) {
      exportToCSV(rowData, fileName);
    }
  }, [rowData]);

  return (
    <Button
      onClick={() => {
        reducer({ type: 'LOADING' });
        executeWorkProcesess();
      }}
      className={classes.pageButtonActive}
    >
      Datos de los procesos
    </Button>
  );
}

export default ExportWorkProcesess;
