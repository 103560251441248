import { FetchResult } from '@apollo/client';
import axios from 'axios';
import { SignS3MutationFn } from '../types';

async function imagesUploadHandler(
  prefix: string,
  identifier: string,
  files: File[],
  executeSignS3: SignS3MutationFn,
): Promise<string[]> {
  const signPromises: Promise<FetchResult>[] = [];
  files.forEach((file, i) => {
    signPromises.push(executeSignS3({
      variables: {
        filename: `${prefix}-${identifier.replaceAll('.', '').replace('-', '')}-${Date.now()}-${i}`,
        filetype: file.type,
      },
    }));
  });
  const urls: string[] = [];
  const signData = await Promise.all(signPromises);
  const s3Promises: Promise<void>[] = [];
  signData.forEach((signObj, i) => {
    const { signS3 } = signObj.data;
    urls.push(signS3.url);
    const file = files[i];
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    s3Promises.push(axios.put(signS3.signedRequest, file, options));
  });
  await Promise.all(s3Promises);
  return urls;
}

export default imagesUploadHandler;
