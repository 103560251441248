import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { ReactElement } from 'react';

const useStyles = makeStyles((theme: Theme) => createStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonClass: {
    width: '100%',
    height: '100%',
    padding: '4%',
    background: '#f5f5f5',
    color: '#115fa6',
    '&:hover': {
      background: '#115fa6',
      color: '#f5f5f5',
    },
  },
}));

function WithInsuranceModal(): ReactElement {
  const classes = useStyles();

  return (
    <div>
      <Button type="button" href="/tomar-hora" className={classes.buttonClass}>
        Reserva Con Seguro
      </Button>
    </div>
  );
}

export default WithInsuranceModal;
