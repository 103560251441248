import { ReactElement, useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { Button } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useExportTasksLazyQuery, ExportTasksQuery } from '../../types';
import { ExportTaskInterface } from '../../interfaces/components.interfaces';
import useButtonstyles from '../../styles/exportButton_style';
import { reducer } from '../../store/reducer';

function formatingData(data: ExportTasksQuery['exportTasks']) {
  const rows: ExportTaskInterface[] = [];
  data.forEach((element) => {
    const task : ExportTaskInterface = {
      Id: +element.id,
      Id_Proceso: +element.workProcess.id,
      RUT_trabajador: element.worker ? element.worker.rut : null,
      Id_estado: +element.status.id,
      Estado: element.status.name,
      Id_concepto: +element.concept.id,
      Concepto: element.concept.name,
      Id_etapa: +element.concept.stage.id,
      Etapa: element.concept.stage.name,
      Id_pieza: element.piece ? +element.piece.id : null,
      Pieza: element.piece ? element.piece.name : null,
    };
    rows.push(task);
  });
  return rows;
}

function ExportTask() : ReactElement {
  const classes = useButtonstyles();
  const fileName = 'Tareas';
  const [rowData, setRowData] = useState([]);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (dataCSV: ExportTaskInterface[], NameFile: string) => {
    const ws = XLSX.utils.json_to_sheet(dataCSV);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, NameFile + fileExtension);
  };
  const [executeTask] = useExportTasksLazyQuery({
    onCompleted: (data) => {
      setRowData(formatingData(data.exportTasks));
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  useEffect(() => {
    if (rowData.length > 0) {
      exportToCSV(rowData, fileName);
    }
  }, [rowData]);

  return (
    <Button
      onClick={() => {
        reducer({ type: 'LOADING' });
        executeTask();
      }}
      className={classes.pageButtonActive}
    >
      Datos de las tareas
    </Button>
  );
}
export default ExportTask;
