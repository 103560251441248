import {
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Dialog,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  Button,
} from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ItemDialogInterface } from '../../interfaces/components.interfaces';
import useItemDialogStyles from '../../styles/item_dialog';
import {
  useUpdateBudgetItemMutation,
  GetPiecesQuery,
  GetConceptsQuery,
  useAddBudgetItemMutation,
} from '../../types';
import { reducer } from '../../store/reducer';

function ItemDialog(props: ItemDialogInterface): ReactElement {
  const classes = useItemDialogStyles();
  const {
    onClose,
    open,
    setOpen,
    item,
    setItem,
    setBudget,
    dentPieces,
    paintPieces,
    mechanicsPieces,
    thirdPartyPieces,
    dentConcepts,
    paintConcepts,
    mechanicsConcepts,
    thirdPartyConcepts,
    budget,
    setTotalPrice,
    totalPrice,
    setHasChanged,
  } = props;
  const dialogRef = useRef(null);
  const [
    selectedPiece,
    setSelectedPiece,
  ] = useState<GetPiecesQuery['getPieces'][0] | null>(null);
  const [originalPrice, setOriginalPrice] = useState(0);
  const [pieceInputValue, setPieceInputValue] = useState('');
  const [conceptInputValue, setConceptInputValue] = useState('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [
    selectedConcept,
    setSelectedConcept,
  ] = useState<GetConceptsQuery['getConcepts'][0] | null>(null);
  const [selectedArea, setSelectedArea] = useState('Paint');
  const [executeAddBudgetItem] = useAddBudgetItemMutation({
    variables: {
      input: {
        pieceId: +selectedPiece?.id,
        conceptId: +selectedConcept?.id,
        price: +item.price,
      },
      budgetId: +budget.id,
    },
    onCompleted: (data) => {
      setBudget({
        ...budget,
        items: [
          ...budget.items,
          data.addBudgetItem,
        ],
      });
      setTotalPrice(totalPrice + data.addBudgetItem.price);
      setOpen(false);
      setHasChanged(true);
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  const [executeUpdateBudgetItem] = useUpdateBudgetItemMutation({
    variables: {
      itemId: +item.id,
      conceptId: +selectedConcept?.id,
      price: item.price,
      pieceId: +selectedPiece?.id,
    },
    onCompleted: (data) => {
      setBudget({
        ...budget,
        items: budget.items.map((actualItem) => {
          if (item.id === actualItem.id) {
            return data.updateBudgetItem;
          }
          return actualItem;
        }),
      });
      setTotalPrice(totalPrice + data.updateBudgetItem.price);
      setOpen(false);
      setHasChanged(true);
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  function getPieces(): GetPiecesQuery['getPieces'] {
    if (selectedArea === 'Paint') {
      return paintPieces;
    }
    if (selectedArea === 'Dent') {
      return dentPieces;
    }
    if (selectedArea === 'Mechanics') {
      return mechanicsPieces;
    }
    return thirdPartyPieces;
  }

  function getConcepts(): GetConceptsQuery['getConcepts'] {
    if (selectedArea === 'Paint') {
      return paintConcepts;
    }
    if (selectedArea === 'Dent') {
      return dentConcepts;
    }
    if (selectedArea === 'Mechanics') {
      return mechanicsConcepts;
    }
    return thirdPartyConcepts;
  }

  useEffect(() => {
    setSelectedPiece(item.id ? item.piece : null);
  }, [open]);

  useEffect(() => {
    if (paintConcepts.some((concept) => concept.name === item.concept.name)) {
      setSelectedArea('Paint');
    } else if (dentConcepts.some((concept) => concept.name === item.concept.name)) {
      setSelectedArea('Dent');
    } else if (mechanicsConcepts.some((concept) => concept.name === item.concept.name)) {
      setSelectedArea('Mechanics');
    } else {
      setSelectedArea('ThirdParty');
    }
    setSelectedConcept(item.id ? item.concept : null);
  }, [open]);

  useEffect(() => {
    if (selectedPiece && selectedConcept && item.price >= 0) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [selectedPiece, selectedConcept, item.price]);

  useEffect(() => {
    setOriginalPrice(item.price);
  }, [open]);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth={false}
      ref={dialogRef}
    >
      <div className={classes.closeBox}>
        <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
      </div>
      <DialogTitle className={classes.dialogTitle}>
        {item.id ? 'Editar' : 'Agregar'}
      </DialogTitle>
      <div className={classes.formContainer}>
        <FormControl className={classes.formInput}>
          <InputLabel id="stage-select">Area</InputLabel>
          <Select
            value={selectedArea}
            labelId="stage-select"
            onChange={(e) => {
              setSelectedArea(e.target.value as string);
              setSelectedPiece(null);
              setSelectedConcept(null);
            }}
          >
            <MenuItem value="Paint">Pintura</MenuItem>
            <MenuItem value="Dent">Desabolladura</MenuItem>
            <MenuItem value="Mechanics">Mecánica</MenuItem>
            <MenuItem value="ThirdParty">Servicio a terceros</MenuItem>
          </Select>
        </FormControl>
        <Autocomplete
          value={selectedPiece}
          onChange={(_e, newValue: string | GetPiecesQuery['getPieces'][0] | null) => {
            setSelectedPiece(newValue as GetPiecesQuery['getPieces'][0]);
          }}
          getOptionSelected={(option, value) => (value as GetPiecesQuery['getPieces'][0]).name
          === (option as GetPiecesQuery['getPieces'][0]).name}
          className={classes.formInput}
          getOptionLabel={(option) => (option as GetPiecesQuery['getPieces'][0]).name}
          options={getPieces()}
          inputValue={pieceInputValue}
          id="pieces-select"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Pieza"
              FormHelperTextProps={{ className: classes.helperText }}
              helperText={selectedPiece ? '\u00a0' : 'Seleccione una pieza'}
            />
          )}
          onInputChange={(_e, value) => {
            setPieceInputValue(value);
          }}
        />
        <Autocomplete
          value={selectedConcept}
          className={classes.formInput}
          onChange={(_e, newValue: string | GetConceptsQuery['getConcepts'][0] | null) => {
            setSelectedConcept(newValue as GetConceptsQuery['getConcepts'][0]);
          }}
          getOptionSelected={(option, value) => (value as GetConceptsQuery['getConcepts'][0]).name
          === (option as GetConceptsQuery['getConcepts'][0]).name}
          getOptionLabel={(option) => (option as GetConceptsQuery['getConcepts'][0]).name}
          options={getConcepts()}
          inputValue={conceptInputValue}
          id="concepts-select"
          renderInput={(params) => (
            <TextField
              {...params}
              label="Concepto"
              FormHelperTextProps={{ className: classes.helperText }}
              helperText={selectedConcept ? '\u00a0' : 'Seleccione un concepto'}
            />
          )}
          onInputChange={(_e, value) => {
            setConceptInputValue(value);
          }}
        />
        <TextField
          label="Precio"
          className={classes.formInput}
          value={item.price >= 0 ? `$${item.price.toLocaleString('de-DE')}` : ''}
          FormHelperTextProps={{ className: classes.helperText }}
          helperText={item.price >= 0 ? '\u00a0' : 'Ingrese un precio'}
          onChange={(e) => setItem({
            ...item,
            price: +e.target.value.replace('$', '').replace(/\./g, '')
              ? +e.target.value.replace('$', '').replace(/\./g, '') : -1,
          })}
        />
        <Button
          variant="contained"
          className={isFormValid ? classes.saveButton : classes.saveButtonDisabled}
          onClick={() => {
            if (item.id && isFormValid) {
              reducer({ type: 'LOADING' });
              setTotalPrice(totalPrice - originalPrice);
              executeUpdateBudgetItem();
            } else if (isFormValid) {
              reducer({ type: 'LOADING' });
              executeAddBudgetItem();
            }
          }}
        >
          {item.id ? 'Guardar' : 'Agregar'}
        </Button>
      </div>
    </Dialog>
  );
}

export default ItemDialog;
