import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.scss';
import { ApolloProvider } from '@apollo/client/react';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import state from './store/getGlobalState';
import App from './App';
import reportWebVitals from './reportWebVitals';

const { REACT_APP_BACKEND_URL } = process.env;

const httpLink = createHttpLink({
  uri: REACT_APP_BACKEND_URL,
});

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    authorization: state().token ? `Bearer ${state().token}` : '',
  },
}));

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
