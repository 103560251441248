import { ReactElement, useRef, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Button, Typography } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import SendIcon from '@material-ui/icons/Send';
import GetAppIcon from '@material-ui/icons/GetApp';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import { useSignS3Mutation, useUpdatePiecesMutation } from '../../types';
import usePiecesManagerStyles from '../../styles/pieces_manager_style';
import { reducer } from '../../store/reducer';

function PiecesManager(): ReactElement {
  const classes = usePiecesManagerStyles();
  const hiddenInput = useRef(null);
  const [selectedFile, setSelectedFile] = useState<File>();
  const [alert, setAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [succes, setSucces] = useState(false);
  const [executeSignS3] = useSignS3Mutation({
    variables: {
      filename: 'VOCABULARIO.csv',
      filetype: 'text/csv',
    },
    onError: (error: ApolloError) => {
      reducer({ type: 'LOADING' });
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setSucces(false);
        setAlert(true);
        setErrorMessage('Lo sentimos ha ocurrido algo inesperado, intente denuevo más tarde.');
      }
    },
  });
  const [updatePieces] = useUpdatePiecesMutation({
    onCompleted: () => {
      setSucces(true);
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      reducer({ type: 'LOADING' });
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setSucces(false);
        setAlert(true);
        setErrorMessage('Lo sentimos ha ocurrido algo inesperado, intente denuevo más tarde.');
      }
    },
  });

  async function sendChanges() {
    try {
      reducer({ type: 'LOADING' });
      const mutationData = await executeSignS3();
      const { signS3 } = mutationData.data;
      const options = {
        headers: {
          'Content-Type': selectedFile.type,
        },
      };
      await axios.put(signS3.signedRequest, selectedFile, options);
      updatePieces();
    } catch {
      setAlert(true);
    }
  }

  return (
    <div className={classes.managerContainer}>
      {alert && (
        <Alert severity="error" className={classes.alert}>
          {errorMessage}
        </Alert>
      )}
      {succes && (
        <Alert severity="success" className={classes.alert}>
          La piezas fueron actualizadas exitosamente.
        </Alert>
      )}
      <Typography variant="h2" className={classes.title}>
        Administrar Piezas
      </Typography>
      <div className={classes.downloadDiv}>
        <Button
          startIcon={<GetAppIcon />}
          className={classes.downloadButton}
          onClick={() => {
            window.location.href = 'https://quelle-alfa.s3.amazonaws.com/VOCABULARIO.csv';
          }}
        >
          Descargar actual
        </Button>
      </div>
      <input
        ref={hiddenInput}
        className={classes.hiddenInput}
        type="file"
        accept=".csv"
        onChange={(e) => {
          if (e.target.files[0].name.split('.').pop() === 'csv') {
            setAlert(false);
            setSelectedFile(e.target.files[0] as File);
          } else {
            setAlert(true);
            setErrorMessage('Debe seleccionar un archivo .csv');
          }
        }}
      />
      {!!selectedFile && (
        <div className={classes.fileName}>
          {selectedFile.name}
        </div>
      )}
      <Button
        className={classes.fileInput}
        startIcon={<PublishIcon />}
        onClick={() => {
          hiddenInput.current.click();
        }}
      >
        {selectedFile ? 'Cambiar archivo' : 'Cargar archivo'}
      </Button>
      {!!selectedFile && (
        <Button
          className={classes.fileInput}
          startIcon={<SendIcon />}
          onClick={() => sendChanges()}
        >
          Enviar cambios
        </Button>
      )}
      <div className={classes.infoText}>
        <p className={classes.justifiedText}>
          {'(*) Recuerda seguir el formato especificado para el archivo. '
          + 'Todos los nombres de las piezas deben ir bajo el titulo PIEZAS y ninguno debe contener comas. '
          + 'Además, el archivo debe tener formato csv. Si tienes algun problema con el formato descarga el '}
          <a href="https://quelle-alfa.s3.amazonaws.com/VOCABULARIO_MUESTRA.csv">
            archivo original.
          </a>
        </p>
      </div>
    </div>
  );
}

export default PiecesManager;
