import { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import EntryReservationsTable from '../../components/table/entryReservations';
import { useStyles } from '../../styles/reservationView_style';

function EntryReservationsTableList(): ReactElement {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.grid}
      justify="center"
      alignItems="center"
      direction="column"
    >
      <Grid item>
        <Typography variant="h2" className={classes.root}>
          Recepciones Agendadas
        </Typography>
      </Grid>
      <Grid item>
        <EntryReservationsTable />
      </Grid>
    </Grid>
  );
}

export default EntryReservationsTableList;
