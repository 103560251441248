import {
  ReactElement,
  useEffect,
} from 'react';
import {
  TextField,
  Box,
} from '@material-ui/core';
import useBudgetAppointmentStyles from '../../styles/budget_appointment';
import rutFormat from '../../helpers/rutFormat';
import mailRegex from '../../helpers/mailRegex';
import { RegisterDataInterface } from '../../interfaces/components.interfaces';
import validateRut from '../../helpers/validateRut';

function RegisterData(props: RegisterDataInterface) : ReactElement {
  const {
    name,
    setName,
    rut,
    setRut,
    mail,
    setMail,
    isNameValid,
    setIsNameValid,
    isMailValid,
    setIsMailValid,
    isRutValid,
    setIsRutValid,
  } = props;
  const classes = useBudgetAppointmentStyles();
  useEffect(() => {
    if (name.length > 0) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  }, [name]);

  useEffect(() => {
    if (mailRegex.test(mail)) {
      setIsMailValid(true);
    } else {
      setIsMailValid(false);
    }
  }, [mail]);

  useEffect(() => {
    if (rut.length >= 11 && validateRut(rut)) {
      setIsRutValid(true);
    } else {
      setIsRutValid(false);
    }
  }, [rut]);

  return (
    <Box className={classes.inputsBox}>
      <TextField
        label="Nombre"
        helperText={!isNameValid ? 'Ingrese su nombre y apellido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setName(e.target.value)}
        value={name}
        className={classes.formInput}
      />
      <TextField
        label="Email"
        helperText={!isMailValid && mail.length > 0 ? 'Ingrese un mail válido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setMail(e.target.value)}
        value={mail}
        className={classes.formInput}
      />
      <TextField
        label="RUT"
        helperText={!isRutValid && rut ? 'Ingrese un RUT válido' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setRut(rutFormat(e.target.value))}
        value={rut}
        className={classes.formInput}
      />
    </Box>
  );
}
export default RegisterData;
