import {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventIcon from '@material-ui/icons/Event';
import Fab from '@material-ui/core/Fab';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { useParams } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import Paper from '@material-ui/core/Paper';
import Alert from '@material-ui/lab/Alert';
import concepts from '../../helpers/translateInventory';
import Loader from '../../components/loader/loader';
import useLoginStyles from '../../styles/folio_style';
import { useGetReceptionByIdShowLazyQuery, GetReceptionByIdShowQuery } from '../../types';
import useBudgetShowStyles from '../../styles/budget_show';
import ReceptionImageDialog from '../../components/reception_dialog/imageDialog';

const externalInventory: GetReceptionByIdShowQuery['getReceptionById']['externalAndOtherInventory'] = {
  antenna: false,
  handles: false,
  leftMirror: false,
  logos: false,
  reverseCamera: false,
  reverseSensors: false,
  rightMirror: false,
  wheelCover: false,
};

const initialClient: GetReceptionByIdShowQuery['getReceptionById']['budget']['client'] = {
  name: '',
  lastname: '',
  phone: '',
  rut: '',
  email: '',
};

const initialCar: GetReceptionByIdShowQuery['getReceptionById']['budget']['car'] = {
  brand: '',
  model: '',
  year: 0,
  plate: '',
  chasis: '',
  color: '',
};

const InternalInventory: GetReceptionByIdShowQuery['getReceptionById']['internalInventory'] = {
  aplifier: false,
  ashtray: false,
  carDocuments: false,
  carpets: false,
  cloth: false,
  contactKey: false,
  fogger: false,
  fuel: '0%',
  lighter: false,
  panel: false,
  radioAndSpeakers: false,
  radioPanel: false,
  rearviewMirror: false,
  reflectingVest: false,
  tag: false,
  tapestry: false,
  valuableObject: false,
  woofer: false,
};

const workingItemsInitial: GetReceptionByIdShowQuery['getReceptionById']['workingItems'] = {
  ac: false,
  alarm: false,
  backLights: false,
  blinker: false,
  centralLock: false,
  fireExtinguisher: false,
  firstAidKit: false,
  frontLights: false,
  glassRisers: false,
  heating: false,
  horn: false,
  jack: false,
  motorCover: false,
  replacementWheel: false,
  triangle: false,
  wheelKey: false,
  windshield: false,
  windshieldNibs: false,
};

const booleanInterpreter = (state: boolean) => {
  if (!state) {
    return 'No';
  }
  if (state === true) {
    return 'Sí';
  }
  return state;
};

function dateFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  const year: number = date.getFullYear();
  const month: number = (date.getMonth());
  const day: number = date.getDate();
  return moment(new Date(year, month, day)).format('DD/MM/YYYY');
}

function timeFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  let hour: string = date.getUTCHours().toString();
  let min: string = date.getUTCMinutes().toString();
  if (min.length === 1) {
    min = `0${min}`;
  }
  if (hour.length === 1) {
    hour = `0${hour}`;
  }
  return `${hour}:${min}`;
}

function ReceptionShow(): ReactElement {
  const { id }: { id: string } = useParams();
  const classes = useBudgetShowStyles();
  const loginClasses = useLoginStyles();
  const [mobile, setMobile] = useState(false);
  const [alert, setAlert] = useState(false);
  const [client, setClient] = useState<
  GetReceptionByIdShowQuery['getReceptionById']['budget']['client']>(initialClient);
  const [internalInventory, setInternalInventory] = useState<
  GetReceptionByIdShowQuery['getReceptionById']['internalInventory']>(InternalInventory);
  const [externalAndOtherInventory, setExternalAndOtherInventory] = useState<
  GetReceptionByIdShowQuery['getReceptionById']['externalAndOtherInventory']>(externalInventory);
  const [workingItems, setWorkingItems] = useState<
  GetReceptionByIdShowQuery['getReceptionById']['workingItems']>(workingItemsInitial);
  const [car, setCar] = useState<
  GetReceptionByIdShowQuery['getReceptionById']['budget']['car']>(initialCar);
  const [images, setImages] = useState<string[]>([]);
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [openImageDisplay, setOpenImageDisplay] = useState<boolean>(false);
  const [actualImage, setActualImage] = useState<string>('');
  const [date, setDate] = useState('');
  const [hour, setHour] = useState('');
  const [observations, setObservations] = useState('');
  const formatingData = (data: GetReceptionByIdShowQuery) => {
    setCar(data.getReceptionById.budget.car);
    setClient(data.getReceptionById.budget.client);
    setInternalInventory(data.getReceptionById.internalInventory);
    setExternalAndOtherInventory(data.getReceptionById.externalAndOtherInventory);
    setWorkingItems(data.getReceptionById.workingItems);
    setImages([
      data.getReceptionById.details,
      data.getReceptionById.clientSignature,
    ]);
    setDate(dateFormat(data.getReceptionById.receptionDate));
    setHour(timeFormat(data.getReceptionById.receptionDate));
    setObservations(data.getReceptionById.observations);
  };

  const resizeView = () => {
    if (window.innerWidth < 870) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const [executeGetReception, { loading }] = useGetReceptionByIdShowLazyQuery({
    variables: {
      id: +id,
    },
    onCompleted: (data) => {
      formatingData(data);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlert(true);
      }
    },
  });

  useEffect(() => {
    resizeView();
    executeGetReception();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeView);
  }, []);

  if (mobile) {
    return (
      <div className={classes.budgetContainer}>
        {loading && <Loader />}
        {alert && <Alert className={loginClasses.alert} severity="error">Problema cargando la solicitud</Alert>}
        {openImageDisplay && (
          <div className={classes.imageDiv}>
            <div className={classes.imageCloseIconContainer}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setOpenImageDisplay(false)}
              />
            </div>
            <img
              alt=""
              src={actualImage}
              className={classes.imageDisplay}
            />
          </div>
        )}
        <h1 className={classes.title}>Recepción</h1>
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <EventIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Hora recepción</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Fecha:
                </p>
                <p className={classes.statValue}>
                  {` ${date}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Hora:
                </p>
                <p className={classes.statValue}>
                  {` ${hour} hrs.`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <AccountCircleIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Datos cliente</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Nombre:
                </p>
                <p className={classes.statValue}>
                  {` ${client.name}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Apellido:
                </p>
                <p className={classes.statValue}>
                  {` ${client.lastname}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Rut:
                </p>
                <p className={classes.statValue}>
                  {` ${client.rut}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Email:
                </p>
                <p className={classes.statValue}>
                  {` ${client.email}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Numero:
                </p>
                <p className={classes.statValue}>
                  {` ${client.phone}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <DirectionsCarIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Datos auto</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Marca:
                </p>
                <p className={classes.statValue}>
                  {` ${car.brand}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Modelo:
                </p>
                <p className={classes.statValue}>
                  {` ${car.model}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Año:
                </p>
                <p className={classes.statValue}>
                  {` ${car.year}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Patente:
                </p>
                <p className={classes.statValue}>
                  {` ${car.plate}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Chasis:
                </p>
                <p className={classes.statValue}>
                  {` ${car.chasis}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Color:
                </p>
                <p className={classes.statValue}>
                  {` ${car.color}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <ListAltIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Observaciones</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statValue}>
                  {`${observations}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <ListAltIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Items auto</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <div className={classes.itemsContainer}>
              {Object.keys(workingItems).map((item) => (
                <div key={item} className={classes.itemContainer}>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Item:</p>
                    <p className={classes.statValue}>{` ${concepts[item]}`}</p>
                  </div>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Funcionando:</p>
                    <p className={classes.statValue}>{` ${booleanInterpreter(workingItems[item])}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <ListAltIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Inventario interior</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.itemsContainer}>
              {Object.keys(internalInventory).map((item) => (
                <div key={item} className={classes.itemContainer}>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Item:</p>
                    <p className={classes.statValue}>{` ${concepts[item]}`}</p>
                  </div>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Incluido:</p>
                    <p className={classes.statValue}>{` ${booleanInterpreter(internalInventory[item])}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <ListAltIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Inventario exterior</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.itemsContainer}>
              {Object.keys(externalAndOtherInventory).map((item) => (
                <div key={item} className={classes.itemContainer}>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Item:</p>
                    <p className={classes.statValue}>{` ${concepts[item]}`}</p>
                  </div>
                  <div className={classes.statContainer}>
                    <p className={classes.statName}>Incluido:</p>
                    <p className={classes.statValue}>{` ${booleanInterpreter(externalAndOtherInventory[item])}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.lastAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <PermMediaIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Imágenes</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <div className={classes.imagesContainer}>
              {images.map((image) => (
                <div
                  key={image}
                  className={classes.budgetImageContainer}
                >
                  <img
                    className={classes.budgetImage}
                    alt=""
                    src={image}
                    onClick={() => {
                      setActualImage(image);
                      setOpenImageDisplay(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
  return (
    <div className={classes.receptionShow}>
      {loading && <Loader />}
      <Fab
        color="primary"
        className={classes.imageButton}
        onClick={() => setOpenImageDialog(true)}
      >
        <PermMediaIcon />
      </Fab>
      <ReceptionImageDialog
        open={openImageDialog}
        onClose={() => {
          setOpenImageDialog(false);
        }}
        setOpen={setOpenImageDialog}
        images={images}
        setActualImage={setActualImage}
        setOpenDisplay={setOpenImageDisplay}
      />
      {openImageDisplay && (
        <div className={classes.imageDiv}>
          <div className={classes.imageCloseIconContainer}>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => setOpenImageDisplay(false)}
            />
          </div>
          <img
            alt=""
            src={actualImage}
            className={classes.imageDisplay}
          />
        </div>
      )}
      <div className={classes.personalDataContainer}>
        <h1 className={classes.personalTitle}>Recepción</h1>
        <h3 className={classes.personalTitle}>Hora Recepción</h3>
        <div className={classes.desktopStatContainer}>
          <p className={classes.desktopStatName}>Fecha:</p>
          <p className={classes.desktopStatValue}>{` ${date}`}</p>
        </div>
        <div className={classes.desktopStatContainer}>
          <p className={classes.desktopStatName}>Hora:</p>
          <p className={classes.desktopStatValue}>{` ${hour} hrs.`}</p>
        </div>
      </div>
      <div className={classes.generalDataContainer}>
        <div className={classes.personalDataContainer}>
          <h1 className={classes.personalTitle}>Cliente</h1>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Nombre:</p>
            <p className={classes.desktopStatValue}>{` ${client.name}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Apellidos:</p>
            <p className={classes.desktopStatValue}>{` ${client.lastname}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>RUT:</p>
            <p className={classes.desktopStatValue}>{` ${client.rut}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Email:</p>
            <p className={classes.desktopStatValue}>{` ${client.email}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Número:</p>
            <p className={classes.desktopStatValue}>{` ${client.phone}`}</p>
          </div>
        </div>
        <div className={classes.dataSeparator} />
        <div className={classes.personalDataContainer}>
          <h1 className={classes.personalTitle}>Auto</h1>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Marca:</p>
            <p className={classes.desktopStatValue}>{` ${car.brand}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Modelo:</p>
            <p className={classes.desktopStatValue}>{` ${car.model}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Año:</p>
            <p className={classes.desktopStatValue}>{` ${car.year}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Patente:</p>
            <p className={classes.desktopStatValue}>{` ${car.plate}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Chasis:</p>
            <p className={classes.desktopStatValue}>{` ${car.chasis}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Color:</p>
            <p className={classes.desktopStatValue}>{` ${car.color}`}</p>
          </div>
        </div>
      </div>
      <div className={classes.descriptionDataContainer}>
        <h1 className={classes.personalTitle}>Observaciones</h1>
        <div className={classes.desktopStatContainer}>
          <p className={classes.desktopStatValue}>{`${observations}`}</p>
        </div>
      </div>
      <div className={classes.dypContainer}>
        <div className={classes.titleContainer}>
          <h1 className={classes.personalTitle}>Items en funcionamiento</h1>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} align="left">Item</TableCell>
                <TableCell className={classes.tableHeader} align="right">Funcionando</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(workingItems).map((item) => (
                <TableRow key={item}>
                  <TableCell align="left">{concepts[item]}</TableCell>
                  <TableCell align="right">{booleanInterpreter(workingItems[item])}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.dypContainer}>
        <div className={classes.titleContainer}>
          <h1 className={classes.personalTitle}>Inventario Interior</h1>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} align="left">Elemento</TableCell>
                <TableCell className={classes.tableHeader} align="right">Incluido</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(internalInventory).map((item) => (
                <TableRow key={item}>
                  <TableCell align="left">{concepts[item]}</TableCell>
                  <TableCell align="right">{booleanInterpreter(internalInventory[item])}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={classes.dyplastContainer}>
        <div className={classes.titleContainer}>
          <h1 className={classes.personalTitle}>Inventario Exterior</h1>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader} align="left">Elemento</TableCell>
                <TableCell className={classes.tableHeader} align="right">Incluido</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(externalAndOtherInventory).map((item) => (
                <TableRow key={item}>
                  <TableCell align="left">{concepts[item]}</TableCell>
                  <TableCell align="right">{booleanInterpreter(externalAndOtherInventory[item])}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default ReceptionShow;
