import { ReactElement, useState } from 'react';
import { Button } from '@material-ui/core';
import Calendar from '../calendar/calendar';
import { useUpdateReservationMutation } from '../../types';
import { SchedulerInterface } from '../../interfaces/components.interfaces';
import { useStyles } from '../../styles/rescheduler';

function Scheduler(props: SchedulerInterface): ReactElement {
  const {
    id,
    idService,
    plate,
    setAlert,
    setAlertContent,
    setSeverity,
  } = props;
  const [disponibility, setDisponibility] = useState<string[]>([]);
  const [initial, setInitial] = useState(0);
  const [daySelected, setDaySelected] = useState('');
  const [hour, setHour] = useState('');
  const [reserve0, setReserve0] = useState<string[]>([]);
  const [reservations, setReservations] = useState<number[]>([]);
  const [carPlate, setCarPlate] = useState(plate);
  const [carReservations, setCarReservations] = useState<string[]>([]);
  const [executeUpdateReservation] = useUpdateReservationMutation({});
  const classes = useStyles();

  const formatTime = () => {
    if (hour.length === 7) {
      return `${daySelected}T0${hour}.000Z`;
    }
    return `${daySelected}T${hour}.000Z`;
  };

  async function updateReservation() {
    executeUpdateReservation({
      variables: {
        id: props.id,
        date: formatTime(),
      },
    })
      .then(() => {
        setAlertContent('Su cambio de fecha se realizó con éxito');
        setSeverity('success');
        setAlert(true);
        window.location.href = `/admin/reservas/${id}`;
      })
      .catch((error) => {
        if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
          window.location.href = '/admin/cambio-clave';
        } else {
          setAlertContent('Falló su solicitud. Intente más tarde');
          setSeverity('error');
          setAlert(true);
        }
      });
  }

  async function handleUpdateReservation() {
    if (hour !== '' && daySelected !== '') {
      updateReservation();
    }
  }

  return (
    <div className={classes.scheduler}>
      <Calendar
        day={daySelected}
        hour={hour}
        reserve0={reserve0}
        setDay={setDaySelected}
        setHour={setHour}
        setReserve0={setReserve0}
        disponibility={disponibility}
        setDisponibility={setDisponibility}
        area={idService}
        initial={initial}
        setInitial={setInitial}
        reservations={reservations}
        setReservations={setReservations}
        plate={carPlate}
        setPlate={setCarPlate}
        carReservations={carReservations}
        setCarReservations={setCarReservations}
      />
      <Button
        className={((daySelected !== '') && (hour !== ''))
          ? classes.buttonActive : classes.buttonDisabled}
        onClick={handleUpdateReservation}
      >
        Reagendar
      </Button>
    </div>
  );
}

export default Scheduler;
