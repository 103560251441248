import { ReactElement, useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { Button } from '@material-ui/core';
import { ReservationsQuery, useReservationsLazyQuery, useAllReservationsLazyQuery } from '../../types';
import { ExportReservationInterface } from '../../interfaces/components.interfaces';
import useButtonstyles from '../../styles/exportButton_style';
import { reducer } from '../../store/reducer';

function timeFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  let hour: string = date.getUTCHours().toString();
  let min: string = date.getUTCMinutes().toString();
  if (min.length === 1) {
    min = `0${min}`;
  }
  if (hour.length === 1) {
    hour = `0${hour}`;
  }
  return `${hour}:${min}`;
}

function dateFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  const year: number = date.getFullYear();
  const month:number = (date.getMonth());
  const day: number = date.getDate();
  return moment(new Date(year, month, day)).format('DD/MM/YYYY');
}
function formatingData(data: ReservationsQuery['reservations']) {
  const rows: ExportReservationInterface[] = [];
  data.forEach((element) => {
    const reservation: ExportReservationInterface = {
      Id: +element.id,
      RUT_cliente: element.client.rut,
      Hora: timeFormat(element.date),
      Fecha: dateFormat(element.date),
      Patente: element.car.plate,
      Id_servicio: +element.service.id,
      Id_seguro: element.insurance ? +element.insurance.id : null,
      Descripción: element.description,
    };
    rows.push(reservation);
  });
  return rows;
}
function ReservedBudget() : ReactElement {
  const classes = useButtonstyles();
  const fileName = 'Horas para presupuesto';
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const [rowData, setRowData] = useState([]);
  const [amount, setAmount] = useState(-1);

  const exportToCSV = (dataCSV: ExportReservationInterface[], NameFile: string) => {
    const ws = XLSX.utils.json_to_sheet(dataCSV);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, NameFile + fileExtension);
  };

  const [executeReservationsQuery] = useReservationsLazyQuery({
    onCompleted: (data) => {
      setRowData(formatingData(data.reservations));
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  const [executeAllReservations] = useAllReservationsLazyQuery({
    onCompleted: (data) => {
      setAmount(data.allReservations);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  useEffect(() => {
    if (rowData.length > 0) {
      exportToCSV(rowData, fileName);
    }
  }, [rowData]);
  useEffect(() => {
    if (amount > -1) {
      executeReservationsQuery({
        variables: {
          page: 1,
          take: amount,
        },
      });
    }
  }, [amount]);

  return (
    <Button
      onClick={() => {
        reducer({ type: 'LOADING' });
        executeAllReservations();
      }}
      className={classes.pageButtonActive}
    >
      Datos reservas para presupuesto
    </Button>
  );
}
export default ReservedBudget;
