import { ReactElement, useRef } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import { ReservationDeleteImageDialogInterface } from '../../interfaces/components.interfaces';
import useDeleteDialogStyle from '../../styles/delete_dialog';
import Loader from '../loader/loader';
import {
  useDeleteReservationImageMutation,
  DeleteReservationImageMutation,
} from '../../types';

function ReservationDeleteImageDialog(props: ReservationDeleteImageDialogInterface): ReactElement {
  const classes = useDeleteDialogStyle();
  const {
    id,
    open,
    image,
    field,
    onClose,
    setOpen,
    setCarnetImages,
    setDeclImages,
    setPermImages,
  } = props;

  const [executeDeleteReservationImageMutation, { loading }] = useDeleteReservationImageMutation({
    variables: {
      reservationId: id,
      link: image,
      field,
    },
    onCompleted: (reservationData: DeleteReservationImageMutation) => {
      setCarnetImages([...reservationData.deleteReservationImage.client.carnet]);
      setDeclImages([...reservationData.deleteReservationImage.declaracionJurada]);
      setPermImages([...reservationData.deleteReservationImage.car.permisoCirculacion]);

      setOpen(false);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const dialogRef = useRef(null);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth={false}
      ref={dialogRef}
    >
      <DialogTitle className={classes.deleteMessage}>¿Esta seguro que desea eliminar?</DialogTitle>
      <div className={classes.buttonsContainer}>
        {loading && <Loader />}
        <div
          className={classes.leftButtonContainer}
          onClick={() => {
            executeDeleteReservationImageMutation({
              variables: {
                reservationId: +id,
                field,
                link: image,
              },
            });
          }}
        >
          <p className={classes.optionMessage}>Si</p>
        </div>
        <div
          className={classes.rightButtonContainer}
          onClick={() => setOpen(false)}
        >
          <p className={classes.optionMessage}>No</p>
        </div>
      </div>
    </Dialog>
  );
}

export default ReservationDeleteImageDialog;
