import { ReactElement } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import WithoutInsuranceModal from '../insurance/withoutinsurance';
import WithInsuranceModal from '../insurance/withinsurance';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      maxWidth: '70%',
    },
  },
}));

function Reserve(): ReactElement {
  const classes = useStyles();
  return (
    <>
      <CssBaseline />
      <Container maxWidth="lg">
        <div className={classes.root}>
          <Grid container spacing={10}>
            <Grid item xs={12}>
              <Paper className={classes.paper} elevation={0}>Selecciona una Opción</Paper>
            </Grid>
            <Grid item xs={12} container spacing={2}>
              <Grid item xs={6} direction="column" alignItems="center" justify="center">
                <Paper className={classes.paper}>
                  <WithInsuranceModal />
                </Paper>
              </Grid>
              <Grid item xs={6}>
                <Paper className={classes.paper}>
                  <WithoutInsuranceModal />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
}

export default Reserve;
