import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({

  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  dates: {
    maxWidth: '100vw',
    overflow: 'auto',
    '& > *': {
      margin: theme.spacing(0.5),
      height: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      maxWidth: '50%',
    },
    [theme.breakpoints.down('md')]: {
      display: 'block',
      textAlign: 'center',
    },
  },
  color: {
    background: '#115fa6',
    color: 'white',
    border: '1px solid black',
    '&:hover': {
      background: '#115fa6',
      color: 'white',
    },
  },
  notColor: {
    color: '#115fa6',
    border: '1px solid black',
    background: 'white',
    '&:hover': {
      background: 'white',
      color: '#115fa6',
    },
  },
  hora: {
    border: '1px solid black',
    borderRadius: '15px',
    margin: theme.spacing(0.5),
    background: 'white',
    width: '60vw',
  },
  daysF: {
    marginBottom: theme.spacing(2),
  },
  hours: {
    width: '100%',
    height: '100%',
  },
  lastButton: {
    color: '#FFFFFF',
    backgroundColor: '#126da6',
    cursor: 'pointer',
    margin: theme.spacing(2),
    float: 'left',
    '&:hover': {
      background: '#126da6',
      color: '#FFFFFF',
    },
  },
  spacing: {
    margin: theme.spacing(2),
  },
  hColor: {
    background: '#115fa6',
    textAlign: 'center',
    border: '1px solid black',
    borderRadius: '15px',
    margin: theme.spacing(0.5),
    color: 'white',
    '&:hover': {
      background: '#115fa6',
      color: 'white',
    },
  },
  formInput: {
    width: '60vw',
    marginButton: '2vh',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      width: '30vw',
    },
  },
  centered: {
    textAlign: 'center',
  },
  gridHours: {
    marginButton: '0vh',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      width: '40vw',
    },
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      width: '80vw',
    },
  },
  hNotColor: {
    color: '#115fa6',
    textAlign: 'center',
    border: '1px solid black',
    borderRadius: '15px',
    margin: theme.spacing(0.5),
    background: 'white',
  },
  containerForm: {
    background: '#F6F4F4',
    borderRadius: '20px',
    maxWidth: '100vw',
    border: '1px solid #115fa6',
  },
  reserved: {
    cursor: 'not-allowed',
    color: '#CFCFCF',
    textAlign: 'center',
    border: '1px solid white',
    borderRadius: '15px',
    margin: theme.spacing(0.5),
    '&:hover': {
      background: '#FFFFFF',
      color: '#CFCFCF',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing(1),
    minWidth: 200,
    [theme.breakpoints.up('md')]: {
      marginTop: '0vh',
    },
  },

}));
export default useStyles;
