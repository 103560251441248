import { makeStyles, createStyles } from '@material-ui/core/styles';

const useDelayDialogStyles = makeStyles((theme) => createStyles({
  dialog: {
    padding: '2vh',
  },
  closeBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: '2vw',
    paddingRight: '2vw',
    paddingLeft: '2vw',
    width: '75vw',
    maxWidth: '400px',
    maxHeight: '400px',
  },
  closeIcon: {
    maxWidth: '50px',
    maxHeight: '50px',
    fontSize: '10vw',
    color: theme.palette.primary.main,
  },
  title: {
    width: '75vw',
    maxWidth: '400px',
    padding: '0 4vw',
    fontSize: '4vh',
  },
  formInput: {
    width: '67vw',
    maxWidth: '400px',
    marginTop: '4vw',
    [theme.breakpoints.up('sm')]: {
      margin: '0 4vw',
      width: '100%',
    },
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '75vw',
    maxWidth: '400px',
    [theme.breakpoints.up('sm')]: {
      padding: '0 4vw',
    },
  },
  helperText: {
    color: 'red',
  },
  sendButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    marginTop: '4vw',
    width: '67vw',
    maxWidth: '400px',
    marginBottom: '4vw',
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
    },
    [theme.breakpoints.up('sm')]: {
      margin: '0 4vw',
      width: '100%',
      marginBottom: '4vw',
      '&:focus': {
        backgroundColor: theme.palette.primary.main,
      },
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

export default useDelayDialogStyles;
