import { ReactElement, useRef } from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import { DeleteImageDialogInterface } from '../../interfaces/components.interfaces';
import useDeleteDialogStyle from '../../styles/delete_dialog';
import { useDeleteBudgetImageMutation } from '../../types';
import { reducer } from '../../store/reducer';

function DeleteImageDialog(props: DeleteImageDialogInterface): ReactElement {
  const classes = useDeleteDialogStyle();
  const {
    open,
    onClose,
    setOpen,
    imageId,
    budget,
    setBudget,
  } = props;
  const [executeDeleteBudgetImageMutation] = useDeleteBudgetImageMutation({
    variables: {
      id: imageId,
    },
    onCompleted: () => {
      setBudget({
        ...budget,
        images: budget.images.filter((image) => +image.id !== imageId),
      });
      reducer({ type: 'LOADING' });
      setOpen(false);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const dialogRef = useRef(null);

  return (
    <Dialog
      onClose={() => onClose()}
      open={open}
      maxWidth={false}
      ref={dialogRef}
    >
      <DialogTitle className={classes.deleteMessage}>¿Esta seguro que desea eliminar?</DialogTitle>
      <div className={classes.buttonsContainer}>
        <div
          className={classes.leftButtonContainer}
          onClick={() => {
            reducer({ type: 'LOADING' });
            executeDeleteBudgetImageMutation();
          }}
        >
          <p className={classes.optionMessage}>Si</p>
        </div>
        <div
          className={classes.rightButtonContainer}
          onClick={() => setOpen(false)}
        >
          <p className={classes.optionMessage}>No</p>
        </div>
      </div>
    </Dialog>
  );
}

export default DeleteImageDialog;
