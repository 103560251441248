import { ReactElement } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Box, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { TracingInterface } from '../../interfaces/components.interfaces';
import useStyles from '../../styles/tracing_style';

function Tracing(props: TracingInterface) : ReactElement {
  const {
    deadLine,
    plate,
    currentProcess,
    brand,
    model,
    color,
    year,
    chasis,
    activeStep,
  } = props;
  const classes = useStyles();
  const fases: { [fase: string]: string } = {
    dent: 'Desabolladura',
    wash: 'Lavado',
    assemble: 'Armado',
    polish: 'Pulido',
    paint: 'Pintura',
    thirdPartyService: 'Servicios a terceros',
    mechanics: 'Mecánica',
  };
  const fasesSteps : string[] = [
    'Desabolladura',
    'Pintura',
    'Armado',
    'Servicios a terceros',
    'Mecánica',
    'Pulido',
    'Lavado',
  ];

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h2" className={classes.typography}>
        Seguimiento de tu vehículo
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.secondBox}
      >
        <form>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <TableContainer component={Paper} className={classes.table}>
              <Table size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Datos</TableCell>
                    <TableCell align="left">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      Patente
                    </TableCell>
                    <TableCell>
                      {plate}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Marca
                    </TableCell>
                    <TableCell>
                      {brand}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Modelo
                    </TableCell>
                    <TableCell>
                      {model}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Color
                    </TableCell>
                    <TableCell>
                      {color}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Año
                    </TableCell>
                    <TableCell>
                      {year}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      Chasis
                    </TableCell>
                    <TableCell>
                      {chasis}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

          </Box>
        </form>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.stepBox}
        >
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel className={classes.steps}>
              {fasesSteps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <div className={classes.text}>
              <p>
                Tu vehículo de encuentra actualmente en &nbsp;
                <b><strong>{fases[currentProcess]}</strong></b>
              </p>
              <p>
                Fecha estimada de finalización &nbsp;
                {deadLine}
              </p>
            </div>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
export default Tracing;
