import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  JSON: any;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  token: Scalars['String'];
  user: User;
};

export type BlockedHour = {
  __typename?: 'BlockedHour';
  id: Scalars['ID'];
  user?: Maybe<User>;
  date?: Maybe<Scalars['DateTime']>;
  services?: Maybe<ServiceBlockedHour>;
};

export type Budget = {
  __typename?: 'Budget';
  id: Scalars['ID'];
  car: Car;
  client: Client;
  insurance?: Maybe<Insurance>;
  images: Array<BudgetImage>;
  items: Array<BudgetItem>;
  user: User;
  replacements: Array<Replacement>;
  folio: Scalars['String'];
  sent: Scalars['Boolean'];
  workOrder?: Maybe<WorkOrder>;
  token?: Maybe<Scalars['String']>;
  status: BudgetStatusOption;
  reception?: Maybe<Reception>;
  entryReservation?: Maybe<EntryReservation>;
};

export type BudgetImage = {
  __typename?: 'BudgetImage';
  id: Scalars['ID'];
  link: Scalars['String'];
  budget: Budget;
};

export type BudgetImageInput = {
  url: Scalars['String'];
};

export type BudgetInput = {
  personalData: PersonalDataInput;
  images: Array<BudgetImageInput>;
  dentParts: Array<Maybe<PartInput>>;
  paintParts: Array<Maybe<PartInput>>;
  mechanicsParts: Array<Maybe<PartInput>>;
  thirdPartyServicesParts: Array<Maybe<PartInput>>;
  replacements: Array<ReplacementInput>;
  sendAuto: Scalars['Boolean'];
};

export type BudgetItem = {
  __typename?: 'BudgetItem';
  id: Scalars['ID'];
  price: Scalars['Int'];
  budget: Budget;
  concept: Concept;
  piece: Piece;
};

export enum BudgetStatusOption {
  Pendiente = 'PENDIENTE',
  Aprobado = 'APROBADO',
  Rechazado = 'RECHAZADO'
}

export type BudgetUpdateInput = {
  budgetID: Scalars['Int'];
  personalData: PersonalDataInput;
  sendAuto: Scalars['Boolean'];
};

export type Car = {
  __typename?: 'Car';
  id: Scalars['ID'];
  plate?: Maybe<Scalars['String']>;
  chasis?: Maybe<Scalars['String']>;
  inside?: Maybe<Scalars['Boolean']>;
  brand?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['Int']>;
  budgets: Array<Budget>;
  reservations?: Maybe<Array<Reservation>>;
  permisoCirculacion?: Maybe<Array<Scalars['String']>>;
};

export type CarWithDeposit = {
  __typename?: 'CarWithDeposit';
  car: Car;
  deposit?: Maybe<Scalars['Int']>;
};

export type Client = {
  __typename?: 'Client';
  rut?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  carnet?: Maybe<Array<Maybe<Scalars['String']>>>;
  reservations?: Maybe<Array<Reservation>>;
};

export type Concept = {
  __typename?: 'Concept';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  stage?: Maybe<Stage>;
  items: Array<BudgetItem>;
};


export type EntryReservation = {
  __typename?: 'EntryReservation';
  id: Scalars['Int'];
  budget?: Maybe<Budget>;
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
};

export type InputDetails = {
  budgetId: Scalars['Int'];
  details: Scalars['String'];
  clientSignature: Scalars['String'];
  craneSignature?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
};

export type InputExternalAndOtherInventory = {
  leftMirror: Scalars['Boolean'];
  rightMirror: Scalars['Boolean'];
  handles: Scalars['Boolean'];
  antenna: Scalars['Boolean'];
  aileron: Scalars['Boolean'];
  wheelCover: Scalars['Boolean'];
  logos: Scalars['Boolean'];
  reverseCamera: Scalars['Boolean'];
  reverseSensors: Scalars['Boolean'];
};

export type InputInternalInventory = {
  carDocuments: Scalars['Boolean'];
  valuableObject: Scalars['Boolean'];
  radioAndSpeakers: Scalars['Boolean'];
  radioPanel: Scalars['Boolean'];
  lighter: Scalars['Boolean'];
  ashtray: Scalars['Boolean'];
  rearviewMirror: Scalars['Boolean'];
  tag: Scalars['Boolean'];
  panel: Scalars['Boolean'];
  tapestry: Scalars['Boolean'];
  carpets: Scalars['Boolean'];
  cloth: Scalars['Boolean'];
  aplifier: Scalars['Boolean'];
  woofer: Scalars['Boolean'];
  reflectingVest: Scalars['Boolean'];
  fogger: Scalars['Boolean'];
  contactKey: Scalars['Boolean'];
  fuel: Scalars['String'];
};

export type InputWorkingItems = {
  horn: Scalars['Boolean'];
  centralLock: Scalars['Boolean'];
  glassRisers: Scalars['Boolean'];
  alarm: Scalars['Boolean'];
  frontLights: Scalars['Boolean'];
  backLights: Scalars['Boolean'];
  blinker: Scalars['Boolean'];
  windshieldNibs: Scalars['Boolean'];
  triangle: Scalars['Boolean'];
  fireExtinguisher: Scalars['Boolean'];
  firstAidKit: Scalars['Boolean'];
  jack: Scalars['Boolean'];
  replacementWheel: Scalars['Boolean'];
  motorCover: Scalars['Boolean'];
  wheelKey: Scalars['Boolean'];
  windshield: Scalars['Boolean'];
  tools: Scalars['Boolean'];
  heating: Scalars['Boolean'];
  ac: Scalars['Boolean'];
};

export type Insurance = {
  __typename?: 'Insurance';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  budgets?: Maybe<Array<Budget>>;
};

export type ItemInput = {
  price: Scalars['Int'];
  conceptId: Scalars['Int'];
  pieceId: Scalars['Int'];
};


export type Mutation = {
  __typename?: 'Mutation';
  signupAdmin?: Maybe<AuthPayload>;
  loginAdmin?: Maybe<AuthPayload>;
  signupWorker?: Maybe<AuthPayload>;
  loginWorker?: Maybe<AuthPayload>;
  changeWorkerPassword?: Maybe<User>;
  deleteWorker?: Maybe<User>;
  createInsurance?: Maybe<Insurance>;
  updateInsurance?: Maybe<Insurance>;
  deleteInsurance?: Maybe<Insurance>;
  createService?: Maybe<Service>;
  updateService?: Maybe<Service>;
  deleteService?: Maybe<Service>;
  createReservation?: Maybe<Reservation>;
  updateReservation?: Maybe<Reservation>;
  deleteReservation?: Maybe<Reservation>;
  createBlockedHour?: Maybe<BlockedHour>;
  deleteBlockedHour?: Maybe<BlockedHour>;
  createBudget?: Maybe<Budget>;
  updateBudget?: Maybe<Budget>;
  deleteBudget?: Maybe<Budget>;
  addReplacementToBudget?: Maybe<Replacement>;
  addBudgetItem?: Maybe<BudgetItem>;
  updateReplacement?: Maybe<Replacement>;
  deleteReplacement?: Maybe<Replacement>;
  createImage?: Maybe<Image>;
  updateImage?: Maybe<Image>;
  deleteImage?: Maybe<Image>;
  signS3: S3Payload;
  signMultipleS3?: Maybe<Array<Maybe<S3Payload>>>;
  deleteBudgetItem?: Maybe<BudgetItem>;
  updateBudgetItem?: Maybe<BudgetItem>;
  createBudgetImage?: Maybe<BudgetImage>;
  deleteBudgetImage?: Maybe<BudgetImage>;
  changeAdminPassword: User;
  createWorkOrder: WorkOrder;
  assignTasks: Array<Task>;
  completeTask: Task;
  startTask: Task;
  notifyDelay: WorkOrder;
  changeRole: User;
  changeBudgetStatus: Budget;
  createEntryReservation?: Maybe<EntryReservation>;
  updateEntryReservation?: Maybe<EntryReservation>;
  deleteEntryReservation?: Maybe<EntryReservation>;
  changeCarDeposit: CarWithDeposit;
  exitCar: WorkOrder;
  createReception: Reception;
  deleteReception: Reception;
  sendBudget: Budget;
  addReservationImage?: Maybe<Reservation>;
  deleteReservationImage?: Maybe<Reservation>;
  updatePieces: Array<Piece>;
};


export type MutationSignupAdminArgs = {
  rut: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
};


export type MutationLoginAdminArgs = {
  rut: Scalars['String'];
  password: Scalars['String'];
};


export type MutationSignupWorkerArgs = {
  rut: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  role?: Maybe<Scalars['String']>;
};


export type MutationLoginWorkerArgs = {
  rut: Scalars['String'];
  password: Scalars['String'];
};


export type MutationChangeWorkerPasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationDeleteWorkerArgs = {
  id: Scalars['Int'];
};


export type MutationCreateInsuranceArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
};


export type MutationUpdateInsuranceArgs = {
  id: Scalars['Int'];
  name: Scalars['String'];
  email: Scalars['String'];
};


export type MutationDeleteInsuranceArgs = {
  id: Scalars['Int'];
};


export type MutationCreateServiceArgs = {
  name: Scalars['String'];
  description: Scalars['String'];
  places: Scalars['Int'];
  lastBlockPlaces: Scalars['Int'];
};


export type MutationUpdateServiceArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  places?: Maybe<Scalars['Int']>;
  lastBlockPlaces?: Maybe<Scalars['Int']>;
};


export type MutationDeleteServiceArgs = {
  id: Scalars['Int'];
};


export type MutationCreateReservationArgs = {
  name: Scalars['String'];
  lastname: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  rut: Scalars['String'];
  plate: Scalars['String'];
  chasis: Scalars['String'];
  brand: Scalars['String'];
  model: Scalars['String'];
  color: Scalars['String'];
  year: Scalars['Int'];
  carnet: Array<Scalars['String']>;
  insuranceId?: Maybe<Scalars['Int']>;
  permisoCirculacion: Array<Scalars['String']>;
  declaracionJurada?: Maybe<Array<Scalars['String']>>;
  siniestro?: Maybe<Scalars['String']>;
  serviceId: Scalars['Int'];
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateReservationArgs = {
  id: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
};


export type MutationDeleteReservationArgs = {
  id: Scalars['Int'];
};


export type MutationCreateBlockedHourArgs = {
  date: Scalars['String'];
  serviceId: Scalars['Int'];
};


export type MutationDeleteBlockedHourArgs = {
  date: Scalars['String'];
  serviceId: Scalars['Int'];
};


export type MutationCreateBudgetArgs = {
  input?: Maybe<BudgetInput>;
};


export type MutationUpdateBudgetArgs = {
  input: BudgetUpdateInput;
};


export type MutationDeleteBudgetArgs = {
  id: Scalars['Int'];
};


export type MutationAddReplacementToBudgetArgs = {
  input?: Maybe<ReplacementInput>;
  budgetId?: Maybe<Scalars['Int']>;
};


export type MutationAddBudgetItemArgs = {
  input?: Maybe<ItemInput>;
  budgetId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateReplacementArgs = {
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
};


export type MutationDeleteReplacementArgs = {
  id: Scalars['Int'];
};


export type MutationCreateImageArgs = {
  link: Scalars['String'];
};


export type MutationUpdateImageArgs = {
  id: Scalars['Int'];
  link: Scalars['String'];
};


export type MutationDeleteImageArgs = {
  id: Scalars['Int'];
};


export type MutationSignS3Args = {
  filename: Scalars['String'];
  filetype: Scalars['String'];
};


export type MutationSignMultipleS3Args = {
  input?: Maybe<Array<Maybe<S3Input>>>;
};


export type MutationDeleteBudgetItemArgs = {
  itemId?: Maybe<Scalars['Int']>;
};


export type MutationUpdateBudgetItemArgs = {
  itemId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  conceptId?: Maybe<Scalars['Int']>;
  pieceId?: Maybe<Scalars['Int']>;
};


export type MutationCreateBudgetImageArgs = {
  budgetID: Scalars['Int'];
  link: Scalars['String'];
};


export type MutationDeleteBudgetImageArgs = {
  id: Scalars['Int'];
};


export type MutationChangeAdminPasswordArgs = {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  validationPassword: Scalars['String'];
};


export type MutationCreateWorkOrderArgs = {
  budgetId: Scalars['Int'];
  deadline: Scalars['String'];
  deposit: Scalars['Int'];
};


export type MutationAssignTasksArgs = {
  taskIds: Array<Scalars['Int']>;
  workerId: Scalars['Int'];
};


export type MutationCompleteTaskArgs = {
  taskId: Scalars['Int'];
};


export type MutationStartTaskArgs = {
  taskId: Scalars['Int'];
};


export type MutationNotifyDelayArgs = {
  workOrderId: Scalars['Int'];
  comments: Scalars['String'];
  newDeadline: Scalars['String'];
};


export type MutationChangeRoleArgs = {
  rut: Scalars['String'];
  role: Scalars['String'];
};


export type MutationChangeBudgetStatusArgs = {
  budgetID: Scalars['Int'];
  status: BudgetStatusOption;
  rut?: Maybe<Scalars['String']>;
  folio?: Maybe<Scalars['String']>;
};


export type MutationCreateEntryReservationArgs = {
  budgetId: Scalars['Int'];
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
};


export type MutationUpdateEntryReservationArgs = {
  id: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
};


export type MutationDeleteEntryReservationArgs = {
  id: Scalars['Int'];
};


export type MutationChangeCarDepositArgs = {
  plate: Scalars['String'];
  deposit: Scalars['Int'];
};


export type MutationExitCarArgs = {
  workOrderId: Scalars['Int'];
};


export type MutationCreateReceptionArgs = {
  inputInternalInventory?: Maybe<InputInternalInventory>;
  inputExternalAndOtherInventory?: Maybe<InputExternalAndOtherInventory>;
  inputWorkingItems?: Maybe<InputWorkingItems>;
  inputDetails?: Maybe<InputDetails>;
};


export type MutationDeleteReceptionArgs = {
  id: Scalars['Int'];
};


export type MutationSendBudgetArgs = {
  budgetId: Scalars['Int'];
};


export type MutationAddReservationImageArgs = {
  reservationId: Scalars['Int'];
  field: Scalars['String'];
  link: Scalars['String'];
};


export type MutationDeleteReservationImageArgs = {
  reservationId: Scalars['Int'];
  field: Scalars['String'];
  link: Scalars['String'];
};

export type PartInput = {
  price: Scalars['Int'];
  concept?: Maybe<Scalars['String']>;
  description: Scalars['String'];
};

export type PersonalDataInput = {
  name: Scalars['String'];
  lastname: Scalars['String'];
  rut: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
  plate: Scalars['String'];
  brand: Scalars['String'];
  model: Scalars['String'];
  color: Scalars['String'];
  year: Scalars['Int'];
  insurance?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['Int']>;
  chasis?: Maybe<Scalars['String']>;
};

export type Piece = {
  __typename?: 'Piece';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  stages: Array<Stage>;
  items: Array<BudgetItem>;
  replacements: Array<Replacement>;
};

export type Query = {
  __typename?: 'Query';
  info: Scalars['String'];
  users: Array<User>;
  insurances: Array<Insurance>;
  workers: Array<User>;
  admins: Array<User>;
  reservations: Array<Reservation>;
  replacements: Array<Replacement>;
  services: Array<Service>;
  cars: Array<Car>;
  clients: Array<Client>;
  blockedHours: Array<BlockedHour>;
  serviceBlockedHours: Array<ServiceBlockedHour>;
  getInsurance?: Maybe<Insurance>;
  getService?: Maybe<Service>;
  getReservation?: Maybe<Reservation>;
  getReservationByCar?: Maybe<Car>;
  getClientByRut?: Maybe<Client>;
  getCarByPlate?: Maybe<Car>;
  getAvailability?: Maybe<Scalars['JSON']>;
  getReservedBlocks?: Maybe<Scalars['JSON']>;
  getBlockedBlocks?: Maybe<Scalars['JSON']>;
  getReplacement?: Maybe<Replacement>;
  getImage?: Maybe<Image>;
  budgets: Array<Budget>;
  getBudget?: Maybe<Budget>;
  getBudgetByFolio?: Maybe<Budget>;
  stages: Array<Stage>;
  getConcepts: Array<Concept>;
  getPieces: Array<Piece>;
  allBudgets?: Maybe<Scalars['Int']>;
  allReservations?: Maybe<Scalars['Int']>;
  sendBudgetToClient?: Maybe<Budget>;
  workOrdersToAssign: Array<WorkOrder>;
  tasksToDo: Array<TasksByPlate>;
  getWorkOrderByFolio?: Maybe<WorkOrder>;
  budgetsWithoutWorkOrder: Array<Budget>;
  getTaskskWithoutWorker: Array<Task>;
  getDeadline: WorkOrder;
  getEntryReservations?: Maybe<Scalars['JSON']>;
  getEntryReservationByBudget?: Maybe<EntryReservation>;
  entryReservations?: Maybe<Array<Maybe<EntryReservation>>>;
  workOrders: Array<WorkOrder>;
  assignedTasks: Array<TasksByPlate>;
  carsInWorkshop: Array<CarWithDeposit>;
  getReceptionById?: Maybe<Reception>;
  receptions?: Maybe<Array<Reception>>;
  allReceptions?: Maybe<Scalars['Int']>;
  getWorkOrdersByPlate?: Maybe<Array<WorkOrder>>;
  allWorkOrders: Scalars['Int'];
  getEntryReservationById?: Maybe<EntryReservation>;
  allEntryReservations: Scalars['Int'];
  exportUsers: Array<User>;
  exportCars: Array<Car>;
  exportClients: Array<Client>;
  exportServices: Array<Service>;
  exportReservations: Array<Reservation>;
  exportEntryReservations: Array<EntryReservation>;
  exportInsurances: Array<Insurance>;
  exportReplacements: Array<Replacement>;
  exportImages: Array<Image>;
  exportStages: Array<Stage>;
  exportConcepts: Array<Concept>;
  exportPieces: Array<Piece>;
  exportBudgets: Array<Budget>;
  exportBudgetImages: Array<BudgetImage>;
  exportBudgetItems: Array<BudgetItem>;
  exportBlockedHours: Array<BlockedHour>;
  exportServiceBlockedHours: Array<ServiceBlockedHour>;
  exportWorkOrders: Array<WorkOrder>;
  exportWorkPocesses: Array<WorkProcess>;
  exportTasks: Array<Task>;
  exportStatus: Array<Status>;
  exportReceptions: Array<Reception>;
};


export type QueryReservationsArgs = {
  sort?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['Boolean']>;
  take?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['Int']>;
  date_ini?: Maybe<Scalars['DateTime']>;
  date_end?: Maybe<Scalars['DateTime']>;
};


export type QueryGetInsuranceArgs = {
  id: Scalars['Int'];
};


export type QueryGetServiceArgs = {
  id: Scalars['Int'];
};


export type QueryGetReservationArgs = {
  id: Scalars['Int'];
};


export type QueryGetReservationByCarArgs = {
  plate: Scalars['String'];
  date: Scalars['String'];
};


export type QueryGetClientByRutArgs = {
  rut: Scalars['String'];
  gte?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCarByPlateArgs = {
  plate: Scalars['String'];
  gte?: Maybe<Scalars['Boolean']>;
};


export type QueryGetAvailabilityArgs = {
  serviceId: Scalars['Int'];
  date: Scalars['String'];
};


export type QueryGetReservedBlocksArgs = {
  date: Scalars['String'];
  serviceId?: Maybe<Scalars['Int']>;
};


export type QueryGetBlockedBlocksArgs = {
  date: Scalars['String'];
  serviceId?: Maybe<Scalars['Int']>;
};


export type QueryGetReplacementArgs = {
  id: Scalars['Int'];
};


export type QueryGetImageArgs = {
  id: Scalars['Int'];
};


export type QueryBudgetsArgs = {
  take?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  entry_reservation?: Maybe<Scalars['String']>;
};


export type QueryGetBudgetArgs = {
  id: Scalars['Int'];
};


export type QueryGetBudgetByFolioArgs = {
  rut: Scalars['String'];
  folio: Scalars['String'];
};


export type QueryGetConceptsArgs = {
  stage?: Maybe<Scalars['String']>;
};


export type QueryGetPiecesArgs = {
  stage?: Maybe<Scalars['String']>;
};


export type QueryAllBudgetsArgs = {
  name?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  entry_reservation?: Maybe<Scalars['String']>;
};


export type QueryAllReservationsArgs = {
  gte?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  service_id?: Maybe<Scalars['Int']>;
  date_ini?: Maybe<Scalars['DateTime']>;
  date_end?: Maybe<Scalars['DateTime']>;
};


export type QuerySendBudgetToClientArgs = {
  id: Scalars['Int'];
};


export type QueryGetWorkOrderByFolioArgs = {
  rut: Scalars['String'];
  folio: Scalars['String'];
};


export type QueryGetTaskskWithoutWorkerArgs = {
  workOrderId: Scalars['Int'];
};


export type QueryGetDeadlineArgs = {
  taskId: Scalars['Int'];
};


export type QueryGetEntryReservationsArgs = {
  date: Scalars['String'];
};


export type QueryGetEntryReservationByBudgetArgs = {
  budgetId: Scalars['Int'];
};


export type QueryEntryReservationsArgs = {
  take?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  date_ini?: Maybe<Scalars['DateTime']>;
  date_end?: Maybe<Scalars['DateTime']>;
};


export type QueryWorkOrdersArgs = {
  take?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  currentProcess?: Maybe<Scalars['String']>;
  inWorkshop?: Maybe<Scalars['Boolean']>;
  deadlineSort?: Maybe<Scalars['String']>;
  nameSort?: Maybe<Scalars['String']>;
  rutSort?: Maybe<Scalars['String']>;
};


export type QueryGetReceptionByIdArgs = {
  id: Scalars['Int'];
};


export type QueryReceptionsArgs = {
  take: Scalars['Int'];
  page: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  date_ini?: Maybe<Scalars['DateTime']>;
  date_end?: Maybe<Scalars['DateTime']>;
};


export type QueryAllReceptionsArgs = {
  name?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  date_ini?: Maybe<Scalars['DateTime']>;
  date_end?: Maybe<Scalars['DateTime']>;
};


export type QueryGetWorkOrdersByPlateArgs = {
  plate?: Maybe<Scalars['String']>;
};


export type QueryAllWorkOrdersArgs = {
  open?: Maybe<Scalars['Boolean']>;
  currentProcess?: Maybe<Scalars['String']>;
  inWorkshop?: Maybe<Scalars['Boolean']>;
};


export type QueryGetEntryReservationByIdArgs = {
  id: Scalars['Int'];
};


export type QueryAllEntryReservationsArgs = {
  name?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  date_ini?: Maybe<Scalars['DateTime']>;
  date_end?: Maybe<Scalars['DateTime']>;
};

export type Reception = {
  __typename?: 'Reception';
  id: Scalars['ID'];
  receptionDate: Scalars['DateTime'];
  budget: Budget;
  internalInventory: Scalars['JSON'];
  externalAndOtherInventory?: Maybe<Scalars['JSON']>;
  details: Scalars['String'];
  workingItems: Scalars['JSON'];
  clientSignature: Scalars['String'];
  craneSignature?: Maybe<Scalars['String']>;
  observations?: Maybe<Scalars['String']>;
};

export type Replacement = {
  __typename?: 'Replacement';
  id: Scalars['ID'];
  piece: Piece;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  budget: Budget;
};

export type ReplacementInput = {
  description: Scalars['String'];
  quantity: Scalars['Int'];
  unitPrice: Scalars['Int'];
};

export type Reservation = {
  __typename?: 'Reservation';
  id: Scalars['ID'];
  createdAt?: Maybe<Scalars['DateTime']>;
  client?: Maybe<Client>;
  car?: Maybe<Car>;
  insurance?: Maybe<Insurance>;
  declaracionJurada?: Maybe<Array<Scalars['String']>>;
  siniestro?: Maybe<Scalars['String']>;
  service?: Maybe<Service>;
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
};

export type S3Payload = {
  __typename?: 'S3Payload';
  signedRequest: Scalars['String'];
  url: Scalars['String'];
};

export type Service = {
  __typename?: 'Service';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  places?: Maybe<Scalars['Int']>;
  lastBlockPlaces?: Maybe<Scalars['Int']>;
  blockedHours?: Maybe<ServiceBlockedHour>;
};

export type ServiceBlockedHour = {
  __typename?: 'ServiceBlockedHour';
  id: Scalars['ID'];
  blocked_hour?: Maybe<BlockedHour>;
  service?: Maybe<Service>;
};

export type Stage = {
  __typename?: 'Stage';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  concepts: Array<Concept>;
  pieces: Array<Piece>;
};

export enum StageOption {
  Dent = 'DENT',
  Paint = 'PAINT'
}

export type Status = {
  __typename?: 'Status';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['ID'];
  workProcess: WorkProcess;
  worker?: Maybe<User>;
  status: Status;
  concept: Concept;
  piece?: Maybe<Piece>;
};

export type TasksByPlate = {
  __typename?: 'TasksByPlate';
  car?: Maybe<Car>;
  tasks: Array<Task>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  rut: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  budgets?: Maybe<Array<Budget>>;
  blocked_hours?: Maybe<Array<BlockedHour>>;
  role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  changedPassword: Scalars['Boolean'];
};

export type WorkOrder = {
  __typename?: 'WorkOrder';
  id: Scalars['ID'];
  budget: Budget;
  deadline: Scalars['String'];
  workProcesses: Array<WorkProcess>;
  currentProcess: Stage;
  comments?: Maybe<Scalars['String']>;
  closed: Scalars['Boolean'];
  deposit?: Maybe<Scalars['Int']>;
  inWorkshop: Scalars['Boolean'];
};

export type WorkProcess = {
  __typename?: 'WorkProcess';
  id: Scalars['ID'];
  workOrder: WorkOrder;
  process: Stage;
  tasks: Array<Task>;
};

export type S3Input = {
  filename: Scalars['String'];
  filetype: Scalars['String'];
};

export type AllEntryReservationsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllEntryReservationsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allEntryReservations'>
);

export type CarsQueryVariables = Exact<{ [key: string]: never; }>;


export type CarsQuery = (
  { __typename?: 'Query' }
  & { cars: Array<(
    { __typename?: 'Car' }
    & Pick<Car, 'id' | 'plate' | 'chasis' | 'brand' | 'model' | 'color' | 'year'>
  )> }
);

export type ClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientsQuery = (
  { __typename?: 'Query' }
  & { clients: Array<(
    { __typename?: 'Client' }
    & Pick<Client, 'rut' | 'name' | 'lastname' | 'phone' | 'email'>
  )> }
);

export type ExportInsurancesQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportInsurancesQuery = (
  { __typename?: 'Query' }
  & { exportInsurances: Array<(
    { __typename?: 'Insurance' }
    & Pick<Insurance, 'id' | 'name' | 'email'>
  )> }
);

export type ExportServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportServicesQuery = (
  { __typename?: 'Query' }
  & { exportServices: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name' | 'description' | 'places' | 'lastBlockPlaces'>
  )> }
);

export type ExportTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportTasksQuery = (
  { __typename?: 'Query' }
  & { exportTasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { workProcess: (
      { __typename?: 'WorkProcess' }
      & Pick<WorkProcess, 'id'>
    ), worker?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'rut'>
    )>, status: (
      { __typename?: 'Status' }
      & Pick<Status, 'id' | 'name'>
    ), concept: (
      { __typename?: 'Concept' }
      & Pick<Concept, 'id' | 'name'>
      & { stage?: Maybe<(
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'name'>
      )> }
    ), piece?: Maybe<(
      { __typename?: 'Piece' }
      & Pick<Piece, 'id' | 'name'>
    )> }
  )> }
);

export type ExportWorkPocessesQueryVariables = Exact<{ [key: string]: never; }>;


export type ExportWorkPocessesQuery = (
  { __typename?: 'Query' }
  & { exportWorkPocesses: Array<(
    { __typename?: 'WorkProcess' }
    & Pick<WorkProcess, 'id'>
    & { workOrder: (
      { __typename?: 'WorkOrder' }
      & Pick<WorkOrder, 'id'>
    ), process: (
      { __typename?: 'Stage' }
      & Pick<Stage, 'id'>
    ) }
  )> }
);

export type EntryReservationsQueryVariables = Exact<{
  take: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type EntryReservationsQuery = (
  { __typename?: 'Query' }
  & { entryReservations?: Maybe<Array<Maybe<(
    { __typename?: 'EntryReservation' }
    & Pick<EntryReservation, 'id' | 'date' | 'description'>
  )>>> }
);

export type UsersQueryVariables = Exact<{ [key: string]: never; }>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'rut' | 'name' | 'role' | 'email'>
  )> }
);

export type AdminChangeBudgetStatusMutationVariables = Exact<{
  budgetID: Scalars['Int'];
  status: BudgetStatusOption;
}>;


export type AdminChangeBudgetStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeBudgetStatus: (
    { __typename?: 'Budget' }
    & Pick<Budget, 'id'>
  ) }
);

export type AdminBudgetDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type AdminBudgetDeleteMutation = (
  { __typename?: 'Mutation' }
  & { deleteBudget?: Maybe<(
    { __typename?: 'Budget' }
    & Pick<Budget, 'id'>
  )> }
);

export type ClientChangeBudgetStatusMutationVariables = Exact<{
  budgetID: Scalars['Int'];
  status: BudgetStatusOption;
  rut?: Maybe<Scalars['String']>;
  folio?: Maybe<Scalars['String']>;
}>;


export type ClientChangeBudgetStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeBudgetStatus: (
    { __typename?: 'Budget' }
    & Pick<Budget, 'id'>
  ) }
);

export type GetAvailabilityQueryVariables = Exact<{
  serviceId: Scalars['Int'];
  date: Scalars['String'];
}>;


export type GetAvailabilityQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getAvailability'>
);

export type GetCarByPlateQueryVariables = Exact<{
  plate: Scalars['String'];
  gte?: Maybe<Scalars['Boolean']>;
}>;


export type GetCarByPlateQuery = (
  { __typename?: 'Query' }
  & { getCarByPlate?: Maybe<(
    { __typename?: 'Car' }
    & Pick<Car, 'id' | 'plate'>
    & { reservations?: Maybe<Array<(
      { __typename?: 'Reservation' }
      & Pick<Reservation, 'id' | 'date'>
    )>> }
  )> }
);

export type NotifyDelayMutationVariables = Exact<{
  workOrderId: Scalars['Int'];
  comments: Scalars['String'];
  newDeadline: Scalars['String'];
}>;


export type NotifyDelayMutation = (
  { __typename?: 'Mutation' }
  & { notifyDelay: (
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'id' | 'deadline'>
  ) }
);

export type AllServicesInformationQueryVariables = Exact<{ [key: string]: never; }>;


export type AllServicesInformationQuery = (
  { __typename?: 'Query' }
  & { services: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name'>
  )> }
);

export type GetWorkOrderByFolioQueryVariables = Exact<{
  rut: Scalars['String'];
  folio: Scalars['String'];
}>;


export type GetWorkOrderByFolioQuery = (
  { __typename?: 'Query' }
  & { getWorkOrderByFolio?: Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'id' | 'deadline' | 'comments' | 'closed'>
    & { budget: (
      { __typename?: 'Budget' }
      & Pick<Budget, 'id'>
      & { car: (
        { __typename?: 'Car' }
        & Pick<Car, 'id' | 'plate' | 'brand' | 'model' | 'color' | 'year' | 'chasis'>
      ) }
    ), workProcesses: Array<(
      { __typename?: 'WorkProcess' }
      & Pick<WorkProcess, 'id'>
      & { process: (
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'name'>
      ) }
    )>, currentProcess: (
      { __typename?: 'Stage' }
      & Pick<Stage, 'id' | 'name'>
    ) }
  )> }
);

export type ServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type ServicesQuery = (
  { __typename?: 'Query' }
  & { services: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'id' | 'name' | 'description' | 'places' | 'lastBlockPlaces'>
  )> }
);

export type AddBudgetItemMutationVariables = Exact<{
  budgetId: Scalars['Int'];
  input: ItemInput;
}>;


export type AddBudgetItemMutation = (
  { __typename?: 'Mutation' }
  & { addBudgetItem?: Maybe<(
    { __typename?: 'BudgetItem' }
    & Pick<BudgetItem, 'id' | 'price'>
    & { concept: (
      { __typename?: 'Concept' }
      & Pick<Concept, 'id' | 'name'>
    ), piece: (
      { __typename?: 'Piece' }
      & Pick<Piece, 'id' | 'name'>
    ) }
  )> }
);

export type AddReplacementToBudgetMutationVariables = Exact<{
  input: ReplacementInput;
  budgetId?: Maybe<Scalars['Int']>;
}>;


export type AddReplacementToBudgetMutation = (
  { __typename?: 'Mutation' }
  & { addReplacementToBudget?: Maybe<(
    { __typename?: 'Replacement' }
    & Pick<Replacement, 'id' | 'quantity' | 'price'>
    & { piece: (
      { __typename?: 'Piece' }
      & Pick<Piece, 'id' | 'name'>
    ) }
  )> }
);

export type DeleteBudgetImageMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteBudgetImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteBudgetImage?: Maybe<(
    { __typename?: 'BudgetImage' }
    & Pick<BudgetImage, 'id'>
  )> }
);

export type DeletBudgetItemMutationVariables = Exact<{
  itemId: Scalars['Int'];
}>;


export type DeletBudgetItemMutation = (
  { __typename?: 'Mutation' }
  & { deleteBudgetItem?: Maybe<(
    { __typename?: 'BudgetItem' }
    & Pick<BudgetItem, 'id' | 'price'>
  )> }
);

export type DeleteReplacementMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteReplacementMutation = (
  { __typename?: 'Mutation' }
  & { deleteReplacement?: Maybe<(
    { __typename?: 'Replacement' }
    & Pick<Replacement, 'id' | 'price' | 'quantity'>
  )> }
);

export type GetConceptsQueryVariables = Exact<{
  stage: Scalars['String'];
}>;


export type GetConceptsQuery = (
  { __typename?: 'Query' }
  & { getConcepts: Array<(
    { __typename?: 'Concept' }
    & Pick<Concept, 'id' | 'name'>
  )> }
);

export type UpdateBudgetMutationVariables = Exact<{
  input: BudgetUpdateInput;
}>;


export type UpdateBudgetMutation = (
  { __typename?: 'Mutation' }
  & { updateBudget?: Maybe<(
    { __typename?: 'Budget' }
    & Pick<Budget, 'id'>
    & { car: (
      { __typename?: 'Car' }
      & Pick<Car, 'plate' | 'brand' | 'model' | 'color' | 'chasis' | 'year'>
    ), client: (
      { __typename?: 'Client' }
      & Pick<Client, 'name' | 'lastname' | 'rut' | 'phone' | 'email'>
    ), insurance?: Maybe<(
      { __typename?: 'Insurance' }
      & Pick<Insurance, 'name' | 'email'>
    )>, images: Array<(
      { __typename?: 'BudgetImage' }
      & Pick<BudgetImage, 'id' | 'link'>
    )>, items: Array<(
      { __typename?: 'BudgetItem' }
      & Pick<BudgetItem, 'id' | 'price'>
      & { concept: (
        { __typename?: 'Concept' }
        & Pick<Concept, 'id' | 'name'>
      ), piece: (
        { __typename?: 'Piece' }
        & Pick<Piece, 'id' | 'name'>
      ) }
    )>, replacements: Array<(
      { __typename?: 'Replacement' }
      & Pick<Replacement, 'id' | 'quantity' | 'price'>
      & { piece: (
        { __typename?: 'Piece' }
        & Pick<Piece, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type UpdateReplacementMutationVariables = Exact<{
  id: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
}>;


export type UpdateReplacementMutation = (
  { __typename?: 'Mutation' }
  & { updateReplacement?: Maybe<(
    { __typename?: 'Replacement' }
    & Pick<Replacement, 'id' | 'quantity' | 'price'>
    & { piece: (
      { __typename?: 'Piece' }
      & Pick<Piece, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateBudgetItemMutationVariables = Exact<{
  itemId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  conceptId?: Maybe<Scalars['Int']>;
  pieceId?: Maybe<Scalars['Int']>;
}>;


export type UpdateBudgetItemMutation = (
  { __typename?: 'Mutation' }
  & { updateBudgetItem?: Maybe<(
    { __typename?: 'BudgetItem' }
    & Pick<BudgetItem, 'id' | 'price'>
    & { concept: (
      { __typename?: 'Concept' }
      & Pick<Concept, 'id' | 'name'>
    ), piece: (
      { __typename?: 'Piece' }
      & Pick<Piece, 'id' | 'name'>
    ) }
  )> }
);

export type UpdateReservationMutationVariables = Exact<{
  id: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateReservationMutation = (
  { __typename?: 'Mutation' }
  & { updateReservation?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id'>
  )> }
);

export type DeleteReservationImageMutationVariables = Exact<{
  reservationId: Scalars['Int'];
  field: Scalars['String'];
  link: Scalars['String'];
}>;


export type DeleteReservationImageMutation = (
  { __typename?: 'Mutation' }
  & { deleteReservationImage?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'declaracionJurada'>
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'carnet'>
    )>, car?: Maybe<(
      { __typename?: 'Car' }
      & Pick<Car, 'permisoCirculacion'>
    )> }
  )> }
);

export type AdminsListQueryVariables = Exact<{ [key: string]: never; }>;


export type AdminsListQuery = (
  { __typename?: 'Query' }
  & { admins: Array<(
    { __typename?: 'User' }
    & Pick<User, 'name' | 'email' | 'rut'>
  )> }
);

export type AllEntryReservationsListQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  dateIni?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  plate?: Maybe<Scalars['String']>;
}>;


export type AllEntryReservationsListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allEntryReservations'>
);

export type AllWorkOrdersQueryVariables = Exact<{
  open?: Maybe<Scalars['Boolean']>;
  currentProcess?: Maybe<Scalars['String']>;
  inWorkshop?: Maybe<Scalars['Boolean']>;
}>;


export type AllWorkOrdersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allWorkOrders'>
);

export type AllBudgetsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  entry_reservation?: Maybe<Scalars['String']>;
}>;


export type AllBudgetsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allBudgets'>
);

export type GetAllReceptionsListQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  dateIni?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  plate?: Maybe<Scalars['String']>;
}>;


export type GetAllReceptionsListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allReceptions'>
);

export type AllReservationsQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  dateIni?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  plate?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
}>;


export type AllReservationsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'allReservations'>
);

export type BudgetsQueryVariables = Exact<{
  take: Scalars['Int'];
  page: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  entry_reservation?: Maybe<Scalars['String']>;
}>;


export type BudgetsQuery = (
  { __typename?: 'Query' }
  & { budgets: Array<(
    { __typename?: 'Budget' }
    & Pick<Budget, 'id' | 'status'>
    & { car: (
      { __typename?: 'Car' }
      & Pick<Car, 'plate'>
    ), client: (
      { __typename?: 'Client' }
      & Pick<Client, 'rut' | 'name' | 'lastname'>
    ), user: (
      { __typename?: 'User' }
      & Pick<User, 'rut' | 'name'>
    ), workOrder?: Maybe<(
      { __typename?: 'WorkOrder' }
      & Pick<WorkOrder, 'id'>
    )>, reception?: Maybe<(
      { __typename?: 'Reception' }
      & Pick<Reception, 'id'>
    )>, entryReservation?: Maybe<(
      { __typename?: 'EntryReservation' }
      & Pick<EntryReservation, 'id'>
    )>, items: Array<(
      { __typename?: 'BudgetItem' }
      & Pick<BudgetItem, 'id' | 'price'>
    )>, replacements: Array<(
      { __typename?: 'Replacement' }
      & Pick<Replacement, 'id' | 'price' | 'quantity'>
    )> }
  )> }
);

export type GetBudgetsByPlateQueryVariables = Exact<{
  plate: Scalars['String'];
}>;


export type GetBudgetsByPlateQuery = (
  { __typename?: 'Query' }
  & { getCarByPlate?: Maybe<(
    { __typename?: 'Car' }
    & { budgets: Array<(
      { __typename?: 'Budget' }
      & Pick<Budget, 'id' | 'folio'>
    )> }
  )> }
);

export type GetEntryReservationsListQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  plate?: Maybe<Scalars['String']>;
  dateIni?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
}>;


export type GetEntryReservationsListQuery = (
  { __typename?: 'Query' }
  & { entryReservations?: Maybe<Array<Maybe<(
    { __typename?: 'EntryReservation' }
    & Pick<EntryReservation, 'id' | 'date' | 'description'>
    & { budget?: Maybe<(
      { __typename?: 'Budget' }
      & { client: (
        { __typename?: 'Client' }
        & Pick<Client, 'name' | 'lastname'>
      ), car: (
        { __typename?: 'Car' }
        & Pick<Car, 'model' | 'plate'>
      ) }
    )> }
  )>>> }
);

export type GetReceptionsListQueryVariables = Exact<{
  page: Scalars['Int'];
  take: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  dateIni?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  plate?: Maybe<Scalars['String']>;
}>;


export type GetReceptionsListQuery = (
  { __typename?: 'Query' }
  & { receptions?: Maybe<Array<(
    { __typename?: 'Reception' }
    & Pick<Reception, 'id' | 'receptionDate'>
    & { budget: (
      { __typename?: 'Budget' }
      & { car: (
        { __typename?: 'Car' }
        & Pick<Car, 'plate' | 'model' | 'color'>
      ), client: (
        { __typename?: 'Client' }
        & Pick<Client, 'name' | 'lastname'>
      ) }
    ) }
  )>> }
);

export type ReservationsQueryVariables = Exact<{
  take: Scalars['Int'];
  page: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  dateIni?: Maybe<Scalars['DateTime']>;
  dateEnd?: Maybe<Scalars['DateTime']>;
  plate?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['Int']>;
}>;


export type ReservationsQuery = (
  { __typename?: 'Query' }
  & { reservations: Array<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'date' | 'description'>
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'rut' | 'name' | 'lastname'>
    )>, service?: Maybe<(
      { __typename?: 'Service' }
      & Pick<Service, 'id' | 'name'>
    )>, car?: Maybe<(
      { __typename?: 'Car' }
      & Pick<Car, 'plate'>
    )>, insurance?: Maybe<(
      { __typename?: 'Insurance' }
      & Pick<Insurance, 'id'>
    )> }
  )> }
);

export type WorkersListQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkersListQuery = (
  { __typename?: 'Query' }
  & { workers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'name' | 'email' | 'rut' | 'role'>
  )> }
);

export type GetBlockedBlocksQueryVariables = Exact<{
  date: Scalars['String'];
  serviceId?: Maybe<Scalars['Int']>;
}>;


export type GetBlockedBlocksQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBlockedBlocks'>
);

export type CreateBlockedHourMutationVariables = Exact<{
  date: Scalars['String'];
  serviceId: Scalars['Int'];
}>;


export type CreateBlockedHourMutation = (
  { __typename?: 'Mutation' }
  & { createBlockedHour?: Maybe<(
    { __typename?: 'BlockedHour' }
    & Pick<BlockedHour, 'id'>
  )> }
);

export type DeleteBlockedHourMutationVariables = Exact<{
  date: Scalars['String'];
  serviceId: Scalars['Int'];
}>;


export type DeleteBlockedHourMutation = (
  { __typename?: 'Mutation' }
  & { deleteBlockedHour?: Maybe<(
    { __typename?: 'BlockedHour' }
    & Pick<BlockedHour, 'id'>
  )> }
);

export type GetReservedBlocksQueryVariables = Exact<{
  date: Scalars['String'];
  serviceId?: Maybe<Scalars['Int']>;
}>;


export type GetReservedBlocksQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getReservedBlocks'>
);

export type CreateReservationMutationVariables = Exact<{
  name: Scalars['String'];
  lastname: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  rut: Scalars['String'];
  plate: Scalars['String'];
  chasis: Scalars['String'];
  brand: Scalars['String'];
  model: Scalars['String'];
  color: Scalars['String'];
  year: Scalars['Int'];
  carnet: Array<Scalars['String']> | Scalars['String'];
  insuranceId?: Maybe<Scalars['Int']>;
  permisoCirculacion: Array<Scalars['String']> | Scalars['String'];
  declaracionJurada?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  siniestro: Scalars['String'];
  serviceId: Scalars['Int'];
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
}>;


export type CreateReservationMutation = (
  { __typename?: 'Mutation' }
  & { createReservation?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'date'>
  )> }
);

export type GetInsurancesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInsurancesQuery = (
  { __typename?: 'Query' }
  & { insurances: Array<(
    { __typename?: 'Insurance' }
    & Pick<Insurance, 'id' | 'name' | 'email'>
  )> }
);

export type SignS3MutationVariables = Exact<{
  filename: Scalars['String'];
  filetype: Scalars['String'];
}>;


export type SignS3Mutation = (
  { __typename?: 'Mutation' }
  & { signS3: (
    { __typename?: 'S3Payload' }
    & Pick<S3Payload, 'signedRequest' | 'url'>
  ) }
);

export type GetBudgetByFolioQueryVariables = Exact<{
  rut: Scalars['String'];
  folio: Scalars['String'];
}>;


export type GetBudgetByFolioQuery = (
  { __typename?: 'Query' }
  & { getBudgetByFolio?: Maybe<(
    { __typename?: 'Budget' }
    & Pick<Budget, 'id' | 'status'>
    & { car: (
      { __typename?: 'Car' }
      & Pick<Car, 'plate' | 'brand' | 'model' | 'color' | 'chasis' | 'year'>
    ), client: (
      { __typename?: 'Client' }
      & Pick<Client, 'name' | 'lastname' | 'rut' | 'phone' | 'email'>
    ), insurance?: Maybe<(
      { __typename?: 'Insurance' }
      & Pick<Insurance, 'name' | 'email'>
    )>, images: Array<(
      { __typename?: 'BudgetImage' }
      & Pick<BudgetImage, 'id' | 'link'>
    )>, items: Array<(
      { __typename?: 'BudgetItem' }
      & Pick<BudgetItem, 'id' | 'price'>
      & { concept: (
        { __typename?: 'Concept' }
        & Pick<Concept, 'id' | 'name'>
      ), piece: (
        { __typename?: 'Piece' }
        & Pick<Piece, 'id' | 'name'>
      ) }
    )>, replacements: Array<(
      { __typename?: 'Replacement' }
      & Pick<Replacement, 'id' | 'quantity' | 'price'>
      & { piece: (
        { __typename?: 'Piece' }
        & Pick<Piece, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type CreateBudgetImageMutationVariables = Exact<{
  budgetID: Scalars['Int'];
  link: Scalars['String'];
}>;


export type CreateBudgetImageMutation = (
  { __typename?: 'Mutation' }
  & { createBudgetImage?: Maybe<(
    { __typename?: 'BudgetImage' }
    & Pick<BudgetImage, 'id' | 'link'>
  )> }
);

export type GetBudgetQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetBudgetQuery = (
  { __typename?: 'Query' }
  & { getBudget?: Maybe<(
    { __typename?: 'Budget' }
    & Pick<Budget, 'id' | 'status'>
    & { car: (
      { __typename?: 'Car' }
      & Pick<Car, 'plate' | 'brand' | 'model' | 'color' | 'chasis' | 'year'>
    ), client: (
      { __typename?: 'Client' }
      & Pick<Client, 'name' | 'lastname' | 'rut' | 'phone' | 'email'>
    ), insurance?: Maybe<(
      { __typename?: 'Insurance' }
      & Pick<Insurance, 'name' | 'email'>
    )>, images: Array<(
      { __typename?: 'BudgetImage' }
      & Pick<BudgetImage, 'id' | 'link'>
    )>, items: Array<(
      { __typename?: 'BudgetItem' }
      & Pick<BudgetItem, 'id' | 'price'>
      & { concept: (
        { __typename?: 'Concept' }
        & Pick<Concept, 'id' | 'name'>
      ), piece: (
        { __typename?: 'Piece' }
        & Pick<Piece, 'id' | 'name'>
      ) }
    )>, replacements: Array<(
      { __typename?: 'Replacement' }
      & Pick<Replacement, 'id' | 'quantity' | 'price'>
      & { piece: (
        { __typename?: 'Piece' }
        & Pick<Piece, 'id' | 'name'>
      ) }
    )>, entryReservation?: Maybe<(
      { __typename?: 'EntryReservation' }
      & Pick<EntryReservation, 'id' | 'date'>
    )> }
  )> }
);

export type GetPiecesQueryVariables = Exact<{
  stage: Scalars['String'];
}>;


export type GetPiecesQuery = (
  { __typename?: 'Query' }
  & { getPieces: Array<(
    { __typename?: 'Piece' }
    & Pick<Piece, 'id' | 'name'>
  )> }
);

export type SendBudgetMutationVariables = Exact<{
  budgetId: Scalars['Int'];
}>;


export type SendBudgetMutation = (
  { __typename?: 'Mutation' }
  & { sendBudget: (
    { __typename?: 'Budget' }
    & Pick<Budget, 'id'>
  ) }
);

export type SendBudgetToClientQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SendBudgetToClientQuery = (
  { __typename?: 'Query' }
  & { sendBudgetToClient?: Maybe<(
    { __typename?: 'Budget' }
    & Pick<Budget, 'id'>
  )> }
);

export type GetDataCarShowQueryVariables = Exact<{
  plate: Scalars['String'];
}>;


export type GetDataCarShowQuery = (
  { __typename?: 'Query' }
  & { getCarByPlate?: Maybe<(
    { __typename?: 'Car' }
    & Pick<Car, 'chasis' | 'brand' | 'model' | 'year' | 'plate'>
  )> }
);

export type ChangeAdminPasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
  validationPassword: Scalars['String'];
}>;


export type ChangeAdminPasswordMutation = (
  { __typename?: 'Mutation' }
  & { changeAdminPassword: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'changedPassword'>
  ) }
);

export type GetEntryReservationsByIdShowQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetEntryReservationsByIdShowQuery = (
  { __typename?: 'Query' }
  & { getEntryReservationById?: Maybe<(
    { __typename?: 'EntryReservation' }
    & Pick<EntryReservation, 'date' | 'description'>
    & { budget?: Maybe<(
      { __typename?: 'Budget' }
      & Pick<Budget, 'id'>
      & { car: (
        { __typename?: 'Car' }
        & Pick<Car, 'model' | 'brand' | 'year' | 'plate' | 'chasis' | 'color'>
      ), client: (
        { __typename?: 'Client' }
        & Pick<Client, 'name' | 'lastname' | 'rut' | 'email' | 'phone'>
      ) }
    )> }
  )> }
);

export type LoginAdminMutationVariables = Exact<{
  rut: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginAdminMutation = (
  { __typename?: 'Mutation' }
  & { loginAdmin?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'changedPassword'>
    ) }
  )> }
);

export type UpdatePiecesMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdatePiecesMutation = (
  { __typename?: 'Mutation' }
  & { updatePieces: Array<(
    { __typename?: 'Piece' }
    & Pick<Piece, 'id'>
  )> }
);

export type CreateEntryReservationMutationVariables = Exact<{
  budgetId: Scalars['Int'];
  date: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
}>;


export type CreateEntryReservationMutation = (
  { __typename?: 'Mutation' }
  & { createEntryReservation?: Maybe<(
    { __typename?: 'EntryReservation' }
    & Pick<EntryReservation, 'id'>
  )> }
);

export type GetEntryReservationsQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type GetEntryReservationsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getEntryReservations'>
);

export type GetEntryReservationByBudgetQueryVariables = Exact<{
  budgetId: Scalars['Int'];
}>;


export type GetEntryReservationByBudgetQuery = (
  { __typename?: 'Query' }
  & { getEntryReservationByBudget?: Maybe<(
    { __typename?: 'EntryReservation' }
    & Pick<EntryReservation, 'id' | 'date'>
    & { budget?: Maybe<(
      { __typename?: 'Budget' }
      & Pick<Budget, 'id'>
      & { car: (
        { __typename?: 'Car' }
        & Pick<Car, 'plate' | 'brand' | 'model' | 'color' | 'year'>
      ), client: (
        { __typename?: 'Client' }
        & Pick<Client, 'rut' | 'name' | 'lastname'>
      ) }
    )> }
  )> }
);

export type UpdateEntryReservationMutationVariables = Exact<{
  id: Scalars['Int'];
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateEntryReservationMutation = (
  { __typename?: 'Mutation' }
  & { updateEntryReservation?: Maybe<(
    { __typename?: 'EntryReservation' }
    & Pick<EntryReservation, 'id'>
  )> }
);

export type GetReceptionByIdShowQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetReceptionByIdShowQuery = (
  { __typename?: 'Query' }
  & { getReceptionById?: Maybe<(
    { __typename?: 'Reception' }
    & Pick<Reception, 'receptionDate' | 'internalInventory' | 'externalAndOtherInventory' | 'details' | 'workingItems' | 'clientSignature' | 'craneSignature' | 'observations'>
    & { budget: (
      { __typename?: 'Budget' }
      & { car: (
        { __typename?: 'Car' }
        & Pick<Car, 'model' | 'brand' | 'year' | 'plate' | 'chasis' | 'color'>
      ), client: (
        { __typename?: 'Client' }
        & Pick<Client, 'name' | 'lastname' | 'rut' | 'email' | 'phone'>
      ) }
    ) }
  )> }
);

export type SignupAdminMutationVariables = Exact<{
  rut: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
}>;


export type SignupAdminMutation = (
  { __typename?: 'Mutation' }
  & { signupAdmin?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
  )> }
);

export type SignupWorkerMutationVariables = Exact<{
  rut: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
  role?: Maybe<Scalars['String']>;
}>;


export type SignupWorkerMutation = (
  { __typename?: 'Mutation' }
  & { signupWorker?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'token'>
  )> }
);

export type AddReservationImageMutationVariables = Exact<{
  reservationId: Scalars['Int'];
  field: Scalars['String'];
  link: Scalars['String'];
}>;


export type AddReservationImageMutation = (
  { __typename?: 'Mutation' }
  & { addReservationImage?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'id' | 'declaracionJurada'>
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'carnet'>
    )>, car?: Maybe<(
      { __typename?: 'Car' }
      & Pick<Car, 'permisoCirculacion'>
    )> }
  )> }
);

export type GetReservationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetReservationQuery = (
  { __typename?: 'Query' }
  & { getReservation?: Maybe<(
    { __typename?: 'Reservation' }
    & Pick<Reservation, 'date' | 'declaracionJurada' | 'description' | 'siniestro'>
    & { client?: Maybe<(
      { __typename?: 'Client' }
      & Pick<Client, 'rut' | 'name' | 'lastname' | 'email' | 'phone' | 'carnet'>
    )>, car?: Maybe<(
      { __typename?: 'Car' }
      & Pick<Car, 'plate' | 'chasis' | 'model' | 'color' | 'year' | 'inside' | 'brand' | 'permisoCirculacion'>
    )>, service?: Maybe<(
      { __typename?: 'Service' }
      & Pick<Service, 'name'>
    )>, insurance?: Maybe<(
      { __typename?: 'Insurance' }
      & Pick<Insurance, 'name' | 'email'>
    )> }
  )> }
);

export type AllServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllServicesQuery = (
  { __typename?: 'Query' }
  & { services: Array<(
    { __typename?: 'Service' }
    & Pick<Service, 'name' | 'id'>
  )> }
);

export type WorkOrdersQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  open?: Maybe<Scalars['Boolean']>;
  currentProcess?: Maybe<Scalars['String']>;
  deadlineSort?: Maybe<Scalars['String']>;
  nameSort?: Maybe<Scalars['String']>;
  rutSort?: Maybe<Scalars['String']>;
}>;


export type WorkOrdersQuery = (
  { __typename?: 'Query' }
  & { workOrders: Array<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'id' | 'deadline' | 'closed' | 'deposit' | 'comments'>
    & { budget: (
      { __typename?: 'Budget' }
      & Pick<Budget, 'id' | 'folio'>
      & { client: (
        { __typename?: 'Client' }
        & Pick<Client, 'rut' | 'name' | 'lastname'>
      ) }
    ), currentProcess: (
      { __typename?: 'Stage' }
      & Pick<Stage, 'id' | 'name'>
    ) }
  )> }
);

export type AssignTaskMutationVariables = Exact<{
  taskIds: Array<Scalars['Int']> | Scalars['Int'];
  workerId: Scalars['Int'];
}>;


export type AssignTaskMutation = (
  { __typename?: 'Mutation' }
  & { assignTasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { concept: (
      { __typename?: 'Concept' }
      & Pick<Concept, 'name'>
    ), piece?: Maybe<(
      { __typename?: 'Piece' }
      & Pick<Piece, 'name'>
    )>, status: (
      { __typename?: 'Status' }
      & Pick<Status, 'name'>
    ), worker?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name'>
    )> }
  )> }
);

export type CompleteTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { completeTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { status: (
      { __typename?: 'Status' }
      & Pick<Status, 'name'>
    ) }
  ) }
);

export type ExitCarMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ExitCarMutation = (
  { __typename?: 'Mutation' }
  & { exitCar: (
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'id'>
  ) }
);

export type GetAdminWorkOrderByFolioQueryVariables = Exact<{
  rut: Scalars['String'];
  folio: Scalars['String'];
}>;


export type GetAdminWorkOrderByFolioQuery = (
  { __typename?: 'Query' }
  & { getWorkOrderByFolio?: Maybe<(
    { __typename?: 'WorkOrder' }
    & Pick<WorkOrder, 'id' | 'closed' | 'inWorkshop' | 'deadline' | 'comments'>
    & { budget: (
      { __typename?: 'Budget' }
      & { car: (
        { __typename?: 'Car' }
        & Pick<Car, 'plate' | 'brand' | 'model' | 'color'>
      ), client: (
        { __typename?: 'Client' }
        & Pick<Client, 'email'>
      ) }
    ), workProcesses: Array<(
      { __typename?: 'WorkProcess' }
      & Pick<WorkProcess, 'id'>
      & { process: (
        { __typename?: 'Stage' }
        & Pick<Stage, 'id' | 'name'>
      ), tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'id'>
        & { worker?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'name'>
        )>, status: (
          { __typename?: 'Status' }
          & Pick<Status, 'name'>
        ), concept: (
          { __typename?: 'Concept' }
          & Pick<Concept, 'name'>
        ), piece?: Maybe<(
          { __typename?: 'Piece' }
          & Pick<Piece, 'name'>
        )> }
      )> }
    )>, currentProcess: (
      { __typename?: 'Stage' }
      & Pick<Stage, 'id'>
    ) }
  )>, workers: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'role'>
  )> }
);

export type StartTaskMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StartTaskMutation = (
  { __typename?: 'Mutation' }
  & { startTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { status: (
      { __typename?: 'Status' }
      & Pick<Status, 'name'>
    ) }
  ) }
);


export const AllEntryReservationsDocument = gql`
    query allEntryReservations {
  allEntryReservations
}
    `;

/**
 * __useAllEntryReservationsQuery__
 *
 * To run a query within a React component, call `useAllEntryReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEntryReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEntryReservationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllEntryReservationsQuery(baseOptions?: Apollo.QueryHookOptions<AllEntryReservationsQuery, AllEntryReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllEntryReservationsQuery, AllEntryReservationsQueryVariables>(AllEntryReservationsDocument, options);
      }
export function useAllEntryReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllEntryReservationsQuery, AllEntryReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllEntryReservationsQuery, AllEntryReservationsQueryVariables>(AllEntryReservationsDocument, options);
        }
export type AllEntryReservationsQueryHookResult = ReturnType<typeof useAllEntryReservationsQuery>;
export type AllEntryReservationsLazyQueryHookResult = ReturnType<typeof useAllEntryReservationsLazyQuery>;
export type AllEntryReservationsQueryResult = Apollo.QueryResult<AllEntryReservationsQuery, AllEntryReservationsQueryVariables>;
export const CarsDocument = gql`
    query cars {
  cars {
    id
    plate
    chasis
    brand
    model
    color
    year
  }
}
    `;

/**
 * __useCarsQuery__
 *
 * To run a query within a React component, call `useCarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCarsQuery(baseOptions?: Apollo.QueryHookOptions<CarsQuery, CarsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CarsQuery, CarsQueryVariables>(CarsDocument, options);
      }
export function useCarsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CarsQuery, CarsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CarsQuery, CarsQueryVariables>(CarsDocument, options);
        }
export type CarsQueryHookResult = ReturnType<typeof useCarsQuery>;
export type CarsLazyQueryHookResult = ReturnType<typeof useCarsLazyQuery>;
export type CarsQueryResult = Apollo.QueryResult<CarsQuery, CarsQueryVariables>;
export const ClientsDocument = gql`
    query clients {
  clients {
    rut
    name
    lastname
    phone
    email
  }
}
    `;

/**
 * __useClientsQuery__
 *
 * To run a query within a React component, call `useClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClientsQuery(baseOptions?: Apollo.QueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
      }
export function useClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClientsQuery, ClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClientsQuery, ClientsQueryVariables>(ClientsDocument, options);
        }
export type ClientsQueryHookResult = ReturnType<typeof useClientsQuery>;
export type ClientsLazyQueryHookResult = ReturnType<typeof useClientsLazyQuery>;
export type ClientsQueryResult = Apollo.QueryResult<ClientsQuery, ClientsQueryVariables>;
export const ExportInsurancesDocument = gql`
    query exportInsurances {
  exportInsurances {
    id
    name
    email
  }
}
    `;

/**
 * __useExportInsurancesQuery__
 *
 * To run a query within a React component, call `useExportInsurancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportInsurancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportInsurancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportInsurancesQuery(baseOptions?: Apollo.QueryHookOptions<ExportInsurancesQuery, ExportInsurancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportInsurancesQuery, ExportInsurancesQueryVariables>(ExportInsurancesDocument, options);
      }
export function useExportInsurancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportInsurancesQuery, ExportInsurancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportInsurancesQuery, ExportInsurancesQueryVariables>(ExportInsurancesDocument, options);
        }
export type ExportInsurancesQueryHookResult = ReturnType<typeof useExportInsurancesQuery>;
export type ExportInsurancesLazyQueryHookResult = ReturnType<typeof useExportInsurancesLazyQuery>;
export type ExportInsurancesQueryResult = Apollo.QueryResult<ExportInsurancesQuery, ExportInsurancesQueryVariables>;
export const ExportServicesDocument = gql`
    query exportServices {
  exportServices {
    id
    name
    description
    places
    lastBlockPlaces
  }
}
    `;

/**
 * __useExportServicesQuery__
 *
 * To run a query within a React component, call `useExportServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportServicesQuery(baseOptions?: Apollo.QueryHookOptions<ExportServicesQuery, ExportServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportServicesQuery, ExportServicesQueryVariables>(ExportServicesDocument, options);
      }
export function useExportServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportServicesQuery, ExportServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportServicesQuery, ExportServicesQueryVariables>(ExportServicesDocument, options);
        }
export type ExportServicesQueryHookResult = ReturnType<typeof useExportServicesQuery>;
export type ExportServicesLazyQueryHookResult = ReturnType<typeof useExportServicesLazyQuery>;
export type ExportServicesQueryResult = Apollo.QueryResult<ExportServicesQuery, ExportServicesQueryVariables>;
export const ExportTasksDocument = gql`
    query exportTasks {
  exportTasks {
    id
    workProcess {
      id
    }
    worker {
      rut
    }
    status {
      id
      name
    }
    concept {
      id
      name
      stage {
        id
        name
      }
    }
    piece {
      id
      name
    }
  }
}
    `;

/**
 * __useExportTasksQuery__
 *
 * To run a query within a React component, call `useExportTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportTasksQuery(baseOptions?: Apollo.QueryHookOptions<ExportTasksQuery, ExportTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportTasksQuery, ExportTasksQueryVariables>(ExportTasksDocument, options);
      }
export function useExportTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportTasksQuery, ExportTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportTasksQuery, ExportTasksQueryVariables>(ExportTasksDocument, options);
        }
export type ExportTasksQueryHookResult = ReturnType<typeof useExportTasksQuery>;
export type ExportTasksLazyQueryHookResult = ReturnType<typeof useExportTasksLazyQuery>;
export type ExportTasksQueryResult = Apollo.QueryResult<ExportTasksQuery, ExportTasksQueryVariables>;
export const ExportWorkPocessesDocument = gql`
    query exportWorkPocesses {
  exportWorkPocesses {
    id
    workOrder {
      id
    }
    process {
      id
    }
  }
}
    `;

/**
 * __useExportWorkPocessesQuery__
 *
 * To run a query within a React component, call `useExportWorkPocessesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportWorkPocessesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportWorkPocessesQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportWorkPocessesQuery(baseOptions?: Apollo.QueryHookOptions<ExportWorkPocessesQuery, ExportWorkPocessesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExportWorkPocessesQuery, ExportWorkPocessesQueryVariables>(ExportWorkPocessesDocument, options);
      }
export function useExportWorkPocessesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExportWorkPocessesQuery, ExportWorkPocessesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExportWorkPocessesQuery, ExportWorkPocessesQueryVariables>(ExportWorkPocessesDocument, options);
        }
export type ExportWorkPocessesQueryHookResult = ReturnType<typeof useExportWorkPocessesQuery>;
export type ExportWorkPocessesLazyQueryHookResult = ReturnType<typeof useExportWorkPocessesLazyQuery>;
export type ExportWorkPocessesQueryResult = Apollo.QueryResult<ExportWorkPocessesQuery, ExportWorkPocessesQueryVariables>;
export const EntryReservationsDocument = gql`
    query entryReservations($take: Int!, $page: Int!) {
  entryReservations(take: $take, page: $page) {
    id
    date
    description
  }
}
    `;

/**
 * __useEntryReservationsQuery__
 *
 * To run a query within a React component, call `useEntryReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntryReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntryReservationsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useEntryReservationsQuery(baseOptions: Apollo.QueryHookOptions<EntryReservationsQuery, EntryReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EntryReservationsQuery, EntryReservationsQueryVariables>(EntryReservationsDocument, options);
      }
export function useEntryReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EntryReservationsQuery, EntryReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EntryReservationsQuery, EntryReservationsQueryVariables>(EntryReservationsDocument, options);
        }
export type EntryReservationsQueryHookResult = ReturnType<typeof useEntryReservationsQuery>;
export type EntryReservationsLazyQueryHookResult = ReturnType<typeof useEntryReservationsLazyQuery>;
export type EntryReservationsQueryResult = Apollo.QueryResult<EntryReservationsQuery, EntryReservationsQueryVariables>;
export const UsersDocument = gql`
    query users {
  users {
    id
    rut
    name
    role
    email
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const AdminChangeBudgetStatusDocument = gql`
    mutation AdminChangeBudgetStatus($budgetID: Int!, $status: BudgetStatusOption!) {
  changeBudgetStatus(budgetID: $budgetID, status: $status) {
    id
  }
}
    `;
export type AdminChangeBudgetStatusMutationFn = Apollo.MutationFunction<AdminChangeBudgetStatusMutation, AdminChangeBudgetStatusMutationVariables>;

/**
 * __useAdminChangeBudgetStatusMutation__
 *
 * To run a mutation, you first call `useAdminChangeBudgetStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminChangeBudgetStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminChangeBudgetStatusMutation, { data, loading, error }] = useAdminChangeBudgetStatusMutation({
 *   variables: {
 *      budgetID: // value for 'budgetID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useAdminChangeBudgetStatusMutation(baseOptions?: Apollo.MutationHookOptions<AdminChangeBudgetStatusMutation, AdminChangeBudgetStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminChangeBudgetStatusMutation, AdminChangeBudgetStatusMutationVariables>(AdminChangeBudgetStatusDocument, options);
      }
export type AdminChangeBudgetStatusMutationHookResult = ReturnType<typeof useAdminChangeBudgetStatusMutation>;
export type AdminChangeBudgetStatusMutationResult = Apollo.MutationResult<AdminChangeBudgetStatusMutation>;
export type AdminChangeBudgetStatusMutationOptions = Apollo.BaseMutationOptions<AdminChangeBudgetStatusMutation, AdminChangeBudgetStatusMutationVariables>;
export const AdminBudgetDeleteDocument = gql`
    mutation AdminBudgetDelete($id: Int!) {
  deleteBudget(id: $id) {
    id
  }
}
    `;
export type AdminBudgetDeleteMutationFn = Apollo.MutationFunction<AdminBudgetDeleteMutation, AdminBudgetDeleteMutationVariables>;

/**
 * __useAdminBudgetDeleteMutation__
 *
 * To run a mutation, you first call `useAdminBudgetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminBudgetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminBudgetDeleteMutation, { data, loading, error }] = useAdminBudgetDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAdminBudgetDeleteMutation(baseOptions?: Apollo.MutationHookOptions<AdminBudgetDeleteMutation, AdminBudgetDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminBudgetDeleteMutation, AdminBudgetDeleteMutationVariables>(AdminBudgetDeleteDocument, options);
      }
export type AdminBudgetDeleteMutationHookResult = ReturnType<typeof useAdminBudgetDeleteMutation>;
export type AdminBudgetDeleteMutationResult = Apollo.MutationResult<AdminBudgetDeleteMutation>;
export type AdminBudgetDeleteMutationOptions = Apollo.BaseMutationOptions<AdminBudgetDeleteMutation, AdminBudgetDeleteMutationVariables>;
export const ClientChangeBudgetStatusDocument = gql`
    mutation ClientChangeBudgetStatus($budgetID: Int!, $status: BudgetStatusOption!, $rut: String, $folio: String) {
  changeBudgetStatus(
    budgetID: $budgetID
    status: $status
    folio: $folio
    rut: $rut
  ) {
    id
  }
}
    `;
export type ClientChangeBudgetStatusMutationFn = Apollo.MutationFunction<ClientChangeBudgetStatusMutation, ClientChangeBudgetStatusMutationVariables>;

/**
 * __useClientChangeBudgetStatusMutation__
 *
 * To run a mutation, you first call `useClientChangeBudgetStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClientChangeBudgetStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clientChangeBudgetStatusMutation, { data, loading, error }] = useClientChangeBudgetStatusMutation({
 *   variables: {
 *      budgetID: // value for 'budgetID'
 *      status: // value for 'status'
 *      rut: // value for 'rut'
 *      folio: // value for 'folio'
 *   },
 * });
 */
export function useClientChangeBudgetStatusMutation(baseOptions?: Apollo.MutationHookOptions<ClientChangeBudgetStatusMutation, ClientChangeBudgetStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClientChangeBudgetStatusMutation, ClientChangeBudgetStatusMutationVariables>(ClientChangeBudgetStatusDocument, options);
      }
export type ClientChangeBudgetStatusMutationHookResult = ReturnType<typeof useClientChangeBudgetStatusMutation>;
export type ClientChangeBudgetStatusMutationResult = Apollo.MutationResult<ClientChangeBudgetStatusMutation>;
export type ClientChangeBudgetStatusMutationOptions = Apollo.BaseMutationOptions<ClientChangeBudgetStatusMutation, ClientChangeBudgetStatusMutationVariables>;
export const GetAvailabilityDocument = gql`
    query getAvailability($serviceId: Int!, $date: String!) {
  getAvailability(serviceId: $serviceId, date: $date)
}
    `;

/**
 * __useGetAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailabilityQuery({
 *   variables: {
 *      serviceId: // value for 'serviceId'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<GetAvailabilityQuery, GetAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAvailabilityQuery, GetAvailabilityQueryVariables>(GetAvailabilityDocument, options);
      }
export function useGetAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAvailabilityQuery, GetAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAvailabilityQuery, GetAvailabilityQueryVariables>(GetAvailabilityDocument, options);
        }
export type GetAvailabilityQueryHookResult = ReturnType<typeof useGetAvailabilityQuery>;
export type GetAvailabilityLazyQueryHookResult = ReturnType<typeof useGetAvailabilityLazyQuery>;
export type GetAvailabilityQueryResult = Apollo.QueryResult<GetAvailabilityQuery, GetAvailabilityQueryVariables>;
export const GetCarByPlateDocument = gql`
    query getCarByPlate($plate: String!, $gte: Boolean) {
  getCarByPlate(plate: $plate, gte: $gte) {
    id
    plate
    reservations {
      id
      date
    }
  }
}
    `;

/**
 * __useGetCarByPlateQuery__
 *
 * To run a query within a React component, call `useGetCarByPlateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCarByPlateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCarByPlateQuery({
 *   variables: {
 *      plate: // value for 'plate'
 *      gte: // value for 'gte'
 *   },
 * });
 */
export function useGetCarByPlateQuery(baseOptions: Apollo.QueryHookOptions<GetCarByPlateQuery, GetCarByPlateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCarByPlateQuery, GetCarByPlateQueryVariables>(GetCarByPlateDocument, options);
      }
export function useGetCarByPlateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCarByPlateQuery, GetCarByPlateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCarByPlateQuery, GetCarByPlateQueryVariables>(GetCarByPlateDocument, options);
        }
export type GetCarByPlateQueryHookResult = ReturnType<typeof useGetCarByPlateQuery>;
export type GetCarByPlateLazyQueryHookResult = ReturnType<typeof useGetCarByPlateLazyQuery>;
export type GetCarByPlateQueryResult = Apollo.QueryResult<GetCarByPlateQuery, GetCarByPlateQueryVariables>;
export const NotifyDelayDocument = gql`
    mutation notifyDelay($workOrderId: Int!, $comments: String!, $newDeadline: String!) {
  notifyDelay(
    workOrderId: $workOrderId
    comments: $comments
    newDeadline: $newDeadline
  ) {
    id
    deadline
  }
}
    `;
export type NotifyDelayMutationFn = Apollo.MutationFunction<NotifyDelayMutation, NotifyDelayMutationVariables>;

/**
 * __useNotifyDelayMutation__
 *
 * To run a mutation, you first call `useNotifyDelayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useNotifyDelayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [notifyDelayMutation, { data, loading, error }] = useNotifyDelayMutation({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *      comments: // value for 'comments'
 *      newDeadline: // value for 'newDeadline'
 *   },
 * });
 */
export function useNotifyDelayMutation(baseOptions?: Apollo.MutationHookOptions<NotifyDelayMutation, NotifyDelayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NotifyDelayMutation, NotifyDelayMutationVariables>(NotifyDelayDocument, options);
      }
export type NotifyDelayMutationHookResult = ReturnType<typeof useNotifyDelayMutation>;
export type NotifyDelayMutationResult = Apollo.MutationResult<NotifyDelayMutation>;
export type NotifyDelayMutationOptions = Apollo.BaseMutationOptions<NotifyDelayMutation, NotifyDelayMutationVariables>;
export const AllServicesInformationDocument = gql`
    query AllServicesInformation {
  services {
    id
    name
  }
}
    `;

/**
 * __useAllServicesInformationQuery__
 *
 * To run a query within a React component, call `useAllServicesInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllServicesInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllServicesInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllServicesInformationQuery(baseOptions?: Apollo.QueryHookOptions<AllServicesInformationQuery, AllServicesInformationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllServicesInformationQuery, AllServicesInformationQueryVariables>(AllServicesInformationDocument, options);
      }
export function useAllServicesInformationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllServicesInformationQuery, AllServicesInformationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllServicesInformationQuery, AllServicesInformationQueryVariables>(AllServicesInformationDocument, options);
        }
export type AllServicesInformationQueryHookResult = ReturnType<typeof useAllServicesInformationQuery>;
export type AllServicesInformationLazyQueryHookResult = ReturnType<typeof useAllServicesInformationLazyQuery>;
export type AllServicesInformationQueryResult = Apollo.QueryResult<AllServicesInformationQuery, AllServicesInformationQueryVariables>;
export const GetWorkOrderByFolioDocument = gql`
    query getWorkOrderByFolio($rut: String!, $folio: String!) {
  getWorkOrderByFolio(rut: $rut, folio: $folio) {
    id
    budget {
      id
      car {
        id
        plate
        brand
        model
        color
        year
        chasis
      }
    }
    deadline
    workProcesses {
      id
      process {
        id
        name
      }
    }
    currentProcess {
      id
      name
    }
    comments
    closed
  }
}
    `;

/**
 * __useGetWorkOrderByFolioQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderByFolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderByFolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderByFolioQuery({
 *   variables: {
 *      rut: // value for 'rut'
 *      folio: // value for 'folio'
 *   },
 * });
 */
export function useGetWorkOrderByFolioQuery(baseOptions: Apollo.QueryHookOptions<GetWorkOrderByFolioQuery, GetWorkOrderByFolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkOrderByFolioQuery, GetWorkOrderByFolioQueryVariables>(GetWorkOrderByFolioDocument, options);
      }
export function useGetWorkOrderByFolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkOrderByFolioQuery, GetWorkOrderByFolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkOrderByFolioQuery, GetWorkOrderByFolioQueryVariables>(GetWorkOrderByFolioDocument, options);
        }
export type GetWorkOrderByFolioQueryHookResult = ReturnType<typeof useGetWorkOrderByFolioQuery>;
export type GetWorkOrderByFolioLazyQueryHookResult = ReturnType<typeof useGetWorkOrderByFolioLazyQuery>;
export type GetWorkOrderByFolioQueryResult = Apollo.QueryResult<GetWorkOrderByFolioQuery, GetWorkOrderByFolioQueryVariables>;
export const ServicesDocument = gql`
    query services {
  services {
    id
    name
    description
    places
    lastBlockPlaces
  }
}
    `;

/**
 * __useServicesQuery__
 *
 * To run a query within a React component, call `useServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useServicesQuery(baseOptions?: Apollo.QueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
      }
export function useServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServicesQuery, ServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServicesQuery, ServicesQueryVariables>(ServicesDocument, options);
        }
export type ServicesQueryHookResult = ReturnType<typeof useServicesQuery>;
export type ServicesLazyQueryHookResult = ReturnType<typeof useServicesLazyQuery>;
export type ServicesQueryResult = Apollo.QueryResult<ServicesQuery, ServicesQueryVariables>;
export const AddBudgetItemDocument = gql`
    mutation AddBudgetItem($budgetId: Int!, $input: ItemInput!) {
  addBudgetItem(budgetId: $budgetId, input: $input) {
    id
    price
    concept {
      id
      name
    }
    piece {
      id
      name
    }
  }
}
    `;
export type AddBudgetItemMutationFn = Apollo.MutationFunction<AddBudgetItemMutation, AddBudgetItemMutationVariables>;

/**
 * __useAddBudgetItemMutation__
 *
 * To run a mutation, you first call `useAddBudgetItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBudgetItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBudgetItemMutation, { data, loading, error }] = useAddBudgetItemMutation({
 *   variables: {
 *      budgetId: // value for 'budgetId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddBudgetItemMutation(baseOptions?: Apollo.MutationHookOptions<AddBudgetItemMutation, AddBudgetItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddBudgetItemMutation, AddBudgetItemMutationVariables>(AddBudgetItemDocument, options);
      }
export type AddBudgetItemMutationHookResult = ReturnType<typeof useAddBudgetItemMutation>;
export type AddBudgetItemMutationResult = Apollo.MutationResult<AddBudgetItemMutation>;
export type AddBudgetItemMutationOptions = Apollo.BaseMutationOptions<AddBudgetItemMutation, AddBudgetItemMutationVariables>;
export const AddReplacementToBudgetDocument = gql`
    mutation AddReplacementToBudget($input: ReplacementInput!, $budgetId: Int) {
  addReplacementToBudget(input: $input, budgetId: $budgetId) {
    id
    piece {
      id
      name
    }
    quantity
    price
  }
}
    `;
export type AddReplacementToBudgetMutationFn = Apollo.MutationFunction<AddReplacementToBudgetMutation, AddReplacementToBudgetMutationVariables>;

/**
 * __useAddReplacementToBudgetMutation__
 *
 * To run a mutation, you first call `useAddReplacementToBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReplacementToBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReplacementToBudgetMutation, { data, loading, error }] = useAddReplacementToBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *      budgetId: // value for 'budgetId'
 *   },
 * });
 */
export function useAddReplacementToBudgetMutation(baseOptions?: Apollo.MutationHookOptions<AddReplacementToBudgetMutation, AddReplacementToBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReplacementToBudgetMutation, AddReplacementToBudgetMutationVariables>(AddReplacementToBudgetDocument, options);
      }
export type AddReplacementToBudgetMutationHookResult = ReturnType<typeof useAddReplacementToBudgetMutation>;
export type AddReplacementToBudgetMutationResult = Apollo.MutationResult<AddReplacementToBudgetMutation>;
export type AddReplacementToBudgetMutationOptions = Apollo.BaseMutationOptions<AddReplacementToBudgetMutation, AddReplacementToBudgetMutationVariables>;
export const DeleteBudgetImageDocument = gql`
    mutation DeleteBudgetImage($id: Int!) {
  deleteBudgetImage(id: $id) {
    id
  }
}
    `;
export type DeleteBudgetImageMutationFn = Apollo.MutationFunction<DeleteBudgetImageMutation, DeleteBudgetImageMutationVariables>;

/**
 * __useDeleteBudgetImageMutation__
 *
 * To run a mutation, you first call `useDeleteBudgetImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBudgetImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBudgetImageMutation, { data, loading, error }] = useDeleteBudgetImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBudgetImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBudgetImageMutation, DeleteBudgetImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBudgetImageMutation, DeleteBudgetImageMutationVariables>(DeleteBudgetImageDocument, options);
      }
export type DeleteBudgetImageMutationHookResult = ReturnType<typeof useDeleteBudgetImageMutation>;
export type DeleteBudgetImageMutationResult = Apollo.MutationResult<DeleteBudgetImageMutation>;
export type DeleteBudgetImageMutationOptions = Apollo.BaseMutationOptions<DeleteBudgetImageMutation, DeleteBudgetImageMutationVariables>;
export const DeletBudgetItemDocument = gql`
    mutation DeletBudgetItem($itemId: Int!) {
  deleteBudgetItem(itemId: $itemId) {
    id
    price
  }
}
    `;
export type DeletBudgetItemMutationFn = Apollo.MutationFunction<DeletBudgetItemMutation, DeletBudgetItemMutationVariables>;

/**
 * __useDeletBudgetItemMutation__
 *
 * To run a mutation, you first call `useDeletBudgetItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletBudgetItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletBudgetItemMutation, { data, loading, error }] = useDeletBudgetItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useDeletBudgetItemMutation(baseOptions?: Apollo.MutationHookOptions<DeletBudgetItemMutation, DeletBudgetItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletBudgetItemMutation, DeletBudgetItemMutationVariables>(DeletBudgetItemDocument, options);
      }
export type DeletBudgetItemMutationHookResult = ReturnType<typeof useDeletBudgetItemMutation>;
export type DeletBudgetItemMutationResult = Apollo.MutationResult<DeletBudgetItemMutation>;
export type DeletBudgetItemMutationOptions = Apollo.BaseMutationOptions<DeletBudgetItemMutation, DeletBudgetItemMutationVariables>;
export const DeleteReplacementDocument = gql`
    mutation DeleteReplacement($id: Int!) {
  deleteReplacement(id: $id) {
    id
    price
    quantity
  }
}
    `;
export type DeleteReplacementMutationFn = Apollo.MutationFunction<DeleteReplacementMutation, DeleteReplacementMutationVariables>;

/**
 * __useDeleteReplacementMutation__
 *
 * To run a mutation, you first call `useDeleteReplacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReplacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReplacementMutation, { data, loading, error }] = useDeleteReplacementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteReplacementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReplacementMutation, DeleteReplacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReplacementMutation, DeleteReplacementMutationVariables>(DeleteReplacementDocument, options);
      }
export type DeleteReplacementMutationHookResult = ReturnType<typeof useDeleteReplacementMutation>;
export type DeleteReplacementMutationResult = Apollo.MutationResult<DeleteReplacementMutation>;
export type DeleteReplacementMutationOptions = Apollo.BaseMutationOptions<DeleteReplacementMutation, DeleteReplacementMutationVariables>;
export const GetConceptsDocument = gql`
    query GetConcepts($stage: String!) {
  getConcepts(stage: $stage) {
    id
    name
  }
}
    `;

/**
 * __useGetConceptsQuery__
 *
 * To run a query within a React component, call `useGetConceptsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConceptsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConceptsQuery({
 *   variables: {
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetConceptsQuery(baseOptions: Apollo.QueryHookOptions<GetConceptsQuery, GetConceptsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConceptsQuery, GetConceptsQueryVariables>(GetConceptsDocument, options);
      }
export function useGetConceptsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConceptsQuery, GetConceptsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConceptsQuery, GetConceptsQueryVariables>(GetConceptsDocument, options);
        }
export type GetConceptsQueryHookResult = ReturnType<typeof useGetConceptsQuery>;
export type GetConceptsLazyQueryHookResult = ReturnType<typeof useGetConceptsLazyQuery>;
export type GetConceptsQueryResult = Apollo.QueryResult<GetConceptsQuery, GetConceptsQueryVariables>;
export const UpdateBudgetDocument = gql`
    mutation UpdateBudget($input: BudgetUpdateInput!) {
  updateBudget(input: $input) {
    id
    car {
      plate
      brand
      model
      color
      chasis
      year
    }
    client {
      name
      lastname
      rut
      phone
      email
    }
    insurance {
      name
      email
    }
    images {
      id
      link
    }
    items {
      id
      price
      concept {
        id
        name
      }
      piece {
        id
        name
      }
    }
    replacements {
      id
      piece {
        id
        name
      }
      quantity
      price
    }
  }
}
    `;
export type UpdateBudgetMutationFn = Apollo.MutationFunction<UpdateBudgetMutation, UpdateBudgetMutationVariables>;

/**
 * __useUpdateBudgetMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetMutation, { data, loading, error }] = useUpdateBudgetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBudgetMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBudgetMutation, UpdateBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBudgetMutation, UpdateBudgetMutationVariables>(UpdateBudgetDocument, options);
      }
export type UpdateBudgetMutationHookResult = ReturnType<typeof useUpdateBudgetMutation>;
export type UpdateBudgetMutationResult = Apollo.MutationResult<UpdateBudgetMutation>;
export type UpdateBudgetMutationOptions = Apollo.BaseMutationOptions<UpdateBudgetMutation, UpdateBudgetMutationVariables>;
export const UpdateReplacementDocument = gql`
    mutation UpdateReplacement($id: Int!, $description: String, $quantity: Int, $price: Int) {
  updateReplacement(
    id: $id
    description: $description
    quantity: $quantity
    price: $price
  ) {
    id
    piece {
      id
      name
    }
    quantity
    price
  }
}
    `;
export type UpdateReplacementMutationFn = Apollo.MutationFunction<UpdateReplacementMutation, UpdateReplacementMutationVariables>;

/**
 * __useUpdateReplacementMutation__
 *
 * To run a mutation, you first call `useUpdateReplacementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReplacementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReplacementMutation, { data, loading, error }] = useUpdateReplacementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      quantity: // value for 'quantity'
 *      price: // value for 'price'
 *   },
 * });
 */
export function useUpdateReplacementMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReplacementMutation, UpdateReplacementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReplacementMutation, UpdateReplacementMutationVariables>(UpdateReplacementDocument, options);
      }
export type UpdateReplacementMutationHookResult = ReturnType<typeof useUpdateReplacementMutation>;
export type UpdateReplacementMutationResult = Apollo.MutationResult<UpdateReplacementMutation>;
export type UpdateReplacementMutationOptions = Apollo.BaseMutationOptions<UpdateReplacementMutation, UpdateReplacementMutationVariables>;
export const UpdateBudgetItemDocument = gql`
    mutation UpdateBudgetItem($itemId: Int, $price: Int, $conceptId: Int, $pieceId: Int) {
  updateBudgetItem(
    itemId: $itemId
    price: $price
    conceptId: $conceptId
    pieceId: $pieceId
  ) {
    id
    price
    concept {
      id
      name
    }
    piece {
      id
      name
    }
  }
}
    `;
export type UpdateBudgetItemMutationFn = Apollo.MutationFunction<UpdateBudgetItemMutation, UpdateBudgetItemMutationVariables>;

/**
 * __useUpdateBudgetItemMutation__
 *
 * To run a mutation, you first call `useUpdateBudgetItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBudgetItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBudgetItemMutation, { data, loading, error }] = useUpdateBudgetItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      price: // value for 'price'
 *      conceptId: // value for 'conceptId'
 *      pieceId: // value for 'pieceId'
 *   },
 * });
 */
export function useUpdateBudgetItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBudgetItemMutation, UpdateBudgetItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBudgetItemMutation, UpdateBudgetItemMutationVariables>(UpdateBudgetItemDocument, options);
      }
export type UpdateBudgetItemMutationHookResult = ReturnType<typeof useUpdateBudgetItemMutation>;
export type UpdateBudgetItemMutationResult = Apollo.MutationResult<UpdateBudgetItemMutation>;
export type UpdateBudgetItemMutationOptions = Apollo.BaseMutationOptions<UpdateBudgetItemMutation, UpdateBudgetItemMutationVariables>;
export const UpdateReservationDocument = gql`
    mutation updateReservation($id: Int!, $date: DateTime) {
  updateReservation(id: $id, date: $date) {
    id
  }
}
    `;
export type UpdateReservationMutationFn = Apollo.MutationFunction<UpdateReservationMutation, UpdateReservationMutationVariables>;

/**
 * __useUpdateReservationMutation__
 *
 * To run a mutation, you first call `useUpdateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReservationMutation, { data, loading, error }] = useUpdateReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *   },
 * });
 */
export function useUpdateReservationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReservationMutation, UpdateReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReservationMutation, UpdateReservationMutationVariables>(UpdateReservationDocument, options);
      }
export type UpdateReservationMutationHookResult = ReturnType<typeof useUpdateReservationMutation>;
export type UpdateReservationMutationResult = Apollo.MutationResult<UpdateReservationMutation>;
export type UpdateReservationMutationOptions = Apollo.BaseMutationOptions<UpdateReservationMutation, UpdateReservationMutationVariables>;
export const DeleteReservationImageDocument = gql`
    mutation DeleteReservationImage($reservationId: Int!, $field: String!, $link: String!) {
  deleteReservationImage(
    reservationId: $reservationId
    field: $field
    link: $link
  ) {
    id
    client {
      carnet
    }
    car {
      permisoCirculacion
    }
    declaracionJurada
  }
}
    `;
export type DeleteReservationImageMutationFn = Apollo.MutationFunction<DeleteReservationImageMutation, DeleteReservationImageMutationVariables>;

/**
 * __useDeleteReservationImageMutation__
 *
 * To run a mutation, you first call `useDeleteReservationImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReservationImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReservationImageMutation, { data, loading, error }] = useDeleteReservationImageMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      field: // value for 'field'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useDeleteReservationImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReservationImageMutation, DeleteReservationImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReservationImageMutation, DeleteReservationImageMutationVariables>(DeleteReservationImageDocument, options);
      }
export type DeleteReservationImageMutationHookResult = ReturnType<typeof useDeleteReservationImageMutation>;
export type DeleteReservationImageMutationResult = Apollo.MutationResult<DeleteReservationImageMutation>;
export type DeleteReservationImageMutationOptions = Apollo.BaseMutationOptions<DeleteReservationImageMutation, DeleteReservationImageMutationVariables>;
export const AdminsListDocument = gql`
    query AdminsList {
  admins {
    name
    email
    rut
  }
}
    `;

/**
 * __useAdminsListQuery__
 *
 * To run a query within a React component, call `useAdminsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminsListQuery(baseOptions?: Apollo.QueryHookOptions<AdminsListQuery, AdminsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminsListQuery, AdminsListQueryVariables>(AdminsListDocument, options);
      }
export function useAdminsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminsListQuery, AdminsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminsListQuery, AdminsListQueryVariables>(AdminsListDocument, options);
        }
export type AdminsListQueryHookResult = ReturnType<typeof useAdminsListQuery>;
export type AdminsListLazyQueryHookResult = ReturnType<typeof useAdminsListLazyQuery>;
export type AdminsListQueryResult = Apollo.QueryResult<AdminsListQuery, AdminsListQueryVariables>;
export const AllEntryReservationsListDocument = gql`
    query allEntryReservationsList($name: String, $dateIni: DateTime, $dateEnd: DateTime, $plate: String) {
  allEntryReservations(
    name: $name
    date_ini: $dateIni
    date_end: $dateEnd
    plate: $plate
  )
}
    `;

/**
 * __useAllEntryReservationsListQuery__
 *
 * To run a query within a React component, call `useAllEntryReservationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEntryReservationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEntryReservationsListQuery({
 *   variables: {
 *      name: // value for 'name'
 *      dateIni: // value for 'dateIni'
 *      dateEnd: // value for 'dateEnd'
 *      plate: // value for 'plate'
 *   },
 * });
 */
export function useAllEntryReservationsListQuery(baseOptions?: Apollo.QueryHookOptions<AllEntryReservationsListQuery, AllEntryReservationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllEntryReservationsListQuery, AllEntryReservationsListQueryVariables>(AllEntryReservationsListDocument, options);
      }
export function useAllEntryReservationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllEntryReservationsListQuery, AllEntryReservationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllEntryReservationsListQuery, AllEntryReservationsListQueryVariables>(AllEntryReservationsListDocument, options);
        }
export type AllEntryReservationsListQueryHookResult = ReturnType<typeof useAllEntryReservationsListQuery>;
export type AllEntryReservationsListLazyQueryHookResult = ReturnType<typeof useAllEntryReservationsListLazyQuery>;
export type AllEntryReservationsListQueryResult = Apollo.QueryResult<AllEntryReservationsListQuery, AllEntryReservationsListQueryVariables>;
export const AllWorkOrdersDocument = gql`
    query allWorkOrders($open: Boolean, $currentProcess: String, $inWorkshop: Boolean) {
  allWorkOrders(
    open: $open
    currentProcess: $currentProcess
    inWorkshop: $inWorkshop
  )
}
    `;

/**
 * __useAllWorkOrdersQuery__
 *
 * To run a query within a React component, call `useAllWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllWorkOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllWorkOrdersQuery({
 *   variables: {
 *      open: // value for 'open'
 *      currentProcess: // value for 'currentProcess'
 *      inWorkshop: // value for 'inWorkshop'
 *   },
 * });
 */
export function useAllWorkOrdersQuery(baseOptions?: Apollo.QueryHookOptions<AllWorkOrdersQuery, AllWorkOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllWorkOrdersQuery, AllWorkOrdersQueryVariables>(AllWorkOrdersDocument, options);
      }
export function useAllWorkOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllWorkOrdersQuery, AllWorkOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllWorkOrdersQuery, AllWorkOrdersQueryVariables>(AllWorkOrdersDocument, options);
        }
export type AllWorkOrdersQueryHookResult = ReturnType<typeof useAllWorkOrdersQuery>;
export type AllWorkOrdersLazyQueryHookResult = ReturnType<typeof useAllWorkOrdersLazyQuery>;
export type AllWorkOrdersQueryResult = Apollo.QueryResult<AllWorkOrdersQuery, AllWorkOrdersQueryVariables>;
export const AllBudgetsDocument = gql`
    query allBudgets($name: String, $status: String, $plate: String, $entry_reservation: String) {
  allBudgets(
    name: $name
    status: $status
    plate: $plate
    entry_reservation: $entry_reservation
  )
}
    `;

/**
 * __useAllBudgetsQuery__
 *
 * To run a query within a React component, call `useAllBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBudgetsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      plate: // value for 'plate'
 *      entry_reservation: // value for 'entry_reservation'
 *   },
 * });
 */
export function useAllBudgetsQuery(baseOptions?: Apollo.QueryHookOptions<AllBudgetsQuery, AllBudgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBudgetsQuery, AllBudgetsQueryVariables>(AllBudgetsDocument, options);
      }
export function useAllBudgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBudgetsQuery, AllBudgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBudgetsQuery, AllBudgetsQueryVariables>(AllBudgetsDocument, options);
        }
export type AllBudgetsQueryHookResult = ReturnType<typeof useAllBudgetsQuery>;
export type AllBudgetsLazyQueryHookResult = ReturnType<typeof useAllBudgetsLazyQuery>;
export type AllBudgetsQueryResult = Apollo.QueryResult<AllBudgetsQuery, AllBudgetsQueryVariables>;
export const GetAllReceptionsListDocument = gql`
    query getAllReceptionsList($name: String, $dateIni: DateTime, $dateEnd: DateTime, $plate: String) {
  allReceptions(
    name: $name
    date_ini: $dateIni
    date_end: $dateEnd
    plate: $plate
  )
}
    `;

/**
 * __useGetAllReceptionsListQuery__
 *
 * To run a query within a React component, call `useGetAllReceptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllReceptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllReceptionsListQuery({
 *   variables: {
 *      name: // value for 'name'
 *      dateIni: // value for 'dateIni'
 *      dateEnd: // value for 'dateEnd'
 *      plate: // value for 'plate'
 *   },
 * });
 */
export function useGetAllReceptionsListQuery(baseOptions?: Apollo.QueryHookOptions<GetAllReceptionsListQuery, GetAllReceptionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllReceptionsListQuery, GetAllReceptionsListQueryVariables>(GetAllReceptionsListDocument, options);
      }
export function useGetAllReceptionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllReceptionsListQuery, GetAllReceptionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllReceptionsListQuery, GetAllReceptionsListQueryVariables>(GetAllReceptionsListDocument, options);
        }
export type GetAllReceptionsListQueryHookResult = ReturnType<typeof useGetAllReceptionsListQuery>;
export type GetAllReceptionsListLazyQueryHookResult = ReturnType<typeof useGetAllReceptionsListLazyQuery>;
export type GetAllReceptionsListQueryResult = Apollo.QueryResult<GetAllReceptionsListQuery, GetAllReceptionsListQueryVariables>;
export const AllReservationsDocument = gql`
    query allReservations($name: String, $dateIni: DateTime, $dateEnd: DateTime, $plate: String, $serviceId: Int) {
  allReservations(
    name: $name
    date_ini: $dateIni
    date_end: $dateEnd
    plate: $plate
    service_id: $serviceId
  )
}
    `;

/**
 * __useAllReservationsQuery__
 *
 * To run a query within a React component, call `useAllReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllReservationsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      dateIni: // value for 'dateIni'
 *      dateEnd: // value for 'dateEnd'
 *      plate: // value for 'plate'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useAllReservationsQuery(baseOptions?: Apollo.QueryHookOptions<AllReservationsQuery, AllReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllReservationsQuery, AllReservationsQueryVariables>(AllReservationsDocument, options);
      }
export function useAllReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllReservationsQuery, AllReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllReservationsQuery, AllReservationsQueryVariables>(AllReservationsDocument, options);
        }
export type AllReservationsQueryHookResult = ReturnType<typeof useAllReservationsQuery>;
export type AllReservationsLazyQueryHookResult = ReturnType<typeof useAllReservationsLazyQuery>;
export type AllReservationsQueryResult = Apollo.QueryResult<AllReservationsQuery, AllReservationsQueryVariables>;
export const BudgetsDocument = gql`
    query budgets($take: Int!, $page: Int!, $name: String, $status: String, $plate: String, $entry_reservation: String) {
  budgets(
    take: $take
    page: $page
    name: $name
    status: $status
    plate: $plate
    entry_reservation: $entry_reservation
  ) {
    id
    car {
      plate
    }
    client {
      rut
      name
      lastname
    }
    user {
      rut
      name
    }
    workOrder {
      id
    }
    reception {
      id
    }
    status
    entryReservation {
      id
    }
    items {
      id
      price
    }
    replacements {
      id
      price
      quantity
    }
  }
}
    `;

/**
 * __useBudgetsQuery__
 *
 * To run a query within a React component, call `useBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBudgetsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      page: // value for 'page'
 *      name: // value for 'name'
 *      status: // value for 'status'
 *      plate: // value for 'plate'
 *      entry_reservation: // value for 'entry_reservation'
 *   },
 * });
 */
export function useBudgetsQuery(baseOptions: Apollo.QueryHookOptions<BudgetsQuery, BudgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BudgetsQuery, BudgetsQueryVariables>(BudgetsDocument, options);
      }
export function useBudgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BudgetsQuery, BudgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BudgetsQuery, BudgetsQueryVariables>(BudgetsDocument, options);
        }
export type BudgetsQueryHookResult = ReturnType<typeof useBudgetsQuery>;
export type BudgetsLazyQueryHookResult = ReturnType<typeof useBudgetsLazyQuery>;
export type BudgetsQueryResult = Apollo.QueryResult<BudgetsQuery, BudgetsQueryVariables>;
export const GetBudgetsByPlateDocument = gql`
    query getBudgetsByPlate($plate: String!) {
  getCarByPlate(plate: $plate) {
    budgets {
      id
      folio
    }
  }
}
    `;

/**
 * __useGetBudgetsByPlateQuery__
 *
 * To run a query within a React component, call `useGetBudgetsByPlateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetsByPlateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetsByPlateQuery({
 *   variables: {
 *      plate: // value for 'plate'
 *   },
 * });
 */
export function useGetBudgetsByPlateQuery(baseOptions: Apollo.QueryHookOptions<GetBudgetsByPlateQuery, GetBudgetsByPlateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBudgetsByPlateQuery, GetBudgetsByPlateQueryVariables>(GetBudgetsByPlateDocument, options);
      }
export function useGetBudgetsByPlateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBudgetsByPlateQuery, GetBudgetsByPlateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBudgetsByPlateQuery, GetBudgetsByPlateQueryVariables>(GetBudgetsByPlateDocument, options);
        }
export type GetBudgetsByPlateQueryHookResult = ReturnType<typeof useGetBudgetsByPlateQuery>;
export type GetBudgetsByPlateLazyQueryHookResult = ReturnType<typeof useGetBudgetsByPlateLazyQuery>;
export type GetBudgetsByPlateQueryResult = Apollo.QueryResult<GetBudgetsByPlateQuery, GetBudgetsByPlateQueryVariables>;
export const GetEntryReservationsListDocument = gql`
    query GetEntryReservationsList($page: Int, $take: Int, $name: String, $plate: String, $dateIni: DateTime, $dateEnd: DateTime) {
  entryReservations(
    take: $take
    page: $page
    plate: $plate
    date_ini: $dateIni
    date_end: $dateEnd
    name: $name
  ) {
    id
    date
    description
    budget {
      client {
        name
        lastname
      }
      car {
        model
        plate
      }
    }
  }
}
    `;

/**
 * __useGetEntryReservationsListQuery__
 *
 * To run a query within a React component, call `useGetEntryReservationsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntryReservationsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntryReservationsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      plate: // value for 'plate'
 *      dateIni: // value for 'dateIni'
 *      dateEnd: // value for 'dateEnd'
 *   },
 * });
 */
export function useGetEntryReservationsListQuery(baseOptions?: Apollo.QueryHookOptions<GetEntryReservationsListQuery, GetEntryReservationsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntryReservationsListQuery, GetEntryReservationsListQueryVariables>(GetEntryReservationsListDocument, options);
      }
export function useGetEntryReservationsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntryReservationsListQuery, GetEntryReservationsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntryReservationsListQuery, GetEntryReservationsListQueryVariables>(GetEntryReservationsListDocument, options);
        }
export type GetEntryReservationsListQueryHookResult = ReturnType<typeof useGetEntryReservationsListQuery>;
export type GetEntryReservationsListLazyQueryHookResult = ReturnType<typeof useGetEntryReservationsListLazyQuery>;
export type GetEntryReservationsListQueryResult = Apollo.QueryResult<GetEntryReservationsListQuery, GetEntryReservationsListQueryVariables>;
export const GetReceptionsListDocument = gql`
    query getReceptionsList($page: Int!, $take: Int!, $name: String, $dateIni: DateTime, $dateEnd: DateTime, $plate: String) {
  receptions(
    take: $take
    page: $page
    name: $name
    date_ini: $dateIni
    date_end: $dateEnd
    plate: $plate
  ) {
    id
    receptionDate
    budget {
      car {
        plate
        model
        color
      }
      client {
        name
        lastname
      }
    }
  }
}
    `;

/**
 * __useGetReceptionsListQuery__
 *
 * To run a query within a React component, call `useGetReceptionsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceptionsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceptionsListQuery({
 *   variables: {
 *      page: // value for 'page'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      dateIni: // value for 'dateIni'
 *      dateEnd: // value for 'dateEnd'
 *      plate: // value for 'plate'
 *   },
 * });
 */
export function useGetReceptionsListQuery(baseOptions: Apollo.QueryHookOptions<GetReceptionsListQuery, GetReceptionsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceptionsListQuery, GetReceptionsListQueryVariables>(GetReceptionsListDocument, options);
      }
export function useGetReceptionsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceptionsListQuery, GetReceptionsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceptionsListQuery, GetReceptionsListQueryVariables>(GetReceptionsListDocument, options);
        }
export type GetReceptionsListQueryHookResult = ReturnType<typeof useGetReceptionsListQuery>;
export type GetReceptionsListLazyQueryHookResult = ReturnType<typeof useGetReceptionsListLazyQuery>;
export type GetReceptionsListQueryResult = Apollo.QueryResult<GetReceptionsListQuery, GetReceptionsListQueryVariables>;
export const ReservationsDocument = gql`
    query reservations($take: Int!, $page: Int!, $name: String, $dateIni: DateTime, $dateEnd: DateTime, $plate: String, $serviceId: Int) {
  reservations(
    sort: "asc"
    take: $take
    page: $page
    plate: $plate
    name: $name
    date_ini: $dateIni
    date_end: $dateEnd
    service_id: $serviceId
  ) {
    id
    date
    client {
      rut
      name
      lastname
    }
    service {
      id
      name
    }
    car {
      plate
    }
    insurance {
      id
    }
    description
  }
}
    `;

/**
 * __useReservationsQuery__
 *
 * To run a query within a React component, call `useReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReservationsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      page: // value for 'page'
 *      name: // value for 'name'
 *      dateIni: // value for 'dateIni'
 *      dateEnd: // value for 'dateEnd'
 *      plate: // value for 'plate'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useReservationsQuery(baseOptions: Apollo.QueryHookOptions<ReservationsQuery, ReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReservationsQuery, ReservationsQueryVariables>(ReservationsDocument, options);
      }
export function useReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReservationsQuery, ReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReservationsQuery, ReservationsQueryVariables>(ReservationsDocument, options);
        }
export type ReservationsQueryHookResult = ReturnType<typeof useReservationsQuery>;
export type ReservationsLazyQueryHookResult = ReturnType<typeof useReservationsLazyQuery>;
export type ReservationsQueryResult = Apollo.QueryResult<ReservationsQuery, ReservationsQueryVariables>;
export const WorkersListDocument = gql`
    query workersList {
  workers {
    name
    email
    rut
    role
  }
}
    `;

/**
 * __useWorkersListQuery__
 *
 * To run a query within a React component, call `useWorkersListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkersListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkersListQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkersListQuery(baseOptions?: Apollo.QueryHookOptions<WorkersListQuery, WorkersListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkersListQuery, WorkersListQueryVariables>(WorkersListDocument, options);
      }
export function useWorkersListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkersListQuery, WorkersListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkersListQuery, WorkersListQueryVariables>(WorkersListDocument, options);
        }
export type WorkersListQueryHookResult = ReturnType<typeof useWorkersListQuery>;
export type WorkersListLazyQueryHookResult = ReturnType<typeof useWorkersListLazyQuery>;
export type WorkersListQueryResult = Apollo.QueryResult<WorkersListQuery, WorkersListQueryVariables>;
export const GetBlockedBlocksDocument = gql`
    query getBlockedBlocks($date: String!, $serviceId: Int) {
  getBlockedBlocks(date: $date, serviceId: $serviceId)
}
    `;

/**
 * __useGetBlockedBlocksQuery__
 *
 * To run a query within a React component, call `useGetBlockedBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockedBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockedBlocksQuery({
 *   variables: {
 *      date: // value for 'date'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useGetBlockedBlocksQuery(baseOptions: Apollo.QueryHookOptions<GetBlockedBlocksQuery, GetBlockedBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlockedBlocksQuery, GetBlockedBlocksQueryVariables>(GetBlockedBlocksDocument, options);
      }
export function useGetBlockedBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlockedBlocksQuery, GetBlockedBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlockedBlocksQuery, GetBlockedBlocksQueryVariables>(GetBlockedBlocksDocument, options);
        }
export type GetBlockedBlocksQueryHookResult = ReturnType<typeof useGetBlockedBlocksQuery>;
export type GetBlockedBlocksLazyQueryHookResult = ReturnType<typeof useGetBlockedBlocksLazyQuery>;
export type GetBlockedBlocksQueryResult = Apollo.QueryResult<GetBlockedBlocksQuery, GetBlockedBlocksQueryVariables>;
export const CreateBlockedHourDocument = gql`
    mutation createBlockedHour($date: String!, $serviceId: Int!) {
  createBlockedHour(date: $date, serviceId: $serviceId) {
    id
  }
}
    `;
export type CreateBlockedHourMutationFn = Apollo.MutationFunction<CreateBlockedHourMutation, CreateBlockedHourMutationVariables>;

/**
 * __useCreateBlockedHourMutation__
 *
 * To run a mutation, you first call `useCreateBlockedHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBlockedHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBlockedHourMutation, { data, loading, error }] = useCreateBlockedHourMutation({
 *   variables: {
 *      date: // value for 'date'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useCreateBlockedHourMutation(baseOptions?: Apollo.MutationHookOptions<CreateBlockedHourMutation, CreateBlockedHourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBlockedHourMutation, CreateBlockedHourMutationVariables>(CreateBlockedHourDocument, options);
      }
export type CreateBlockedHourMutationHookResult = ReturnType<typeof useCreateBlockedHourMutation>;
export type CreateBlockedHourMutationResult = Apollo.MutationResult<CreateBlockedHourMutation>;
export type CreateBlockedHourMutationOptions = Apollo.BaseMutationOptions<CreateBlockedHourMutation, CreateBlockedHourMutationVariables>;
export const DeleteBlockedHourDocument = gql`
    mutation deleteBlockedHour($date: String!, $serviceId: Int!) {
  deleteBlockedHour(date: $date, serviceId: $serviceId) {
    id
  }
}
    `;
export type DeleteBlockedHourMutationFn = Apollo.MutationFunction<DeleteBlockedHourMutation, DeleteBlockedHourMutationVariables>;

/**
 * __useDeleteBlockedHourMutation__
 *
 * To run a mutation, you first call `useDeleteBlockedHourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockedHourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockedHourMutation, { data, loading, error }] = useDeleteBlockedHourMutation({
 *   variables: {
 *      date: // value for 'date'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useDeleteBlockedHourMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlockedHourMutation, DeleteBlockedHourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBlockedHourMutation, DeleteBlockedHourMutationVariables>(DeleteBlockedHourDocument, options);
      }
export type DeleteBlockedHourMutationHookResult = ReturnType<typeof useDeleteBlockedHourMutation>;
export type DeleteBlockedHourMutationResult = Apollo.MutationResult<DeleteBlockedHourMutation>;
export type DeleteBlockedHourMutationOptions = Apollo.BaseMutationOptions<DeleteBlockedHourMutation, DeleteBlockedHourMutationVariables>;
export const GetReservedBlocksDocument = gql`
    query getReservedBlocks($date: String!, $serviceId: Int) {
  getReservedBlocks(date: $date, serviceId: $serviceId)
}
    `;

/**
 * __useGetReservedBlocksQuery__
 *
 * To run a query within a React component, call `useGetReservedBlocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservedBlocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservedBlocksQuery({
 *   variables: {
 *      date: // value for 'date'
 *      serviceId: // value for 'serviceId'
 *   },
 * });
 */
export function useGetReservedBlocksQuery(baseOptions: Apollo.QueryHookOptions<GetReservedBlocksQuery, GetReservedBlocksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservedBlocksQuery, GetReservedBlocksQueryVariables>(GetReservedBlocksDocument, options);
      }
export function useGetReservedBlocksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservedBlocksQuery, GetReservedBlocksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservedBlocksQuery, GetReservedBlocksQueryVariables>(GetReservedBlocksDocument, options);
        }
export type GetReservedBlocksQueryHookResult = ReturnType<typeof useGetReservedBlocksQuery>;
export type GetReservedBlocksLazyQueryHookResult = ReturnType<typeof useGetReservedBlocksLazyQuery>;
export type GetReservedBlocksQueryResult = Apollo.QueryResult<GetReservedBlocksQuery, GetReservedBlocksQueryVariables>;
export const CreateReservationDocument = gql`
    mutation CreateReservation($name: String!, $lastname: String!, $phone: String!, $email: String!, $rut: String!, $plate: String!, $chasis: String!, $brand: String!, $model: String!, $color: String!, $year: Int!, $carnet: [String!]!, $insuranceId: Int, $permisoCirculacion: [String!]!, $declaracionJurada: [String!], $siniestro: String!, $serviceId: Int!, $date: DateTime!, $description: String) {
  createReservation(
    name: $name
    lastname: $lastname
    rut: $rut
    email: $email
    plate: $plate
    chasis: $chasis
    brand: $brand
    model: $model
    color: $color
    year: $year
    carnet: $carnet
    insuranceId: $insuranceId
    permisoCirculacion: $permisoCirculacion
    declaracionJurada: $declaracionJurada
    siniestro: $siniestro
    serviceId: $serviceId
    date: $date
    description: $description
    phone: $phone
  ) {
    id
    date
  }
}
    `;
export type CreateReservationMutationFn = Apollo.MutationFunction<CreateReservationMutation, CreateReservationMutationVariables>;

/**
 * __useCreateReservationMutation__
 *
 * To run a mutation, you first call `useCreateReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReservationMutation, { data, loading, error }] = useCreateReservationMutation({
 *   variables: {
 *      name: // value for 'name'
 *      lastname: // value for 'lastname'
 *      phone: // value for 'phone'
 *      email: // value for 'email'
 *      rut: // value for 'rut'
 *      plate: // value for 'plate'
 *      chasis: // value for 'chasis'
 *      brand: // value for 'brand'
 *      model: // value for 'model'
 *      color: // value for 'color'
 *      year: // value for 'year'
 *      carnet: // value for 'carnet'
 *      insuranceId: // value for 'insuranceId'
 *      permisoCirculacion: // value for 'permisoCirculacion'
 *      declaracionJurada: // value for 'declaracionJurada'
 *      siniestro: // value for 'siniestro'
 *      serviceId: // value for 'serviceId'
 *      date: // value for 'date'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateReservationMutation(baseOptions?: Apollo.MutationHookOptions<CreateReservationMutation, CreateReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReservationMutation, CreateReservationMutationVariables>(CreateReservationDocument, options);
      }
export type CreateReservationMutationHookResult = ReturnType<typeof useCreateReservationMutation>;
export type CreateReservationMutationResult = Apollo.MutationResult<CreateReservationMutation>;
export type CreateReservationMutationOptions = Apollo.BaseMutationOptions<CreateReservationMutation, CreateReservationMutationVariables>;
export const GetInsurancesDocument = gql`
    query GetInsurances {
  insurances {
    id
    name
    email
  }
}
    `;

/**
 * __useGetInsurancesQuery__
 *
 * To run a query within a React component, call `useGetInsurancesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsurancesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsurancesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInsurancesQuery(baseOptions?: Apollo.QueryHookOptions<GetInsurancesQuery, GetInsurancesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInsurancesQuery, GetInsurancesQueryVariables>(GetInsurancesDocument, options);
      }
export function useGetInsurancesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInsurancesQuery, GetInsurancesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInsurancesQuery, GetInsurancesQueryVariables>(GetInsurancesDocument, options);
        }
export type GetInsurancesQueryHookResult = ReturnType<typeof useGetInsurancesQuery>;
export type GetInsurancesLazyQueryHookResult = ReturnType<typeof useGetInsurancesLazyQuery>;
export type GetInsurancesQueryResult = Apollo.QueryResult<GetInsurancesQuery, GetInsurancesQueryVariables>;
export const SignS3Document = gql`
    mutation SignS3($filename: String!, $filetype: String!) {
  signS3(filename: $filename, filetype: $filetype) {
    signedRequest
    url
  }
}
    `;
export type SignS3MutationFn = Apollo.MutationFunction<SignS3Mutation, SignS3MutationVariables>;

/**
 * __useSignS3Mutation__
 *
 * To run a mutation, you first call `useSignS3Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignS3Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signS3Mutation, { data, loading, error }] = useSignS3Mutation({
 *   variables: {
 *      filename: // value for 'filename'
 *      filetype: // value for 'filetype'
 *   },
 * });
 */
export function useSignS3Mutation(baseOptions?: Apollo.MutationHookOptions<SignS3Mutation, SignS3MutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignS3Mutation, SignS3MutationVariables>(SignS3Document, options);
      }
export type SignS3MutationHookResult = ReturnType<typeof useSignS3Mutation>;
export type SignS3MutationResult = Apollo.MutationResult<SignS3Mutation>;
export type SignS3MutationOptions = Apollo.BaseMutationOptions<SignS3Mutation, SignS3MutationVariables>;
export const GetBudgetByFolioDocument = gql`
    query getBudgetByFolio($rut: String!, $folio: String!) {
  getBudgetByFolio(rut: $rut, folio: $folio) {
    id
    car {
      plate
      brand
      model
      color
      chasis
      year
    }
    client {
      name
      lastname
      rut
      phone
      email
    }
    insurance {
      name
      email
    }
    images {
      id
      link
    }
    items {
      id
      price
      concept {
        id
        name
      }
      piece {
        id
        name
      }
    }
    replacements {
      id
      piece {
        id
        name
      }
      quantity
      price
    }
    status
  }
}
    `;

/**
 * __useGetBudgetByFolioQuery__
 *
 * To run a query within a React component, call `useGetBudgetByFolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetByFolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetByFolioQuery({
 *   variables: {
 *      rut: // value for 'rut'
 *      folio: // value for 'folio'
 *   },
 * });
 */
export function useGetBudgetByFolioQuery(baseOptions: Apollo.QueryHookOptions<GetBudgetByFolioQuery, GetBudgetByFolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBudgetByFolioQuery, GetBudgetByFolioQueryVariables>(GetBudgetByFolioDocument, options);
      }
export function useGetBudgetByFolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBudgetByFolioQuery, GetBudgetByFolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBudgetByFolioQuery, GetBudgetByFolioQueryVariables>(GetBudgetByFolioDocument, options);
        }
export type GetBudgetByFolioQueryHookResult = ReturnType<typeof useGetBudgetByFolioQuery>;
export type GetBudgetByFolioLazyQueryHookResult = ReturnType<typeof useGetBudgetByFolioLazyQuery>;
export type GetBudgetByFolioQueryResult = Apollo.QueryResult<GetBudgetByFolioQuery, GetBudgetByFolioQueryVariables>;
export const CreateBudgetImageDocument = gql`
    mutation CreateBudgetImage($budgetID: Int!, $link: String!) {
  createBudgetImage(budgetID: $budgetID, link: $link) {
    id
    link
  }
}
    `;
export type CreateBudgetImageMutationFn = Apollo.MutationFunction<CreateBudgetImageMutation, CreateBudgetImageMutationVariables>;

/**
 * __useCreateBudgetImageMutation__
 *
 * To run a mutation, you first call `useCreateBudgetImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBudgetImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBudgetImageMutation, { data, loading, error }] = useCreateBudgetImageMutation({
 *   variables: {
 *      budgetID: // value for 'budgetID'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useCreateBudgetImageMutation(baseOptions?: Apollo.MutationHookOptions<CreateBudgetImageMutation, CreateBudgetImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBudgetImageMutation, CreateBudgetImageMutationVariables>(CreateBudgetImageDocument, options);
      }
export type CreateBudgetImageMutationHookResult = ReturnType<typeof useCreateBudgetImageMutation>;
export type CreateBudgetImageMutationResult = Apollo.MutationResult<CreateBudgetImageMutation>;
export type CreateBudgetImageMutationOptions = Apollo.BaseMutationOptions<CreateBudgetImageMutation, CreateBudgetImageMutationVariables>;
export const GetBudgetDocument = gql`
    query GetBudget($id: Int!) {
  getBudget(id: $id) {
    id
    car {
      plate
      brand
      model
      color
      chasis
      year
    }
    client {
      name
      lastname
      rut
      phone
      email
    }
    insurance {
      name
      email
    }
    images {
      id
      link
    }
    items {
      id
      price
      concept {
        id
        name
      }
      piece {
        id
        name
      }
    }
    replacements {
      id
      piece {
        id
        name
      }
      quantity
      price
    }
    status
    entryReservation {
      id
      date
    }
  }
}
    `;

/**
 * __useGetBudgetQuery__
 *
 * To run a query within a React component, call `useGetBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBudgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBudgetQuery(baseOptions: Apollo.QueryHookOptions<GetBudgetQuery, GetBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBudgetQuery, GetBudgetQueryVariables>(GetBudgetDocument, options);
      }
export function useGetBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBudgetQuery, GetBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBudgetQuery, GetBudgetQueryVariables>(GetBudgetDocument, options);
        }
export type GetBudgetQueryHookResult = ReturnType<typeof useGetBudgetQuery>;
export type GetBudgetLazyQueryHookResult = ReturnType<typeof useGetBudgetLazyQuery>;
export type GetBudgetQueryResult = Apollo.QueryResult<GetBudgetQuery, GetBudgetQueryVariables>;
export const GetPiecesDocument = gql`
    query GetPieces($stage: String!) {
  getPieces(stage: $stage) {
    id
    name
  }
}
    `;

/**
 * __useGetPiecesQuery__
 *
 * To run a query within a React component, call `useGetPiecesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPiecesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPiecesQuery({
 *   variables: {
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useGetPiecesQuery(baseOptions: Apollo.QueryHookOptions<GetPiecesQuery, GetPiecesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPiecesQuery, GetPiecesQueryVariables>(GetPiecesDocument, options);
      }
export function useGetPiecesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPiecesQuery, GetPiecesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPiecesQuery, GetPiecesQueryVariables>(GetPiecesDocument, options);
        }
export type GetPiecesQueryHookResult = ReturnType<typeof useGetPiecesQuery>;
export type GetPiecesLazyQueryHookResult = ReturnType<typeof useGetPiecesLazyQuery>;
export type GetPiecesQueryResult = Apollo.QueryResult<GetPiecesQuery, GetPiecesQueryVariables>;
export const SendBudgetDocument = gql`
    mutation sendBudget($budgetId: Int!) {
  sendBudget(budgetId: $budgetId) {
    id
  }
}
    `;
export type SendBudgetMutationFn = Apollo.MutationFunction<SendBudgetMutation, SendBudgetMutationVariables>;

/**
 * __useSendBudgetMutation__
 *
 * To run a mutation, you first call `useSendBudgetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBudgetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBudgetMutation, { data, loading, error }] = useSendBudgetMutation({
 *   variables: {
 *      budgetId: // value for 'budgetId'
 *   },
 * });
 */
export function useSendBudgetMutation(baseOptions?: Apollo.MutationHookOptions<SendBudgetMutation, SendBudgetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendBudgetMutation, SendBudgetMutationVariables>(SendBudgetDocument, options);
      }
export type SendBudgetMutationHookResult = ReturnType<typeof useSendBudgetMutation>;
export type SendBudgetMutationResult = Apollo.MutationResult<SendBudgetMutation>;
export type SendBudgetMutationOptions = Apollo.BaseMutationOptions<SendBudgetMutation, SendBudgetMutationVariables>;
export const SendBudgetToClientDocument = gql`
    query SendBudgetToClient($id: Int!) {
  sendBudgetToClient(id: $id) {
    id
  }
}
    `;

/**
 * __useSendBudgetToClientQuery__
 *
 * To run a query within a React component, call `useSendBudgetToClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendBudgetToClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendBudgetToClientQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendBudgetToClientQuery(baseOptions: Apollo.QueryHookOptions<SendBudgetToClientQuery, SendBudgetToClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SendBudgetToClientQuery, SendBudgetToClientQueryVariables>(SendBudgetToClientDocument, options);
      }
export function useSendBudgetToClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SendBudgetToClientQuery, SendBudgetToClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SendBudgetToClientQuery, SendBudgetToClientQueryVariables>(SendBudgetToClientDocument, options);
        }
export type SendBudgetToClientQueryHookResult = ReturnType<typeof useSendBudgetToClientQuery>;
export type SendBudgetToClientLazyQueryHookResult = ReturnType<typeof useSendBudgetToClientLazyQuery>;
export type SendBudgetToClientQueryResult = Apollo.QueryResult<SendBudgetToClientQuery, SendBudgetToClientQueryVariables>;
export const GetDataCarShowDocument = gql`
    query getDataCarShow($plate: String!) {
  getCarByPlate(plate: $plate) {
    chasis
    brand
    model
    year
    plate
  }
}
    `;

/**
 * __useGetDataCarShowQuery__
 *
 * To run a query within a React component, call `useGetDataCarShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataCarShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataCarShowQuery({
 *   variables: {
 *      plate: // value for 'plate'
 *   },
 * });
 */
export function useGetDataCarShowQuery(baseOptions: Apollo.QueryHookOptions<GetDataCarShowQuery, GetDataCarShowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDataCarShowQuery, GetDataCarShowQueryVariables>(GetDataCarShowDocument, options);
      }
export function useGetDataCarShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDataCarShowQuery, GetDataCarShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDataCarShowQuery, GetDataCarShowQueryVariables>(GetDataCarShowDocument, options);
        }
export type GetDataCarShowQueryHookResult = ReturnType<typeof useGetDataCarShowQuery>;
export type GetDataCarShowLazyQueryHookResult = ReturnType<typeof useGetDataCarShowLazyQuery>;
export type GetDataCarShowQueryResult = Apollo.QueryResult<GetDataCarShowQuery, GetDataCarShowQueryVariables>;
export const ChangeAdminPasswordDocument = gql`
    mutation ChangeAdminPassword($currentPassword: String!, $newPassword: String!, $validationPassword: String!) {
  changeAdminPassword(
    currentPassword: $currentPassword
    newPassword: $newPassword
    validationPassword: $validationPassword
  ) {
    id
    changedPassword
  }
}
    `;
export type ChangeAdminPasswordMutationFn = Apollo.MutationFunction<ChangeAdminPasswordMutation, ChangeAdminPasswordMutationVariables>;

/**
 * __useChangeAdminPasswordMutation__
 *
 * To run a mutation, you first call `useChangeAdminPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeAdminPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeAdminPasswordMutation, { data, loading, error }] = useChangeAdminPasswordMutation({
 *   variables: {
 *      currentPassword: // value for 'currentPassword'
 *      newPassword: // value for 'newPassword'
 *      validationPassword: // value for 'validationPassword'
 *   },
 * });
 */
export function useChangeAdminPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeAdminPasswordMutation, ChangeAdminPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeAdminPasswordMutation, ChangeAdminPasswordMutationVariables>(ChangeAdminPasswordDocument, options);
      }
export type ChangeAdminPasswordMutationHookResult = ReturnType<typeof useChangeAdminPasswordMutation>;
export type ChangeAdminPasswordMutationResult = Apollo.MutationResult<ChangeAdminPasswordMutation>;
export type ChangeAdminPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeAdminPasswordMutation, ChangeAdminPasswordMutationVariables>;
export const GetEntryReservationsByIdShowDocument = gql`
    query getEntryReservationsByIdShow($id: Int!) {
  getEntryReservationById(id: $id) {
    date
    budget {
      id
      car {
        model
        brand
        year
        plate
        chasis
        color
      }
      client {
        name
        lastname
        rut
        email
        phone
      }
    }
    description
  }
}
    `;

/**
 * __useGetEntryReservationsByIdShowQuery__
 *
 * To run a query within a React component, call `useGetEntryReservationsByIdShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntryReservationsByIdShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntryReservationsByIdShowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEntryReservationsByIdShowQuery(baseOptions: Apollo.QueryHookOptions<GetEntryReservationsByIdShowQuery, GetEntryReservationsByIdShowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntryReservationsByIdShowQuery, GetEntryReservationsByIdShowQueryVariables>(GetEntryReservationsByIdShowDocument, options);
      }
export function useGetEntryReservationsByIdShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntryReservationsByIdShowQuery, GetEntryReservationsByIdShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntryReservationsByIdShowQuery, GetEntryReservationsByIdShowQueryVariables>(GetEntryReservationsByIdShowDocument, options);
        }
export type GetEntryReservationsByIdShowQueryHookResult = ReturnType<typeof useGetEntryReservationsByIdShowQuery>;
export type GetEntryReservationsByIdShowLazyQueryHookResult = ReturnType<typeof useGetEntryReservationsByIdShowLazyQuery>;
export type GetEntryReservationsByIdShowQueryResult = Apollo.QueryResult<GetEntryReservationsByIdShowQuery, GetEntryReservationsByIdShowQueryVariables>;
export const LoginAdminDocument = gql`
    mutation LoginAdmin($rut: String!, $password: String!) {
  loginAdmin(rut: $rut, password: $password) {
    token
    user {
      changedPassword
    }
  }
}
    `;
export type LoginAdminMutationFn = Apollo.MutationFunction<LoginAdminMutation, LoginAdminMutationVariables>;

/**
 * __useLoginAdminMutation__
 *
 * To run a mutation, you first call `useLoginAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginAdminMutation, { data, loading, error }] = useLoginAdminMutation({
 *   variables: {
 *      rut: // value for 'rut'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginAdminMutation(baseOptions?: Apollo.MutationHookOptions<LoginAdminMutation, LoginAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginAdminMutation, LoginAdminMutationVariables>(LoginAdminDocument, options);
      }
export type LoginAdminMutationHookResult = ReturnType<typeof useLoginAdminMutation>;
export type LoginAdminMutationResult = Apollo.MutationResult<LoginAdminMutation>;
export type LoginAdminMutationOptions = Apollo.BaseMutationOptions<LoginAdminMutation, LoginAdminMutationVariables>;
export const UpdatePiecesDocument = gql`
    mutation updatePieces {
  updatePieces {
    id
  }
}
    `;
export type UpdatePiecesMutationFn = Apollo.MutationFunction<UpdatePiecesMutation, UpdatePiecesMutationVariables>;

/**
 * __useUpdatePiecesMutation__
 *
 * To run a mutation, you first call `useUpdatePiecesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePiecesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePiecesMutation, { data, loading, error }] = useUpdatePiecesMutation({
 *   variables: {
 *   },
 * });
 */
export function useUpdatePiecesMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePiecesMutation, UpdatePiecesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePiecesMutation, UpdatePiecesMutationVariables>(UpdatePiecesDocument, options);
      }
export type UpdatePiecesMutationHookResult = ReturnType<typeof useUpdatePiecesMutation>;
export type UpdatePiecesMutationResult = Apollo.MutationResult<UpdatePiecesMutation>;
export type UpdatePiecesMutationOptions = Apollo.BaseMutationOptions<UpdatePiecesMutation, UpdatePiecesMutationVariables>;
export const CreateEntryReservationDocument = gql`
    mutation createEntryReservation($budgetId: Int!, $date: DateTime!, $description: String) {
  createEntryReservation(
    budgetId: $budgetId
    date: $date
    description: $description
  ) {
    id
  }
}
    `;
export type CreateEntryReservationMutationFn = Apollo.MutationFunction<CreateEntryReservationMutation, CreateEntryReservationMutationVariables>;

/**
 * __useCreateEntryReservationMutation__
 *
 * To run a mutation, you first call `useCreateEntryReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntryReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntryReservationMutation, { data, loading, error }] = useCreateEntryReservationMutation({
 *   variables: {
 *      budgetId: // value for 'budgetId'
 *      date: // value for 'date'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateEntryReservationMutation(baseOptions?: Apollo.MutationHookOptions<CreateEntryReservationMutation, CreateEntryReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEntryReservationMutation, CreateEntryReservationMutationVariables>(CreateEntryReservationDocument, options);
      }
export type CreateEntryReservationMutationHookResult = ReturnType<typeof useCreateEntryReservationMutation>;
export type CreateEntryReservationMutationResult = Apollo.MutationResult<CreateEntryReservationMutation>;
export type CreateEntryReservationMutationOptions = Apollo.BaseMutationOptions<CreateEntryReservationMutation, CreateEntryReservationMutationVariables>;
export const GetEntryReservationsDocument = gql`
    query getEntryReservations($date: String!) {
  getEntryReservations(date: $date)
}
    `;

/**
 * __useGetEntryReservationsQuery__
 *
 * To run a query within a React component, call `useGetEntryReservationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntryReservationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntryReservationsQuery({
 *   variables: {
 *      date: // value for 'date'
 *   },
 * });
 */
export function useGetEntryReservationsQuery(baseOptions: Apollo.QueryHookOptions<GetEntryReservationsQuery, GetEntryReservationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntryReservationsQuery, GetEntryReservationsQueryVariables>(GetEntryReservationsDocument, options);
      }
export function useGetEntryReservationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntryReservationsQuery, GetEntryReservationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntryReservationsQuery, GetEntryReservationsQueryVariables>(GetEntryReservationsDocument, options);
        }
export type GetEntryReservationsQueryHookResult = ReturnType<typeof useGetEntryReservationsQuery>;
export type GetEntryReservationsLazyQueryHookResult = ReturnType<typeof useGetEntryReservationsLazyQuery>;
export type GetEntryReservationsQueryResult = Apollo.QueryResult<GetEntryReservationsQuery, GetEntryReservationsQueryVariables>;
export const GetEntryReservationByBudgetDocument = gql`
    query getEntryReservationByBudget($budgetId: Int!) {
  getEntryReservationByBudget(budgetId: $budgetId) {
    id
    budget {
      id
      car {
        plate
        brand
        model
        color
        year
      }
      client {
        rut
        name
        lastname
      }
    }
    date
  }
}
    `;

/**
 * __useGetEntryReservationByBudgetQuery__
 *
 * To run a query within a React component, call `useGetEntryReservationByBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntryReservationByBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntryReservationByBudgetQuery({
 *   variables: {
 *      budgetId: // value for 'budgetId'
 *   },
 * });
 */
export function useGetEntryReservationByBudgetQuery(baseOptions: Apollo.QueryHookOptions<GetEntryReservationByBudgetQuery, GetEntryReservationByBudgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntryReservationByBudgetQuery, GetEntryReservationByBudgetQueryVariables>(GetEntryReservationByBudgetDocument, options);
      }
export function useGetEntryReservationByBudgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntryReservationByBudgetQuery, GetEntryReservationByBudgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntryReservationByBudgetQuery, GetEntryReservationByBudgetQueryVariables>(GetEntryReservationByBudgetDocument, options);
        }
export type GetEntryReservationByBudgetQueryHookResult = ReturnType<typeof useGetEntryReservationByBudgetQuery>;
export type GetEntryReservationByBudgetLazyQueryHookResult = ReturnType<typeof useGetEntryReservationByBudgetLazyQuery>;
export type GetEntryReservationByBudgetQueryResult = Apollo.QueryResult<GetEntryReservationByBudgetQuery, GetEntryReservationByBudgetQueryVariables>;
export const UpdateEntryReservationDocument = gql`
    mutation updateEntryReservation($id: Int!, $date: DateTime, $description: String) {
  updateEntryReservation(id: $id, date: $date, description: $description) {
    id
  }
}
    `;
export type UpdateEntryReservationMutationFn = Apollo.MutationFunction<UpdateEntryReservationMutation, UpdateEntryReservationMutationVariables>;

/**
 * __useUpdateEntryReservationMutation__
 *
 * To run a mutation, you first call `useUpdateEntryReservationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntryReservationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntryReservationMutation, { data, loading, error }] = useUpdateEntryReservationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      date: // value for 'date'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateEntryReservationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntryReservationMutation, UpdateEntryReservationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntryReservationMutation, UpdateEntryReservationMutationVariables>(UpdateEntryReservationDocument, options);
      }
export type UpdateEntryReservationMutationHookResult = ReturnType<typeof useUpdateEntryReservationMutation>;
export type UpdateEntryReservationMutationResult = Apollo.MutationResult<UpdateEntryReservationMutation>;
export type UpdateEntryReservationMutationOptions = Apollo.BaseMutationOptions<UpdateEntryReservationMutation, UpdateEntryReservationMutationVariables>;
export const GetReceptionByIdShowDocument = gql`
    query getReceptionByIdShow($id: Int!) {
  getReceptionById(id: $id) {
    receptionDate
    budget {
      car {
        model
        brand
        year
        plate
        chasis
        color
      }
      client {
        name
        lastname
        rut
        email
        phone
      }
    }
    internalInventory
    externalAndOtherInventory
    details
    workingItems
    clientSignature
    craneSignature
    observations
  }
}
    `;

/**
 * __useGetReceptionByIdShowQuery__
 *
 * To run a query within a React component, call `useGetReceptionByIdShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceptionByIdShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceptionByIdShowQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReceptionByIdShowQuery(baseOptions: Apollo.QueryHookOptions<GetReceptionByIdShowQuery, GetReceptionByIdShowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceptionByIdShowQuery, GetReceptionByIdShowQueryVariables>(GetReceptionByIdShowDocument, options);
      }
export function useGetReceptionByIdShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceptionByIdShowQuery, GetReceptionByIdShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceptionByIdShowQuery, GetReceptionByIdShowQueryVariables>(GetReceptionByIdShowDocument, options);
        }
export type GetReceptionByIdShowQueryHookResult = ReturnType<typeof useGetReceptionByIdShowQuery>;
export type GetReceptionByIdShowLazyQueryHookResult = ReturnType<typeof useGetReceptionByIdShowLazyQuery>;
export type GetReceptionByIdShowQueryResult = Apollo.QueryResult<GetReceptionByIdShowQuery, GetReceptionByIdShowQueryVariables>;
export const SignupAdminDocument = gql`
    mutation SignupAdmin($rut: String!, $email: String!, $name: String!) {
  signupAdmin(rut: $rut, email: $email, name: $name) {
    token
  }
}
    `;
export type SignupAdminMutationFn = Apollo.MutationFunction<SignupAdminMutation, SignupAdminMutationVariables>;

/**
 * __useSignupAdminMutation__
 *
 * To run a mutation, you first call `useSignupAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupAdminMutation, { data, loading, error }] = useSignupAdminMutation({
 *   variables: {
 *      rut: // value for 'rut'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSignupAdminMutation(baseOptions?: Apollo.MutationHookOptions<SignupAdminMutation, SignupAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupAdminMutation, SignupAdminMutationVariables>(SignupAdminDocument, options);
      }
export type SignupAdminMutationHookResult = ReturnType<typeof useSignupAdminMutation>;
export type SignupAdminMutationResult = Apollo.MutationResult<SignupAdminMutation>;
export type SignupAdminMutationOptions = Apollo.BaseMutationOptions<SignupAdminMutation, SignupAdminMutationVariables>;
export const SignupWorkerDocument = gql`
    mutation signupWorker($rut: String!, $email: String!, $name: String!, $role: String) {
  signupWorker(rut: $rut, email: $email, name: $name, role: $role) {
    token
  }
}
    `;
export type SignupWorkerMutationFn = Apollo.MutationFunction<SignupWorkerMutation, SignupWorkerMutationVariables>;

/**
 * __useSignupWorkerMutation__
 *
 * To run a mutation, you first call `useSignupWorkerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupWorkerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupWorkerMutation, { data, loading, error }] = useSignupWorkerMutation({
 *   variables: {
 *      rut: // value for 'rut'
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useSignupWorkerMutation(baseOptions?: Apollo.MutationHookOptions<SignupWorkerMutation, SignupWorkerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupWorkerMutation, SignupWorkerMutationVariables>(SignupWorkerDocument, options);
      }
export type SignupWorkerMutationHookResult = ReturnType<typeof useSignupWorkerMutation>;
export type SignupWorkerMutationResult = Apollo.MutationResult<SignupWorkerMutation>;
export type SignupWorkerMutationOptions = Apollo.BaseMutationOptions<SignupWorkerMutation, SignupWorkerMutationVariables>;
export const AddReservationImageDocument = gql`
    mutation addReservationImage($reservationId: Int!, $field: String!, $link: String!) {
  addReservationImage(reservationId: $reservationId, field: $field, link: $link) {
    id
    client {
      carnet
    }
    car {
      permisoCirculacion
    }
    declaracionJurada
  }
}
    `;
export type AddReservationImageMutationFn = Apollo.MutationFunction<AddReservationImageMutation, AddReservationImageMutationVariables>;

/**
 * __useAddReservationImageMutation__
 *
 * To run a mutation, you first call `useAddReservationImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddReservationImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addReservationImageMutation, { data, loading, error }] = useAddReservationImageMutation({
 *   variables: {
 *      reservationId: // value for 'reservationId'
 *      field: // value for 'field'
 *      link: // value for 'link'
 *   },
 * });
 */
export function useAddReservationImageMutation(baseOptions?: Apollo.MutationHookOptions<AddReservationImageMutation, AddReservationImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddReservationImageMutation, AddReservationImageMutationVariables>(AddReservationImageDocument, options);
      }
export type AddReservationImageMutationHookResult = ReturnType<typeof useAddReservationImageMutation>;
export type AddReservationImageMutationResult = Apollo.MutationResult<AddReservationImageMutation>;
export type AddReservationImageMutationOptions = Apollo.BaseMutationOptions<AddReservationImageMutation, AddReservationImageMutationVariables>;
export const GetReservationDocument = gql`
    query getReservation($id: Int!) {
  getReservation(id: $id) {
    client {
      rut
      name
      lastname
      email
      phone
      email
      carnet
    }
    car {
      plate
      chasis
      model
      color
      year
      inside
      brand
      permisoCirculacion
    }
    date
    declaracionJurada
    description
    siniestro
    service {
      name
    }
    insurance {
      name
      email
    }
  }
}
    `;

/**
 * __useGetReservationQuery__
 *
 * To run a query within a React component, call `useGetReservationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReservationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReservationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReservationQuery(baseOptions: Apollo.QueryHookOptions<GetReservationQuery, GetReservationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReservationQuery, GetReservationQueryVariables>(GetReservationDocument, options);
      }
export function useGetReservationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReservationQuery, GetReservationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReservationQuery, GetReservationQueryVariables>(GetReservationDocument, options);
        }
export type GetReservationQueryHookResult = ReturnType<typeof useGetReservationQuery>;
export type GetReservationLazyQueryHookResult = ReturnType<typeof useGetReservationLazyQuery>;
export type GetReservationQueryResult = Apollo.QueryResult<GetReservationQuery, GetReservationQueryVariables>;
export const AllServicesDocument = gql`
    query allServices {
  services {
    name
    id
  }
}
    `;

/**
 * __useAllServicesQuery__
 *
 * To run a query within a React component, call `useAllServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllServicesQuery(baseOptions?: Apollo.QueryHookOptions<AllServicesQuery, AllServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllServicesQuery, AllServicesQueryVariables>(AllServicesDocument, options);
      }
export function useAllServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllServicesQuery, AllServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllServicesQuery, AllServicesQueryVariables>(AllServicesDocument, options);
        }
export type AllServicesQueryHookResult = ReturnType<typeof useAllServicesQuery>;
export type AllServicesLazyQueryHookResult = ReturnType<typeof useAllServicesLazyQuery>;
export type AllServicesQueryResult = Apollo.QueryResult<AllServicesQuery, AllServicesQueryVariables>;
export const WorkOrdersDocument = gql`
    query workOrders($take: Int, $page: Int, $open: Boolean, $currentProcess: String, $deadlineSort: String, $nameSort: String, $rutSort: String) {
  workOrders(
    take: $take
    page: $page
    open: $open
    currentProcess: $currentProcess
    deadlineSort: $deadlineSort
    nameSort: $nameSort
    rutSort: $rutSort
  ) {
    id
    budget {
      id
      folio
      client {
        rut
        name
        lastname
      }
    }
    deadline
    currentProcess {
      id
      name
    }
    closed
    deposit
    comments
  }
}
    `;

/**
 * __useWorkOrdersQuery__
 *
 * To run a query within a React component, call `useWorkOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkOrdersQuery({
 *   variables: {
 *      take: // value for 'take'
 *      page: // value for 'page'
 *      open: // value for 'open'
 *      currentProcess: // value for 'currentProcess'
 *      deadlineSort: // value for 'deadlineSort'
 *      nameSort: // value for 'nameSort'
 *      rutSort: // value for 'rutSort'
 *   },
 * });
 */
export function useWorkOrdersQuery(baseOptions?: Apollo.QueryHookOptions<WorkOrdersQuery, WorkOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkOrdersQuery, WorkOrdersQueryVariables>(WorkOrdersDocument, options);
      }
export function useWorkOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkOrdersQuery, WorkOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkOrdersQuery, WorkOrdersQueryVariables>(WorkOrdersDocument, options);
        }
export type WorkOrdersQueryHookResult = ReturnType<typeof useWorkOrdersQuery>;
export type WorkOrdersLazyQueryHookResult = ReturnType<typeof useWorkOrdersLazyQuery>;
export type WorkOrdersQueryResult = Apollo.QueryResult<WorkOrdersQuery, WorkOrdersQueryVariables>;
export const AssignTaskDocument = gql`
    mutation assignTask($taskIds: [Int!]!, $workerId: Int!) {
  assignTasks(taskIds: $taskIds, workerId: $workerId) {
    id
    concept {
      name
    }
    piece {
      name
    }
    status {
      name
    }
    worker {
      name
    }
  }
}
    `;
export type AssignTaskMutationFn = Apollo.MutationFunction<AssignTaskMutation, AssignTaskMutationVariables>;

/**
 * __useAssignTaskMutation__
 *
 * To run a mutation, you first call `useAssignTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignTaskMutation, { data, loading, error }] = useAssignTaskMutation({
 *   variables: {
 *      taskIds: // value for 'taskIds'
 *      workerId: // value for 'workerId'
 *   },
 * });
 */
export function useAssignTaskMutation(baseOptions?: Apollo.MutationHookOptions<AssignTaskMutation, AssignTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AssignTaskMutation, AssignTaskMutationVariables>(AssignTaskDocument, options);
      }
export type AssignTaskMutationHookResult = ReturnType<typeof useAssignTaskMutation>;
export type AssignTaskMutationResult = Apollo.MutationResult<AssignTaskMutation>;
export type AssignTaskMutationOptions = Apollo.BaseMutationOptions<AssignTaskMutation, AssignTaskMutationVariables>;
export const CompleteTaskDocument = gql`
    mutation completeTask($id: Int!) {
  completeTask(taskId: $id) {
    id
    status {
      name
    }
  }
}
    `;
export type CompleteTaskMutationFn = Apollo.MutationFunction<CompleteTaskMutation, CompleteTaskMutationVariables>;

/**
 * __useCompleteTaskMutation__
 *
 * To run a mutation, you first call `useCompleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTaskMutation, { data, loading, error }] = useCompleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompleteTaskMutation(baseOptions?: Apollo.MutationHookOptions<CompleteTaskMutation, CompleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteTaskMutation, CompleteTaskMutationVariables>(CompleteTaskDocument, options);
      }
export type CompleteTaskMutationHookResult = ReturnType<typeof useCompleteTaskMutation>;
export type CompleteTaskMutationResult = Apollo.MutationResult<CompleteTaskMutation>;
export type CompleteTaskMutationOptions = Apollo.BaseMutationOptions<CompleteTaskMutation, CompleteTaskMutationVariables>;
export const ExitCarDocument = gql`
    mutation exitCar($id: Int!) {
  exitCar(workOrderId: $id) {
    id
  }
}
    `;
export type ExitCarMutationFn = Apollo.MutationFunction<ExitCarMutation, ExitCarMutationVariables>;

/**
 * __useExitCarMutation__
 *
 * To run a mutation, you first call `useExitCarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExitCarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exitCarMutation, { data, loading, error }] = useExitCarMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExitCarMutation(baseOptions?: Apollo.MutationHookOptions<ExitCarMutation, ExitCarMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExitCarMutation, ExitCarMutationVariables>(ExitCarDocument, options);
      }
export type ExitCarMutationHookResult = ReturnType<typeof useExitCarMutation>;
export type ExitCarMutationResult = Apollo.MutationResult<ExitCarMutation>;
export type ExitCarMutationOptions = Apollo.BaseMutationOptions<ExitCarMutation, ExitCarMutationVariables>;
export const GetAdminWorkOrderByFolioDocument = gql`
    query getAdminWorkOrderByFolio($rut: String!, $folio: String!) {
  getWorkOrderByFolio(rut: $rut, folio: $folio) {
    id
    budget {
      car {
        plate
        brand
        model
        color
      }
      client {
        email
      }
    }
    closed
    inWorkshop
    deadline
    workProcesses {
      id
      process {
        id
        name
      }
      tasks {
        id
        worker {
          name
        }
        status {
          name
        }
        concept {
          name
        }
        piece {
          name
        }
      }
    }
    currentProcess {
      id
    }
    comments
    closed
  }
  workers {
    id
    name
    role
  }
}
    `;

/**
 * __useGetAdminWorkOrderByFolioQuery__
 *
 * To run a query within a React component, call `useGetAdminWorkOrderByFolioQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminWorkOrderByFolioQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminWorkOrderByFolioQuery({
 *   variables: {
 *      rut: // value for 'rut'
 *      folio: // value for 'folio'
 *   },
 * });
 */
export function useGetAdminWorkOrderByFolioQuery(baseOptions: Apollo.QueryHookOptions<GetAdminWorkOrderByFolioQuery, GetAdminWorkOrderByFolioQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminWorkOrderByFolioQuery, GetAdminWorkOrderByFolioQueryVariables>(GetAdminWorkOrderByFolioDocument, options);
      }
export function useGetAdminWorkOrderByFolioLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminWorkOrderByFolioQuery, GetAdminWorkOrderByFolioQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminWorkOrderByFolioQuery, GetAdminWorkOrderByFolioQueryVariables>(GetAdminWorkOrderByFolioDocument, options);
        }
export type GetAdminWorkOrderByFolioQueryHookResult = ReturnType<typeof useGetAdminWorkOrderByFolioQuery>;
export type GetAdminWorkOrderByFolioLazyQueryHookResult = ReturnType<typeof useGetAdminWorkOrderByFolioLazyQuery>;
export type GetAdminWorkOrderByFolioQueryResult = Apollo.QueryResult<GetAdminWorkOrderByFolioQuery, GetAdminWorkOrderByFolioQueryVariables>;
export const StartTaskDocument = gql`
    mutation startTask($id: Int!) {
  startTask(taskId: $id) {
    id
    status {
      name
    }
  }
}
    `;
export type StartTaskMutationFn = Apollo.MutationFunction<StartTaskMutation, StartTaskMutationVariables>;

/**
 * __useStartTaskMutation__
 *
 * To run a mutation, you first call `useStartTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTaskMutation, { data, loading, error }] = useStartTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStartTaskMutation(baseOptions?: Apollo.MutationHookOptions<StartTaskMutation, StartTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartTaskMutation, StartTaskMutationVariables>(StartTaskDocument, options);
      }
export type StartTaskMutationHookResult = ReturnType<typeof useStartTaskMutation>;
export type StartTaskMutationResult = Apollo.MutationResult<StartTaskMutation>;
export type StartTaskMutationOptions = Apollo.BaseMutationOptions<StartTaskMutation, StartTaskMutationVariables>;