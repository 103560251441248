import { CircularProgress } from '@material-ui/core';
import { ReactElement } from 'react';
import useLoaderStyles from '../../styles/loader';

function Loader(): ReactElement {
  const classes = useLoaderStyles();
  return (
    <div className={classes.loaderDiv}>
      <CircularProgress className={classes.circularProgress} />
      <p className={classes.message}>Estamos procesando su solicitud...</p>
    </div>
  );
}

export default Loader;
