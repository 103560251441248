import DataTable from 'react-data-table-component';
import { ReactElement, useEffect, useState } from 'react';
import {
  Button,
  Box,
} from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import { useWorkOrdersLazyQuery, WorkOrdersQuery, useAllWorkOrdersLazyQuery } from '../../types';
import { useStyles } from '../../styles/workOrder_style';
import { WorkOrderInterface } from '../../interfaces/components.interfaces';
import WorkOrderFilter from '../filters/workOrderFilters';

function redirectToReservationView(rut: string, folio: string) {
  window.location.href = `/admin/ordenes-trabajo/${rut}/${folio}`;
}

const columns = [
  {
    name: 'Nombre cliente', selector: 'clientName',
  },
  {
    name: 'RUT cliente', selector: 'clientRut',
  },
  {
    name: 'Plazo límite', selector: 'deadline',
  },
  {
    name: 'Etapa actual', selector: 'currentProcessName',
  },
  {
    name: 'Estado', selector: 'closed',
  },
  {
    name: '',
    cell: (params: { clientRut: string, folio: string }) => {
      const onClick = () => {
        redirectToReservationView(params.clientRut, params.folio);
      };
      return <Button onClick={onClick}>Ver</Button>;
    },
  },
];
const fases: { [fase: string]: string } = {
  dent: 'Desabolladura',
  wash: 'Lavado',
  polish: 'Pulido',
  paint: 'Pintura',
};

function formatingData(data: WorkOrdersQuery['workOrders']) {
  const rows: WorkOrderInterface[] = [];
  data.forEach((element) => {
    const order: WorkOrderInterface = {
      id: +element.id,
      budgetId: +element.budget.id,
      folio: element.budget.folio,
      clientRut: element.budget.client.rut,
      clientName: `${element.budget.client.name} ${element.budget.client.lastname}`,
      deadline: element.deadline,
      currentProcessId: +element.currentProcess.id,
      currentProcessName: fases[element.currentProcess.name],
      closed: element.closed ? 'Finalizada' : 'En proceso',
    };
    rows.push(order);
  });
  return rows;
}

function WorkOrderTable(): ReactElement {
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [open, setOpen] = useState('');
  const [currentProcess, setCurrentProcess] = useState('');
  const [deadLineSort, setDeadLineSort] = useState('');
  const [nameSort, setNameSort] = useState('');
  const [rutSort, setRutSort] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const take = 6;
  const [executeAll] = useAllWorkOrdersLazyQuery({
    variables: {
      open: open === 'true' || open === 'false' ? open === 'true' : null,
      currentProcess: currentProcess !== 'Todas' ? currentProcess : null,
    },
    onCompleted: (data) => {
      setAllRows(data.allWorkOrders);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlertContent('Falló la solicitud, inténtelo nuevamente');
        setAlert(true);
      }
    },
  });
  const [executeBudgetsQuery] = useWorkOrdersLazyQuery({
    variables: {
      page,
      take,
      open: open === 'true' || open === 'false' ? open === 'true' : null,
      currentProcess: currentProcess !== 'Todas' ? currentProcess : null,
      deadlineSort: deadLineSort,
      nameSort,
      rutSort,
    },
    onCompleted: (data) => {
      executeAll();
      setRowData(formatingData(data.workOrders));
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlertContent('Falló la solicitud, inténtelo nuevamente');
        setAlert(true);
      }
    },
  });

  useEffect(() => {
    executeBudgetsQuery();
  }, [page]);
  return (
    <div className={classes.root}>
      { alert ? <Alert className={classes.alert} severity="error">{alertContent}</Alert> : <></>}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.buttonsContainer}
      >
        <WorkOrderFilter
          deadLineSort={deadLineSort}
          setDeadLineSort={setDeadLineSort}
          nameSort={nameSort}
          setNameSort={setNameSort}
          rutSort={rutSort}
          setRutSort={setRutSort}
          open={open}
          setOpen={setOpen}
          currentProcess={currentProcess}
          setCurrentProcess={setCurrentProcess}
        />
      </Box>
      <DataTable
        noHeader
        columns={columns}
        data={rowData}
        noDataComponent="No se ha encontrado información para mostrar"
        pagination
        paginationServer
        paginationPerPage={take}
        paginationTotalRows={allRows}
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: 'de',
        }}
        paginationRowsPerPageOptions={[6]}
        onChangePage={(pageNumber) => setPage(pageNumber)}
      />
    </div>
  );
}

export default WorkOrderTable;
