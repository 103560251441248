import { ReactElement } from 'react';
import { Dialog } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useBudgetImageDialogStyle from '../../styles/budget_image_dialog';
import { ClientBudgetImageDialogInterface } from '../../interfaces/components.interfaces';

function ClientImageDialog(props: ClientBudgetImageDialogInterface): ReactElement {
  const classes = useBudgetImageDialogStyle();
  const {
    open,
    images,
    setOpen,
    onClose,
    setActualImage,
    setOpenDisplay,
  } = props;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <div className={classes.closeBox}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={classes.titleContainer}>
        <h1 className={classes.dialogTitle}>Imagenes</h1>
      </div>
      <div className={classes.imagesContainer}>
        {images.map((image) => (
          <div
            className={classes.budgetImageContainer}
            key={image.id}
          >
            <img
              alt=""
              src={image.link}
              className={classes.budgetImage}
              onClick={() => {
                setActualImage(image.link);
                setOpenDisplay(true);
              }}
            />
          </div>
        ))}
      </div>
    </Dialog>
  );
}

export default ClientImageDialog;
