import MaterialTable from 'material-table';
import { ReactElement, useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { useStyles } from '../../styles/admins_table_style';
import { useAdminsListLazyQuery, AdminsListQuery } from '../../types';

function AdminsTable() : ReactElement {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [executeAdminsListQuery] = useAdminsListLazyQuery({
    onCompleted: (dataAdmins) => {
      const allAdminsInfo: AdminsListQuery['admins'] = [];
      dataAdmins.admins.forEach((admin) => {
        const adminInfo = {
          name: admin.name,
          email: admin.email,
          rut: admin.rut,
        };
        allAdminsInfo.push(adminInfo);
      });
      setData(allAdminsInfo);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  useEffect(() => {
    executeAdminsListQuery();
  }, []);

  const columns = [
    {
      title: 'Nombre',
      field: 'name',
      sorting: false,
    },
    {
      title: 'Correo',
      field: 'email',
      sorting: false,
    },
    {
      title: 'Rut',
      field: 'rut',
      sorting: false,
    },
  ];

  return (
    <div className={classes.table}>
      <MaterialTable
        title=""
        columns={columns}
        data={data}
        localization={{
          body: {
            emptyDataSourceMessage: 'No se encuentra información',
          },
          toolbar: {
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar',
            exportPDFName: 'Exportar PDF',
            exportCSVName: 'Exportar CSV',
          },
          pagination: {
            labelRowsSelect: 'filas',
            labelDisplayedRows: '{from}-{to} de {count}',
            firstTooltip: 'Primera Página',
            previousTooltip: 'Página anterior',
            nextTooltip: 'Próxima página',
            lastTooltip: 'Última página',
          },
        }}
        options={{
          pageSize: 4,
          pageSizeOptions: [4],
          exportButton: true,
          exportAllData: true,
        }}
      />
    </div>
  );
}

export default AdminsTable;
