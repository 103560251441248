import { makeVar } from '@apollo/client';
import { StateInterface } from '../interfaces';

const initialState: StateInterface = {
  isLoading: false,
  token: '',
  name: '',
};

const state = makeVar(initialState);

export default state;
