import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  paper: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '80vw',
    height: '85vh',
    textAlign: 'center',
    minHeight: '480px',
    minWidth: '280px',
  },
  rescheduleModal: {
    justifyContent: 'center',
    display: 'flex',
    alignContent: 'center',
    flexDirection: 'column',
  },
  button: {
    height: '4vh',
    backgroundColor: '#126da6',
    color: '#ffffff',
    '&:hover': {
      TransitionEvent: '2s',
      backgroundColor: '#ffffff',
      color: '#3c52b2',
    },
  },
  modal: {
    justifyContent: 'center',
    display: 'flex',
    marginTop: '4vh',
  },
  closeIcon: {
    maxWidth: '50px',
    maxHeight: '50px',
    fontSize: '10vw',
  },
  closeBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginTop: '2vw',
    paddingRight: '2vw',
    paddingLeft: '2vw',
    width: '80vw',
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export default useStyles;
