import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useButtonstyles = makeStyles((theme: Theme) => createStyles({
  pageButtonActive: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: '15vw',
      height: '10vh',
    },
    [theme.breakpoints.down('md')]: {
      width: '55vw',
      height: '20vh',
    },
    color: '#FFFFFF',
    marginTop: '5vh',
    backgroundColor: '#126da6',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      color: '#126da6',
    },
  },
}));
export default useButtonstyles;
