import {
  ReactElement,
  useEffect,
} from 'react';
import {
  TextField,
  Box,
  FormHelperText,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@material-ui/core';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import ImageDialog from '../image_dialog/image_dialog';
import { InsuranceDataInterface } from '../../interfaces/components.interfaces';
import { useGetInsurancesLazyQuery, GetInsurancesQuery } from '../../types';
import useBudgetAppointmentStyles from '../../styles/budget_appointment';

function InsuranceData(props: InsuranceDataInterface) : ReactElement {
  const {
    insuranceId,
    setInsuranceId,
    accidentNumber,
    setAccidentNumber,
    swornDeclarationFiles,
    setSwornDeclarationFiles,
    openSwornDeclaration,
    setOpenSwornDeclaration,
    insurances,
    setInsurances,
    nextClicked,
  } = props;
  const classes = useBudgetAppointmentStyles();
  const [executeGetInsurances] = useGetInsurancesLazyQuery({
    onCompleted: (data) => setInsurances(data.insurances),
  });

  useEffect(() => {
    executeGetInsurances();
  }, []);
  return (
    <Box className={classes.inputsBox}>
      <div className={classes.formTitle}>
        Información del seguro
      </div>
      <FormControl className={classes.formInput}>
        <InputLabel id="security-select">Seguro</InputLabel>
        <Select
          labelId="security-select"
          onChange={(e) => setInsuranceId(+e.target.value as number)}
          value={+insuranceId}
        >
          {insurances.map((insurance: GetInsurancesQuery['insurances'][0]) => (
            <MenuItem
              key={insurance.id}
              value={+insurance.id}
            >
              {insurance.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {'\u00a0'}
        </FormHelperText>
      </FormControl>
      <TextField
        label="Numero de siniestro"
        helperText={(accidentNumber.length === 0 && nextClicked) ? 'Ingrese el numero de siniestro' : '\u00a0'}
        FormHelperTextProps={{ className: classes.helperText }}
        onChange={(e) => setAccidentNumber(e.target.value)}
        value={accidentNumber}
        className={classes.formInput}
      />
      <div className={classes.uploadContainer}>
        <div
          onClick={() => setOpenSwornDeclaration(true)}
          className={swornDeclarationFiles.length > 0 ? classes.uploadButtonActive : classes.uploadButton}
        >
          <p className={classes.uploadTitle}>
            Declaración Jurada
          </p>
          {(swornDeclarationFiles.length > 0)
            ? (
              <div className={classes.filesCount}>
                <div className={classes.mycount}>
                  {swornDeclarationFiles.length}
                </div>
              </div>
            ) : <CameraAltIcon className={classes.uploadIcon} />}
        </div>
        <FormHelperText className={classes.helperText}>
          {(swornDeclarationFiles.length > 0 || !nextClicked) ? '\u00a0' : 'Agregue su declaración jurada'}
        </FormHelperText>
        <ImageDialog
          files={swornDeclarationFiles}
          open={openSwornDeclaration}
          setOpen={setOpenSwornDeclaration}
          onClose={() => setOpenSwornDeclaration(false)}
          setFiles={setSwornDeclarationFiles}
          title="Declaración Jurada"
        />
      </div>
    </Box>
  );
}
export default InsuranceData;
