import { useState, ReactElement } from 'react';
import { ApolloError } from '@apollo/client';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Loader from '../loader/loader';
import { ClientChangeBudgetStatusDialogInterface } from '../../interfaces/components.interfaces';
import { BudgetStatusOption, useClientChangeBudgetStatusMutation } from '../../types';
import useBudgetShowStyle from '../../styles/budget_show';

function AcceptDialog({ id, rut, folio } : ClientChangeBudgetStatusDialogInterface): ReactElement {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const useStyles = useBudgetShowStyle();
  const [executeUpdateBudgetStatus] = useClientChangeBudgetStatusMutation({
    variables: {
      budgetID: id,
      rut,
      folio,
      status: BudgetStatusOption.Aprobado,
    },
    onCompleted: () => {
      setIsLoading(false);
      window.location.href = '/';
    },
    onError: (error: ApolloError) => {
      setIsLoading(false);
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const acceptBudget = () => {
    executeUpdateBudgetStatus();
    setOpen(false);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Button className={useStyles.acceptButton} onClick={handleClickOpen}>
        Aceptar
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">¿Está seguro de aprobar el presupuesto?</DialogTitle>
        <DialogActions>
          <Button onClick={acceptBudget} color="primary">
            Confirmar
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AcceptDialog;
