import { ReactElement, useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { Button } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useWorkOrdersLazyQuery, WorkOrdersQuery, useAllWorkOrdersLazyQuery } from '../../types';
import { ExportWorkOrderInterface } from '../../interfaces/components.interfaces';
import useButtonstyles from '../../styles/exportButton_style';
import { reducer } from '../../store/reducer';

function formatingData(data: WorkOrdersQuery['workOrders']) {
  const rows: ExportWorkOrderInterface[] = [];
  data.forEach((element) => {
    const workOrder : ExportWorkOrderInterface = {
      Id: +element.id,
      Id_presupuesto: +element.budget.id,
      Fecha_límite: element.deadline,
      Id_etapa_actual: +element.currentProcess.id,
      Nombre_etapa_actual: element.currentProcess.name,
      Cerrada: element.closed,
      Deposito: element.deposit,
      Comentarios: element.comments,
    };
    rows.push(workOrder);
  });
  return rows;
}

function ExportWorkOrder() : ReactElement {
  const classes = useButtonstyles();
  const fileName = 'Órdenes de trabajo';
  const [rowData, setRowData] = useState([]);
  const [amount, setAmount] = useState(-1);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (dataCSV: ExportWorkOrderInterface[], NameFile: string) => {
    const ws = XLSX.utils.json_to_sheet(dataCSV);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, NameFile + fileExtension);
  };
  const [executeAllWorkOrders] = useAllWorkOrdersLazyQuery({
    onCompleted: (data) => {
      setAmount(data.allWorkOrders);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  const [executeWorkOrder] = useWorkOrdersLazyQuery({
    onCompleted: (data) => {
      setRowData(formatingData(data.workOrders));
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });
  useEffect(() => {
    if (rowData.length > 0) {
      exportToCSV(rowData, fileName);
    }
  }, [rowData]);
  useEffect(() => {
    if (amount > -1) {
      executeWorkOrder({
        variables: {
          page: 1,
          take: amount,
        },
      });
    }
  }, [amount]);

  return (
    <Button
      onClick={() => {
        reducer({ type: 'LOADING' });
        executeAllWorkOrders();
      }}
      className={classes.pageButtonActive}
    >
      Datos de las ordenes de trabajo
    </Button>
  );
}
export default ExportWorkOrder;
