import { GetReceptionByIdShowQuery } from '../types';

const concepts: GetReceptionByIdShowQuery['getReceptionById']['internalInventory'] = {
  aplifier: 'Amplificador',
  ashtray: 'Cenicero',
  carDocuments: 'Docs. de vehículo',
  carpets: 'Pisos',
  cloth: 'Paños',
  contactKey: 'Llave de Contacto',
  fogger: 'Neblinero',
  fuel: 'Nivel de Bencina',
  lighter: 'Encendedor',
  panel: 'Tablero',
  radioAndSpeakers: 'Radio y Parlanter',
  radioPanel: 'Panel de radio',
  rearviewMirror: 'Espejo retrovisor',
  reflectingVest: 'Chaleco reflectante',
  tag: 'tag',
  tapestry: 'tapiz',
  valuableObject: 'Objetos de valor',
  woofer: 'Woofer',
  aileron: 'Alerón',
  antenna: 'Antena',
  handles: 'Manillas puertas',
  leftMirror: 'Retrovisor izquierdo',
  logos: 'Logos',
  reverseCamera: 'Cámara de retroceso',
  reverseSensors: 'Sensor de retroceso',
  rightMirror: 'Retrovisor derecho',
  wheelCover: 'Tapa ruedas',
  ac: 'A/C',
  alarm: 'Alarma',
  backLights: 'Luces traseras',
  blinker: 'Intermitentes',
  centralLock: 'Cierre central',
  fireExtinguisher: 'Extintor',
  firstAidKit: 'Botiquín',
  frontLights: 'Luces delanteras',
  glassRisers: 'Alza vidrios',
  heating: 'Calefacción',
  horn: 'Bocina',
  jack: 'Gata',
  motorCover: 'Cubre motor',
  replacementWheel: 'Rueda de repuesto',
  tools: 'Herramientas',
  triangle: 'Triángulo',
  wheelKey: 'Llave rueda',
  windshield: 'Parabrisas',
  windshieldNibs: 'Plumillas parabrisas',
};

export default concepts;
