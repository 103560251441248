import {
  ReactElement,
  useEffect,
  useState,
  useRef,
} from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import BuildIcon from '@material-ui/icons/Build';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SecurityIcon from '@material-ui/icons/Security';
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import moment from 'moment';
import imagesUploadHandler from '../../helpers/imagesUploadHandler';
import { reducer } from '../../store/reducer';
import ReservationDeleteImageDialog from '../../components/reservation_dialog/delete_image_dialog';
import ReservationImageDialog from '../../components/reservation_dialog/reservation_dialog';
import { ReservationDataInterface } from '../../interfaces/components.interfaces';
import {
  useGetReservationLazyQuery,
  GetReservationQuery,
  useSignS3Mutation,
  useAllServicesLazyQuery,
  useAddReservationImageMutation,
} from '../../types';

import { useStyles } from '../../styles/reservation_data_style';
import RescheduleModal from '../../components/modal/rescheduleModal';

function dateFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  const year: number = date.getFullYear();
  const month: number = (date.getMonth());
  const day: number = date.getDate();
  return moment(new Date(year, month, day)).format('DD/MM/YYYY');
}

function timeFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  let hour: string = date.getUTCHours().toString();
  let min: string = date.getUTCMinutes().toString();
  if (min.length === 1) {
    min = `0${min}`;
  }
  if (hour.length === 1) {
    hour = `0${hour}`;
  }
  return `${hour}:${min}`;
}

function insideStateFormat(inside: boolean) {
  if (inside) {
    return 'Ingresado';
  }
  return 'No ingresado';
}

function ReservationData(props: ReservationDataInterface) : ReactElement {
  const {
    id,
  } = props;
  const classes = useStyles();
  const [mobile, setMobile] = useState(false);
  const [date, setDate] = useState('');
  const [hour, setHour] = useState('');
  const [chasis, setChasis] = useState('');
  const [plate, setPlate] = useState('');
  const [model, setModel] = useState('');
  const [inside, setInside] = useState('No ingresado');
  const [year, setYear] = useState(0);
  const [color, setColor] = useState('');
  const [siniestro, setSiniestro] = useState('');
  const [brand, setBrand] = useState('');
  const [nameInsurance, setNameInsurance] = useState('No Aplica');
  const [emailInsurance, setEmailInsurance] = useState('No Aplica');
  const [openDeleteImageDialog, setOpenDeleteImageDialog] = useState<boolean>(false);
  const [nameService, setNameService] = useState('');
  const [idService, setIdService] = useState(-1);
  const [name, setName] = useState('Sin Nombre');
  const [lastname, setLastname] = useState('');
  const [rut, setRut] = useState('Sin Rut');
  const [email, setEmail] = useState('Sin Correo');
  const [phone, setPhone] = useState('Sin Celular');
  const [description, setDescription] = useState('No informado');
  const [alert, setAlert] = useState(false);
  const [openImageDisplay, setOpenImageDisplay] = useState<boolean>(false);
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [actualImage, setActualImage] = useState<string>('');
  const hiddenInputRef = useRef(null);
  const [carnetImages, setCarnetImages] = useState<string[]>([]);
  const [declImages, setDeclImages] = useState<string[]>([]);
  const [permImages, setPermImages] = useState<string[]>([]);
  const [field, setField] = useState<string>('');
  const [existenciaDeclaracion, setExistenciaDeclaracion] = useState(false);

  const imagesConcatenation = (reservationData: GetReservationQuery) => {
    setCarnetImages([...reservationData.getReservation.client.carnet]);
    setPermImages([...reservationData.getReservation.car.permisoCirculacion]);

    if (reservationData.getReservation.insurance) {
      setDeclImages([...reservationData.getReservation.declaracionJurada]);
      setExistenciaDeclaracion(true);
    }
  };

  const resizeView = () => {
    if (window.innerWidth < 850) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const insuranceVerification = (reservationData: GetReservationQuery) => {
    if (reservationData.getReservation.insurance) {
      setNameInsurance(reservationData.getReservation.insurance.name);
      setEmailInsurance(reservationData.getReservation.insurance.email);
    }
  };

  const customerVerification = (reservationData: GetReservationQuery) => {
    if (reservationData.getReservation.client) {
      setName(reservationData.getReservation.client.name);
      setLastname(reservationData.getReservation.client.lastname);
      setEmail(reservationData.getReservation.client.email);
      setPhone(reservationData.getReservation.client.phone);
      setRut(reservationData.getReservation.client.rut);
    }
  };

  const reasonReservation = (reservationData: GetReservationQuery) => {
    if (reservationData.getReservation.description) {
      setDescription(reservationData.getReservation.description);
    }
  };

  const dataQueryFormat = (reservationData: GetReservationQuery) => {
    setDate(dateFormat(reservationData.getReservation.date));
    setHour(timeFormat(reservationData.getReservation.date));
    setChasis(reservationData.getReservation.car.chasis);
    setPlate(reservationData.getReservation.car.plate);
    setModel(reservationData.getReservation.car.model);
    setInside(insideStateFormat(reservationData.getReservation.car.inside));
    setYear(reservationData.getReservation.car.year);
    setColor(reservationData.getReservation.car.color);
    setSiniestro(reservationData.getReservation.siniestro);
    setBrand(reservationData.getReservation.car.brand);
    setNameService(reservationData.getReservation.service.name);
    reasonReservation(reservationData);
    customerVerification(reservationData);
    insuranceVerification(reservationData);
    imagesConcatenation(reservationData);
  };

  const [executeAllServices] = useAllServicesLazyQuery({
    onCompleted: (allServicesData) => {
      allServicesData.services.forEach((ser) => {
        if (ser.name === nameService) {
          setIdService(parseInt(ser.id, 10));
        }
      });
    },
    onError: () => {
      setAlert(true);
    },
  });

  const [executeGetReservationQuery] = useGetReservationLazyQuery({
    variables: {
      id: parseInt(id, 10),
    },
    onCompleted: (reservationData) => {
      dataQueryFormat(reservationData);
      executeAllServices();
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlert(true);
      }
    },
  });

  const [executeSignS3] = useSignS3Mutation({
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const [executeAddReservationImage] = useAddReservationImageMutation({
    variables: {
      reservationId: +id,
      field,
      link: actualImage,
    },
    onCompleted: (data) => {
      setCarnetImages([...data.addReservationImage.client.carnet]);
      setDeclImages([...data.addReservationImage.declaracionJurada]);
      setPermImages([...data.addReservationImage.car.permisoCirculacion]);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  useEffect(() => {
    executeGetReservationQuery();
  }, []);

  useEffect(() => {
    resizeView();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeView);
  }, []);

  if (mobile) {
    return (
      <div className={classes.budgetContainer}>
        {alert ? <Alert className={classes.alert} severity="error">Error procesando la solicitud</Alert> : <></> }
        {openImageDisplay && (
          <div className={classes.imageDiv}>
            <div className={classes.imageCloseIconContainer}>
              <CloseIcon
                className={classes.closeIcon}
                onClick={() => setOpenImageDisplay(false)}
              />
            </div>
            <img
              alt=""
              src={actualImage}
              className={classes.imageDisplay}
            />
          </div>
        )}
        <h1 className={classes.title}>Reserva</h1>
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <EventIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Hora agendada</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Fecha:
                </p>
                <p className={classes.statValue}>
                  {` ${date}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Hora:
                </p>
                <p className={classes.statValue}>
                  {` ${hour} hrs.`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <div className={classes.statValue}>
                  <RescheduleModal
                    id={parseInt(id, 10)}
                    idService={idService}
                    plate={plate}
                    date={date}
                    hour={hour}
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <AccountCircleIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Datos cliente</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Nombre:
                </p>
                <p className={classes.statValue}>
                  {` ${name}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Apellido:
                </p>
                <p className={classes.statValue}>
                  {` ${lastname}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Rut:
                </p>
                <p className={classes.statValue}>
                  {` ${rut}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Email:
                </p>
                <p className={classes.statValue}>
                  {` ${email}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Phone:
                </p>
                <p className={classes.statValue}>
                  {` ${phone}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <DirectionsCarIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Datos auto</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Patente:
                </p>
                <p className={classes.statValue}>
                  {` ${plate}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Modelo:
                </p>
                <p className={classes.statValue}>
                  {` ${model}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Marca:
                </p>
                <p className={classes.statValue}>
                  {` ${brand}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Color:
                </p>
                <p className={classes.statValue}>
                  {` ${color}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Año:
                </p>
                <p className={classes.statValue}>
                  {` ${year}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Chasis:
                </p>
                <p className={classes.statValue}>
                  {` ${chasis}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Siniestro:
                </p>
                <p className={classes.statValue}>
                  {` ${siniestro}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {(nameInsurance !== 'No Aplica') && (
          <Accordion className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
            >
              <div className={classes.accordionContainer}>
                <SecurityIcon className={classes.accordionIcon} />
                <p className={classes.accordionTitle}>{nameInsurance}</p>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.statsContainer}>
                <div className={classes.statContainer}>
                  <AccountCircleIcon className={classes.transparentIcon} />
                  <p className={classes.statName}>
                    Email:
                  </p>
                  <p className={classes.statValue}>
                    {` ${emailInsurance}`}
                  </p>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <BuildIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Servicio</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Servicio:
                </p>
                <p className={classes.statValue}>
                  {` ${nameService}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Vehículo:
                </p>
                <p className={classes.statValue}>
                  {` ${inside}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Motivo:
                </p>
                <p className={classes.statValue}>
                  {` ${description}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <div className={classes.imagesContainer}>
            {openImageDisplay && (
              <div className={classes.imageDiv}>
                <div className={classes.imageCloseIconContainer}>
                  <CloseIcon
                    className={classes.closeIcon}
                    onClick={() => setOpenImageDisplay(false)}
                  />
                </div>
                <img
                  alt=""
                  src={actualImage}
                  className={classes.imageDisplay}
                />
              </div>
            )}
            <ReservationDeleteImageDialog
              onClose={() => setOpenDeleteImageDialog(false)}
              id={+id}
              image={actualImage}
              field={field}
              open={openDeleteImageDialog}
              setOpen={setOpenDeleteImageDialog}
              setCarnetImages={setCarnetImages}
              setDeclImages={setDeclImages}
              setPermImages={setPermImages}
            />
            <ReservationImageDialog
              open={openImageDialog}
              onClose={() => {
                setOpenImageDialog(false);
              }}
              setOpen={setOpenImageDialog}
              carnetImages={carnetImages}
              declImages={declImages}
              permImages={permImages}
              setActualImage={setActualImage}
              setOpenDisplay={setOpenImageDisplay}
              setOpenDelete={setOpenDeleteImageDialog}
              inputRef={hiddenInputRef}
              setField={setField}
              existenciaDeclaracion={existenciaDeclaracion}
            />
            <Fab
              color="primary"
              className={classes.imageButton}
              onClick={() => setOpenImageDialog(true)}
            >
              <PermMediaIcon />
            </Fab>
            <input
              ref={hiddenInputRef}
              accept="image/*"
              type="file"
              multiple
              className={classes.hiddenInput}
              onChange={(e) => {
                reducer({ type: 'LOADING' });
                imagesUploadHandler('reservation_image', id, [...e.target.files], executeSignS3)
                  .then((urls) => {
                    urls.forEach((url) => {
                      executeAddReservationImage({
                        variables: {
                          reservationId: +id,
                          field,
                          link: url,
                        },
                      });
                    });
                    reducer({ type: 'LOADING' });
                  });
              }}
            />
          </div>
        </Accordion>
      </div>
    );
  }

  return (
    <div className={classes.desktopContainer}>
      {alert ? <Alert className={classes.alert} severity="error">Error procesando la solicitud</Alert> : <></> }
      {openImageDisplay && (
        <div className={classes.imageDiv}>
          <div className={classes.imageCloseIconContainer}>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => setOpenImageDisplay(false)}
            />
          </div>
          <img
            alt=""
            src={actualImage}
            className={classes.imageDisplay}
          />
        </div>
      )}
      <ReservationDeleteImageDialog
        onClose={() => setOpenDeleteImageDialog(false)}
        id={+id}
        image={actualImage}
        field={field}
        open={openDeleteImageDialog}
        setOpen={setOpenDeleteImageDialog}
        setCarnetImages={setCarnetImages}
        setDeclImages={setDeclImages}
        setPermImages={setPermImages}
      />
      <ReservationImageDialog
        open={openImageDialog}
        onClose={() => {
          setOpenImageDialog(false);
        }}
        setOpen={setOpenImageDialog}
        carnetImages={carnetImages}
        declImages={declImages}
        permImages={permImages}
        setActualImage={setActualImage}
        setOpenDisplay={setOpenImageDisplay}
        setOpenDelete={setOpenDeleteImageDialog}
        inputRef={hiddenInputRef}
        setField={setField}
        existenciaDeclaracion={existenciaDeclaracion}
      />
      <Fab
        color="primary"
        className={classes.imageButton}
        onClick={() => setOpenImageDialog(true)}
      >
        <PermMediaIcon />
      </Fab>
      <div className={classes.personalDataContainer}>
        <h1 className={classes.personalTitle}>Reserva</h1>
        <h3 className={classes.desktopStatName}>Hora agendada</h3>
        <div className={classes.desktopStatContainer}>
          <p className={classes.desktopStatName}>Fecha:</p>
          <p className={classes.desktopStatValue}>{` ${date}`}</p>
        </div>
        <div className={classes.desktopStatContainer}>
          <p className={classes.desktopStatName}>Hora:</p>
          <p className={classes.desktopStatValue}>{` ${hour} hrs.`}</p>
        </div>
        <div className={classes.statValue}>
          <RescheduleModal
            id={parseInt(id, 10)}
            idService={idService}
            plate={plate}
            date={date}
            hour={hour}
          />
        </div>
      </div>
      <Divider variant="middle" />
      <div className={classes.generalDataContainer}>
        <div className={classes.personalDataContainer}>
          <h1 className={classes.personalTitle}>Vehículo</h1>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Marca:</p>
            <p className={classes.desktopStatValue}>{` ${brand}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Modelo:</p>
            <p className={classes.desktopStatValue}>{` ${model}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Año:</p>
            <p className={classes.desktopStatValue}>{` ${year}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Patente:</p>
            <p className={classes.desktopStatValue}>{` ${plate}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Chasis:</p>
            <p className={classes.desktopStatValue}>{` ${chasis}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Color:</p>
            <p className={classes.desktopStatValue}>{` ${color}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Siniestro:</p>
            <p className={classes.desktopStatValue}>{` ${siniestro}`}</p>
          </div>
        </div>
        <div className={classes.dataSeparator} />
        <div className={classes.personalDataContainer}>
          <h1 className={classes.personalTitle}>Servicio</h1>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Servicio:</p>
            <p className={classes.desktopStatValue}>{` ${nameService}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Vehículo:</p>
            <p className={classes.desktopStatValue}>{` ${inside}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Motivo:</p>
            <p className={classes.desktopStatValue}>{` ${description}`}</p>
          </div>
        </div>
      </div>
      {(nameInsurance !== 'No Aplica') && (
        <div className={classes.personalDataContainer}>
          <h1 className={classes.personalTitle}>{nameInsurance}</h1>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Correo:</p>
            <p className={classes.desktopStatValue}>{` ${emailInsurance}`}</p>
          </div>
        </div>
      )}
      <input
        ref={hiddenInputRef}
        accept="image/*"
        type="file"
        multiple
        className={classes.hiddenInput}
        onChange={(e) => {
          reducer({ type: 'LOADING' });
          imagesUploadHandler('reservation_image', id, [...e.target.files], executeSignS3)
            .then((urls) => {
              urls.forEach((url) => {
                executeAddReservationImage({
                  variables: {
                    reservationId: +id,
                    field,
                    link: url,
                  },
                });
              });
              reducer({ type: 'LOADING' });
            });
        }}
      />
    </div>
  );
}

export default ReservationData;
