import {
  ReactElement,
  useState,
  ChangeEvent,
  useEffect,
} from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import Alert from '@material-ui/lab/Alert';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import { useParams } from 'react-router-dom';
import {
  useGetEntryReservationsLazyQuery,
  useCreateEntryReservationMutation,
  useGetBudgetLazyQuery,
  useUpdateEntryReservationMutation,
} from '../../types';
import useStyles from '../../styles/reception_style';
import { reducer } from '../../store/reducer';

function timeFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  let hour: string = date.getUTCHours().toString();
  let min: string = date.getUTCMinutes().toString();
  if (min.length === 1) {
    min = `0${min}`;
  }
  if (hour.length === 1) {
    hour = `0${hour}`;
  }
  return `${hour}:${min}`;
}

function dateFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  const year: number = date.getFullYear();
  const month:number = (date.getMonth());
  const day: number = date.getDate();
  return moment(new Date(year, month, day)).format('DD/MM/YYYY');
}

function Reception(): ReactElement {
  const [day, setDay] = useState('');
  const [disponibility, setDisponibility] = useState([]);
  const [hour, setHour] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [severityError, setSeverityError] = useState(false);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [rut, setRut] = useState('');
  const [plate, setPlate] = useState('');
  const [reservedHour, setReservedHour] = useState('');
  const [reservedDay, setReservedDay] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [color, setColor] = useState('');
  const [haveReservation, setHaveReservation] = useState(0);
  const [caryear, setCarYear] = useState(0);
  const { budgetId }: { budgetId:string } = useParams();
  const classes = useStyles();
  const [executeAvailable] = useGetEntryReservationsLazyQuery({
    variables: {
      date: day,
    },
    onCompleted: (data) => {
      setDisponibility(data.getEntryReservations);
      setHour('');
    },
  });
  const [executeCreate] = useCreateEntryReservationMutation({
    onCompleted: () => {
      setAlertContent('Se ha actualizado correctamente');
      setAlert(true);
      setSeverityError(true);
      reducer({ type: 'LOADING' });
    },

    onError: () => {
      setAlertContent('No se pudo reservar la hora. Intente más tarde.');
      setAlert(true);
      setSeverityError(false);
      reducer({ type: 'LOADING' });
    },
  });
  const [executeEntryRecervation] = useGetBudgetLazyQuery({
    variables: {
      id: +budgetId,
    },
    onCompleted: (data) => {
      setName(data.getBudget.client.name);
      setLastname(data.getBudget.client.lastname);
      setRut(data.getBudget.client.rut);
      setPlate(data.getBudget.car.plate);
      setBrand(data.getBudget.car.brand);
      setModel(data.getBudget.car.model);
      setCarYear(data.getBudget.car.year);
      setColor(data.getBudget.car.color);
      setHaveReservation(data.getBudget.entryReservation === null ? 0 : data.getBudget.entryReservation.id);
      setReservedHour(data.getBudget.entryReservation ? timeFormat(data.getBudget.entryReservation.date) : '');
      setReservedDay(data.getBudget.entryReservation ? dateFormat(data.getBudget.entryReservation.date) : '');
    },
  });
  const [executeUpdate] = useUpdateEntryReservationMutation({
    onCompleted: () => {
      setAlertContent('Se ha actualizado correctamente');
      setAlert(true);
      setSeverityError(true);
      reducer({ type: 'LOADING' });
    },
    onError: () => {
      setAlertContent('No se pudo reservar la hora. Intente más tarde.');
      setAlert(true);
      setSeverityError(false);
      reducer({ type: 'LOADING' });
    },
  });
  function changeVal(text: string) {
    setDay(text);
    setHour('');
    setDisponibility([]);
    executeAvailable();
  }
  function addValue(e:ChangeEvent<HTMLInputElement>, text: string) {
    if (e.target.checked) {
      setHour(text);
    } else {
      setHour('');
    }
  }
  function sendAlert(alertType: boolean): ReactElement {
    if (alertType) {
      return (
        <Alert className={classes.alert} severity="success">
          {alertContent}
        </Alert>
      );
    }
    return (
      <Alert className={classes.alert} severity="error">
        {alertContent}
      </Alert>
    );
  }
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1;
  const today = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  let newMonth = month.toString();
  if (month < 10) {
    newMonth = `0${month}`;
  }
  const handleSubmit = () => {
    reducer({ type: 'LOADING' });
    if (haveReservation > 0) {
      executeUpdate({
        variables: {
          id: haveReservation,
          date: `${day}T${hour}.000Z`,
        },
      });
    } else {
      executeCreate({
        variables: {
          budgetId: +budgetId,
          date: `${day}T${hour}.000Z`,
        },
      });
    }
  };
  useEffect(() => {
    executeEntryRecervation();
  }, []);

  const newdate = `${year}-${newMonth}-${today}`;
  return (
    <div className={classes.base}>
      {alert ? sendAlert(severityError) : <></>}
      <Typography variant="h2" className={classes.typography}>
        Agendamiento de la recepción
      </Typography>
      {reservedHour !== ''
        ? (
          <div className={classes.statsContainer}>
            <div className={classes.statContainer}>
              <p className={classes.statNameTittle}>
                Hora agendada
              </p>
            </div>
            <div className={classes.statContainer}>
              <p className={classes.statName}>
                Fecha:
              </p>
              <p className={classes.statValue}>
                {` ${reservedHour} ${reservedDay}`}
              </p>
            </div>

          </div>
        )
        : null }
      <Box
        className={classes.hours}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className={classes.dates}
        >
          <TableContainer component={Paper} className={classes.dataTable}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Datos</TableCell>
                  <TableCell align="center">Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow id="name">
                  <TableCell align="center" component="th" scope="row">
                    Nombre
                  </TableCell>
                  <TableCell align="center">
                    {`${name} ${lastname}`}
                  </TableCell>
                </TableRow>
                <TableRow id="rut">
                  <TableCell align="center" component="th" scope="row">
                    RUT
                  </TableCell>
                  <TableCell align="center">
                    {rut}
                  </TableCell>
                </TableRow>
                <TableRow id="plate">
                  <TableCell align="center" component="th" scope="row">
                    Patente
                  </TableCell>
                  <TableCell align="center">
                    {plate}
                  </TableCell>
                </TableRow>
                <TableRow id="brand">
                  <TableCell align="center" component="th" scope="row">
                    Marca
                  </TableCell>
                  <TableCell align="center">
                    {brand}
                  </TableCell>
                </TableRow>
                <TableRow id="model">
                  <TableCell align="center" component="th" scope="row">
                    Modelo
                  </TableCell>
                  <TableCell align="center">
                    {model}
                  </TableCell>
                </TableRow>
                <TableRow id="color">
                  <TableCell align="center" component="th" scope="row">
                    Color
                  </TableCell>
                  <TableCell align="center">
                    {color}
                  </TableCell>
                </TableRow>
                <TableRow id="year">
                  <TableCell align="center" component="th" scope="row">
                    Año
                  </TableCell>
                  <TableCell align="center">
                    {caryear}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.secondBoxs}
        >
          <TextField
            id="date"
            label="Seleccione fecha"
            type="date"
            value={day}
            onChange={(e) => changeVal(e.target.value)}
            className={classes.formInput}
            inputProps={{ min: newdate }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TableContainer component={Paper} className={classes.table}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">Horas</TableCell>
                  <TableCell align="center">Cantidad de reservas</TableCell>
                  <TableCell align="center">
                    Seleccionar hora
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(disponibility).map((key: string, index: number) => (
                  <TableRow key={key}>
                    <TableCell align="center">
                      {key.slice(0, -3)}
                    </TableCell>
                    <TableCell align="center">
                      {Object.values(disponibility)[index]}
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        color="primary"
                        checked={hour === Object.keys(disponibility)[index]}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={(e) => addValue(e, Object.keys(disponibility)[index])}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Button
        variant="contained"
        className={hour !== ''
          ? classes.pageButtonActive
          : classes.pageButton}
        onClick={handleSubmit}
      >
        Agendar hora
      </Button>
    </div>
  );
}
export default Reception;
