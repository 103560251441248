import { ReactElement, useEffect, useState } from 'react';
import {
  TextField,
  Box,
  Button,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import FormatPaintIcon from '@material-ui/icons/FormatPaint';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import Alert from '@material-ui/lab/Alert';
import Table from '@material-ui/core/Table';
import Fab from '@material-ui/core/Fab';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CloseIcon from '@material-ui/icons/Close';
import useLoginStyles from '../../styles/folio_style';
import useBudgetShowStyle from '../../styles/budget_show';
import rutFormat from '../../helpers/rutFormat';
import validateRut from '../../helpers/validateRut';
import Loader from '../../components/loader/loader';
import { GetBudgetByFolioQuery, useGetBudgetByFolioLazyQuery } from '../../types';
import ClientImageDialog from '../../components/item_dialog/client_image_dialog';
import AcceptDialog from '../../components/budget_dialog/acceptBudget';
import RejectDialog from '../../components/budget_dialog/rejectBudget';

function BudgetClient() : ReactElement {
  const classes = useLoginStyles();
  const budgetClasses = useBudgetShowStyle();
  const [rut, setRut] = useState('');
  const [folio, setFolio] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [budget, setBudget] = useState<GetBudgetByFolioQuery['getBudgetByFolio']>(null);
  const [openImageDisplay, setOpenImageDisplay] = useState<boolean>(false);
  const [isRutValid, setIsRutValid] = useState(false);
  const [isFolioValid, setIsFolioValid] = useState(false);
  const [actualImage, setActualImage] = useState<string>('');
  const [step, setStep] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [openImageDialog, setOpenImageDialog] = useState<boolean>(false);
  const [mobile, setMobile] = useState<boolean>(window.innerWidth < 850);
  const [executeGetBudget] = useGetBudgetByFolioLazyQuery({
    onCompleted: (data) => {
      setIsLoading(false);
      setBudget(data.getBudgetByFolio);
      let total = 0;
      data.getBudgetByFolio.items.forEach((item) => {
        total += item.price;
      });
      data.getBudgetByFolio.replacements.forEach((replacement) => {
        total += replacement.price && replacement.quantity ? replacement.price * replacement.quantity : 0;
      });
      setTotalPrice(total);
      setStep(1);
    },
    onError: (err) => {
      setIsLoading(false);
      setAlertContent(err.message);
      setAlert(true);
    },
  });

  const resizeView = () => {
    if (window.innerWidth < 870) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', resizeView);
  }, []);

  useEffect(() => {
    if (folio.length > 0) {
      setIsFolioValid(true);
    } else {
      setIsFolioValid(false);
    }
  }, [folio]);
  useEffect(() => {
    if (rut.length >= 11 && validateRut(rut)) {
      setIsRutValid(true);
    } else {
      setIsRutValid(false);
    }
  }, [rut]);
  function submit() {
    if (isFolioValid && isRutValid) {
      setIsLoading(true);
      executeGetBudget({
        variables: {
          rut,
          folio,
        },
      });
    }
  }
  if (step === 0) {
    return (
      <div className={classes.loginContainer}>
        {isLoading && <Loader />}
        {alert ? <Alert className={classes.alert} severity="error">{alertContent}</Alert> : <></> }
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.folioGrid}
        >
          <Typography className={classes.formTitle}> Ver mi presupuesto </Typography>
          <div>
            <Box className={classes.inputsBox}>
              <TextField
                label="RUT"
                helperText={!isRutValid && rut ? 'Ingrese un RUT válido' : '\u00a0'}
                FormHelperTextProps={{ className: classes.helperText }}
                onChange={(e) => setRut(rutFormat(e.target.value))}
                value={rut}
                className={classes.formInput}
              />
              <TextField
                label="Folio"
                helperText={!isFolioValid && folio.length > 0 ? 'Ingrese folio válido' : '\u00a0'}
                FormHelperTextProps={{ className: classes.helperText }}
                onChange={(e) => setFolio(e.target.value)}
                value={folio}
                className={classes.formInput}
              />
            </Box>
          </div>
          <Button
            variant="contained"
            onClick={() => submit()}
            className={(isRutValid && isFolioValid)
              ? classes.pageButtonActive
              : classes.pageButton}
          >
            Ingresar
          </Button>
        </Grid>
      </div>
    );
  }
  if (mobile) {
    return (
      <div className={budgetClasses.budgetContainer}>
        {openImageDisplay && (
          <div className={budgetClasses.imageDiv}>
            <div className={budgetClasses.imageCloseIconContainer}>
              <CloseIcon
                className={budgetClasses.closeIcon}
                onClick={() => setOpenImageDisplay(false)}
              />
            </div>
            <img
              alt=""
              src={actualImage}
              className={budgetClasses.imageDisplay}
            />
          </div>
        )}
        <h1 className={budgetClasses.title}>Presupuesto</h1>
        <h1 className={budgetClasses.title}>{`Estado: ${budget.status}`}</h1>
        <h1 className={budgetClasses.title}>{`Total: $${totalPrice.toLocaleString('de-DE')}`}</h1>
        {budget.status === 'PENDIENTE' && (
          <div className={budgetClasses.budgetContainer}>
            <h1 className={budgetClasses.title}>¿Confirmar?</h1>
            <div className={budgetClasses.buttonArea}>
              <AcceptDialog
                id={parseInt(budget.id, 10)}
                folio={folio}
                rut={rut}
              />
              <RejectDialog
                id={parseInt(budget.id, 10)}
                folio={folio}
                rut={rut}
              />
            </div>
          </div>
        )}
        <Accordion className={budgetClasses.accordion} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={budgetClasses.accordionContainer}>
              <AccountCircleIcon className={budgetClasses.accordionIcon} />
              <p className={budgetClasses.accordionTitle}>Datos cliente</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={budgetClasses.accordionDetail}>
            <div className={budgetClasses.statsContainer}>
              <div className={budgetClasses.statContainer}>
                <AccountCircleIcon className={budgetClasses.transparentIcon} />
                <p className={budgetClasses.statName}>
                  Nombre:
                </p>
                <p className={budgetClasses.statValue}>
                  {` ${budget.client.name}`}
                </p>
              </div>
              <div className={budgetClasses.statContainer}>
                <AccountCircleIcon className={budgetClasses.transparentIcon} />
                <p className={budgetClasses.statName}>
                  Apellido:
                </p>
                <p className={budgetClasses.statValue}>
                  {` ${budget.client.lastname}`}
                </p>
              </div>
              <div className={budgetClasses.statContainer}>
                <AccountCircleIcon className={budgetClasses.transparentIcon} />
                <p className={budgetClasses.statName}>
                  Rut:
                </p>
                <p className={budgetClasses.statValue}>
                  {` ${budget.client.rut}`}
                </p>
              </div>
              <div className={budgetClasses.statContainer}>
                <AccountCircleIcon className={budgetClasses.transparentIcon} />
                <p className={budgetClasses.statName}>
                  Email:
                </p>
                <p className={budgetClasses.statValue}>
                  {` ${budget.client.email}`}
                </p>
              </div>
              <div className={budgetClasses.statContainer}>
                <AccountCircleIcon className={budgetClasses.transparentIcon} />
                <p className={budgetClasses.statName}>
                  Numero:
                </p>
                <p className={budgetClasses.statValue}>
                  {` ${budget.client.phone}`}
                </p>
              </div>
              {(budget.insurance) && (
                <div className={budgetClasses.statContainer}>
                  <AccountCircleIcon className={budgetClasses.transparentIcon} />
                  <p className={budgetClasses.statName}>
                    Seguro:
                  </p>
                  <p className={budgetClasses.statValue}>
                    {` ${budget.insurance.name}`}
                  </p>
                </div>
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={budgetClasses.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={budgetClasses.accordionContainer}>
              <DirectionsCarIcon className={budgetClasses.accordionIcon} />
              <p className={budgetClasses.accordionTitle}>Datos auto</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={budgetClasses.statsContainer}>
              <div className={budgetClasses.statContainer}>
                <AccountCircleIcon className={budgetClasses.transparentIcon} />
                <p className={budgetClasses.statName}>
                  Marca:
                </p>
                <p className={budgetClasses.statValue}>
                  {` ${budget.car.brand}`}
                </p>
              </div>
              <div className={budgetClasses.statContainer}>
                <AccountCircleIcon className={budgetClasses.transparentIcon} />
                <p className={budgetClasses.statName}>
                  Modelo:
                </p>
                <p className={budgetClasses.statValue}>
                  {` ${budget.car.model}`}
                </p>
              </div>
              <div className={budgetClasses.statContainer}>
                <AccountCircleIcon className={budgetClasses.transparentIcon} />
                <p className={budgetClasses.statName}>
                  Año:
                </p>
                <p className={budgetClasses.statValue}>
                  {` ${budget.car.year}`}
                </p>
              </div>
              <div className={budgetClasses.statContainer}>
                <AccountCircleIcon className={budgetClasses.transparentIcon} />
                <p className={budgetClasses.statName}>
                  Patente:
                </p>
                <p className={budgetClasses.statValue}>
                  {` ${budget.car.plate}`}
                </p>
              </div>
              <div className={budgetClasses.statContainer}>
                <AccountCircleIcon className={budgetClasses.transparentIcon} />
                <p className={budgetClasses.statName}>
                  Chasis:
                </p>
                <p className={budgetClasses.statValue}>
                  {` ${budget.car.chasis}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={budgetClasses.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={budgetClasses.accordionContainer}>
              <FormatPaintIcon className={budgetClasses.accordionIcon} />
              <p className={budgetClasses.accordionTitle}>DYP</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={budgetClasses.accordionDetail}>
            <div className={budgetClasses.itemsContainer}>
              <div className={budgetClasses.addButtonContainer} />
              {budget.items.map((item) => (
                <div key={item.id} className={budgetClasses.itemContainer}>
                  <div className={budgetClasses.statContainer}>
                    <p className={budgetClasses.statName}>Pieza:</p>
                    <p className={budgetClasses.statValue}>{` ${item.piece.name}`}</p>
                  </div>
                  <div className={budgetClasses.statContainer}>
                    <p className={budgetClasses.statName}>Concepto:</p>
                    <p className={budgetClasses.statValue}>{` ${item.concept.name}`}</p>
                  </div>
                  <div className={budgetClasses.statContainer}>
                    <p className={budgetClasses.statName}>Precio:</p>
                    <p className={budgetClasses.statValue}>{` $${item.price.toLocaleString('de-DE')}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={budgetClasses.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={budgetClasses.accordionContainer}>
              <BuildIcon className={budgetClasses.accordionIcon} />
              <p className={budgetClasses.accordionTitle}>Repuestos</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={budgetClasses.itemsContainer}>
              {budget.replacements.map((replacement) => (
                <div key={replacement.id} className={budgetClasses.itemContainer}>
                  <div className={budgetClasses.statContainer}>
                    <p className={budgetClasses.statName}>Descripción:</p>
                    <p className={budgetClasses.statValue}>{` ${replacement.piece.name}`}</p>
                  </div>
                  <div className={budgetClasses.statContainer}>
                    <p className={budgetClasses.statName}>Cantidad:</p>
                    <p className={budgetClasses.statValue}>{` ${replacement.quantity}`}</p>
                  </div>
                  <div className={budgetClasses.statContainer}>
                    <p className={budgetClasses.statName}>Precio:</p>
                    <p className={budgetClasses.statValue}>{` $${replacement.price.toLocaleString('de-DE')}`}</p>
                  </div>
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={budgetClasses.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={budgetClasses.accordionContainer}>
              <PermMediaIcon className={budgetClasses.accordionIcon} />
              <p className={budgetClasses.accordionTitle}>Imágenes taller</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={budgetClasses.accordionDetail}>
            <div className={budgetClasses.imagesContainer}>
              {budget.images.map((image) => (
                <div
                  key={image.id}
                  className={budgetClasses.budgetImageContainer}
                >
                  <img
                    className={budgetClasses.budgetImage}
                    alt=""
                    src={image.link}
                    onClick={() => {
                      setActualImage(image.link);
                      setOpenImageDisplay(true);
                    }}
                  />
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
  return (
    <div className={budgetClasses.desktopContainer}>
      <Fab
        color="primary"
        className={budgetClasses.imageButton}
        onClick={() => setOpenImageDialog(true)}
      >
        <PermMediaIcon />
      </Fab>
      <ClientImageDialog
        open={openImageDialog}
        onClose={() => {
          setOpenImageDialog(false);
        }}
        setOpen={setOpenImageDialog}
        images={budget.images}
        setActualImage={setActualImage}
        setOpenDisplay={setOpenImageDisplay}
      />
      {openImageDisplay && (
        <div className={budgetClasses.imageDiv}>
          <div className={budgetClasses.imageCloseIconContainer}>
            <CloseIcon
              className={budgetClasses.closeIcon}
              onClick={() => setOpenImageDisplay(false)}
            />
          </div>
          <img
            alt=""
            src={actualImage}
            className={budgetClasses.imageDisplay}
          />
        </div>
      )}
      <h1 className={budgetClasses.personalTitle}>{`Estado: ${budget.status}`}</h1>
      <h1 className={budgetClasses.personalTitle}>{`Total: $${totalPrice.toLocaleString('de-DE')}`}</h1>
      {budget.status === 'PENDIENTE' && (
        <div>
          <h1 className={budgetClasses.personalTitle}>¿Confirmar Presupuesto?</h1>
          <div className={budgetClasses.buttonArea}>
            <AcceptDialog
              id={parseInt(budget.id, 10)}
              folio={folio}
              rut={rut}
            />
            <RejectDialog
              id={parseInt(budget.id, 10)}
              folio={folio}
              rut={rut}
            />
          </div>
        </div>
      )}
      <div className={budgetClasses.generalDataContainer}>
        <div className={budgetClasses.personalDataContainer}>
          <h1 className={budgetClasses.personalTitle}>Cliente</h1>
          <div className={budgetClasses.desktopStatContainer}>
            <p className={budgetClasses.desktopStatName}>Nombre:</p>
            <p className={budgetClasses.desktopStatValue}>{` ${budget.client.name}`}</p>
          </div>
          <div className={budgetClasses.desktopStatContainer}>
            <p className={budgetClasses.desktopStatName}>Apellidos:</p>
            <p className={budgetClasses.desktopStatValue}>{` ${budget.client.lastname}`}</p>
          </div>
          <div className={budgetClasses.desktopStatContainer}>
            <p className={budgetClasses.desktopStatName}>RUT:</p>
            <p className={budgetClasses.desktopStatValue}>{` ${budget.client.rut}`}</p>
          </div>
          <div className={budgetClasses.desktopStatContainer}>
            <p className={budgetClasses.desktopStatName}>Email:</p>
            <p className={budgetClasses.desktopStatValue}>{` ${budget.client.email}`}</p>
          </div>
          <div className={budgetClasses.desktopStatContainer}>
            <p className={budgetClasses.desktopStatName}>Número:</p>
            <p className={budgetClasses.desktopStatValue}>{` ${budget.client.phone}`}</p>
          </div>
        </div>
        <div className={budgetClasses.dataSeparator} />
        <div className={budgetClasses.personalDataContainer}>
          <h1 className={budgetClasses.personalTitle}>Auto</h1>
          <div className={budgetClasses.desktopStatContainer}>
            <p className={budgetClasses.desktopStatName}>Marca:</p>
            <p className={budgetClasses.desktopStatValue}>{` ${budget.car.brand}`}</p>
          </div>
          <div className={budgetClasses.desktopStatContainer}>
            <p className={budgetClasses.desktopStatName}>Modelo:</p>
            <p className={budgetClasses.desktopStatValue}>{` ${budget.car.model}`}</p>
          </div>
          <div className={budgetClasses.desktopStatContainer}>
            <p className={budgetClasses.desktopStatName}>Año:</p>
            <p className={budgetClasses.desktopStatValue}>{` ${budget.car.year}`}</p>
          </div>
          <div className={budgetClasses.desktopStatContainer}>
            <p className={budgetClasses.desktopStatName}>Patente:</p>
            <p className={budgetClasses.desktopStatValue}>{` ${budget.car.plate}`}</p>
          </div>
          <div className={budgetClasses.desktopStatContainer}>
            <p className={budgetClasses.desktopStatName}>Chasis:</p>
            <p className={budgetClasses.desktopStatValue}>{` ${budget.car.chasis}`}</p>
          </div>
        </div>
      </div>
      <div className={budgetClasses.dypContainer}>
        <div className={budgetClasses.titleContainer}>
          <h1 className={budgetClasses.personalTitle}>Desabolladura y Pintura</h1>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={budgetClasses.tableHeader} align="left">Pieza</TableCell>
                <TableCell className={budgetClasses.tableHeader} align="center">Concepto</TableCell>
                <TableCell className={budgetClasses.tableHeader} align="center">Precio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {budget.items.map((item) => (
                <TableRow key={item.id}>
                  <TableCell align="left">{item.piece.name}</TableCell>
                  <TableCell align="center">{item.concept.name}</TableCell>
                  <TableCell align="center">{`$${item.price.toLocaleString('de-DE')}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className={budgetClasses.dypContainer}>
        <div className={budgetClasses.titleContainer}>
          <h1 className={budgetClasses.personalTitle}>Repuestos</h1>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={budgetClasses.tableHeader} align="left">Pieza</TableCell>
                <TableCell className={budgetClasses.tableHeader} align="center">Cantidad</TableCell>
                <TableCell className={budgetClasses.tableHeader} align="center">Precio</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {budget.replacements.map((replacement) => (
                <TableRow key={replacement.id}>
                  <TableCell align="left">{replacement.piece.name}</TableCell>
                  <TableCell align="center">{replacement.quantity}</TableCell>
                  <TableCell align="center">{`$${replacement.price.toLocaleString('de-DE')}`}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}
export default BudgetClient;
