import { ReactElement } from 'react';
import { Box, TextField } from '@material-ui/core';
import moment from 'moment';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { useStyles } from '../../styles/workOrderFilter_style';
import { ReceptionFilterInterface } from '../../interfaces/components.interfaces';

function ReceptionFilter(props: ReceptionFilterInterface): ReactElement {
  const {
    name,
    setName,
    plate,
    setPlate,
    dateIni,
    setDateIni,
    dateEnd,
    setDateEnd,
  } = props;
  const classes = useStyles();

  function dateFormat(dateTime: string) {
    const date: Date = new Date(dateTime);
    const year: number = date.getUTCFullYear();
    const month:number = (date.getUTCMonth());
    const day: number = date.getUTCDate();
    return moment(new Date(year, month, day)).format('YYYY-MM-DD');
  }

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.buttonsContainer}
    >
      <FormControl className={classes.formControl}>
        <InputLabel>Cliente</InputLabel>
        <Input
          id="name"
          value={name}
          aria-describedby="component-helper-text"
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>Patente</InputLabel>
        <Input
          id="plate"
          value={plate}
          aria-describedby="component-helper-text"
          onChange={(e) => setPlate(e.target.value)}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label="Desde"
          id="dateIni"
          type="date"
          value={dateIni}
          onChange={(e) => setDateIni(`${dateFormat(e.target.value)}`)}
        />
      </FormControl>
      <FormControl className={classes.formControl}>
        <TextField
          label="Hasta"
          id="dateEnd"
          type="date"
          value={dateEnd}
          onChange={(e) => setDateEnd(`${dateFormat(e.target.value)}`)}
        />
      </FormControl>
    </Box>

  );
}
export default ReceptionFilter;
