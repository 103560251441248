import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({

  root: {
    width: '100vw',
    [theme.breakpoints.up('md')]: {
      width: '60vw',
    },
  },
  steps: {
    paddingLeft: '0vw',
    paddingRight: '0vw',
    overflow: 'auto',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  secondBox: {
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  table: {
    width: '80vw',
    padding: theme.spacing(3),
    textAlign: 'center',
    marginBottom: '5vh',
    [theme.breakpoints.up('md')]: {
      width: '20vw',
      maxWidth: '45vw',
      marginBottom: '0vh',
      alignItems: 'center',
    },
  },
  text: {
    textAlign: 'center',
  },
  stepBox: {
    margin: '3vw',
  },
  typography: {
    marginTop: '5vh',
    padding: '20px',
    color: 'black',
    fontSize: 36,
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
      marginBottom: '5vh',
      fontSize: 32,
    },
  },
}));
export default useStyles;
