import {
  ReactElement,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { ApolloError } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import WarningIcon from '@material-ui/icons/Warning';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  withStyles,
  WithStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
} from '@material-ui/core';
import { PersonData, WarningData } from '../../interfaces/components.interfaces';
import {
  ServicesQuery,
  useServicesLazyQuery,
  useGetReservedBlocksLazyQuery,
  useGetBlockedBlocksLazyQuery,
  useCreateBlockedHourMutation,
  useDeleteBlockedHourMutation,
  GetBlockedBlocksDocument,
} from '../../types';
import { useStyles } from '../../styles/block_style';
import { styles } from '../../styles/block_style2';
import { reducer } from '../../store/reducer';

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {
    children,
    classes,
    onClose,
    ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function BlockDays() : ReactElement {
  const [allArea, setAllArea] = useState<ServicesQuery['services']>([]);
  const [open, setOpen] = useState(false);
  const [area, setArea] = useState(-1);
  const [day, setDay] = useState('');
  const [hour, setHour] = useState('');
  const [disponibility, setDisponibility] = useState<string[]>([]);
  const [position, setPosition] = useState(0);
  const [blockedHours, setBlockedHours] = useState<string[]>([]);
  const [ready, setReady] = useState(0);
  const [checkedAll, setCheckedAll] = useState(false);
  const [clickeado, setClickeado] = useState<string[]>([]);
  const [action, setAction] = useState(0);
  const [openPaper, setOpenPaper] = useState(false);
  const [phones, setPhones] = useState<PersonData[]>([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [severityError, setSeverityError] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [answerWarning, setAnswerWarning] = useState(false);
  const [allWarnings, setAllWarning] = useState<WarningData[]>([]);

  const handleClickOpenPaper = (key: string) => {
    setOpenPaper(true);
    const dispoKeys = Object.values(disponibility);
    for (let i = 0; i < Object.values(disponibility).length; i += 1) {
      if (Object.keys(disponibility)[i] === key) {
        const lastList: PersonData[] = [];
        const value = Object.values(dispoKeys[i])[1];
        for (let j = 0; j < value.length; j += 1) {
          const personal: PersonData = {
            name: Object.values(value[j])[0],
            lastname: Object.values(value[j])[1],
            phone: Object.values(value[j])[2],
            email: Object.values(value[j])[3],
            plate: Object.values(value[j])[4],
            brand: Object.values(value[j])[5],
            service: Object.values(value[j])[6],
          };
          lastList.push(personal);
        }
        setPhones(lastList);
        break;
      }
    }
  };
  const handleClosePaper = () => {
    setOpenPaper(false);
  };

  const handleClickOpenWarning = () => {
    setOpenWarning(true);
  };

  const handleCloseWarning = () => {
    setOpenWarning(false);
  };
  const classes = useStyles();
  const dateObj = new Date();
  const month = dateObj.getUTCMonth() + 1;
  const today = dateObj.getUTCDate();
  const year = dateObj.getUTCFullYear();
  let newMonth = month.toString();
  let newDay = today.toString();
  if (month < 10) {
    newMonth = `0${month}`;
  }
  if (today < 10) {
    newDay = `0${today}`;
  }
  const newdate = `${year}-${newMonth}-${newDay}`;

  const [executeAvailable] = useGetReservedBlocksLazyQuery({
    variables: {
      serviceId: +area,
      date: day,
    },
    onCompleted: (data) => {
      setDisponibility(data.getReservedBlocks);
    },
  });
  const [executeAvailableGT] = useGetReservedBlocksLazyQuery({
    variables: {
      date: day,
    },
    onCompleted: (data) => {
      setDisponibility(data.getReservedBlocks);
    },
  });

  const [executeBlocked] = useGetBlockedBlocksLazyQuery({
    variables: {
      date: day,
      serviceId: +area,
    },
    onCompleted: (data) => {
      setBlockedHours(data.getBlockedBlocks);
    },
  });
  const [executeBlockedGT] = useGetBlockedBlocksLazyQuery({
    variables: {
      date: day,
    },
    onCompleted: (data) => {
      setBlockedHours(data.getBlockedBlocks);
    },
  });
  const [executeDelete] = useDeleteBlockedHourMutation({
    variables: {
      date: `${day}T${hour}.000Z`,
      serviceId: +area,
    },
    onCompleted: () => {
      setPosition(position + 1);
    },
    refetchQueries: [
      { query: GetBlockedBlocksDocument, variables: { date: day, serviceId: +area } },
    ],
    awaitRefetchQueries: true,
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlertContent('No se pudo bloquear la hora. Intente más tarde.');
        setAlert(true);
        setSeverityError(false);
      }
    },
  });

  const [executeServices] = useServicesLazyQuery({
    onCompleted: (data) => setAllArea(data.services),
  });
  useEffect(() => {
    executeServices();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setArea(event.target.value as number);
    setDay('');
    setClickeado([]);
    setDisponibility([]);
    setCheckedAll(false);
    setBlockedHours([]);
  };

  function changeVal(text: string) {
    setDay(text);
    setClickeado([]);
    setDisponibility([]);
    setCheckedAll(false);
    setBlockedHours([]);
    if (+area < 5) {
      executeBlocked();
      executeAvailable();
    } else {
      executeBlockedGT();
      executeAvailableGT();
    }
  }
  useEffect(() => {
    const newList:string[] = [];
    for (let i = 0; i < Object.keys(blockedHours).length; i += 1) {
      if (Object.values(blockedHours)[i] === '1') {
        newList.push(Object.keys(blockedHours)[i]);
      }
    }
    setClickeado(newList);
  }, [blockedHours]);

  function checkAll(e: React.ChangeEvent<HTMLInputElement>) {
    const newList:string[] = [];
    if (e.target.checked) {
      for (let i = 0; i < Object.keys(disponibility).length; i += 1) {
        newList.push(Object.keys(disponibility)[i]);
      }
    }
    setClickeado(newList);
    setCheckedAll(e.target.checked);
  }
  function addValue(e:ChangeEvent<HTMLInputElement>, text: string) {
    if (e.target.checked) {
      setClickeado([...clickeado, text]);
    } else {
      const newTodos = clickeado.filter((_, index) => clickeado[index] !== text);
      setClickeado(newTodos);
    }
  }
  const dispoKeys = Object.values(disponibility);
  const variable:string[] = [];
  for (let i = 0; i < Object.values(disponibility).length; i += 1) {
    if (Object.values(blockedHours)[i] === '1') {
      variable.push('Bloqueado');
    } else {
      variable.push('No reservado');
    }
  }
  const [executeBlock] = useCreateBlockedHourMutation({
    variables: {
      date: `${day}T${hour}.000Z`,
      serviceId: +area,
    },
    onCompleted: () => {
      setPosition(position + 1);
    },
    refetchQueries: [
      { query: GetBlockedBlocksDocument, variables: { date: day, serviceId: +area } },
    ],
    awaitRefetchQueries: true,
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlertContent('No se pudo bloquear la hora. Intente más tarde.');
        setAlert(true);
        setSeverityError(false);
      }
    },
  });
  useEffect(() => {
    if (day !== '' && area !== -1 && action !== 0) {
      if (action === 1) {
        executeBlock();
      } else {
        executeDelete();
      }
    }
  }, [hour]);
  useEffect(() => {
    if (action !== 0) {
      const newList:number[] = [];
      for (let i = 0; i < Object.values(disponibility).length; i += 1) {
        if (Object.values(blockedHours)[i] === '0' && clickeado.includes(Object.keys(blockedHours)[i])) {
          newList.push(i);
        }
        if (Object.values(blockedHours)[i] === '1' && !clickeado.includes(Object.keys(blockedHours)[i])) {
          newList.push(i);
        }
      }
      const value:number = newList[position];
      if (position < newList.length) {
        if (Object.values(blockedHours)[value] === '1'
        && !clickeado.includes(Object.keys(blockedHours)[value])) {
          setAction(2);
          if (Object.keys(blockedHours)[value].length === 7) {
            setHour(`0${Object.keys(blockedHours)[value]}`);
          } else {
            setHour(Object.keys(blockedHours)[value]);
          }
        }
        if (Object.values(blockedHours)[value] === '0'
        && clickeado.includes(Object.keys(blockedHours)[value])) {
          setAction(1);
          if (Object.keys(blockedHours)[value].length === 7) {
            setHour(`0${Object.keys(blockedHours)[value]}`);
          } else {
            setHour(Object.keys(blockedHours)[value]);
          }
        }
      } else {
        setArea((+area) + 10);
      }
    }
  }, [position]);
  useEffect(() => {
    if (area > 5 && hour !== '') {
      setReady(1);
      setCheckedAll(false);
    }
  }, [area]);
  useEffect(() => {
    if (ready === 1) {
      setAlertContent('Se ha actualizado correctamente');
      setAlert(true);
      setSeverityError(true);
      setReady(0);
      setAction(0);
      setPosition(0);
      setArea((+area) - 10);
      reducer({ type: 'LOADING' });
      setAnswerWarning(false);
    }
  }, [ready]);
  useEffect(() => {
    if (day === '') {
      setHour('');
    }
  }, [day]);

  function sendAlert(alertType: boolean): ReactElement {
    if (alertType) {
      return (
        <Alert className={classes.alert} severity="success">
          {alertContent}
        </Alert>
      );
    }
    return (
      <Alert className={classes.alert} severity="error">
        {alertContent}
      </Alert>
    );
  }
  useEffect(() => {
    handleCloseWarning();
    if (answerWarning) {
      reducer({ type: 'LOADING' });
      if (+area !== 5) {
        for (let i = 0; i < Object.values(disponibility).length; i += 1) {
          if (Object.values(blockedHours)[i] === '1' && !clickeado.includes(Object.keys(blockedHours)[i])) {
            setAction(2);
            if (Object.keys(blockedHours)[i].length === 7) {
              setHour(`0${Object.keys(blockedHours)[i]}`);
            } else {
              setHour(Object.keys(blockedHours)[i]);
            }
            return;
          }
          if (Object.values(blockedHours)[i] === '0' && clickeado.includes(Object.keys(blockedHours)[i])) {
            setAction(1);
            if (Object.keys(blockedHours)[i].length === 7) {
              setHour(`0${Object.keys(blockedHours)[i]}`);
            } else {
              setHour(Object.keys(blockedHours)[i]);
            }
            return;
          }
        }
        setAlertContent('No se han realizado acciones');
        setAlert(true);
        setSeverityError(false);
        sendAlert(severityError);
      }
    }
  }, [answerWarning]);

  function submit() {
    const newList: WarningData[] = [];
    for (let i = 0; i < Object.values(disponibility).length; i += 1) {
      if (Object.values(blockedHours)[i] === '0' && clickeado.includes(Object.keys(blockedHours)[i])
      && Object.values(Object.values(disponibility)[i])[1].length > 0) {
        const Warning: WarningData = {
          hour: Object.keys(disponibility)[i],
          lenght: Object.values(Object.values(disponibility)[i])[1].length,
        };
        newList.push(Warning);
        setAllWarning(newList);
      }
    }
    if (newList.length > 0) {
      handleClickOpenWarning();
    } else {
      for (let i = 0; i < Object.values(disponibility).length; i += 1) {
        if (Object.values(blockedHours)[i] === '1' && !clickeado.includes(Object.keys(blockedHours)[i])) {
          setAction(2);
          if (Object.keys(blockedHours)[i].length === 7) {
            setHour(`0${Object.keys(blockedHours)[i]}`);
          } else {
            setHour(Object.keys(blockedHours)[i]);
          }
          reducer({ type: 'LOADING' });
          return;
        }
        if (Object.values(blockedHours)[i] === '0' && clickeado.includes(Object.keys(blockedHours)[i])) {
          setAction(1);
          if (Object.keys(blockedHours)[i].length === 7) {
            setHour(`0${Object.keys(blockedHours)[i]}`);
          } else {
            setHour(Object.keys(blockedHours)[i]);
          }
          reducer({ type: 'LOADING' });
          return;
        }
      }
      setAlertContent('No se han realizado acciones');
      setAlert(true);
      setSeverityError(false);
      sendAlert(severityError);
    }
  }

  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {alert ? sendAlert(severityError) : <></>}
      <Typography variant="h2" className={classes.typography}>
        Bloquear Horas
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.secondBox}
      >
        <form>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <FormControl className={classes.formInput}>
              <InputLabel id="demo-controlled-open-select-label">
                Selecciona área
              </InputLabel>
              <Select
                labelId="demo-controlled-open-select-label"
                id="demo-controlled-open-select"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={area}
                onChange={handleChange}
              >
                {allArea.map((areas: ServicesQuery['services'][0]) => (
                  <MenuItem
                    key={areas.id}
                    value={areas.id}
                  >
                    {areas.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              id="date"
              label="Seleccione fecha"
              type="date"
              value={day}
              onChange={(e) => changeVal(e.target.value)}
              className={classes.formInput}
              inputProps={{ min: newdate }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </form>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.secondBoxs}
        >
          <TableContainer component={Paper} className={classes.table}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.firstColumn}>Horas</TableCell>
                  <TableCell align="right" className={classes.firstColumn}>Estado</TableCell>
                  <TableCell align="right" className={classes.secondColumn}>
                    Bloquear todos
                    <Checkbox
                      color="primary"
                      onChange={(e) => checkAll(e)}
                      checked={checkedAll}
                      inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                  </TableCell>
                  <TableCell align="right" className={classes.thirdColumn}>Contactos</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.keys(disponibility).map((key: string, index: number) => (
                  <TableRow key={key}>
                    <TableCell component="th" scope="row">
                      {key}
                    </TableCell>
                    <TableCell align="right">
                      {Object.values(dispoKeys[index])[0] === '1' ? 'Reservado' : variable[index]}
                    </TableCell>
                    <TableCell align="right">
                      <Checkbox
                        color="primary"
                        checked={clickeado.includes(Object.keys(disponibility)[index])}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={(e) => addValue(e, Object.keys(disponibility)[index])}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <div>
                        <Button
                          size="small"
                          color="primary"
                          onClick={Object.values(dispoKeys[index])[0] === '1'
                            ? () => handleClickOpenPaper(key) : null}
                          id={key}
                          className={Object.values(dispoKeys[index])[0] === '1'
                            ? classes.pageButtonActive : classes.pageButton}
                        >
                          Ver
                        </Button>
                        <Dialog
                          className={classes.dialog}
                          onClose={handleClosePaper}
                          aria-labelledby="customized-dialog-title"
                          open={openPaper}
                        >
                          <DialogTitle
                            id="customized-dialog-title"
                            onClose={handleClosePaper}
                          >
                            Usuarios con reserva
                          </DialogTitle>
                          <DialogContent dividers>
                            <List>
                              {phones.length > 0 ? phones.map((val: PersonData, pos: number) => (
                                <ListItem key={pos.toString()}>
                                  {`${pos + 1}. ${val.name} ${val.lastname}: ${val.phone} (${val.brand}, ${val.plate})`}
                                </ListItem>
                              )) : 'No hay ningun usuario con reserva'}
                            </List>
                          </DialogContent>
                        </Dialog>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Box>
        <Button
          className={day !== '' && area >= 0 ? classes.pageButtonActive : classes.pageButton}
          onClick={() => submit()}
        >
          Actualizar cambios
        </Button>
      </Box>
      <div>
        <Dialog
          open={openWarning}
          onClose={handleCloseWarning}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" onClose={handleCloseWarning}>
            <WarningIcon fontSize="large" />
          </DialogTitle>
          <DialogTitle id="alert-dialog-title" onClose={handleCloseWarning}>
            Existen reservas
          </DialogTitle>
          <DialogContent>
            {allWarnings.map((info: WarningData, index: number) => (
              <DialogContentText id="alert-dialog-description" key={index.toString()}>
                {`${info.hour}
                : hay ${info.lenght} reserva(s)`}
              </DialogContentText>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseWarning} color="primary">
              Cancelar
            </Button>
            <Button onClick={() => setAnswerWarning(true)} color="primary" autoFocus>
              Actualizar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
}
export default BlockDays;
