import { ReactElement, useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { Button } from '@material-ui/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useUsersLazyQuery, UsersQuery } from '../../types';
import { UsersInterface } from '../../interfaces/components.interfaces';
import useButtonstyles from '../../styles/exportButton_style';
import { reducer } from '../../store/reducer';

function formatingData(data: UsersQuery['users']) {
  const rows: UsersInterface[] = [];
  data.forEach((element) => {
    const user: UsersInterface = {
      Id: +element.id,
      RUT: element.rut,
      Nombre: element.name,
      Rol: element.role,
      Mail: element.email,
    };
    rows.push(user);
  });
  return rows;
}

function ExportUsers() : ReactElement {
  const classes = useButtonstyles();
  const fileName = 'Usuarios';
  const [rowData, setRowData] = useState([]);
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = (dataCSV: UsersInterface[], NameFile: string) => {
    const ws = XLSX.utils.json_to_sheet(dataCSV);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, NameFile + fileExtension);
  };

  const [executeUsers] = useUsersLazyQuery({
    onCompleted: (data) => {
      setRowData(formatingData(data.users));
      reducer({ type: 'LOADING' });
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  useEffect(() => {
    if (rowData.length > 0) {
      exportToCSV(rowData, fileName);
    }
  }, [rowData]);

  return (
    <Button
      onClick={() => {
        reducer({ type: 'LOADING' });
        executeUsers();
      }}
      className={classes.pageButtonActive}
    >
      Datos de los usuarios
    </Button>
  );
}
export default ExportUsers;
