import {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { ApolloError } from '@apollo/client';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EventIcon from '@material-ui/icons/Event';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import DescriptionIcon from '@material-ui/icons/Description';
import { useParams } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import Loader from '../../components/loader/loader';
import useLoginStyles from '../../styles/folio_style';
import { useGetEntryReservationsByIdShowLazyQuery, GetEntryReservationsByIdShowQuery } from '../../types';
import useBudgetShowStyles from '../../styles/budget_show';

const initialClient: GetEntryReservationsByIdShowQuery['getEntryReservationById']['budget']['client'] = {
  name: '',
  lastname: '',
  phone: '',
  rut: '',
  email: '',
};

const initialCar: GetEntryReservationsByIdShowQuery['getEntryReservationById']['budget']['car'] = {
  brand: '',
  model: '',
  year: 0,
  plate: '',
  chasis: '',
  color: '',
};

function dateFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  const year: number = date.getFullYear();
  const month: number = (date.getMonth());
  const day: number = date.getDate();
  return moment(new Date(year, month, day)).format('DD/MM/YYYY');
}

function timeFormat(dateTime: string) {
  const date: Date = new Date(dateTime);
  let hour: string = date.getUTCHours().toString();
  let min: string = date.getUTCMinutes().toString();
  if (min.length === 1) {
    min = `0${min}`;
  }
  if (hour.length === 1) {
    hour = `0${hour}`;
  }
  return `${hour}:${min}`;
}

function EntryReservationsShow(): ReactElement {
  const { id }: { id: string } = useParams();
  const classes = useBudgetShowStyles();
  const loginClasses = useLoginStyles();
  const [mobile, setMobile] = useState(false);
  const [alert, setAlert] = useState(false);
  const [budget, setBudget] = useState(0);
  const [client, setClient] = useState<
  GetEntryReservationsByIdShowQuery['getEntryReservationById']['budget']['client']>(initialClient);
  const [car, setCar] = useState<
  GetEntryReservationsByIdShowQuery['getEntryReservationById']['budget']['car']>(initialCar);
  const [date, setDate] = useState('');
  const [hour, setHour] = useState('');
  const [description, setDescription] = useState('Sin descripción.');
  function setFormatDescription(data: GetEntryReservationsByIdShowQuery['getEntryReservationById']['description']) {
    if (data) {
      setDescription(data);
    }
  }

  const formatingData = (data: GetEntryReservationsByIdShowQuery) => {
    setCar(data.getEntryReservationById.budget.car);
    setClient(data.getEntryReservationById.budget.client);
    setDate(dateFormat(data.getEntryReservationById.date));
    setHour(timeFormat(data.getEntryReservationById.date));
    setFormatDescription(data.getEntryReservationById.description);
    setBudget(+data.getEntryReservationById.budget.id);
  };

  const resizeView = () => {
    if (window.innerWidth < 870) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const [executeGetEntryReservation, { loading }] = useGetEntryReservationsByIdShowLazyQuery({
    variables: {
      id: +id,
    },
    onCompleted: (data) => {
      formatingData(data);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlert(true);
      }
    },
  });

  useEffect(() => {
    resizeView();
    executeGetEntryReservation();
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resizeView);
  }, []);
  const handleButton = () => {
    window.location.href = `/admin/agendar-recepcion/${budget}`;
  };

  if (mobile) {
    return (
      <div className={classes.budgetContainer}>
        {loading && <Loader />}
        {alert && <Alert className={loginClasses.alert} severity="error">Problema cargando la solicitud</Alert>}
        <h1 className={classes.title}>Recepción</h1>
        <Accordion className={classes.accordion} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <EventIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Hora Agendada</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Fecha:
                </p>
                <p className={classes.statValue}>
                  {` ${date}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Hora:
                </p>
                <p className={classes.statValue}>
                  {` ${hour} hrs.`}
                </p>
              </div>
              <div style={{
                alignItems: 'center',
                flexDirection: 'column',
                display: 'flex',
                justifyContent: 'center',
                marginInlineStart: '5vh',
              }}
              >
                <Button
                  className={classes.button}
                >
                  Reagendar
                </Button>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <AccountCircleIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Datos cliente</p>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetail}>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Nombre:
                </p>
                <p className={classes.statValue}>
                  {` ${client.name}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Apellido:
                </p>
                <p className={classes.statValue}>
                  {` ${client.lastname}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Rut:
                </p>
                <p className={classes.statValue}>
                  {` ${client.rut}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Email:
                </p>
                <p className={classes.statValue}>
                  {` ${client.email}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Numero:
                </p>
                <p className={classes.statValue}>
                  {` ${client.phone}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <DirectionsCarIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Datos auto</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Marca:
                </p>
                <p className={classes.statValue}>
                  {` ${car.brand}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Modelo:
                </p>
                <p className={classes.statValue}>
                  {` ${car.model}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Año:
                </p>
                <p className={classes.statValue}>
                  {` ${car.year}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Patente:
                </p>
                <p className={classes.statValue}>
                  {` ${car.plate}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Chasis:
                </p>
                <p className={classes.statValue}>
                  {` ${car.chasis}`}
                </p>
              </div>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statName}>
                  Color:
                </p>
                <p className={classes.statValue}>
                  {` ${car.color}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion className={classes.lastAccordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <div className={classes.accordionContainer}>
              <DescriptionIcon className={classes.accordionIcon} />
              <p className={classes.accordionTitle}>Descripción</p>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className={classes.statsContainer}>
              <div className={classes.statContainer}>
                <AccountCircleIcon className={classes.transparentIcon} />
                <p className={classes.statValue}>
                  {`${description}`}
                </p>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
  return (
    <div className={classes.receptionShow}>
      {loading && <Loader />}
      <div className={classes.personalDataContainer}>
        <h1 className={classes.personalTitle}>Recepción</h1>
        <h3 className={classes.personalTitle}>Hora Agendada</h3>
        <div className={classes.desktopStatContainer}>
          <p className={classes.desktopStatName}>Fecha:</p>
          <p className={classes.desktopStatValue}>{` ${date}`}</p>
        </div>
        <div className={classes.desktopStatContainer}>
          <p className={classes.desktopStatName}>Hora:</p>
          <p className={classes.desktopStatValue}>{` ${hour} hrs.`}</p>
        </div>
        <Button
          className={classes.button}
          onClick={handleButton}
        >
          Reagendar
        </Button>
      </div>
      <div className={classes.generalDataContainer}>
        <div className={classes.personalDataContainer}>
          <h1 className={classes.personalTitle}>Cliente</h1>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Nombre:</p>
            <p className={classes.desktopStatValue}>{` ${client.name}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Apellidos:</p>
            <p className={classes.desktopStatValue}>{` ${client.lastname}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>RUT:</p>
            <p className={classes.desktopStatValue}>{` ${client.rut}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Email:</p>
            <p className={classes.desktopStatValue}>{` ${client.email}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Número:</p>
            <p className={classes.desktopStatValue}>{` ${client.phone}`}</p>
          </div>
        </div>
        <div className={classes.dataSeparator} />
        <div className={classes.personalDataContainer}>
          <h1 className={classes.personalTitle}>Auto</h1>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Marca:</p>
            <p className={classes.desktopStatValue}>{` ${car.brand}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Modelo:</p>
            <p className={classes.desktopStatValue}>{` ${car.model}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Año:</p>
            <p className={classes.desktopStatValue}>{` ${car.year}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Patente:</p>
            <p className={classes.desktopStatValue}>{` ${car.plate}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Chasis:</p>
            <p className={classes.desktopStatValue}>{` ${car.chasis}`}</p>
          </div>
          <div className={classes.desktopStatContainer}>
            <p className={classes.desktopStatName}>Color:</p>
            <p className={classes.desktopStatValue}>{` ${car.color}`}</p>
          </div>
        </div>
      </div>
      <div className={classes.descriptionDataContainer}>
        <h1 className={classes.personalTitle}>Descripción</h1>
        <div className={classes.desktopStatContainer}>
          <p className={classes.desktopStatValue}>{`${description}`}</p>
        </div>
      </div>
    </div>
  );
}

export default EntryReservationsShow;
