import { ReactElement } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Typography from '@material-ui/core/Typography';
import useBudgetImageDialogStyle from '../../styles/budget_image_dialog';
import { ReservationImageDialogInterface } from '../../interfaces/components.interfaces';

function ReservationImageDialog(props: ReservationImageDialogInterface): ReactElement {
  const classes = useBudgetImageDialogStyle();
  const {
    open,
    setOpen,
    carnetImages,
    permImages,
    declImages,
    onClose,
    setOpenDelete,
    setActualImage,
    setOpenDisplay,
    inputRef,
    setField,
    existenciaDeclaracion,
  } = props;

  const handleAdd = () => {
    inputRef.current.click();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={false}
    >
      <div className={classes.closeBox}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={classes.titleContainer}>
        <Typography variant="h2" className={classes.root}>
          Imágenes
        </Typography>
      </div>
      <div className={classes.imagesReservationContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h2" className={classes.root}>
            Carnet
          </Typography>
        </div>
        {(carnetImages.length === 2) && (
        <div>
          <p>Este campo debe contener mínimo 2 fotos. Agrega otra foto para eliminar la actual. </p>
        </div>
        )}
        {carnetImages.map((image) => (
          <div
            className={classes.ReservationImageContainer}
            key={image}
          >
            {(carnetImages.length > 2) && (
            <DeleteIcon
              className={classes.deleteImageIcon}
              onClick={() => {
                setActualImage(image);
                setOpenDelete(true);
                setField('carnet');
              }}
            />
            )}
            <img
              alt=""
              src={image}
              className={classes.budgetImage}
              onClick={() => {
                setActualImage(image);
                setOpenDisplay(true);
              }}
            />
          </div>
        ))}
        <div className={classes.addIconContainer}>
          <AddCircleOutlineIcon
            className={classes.addReservationImageIcon}
            onClick={() => {
              setField('carnet');
              handleAdd();
            }}
          />
        </div>
      </div>
      <div className={classes.imagesReservationContainer}>
        <div className={classes.titleContainer}>
          <Typography variant="h2" className={classes.root}>
            Permiso de circulación
          </Typography>
        </div>
        {(permImages.length === 1) && (
        <div>
          <p>Este campo debe contener mínimo 1 foto. Agrega otra foto para eliminar la actual. </p>
        </div>
        )}
        {permImages.map((image) => (
          <div
            className={classes.ReservationImageContainer}
            key={image}
          >
            {(permImages.length > 1) && (
            <DeleteIcon
              className={classes.deleteImageIcon}
              onClick={() => {
                setActualImage(image);
                setOpenDelete(true);
                setField('permiso');
              }}
            />
            )}
            <img
              alt=""
              src={image}
              className={classes.budgetImage}
              onClick={() => {
                setActualImage(image);
                setOpenDisplay(true);
              }}
            />
          </div>
        ))}
        <div className={classes.addIconContainer}>
          <AddCircleOutlineIcon
            className={classes.addReservationImageIcon}
            onClick={() => {
              setField('permiso');
              handleAdd();
            }}
          />
        </div>
      </div>
      { existenciaDeclaracion && (
        <div className={classes.imagesReservationContainer}>
          <div className={classes.titleContainer}>
            <Typography variant="h2" className={classes.root}>
              Declaración jurada
            </Typography>
          </div>
          {(declImages.length === 1) && (
          <div>
            <p>Este campo debe contener mínimo 1 foto. Agrega otra foto para eliminar la actual. </p>
          </div>
          )}
          {declImages.map((image) => (
            <div
              className={classes.ReservationImageContainer}
              key={image}
            >
              {(declImages.length > 1) && (
              <DeleteIcon
                className={classes.deleteImageIcon}
                onClick={() => {
                  setActualImage(image);
                  setOpenDelete(true);
                  setField('declaracion');
                }}
              />
              )}
              <img
                alt=""
                src={image}
                className={classes.budgetImage}
                onClick={() => {
                  setActualImage(image);
                  setOpenDisplay(true);
                }}
              />
            </div>
          ))}
          <div className={classes.addIconContainer}>
            <AddCircleOutlineIcon
              className={classes.addReservationImageIcon}
              onClick={() => {
                setField('declaracion');
                handleAdd();
              }}
            />
          </div>
        </div>
      )}
    </Dialog>
  );
}

export default ReservationImageDialog;
