import {
  useState,
  ReactElement,
  useEffect,
} from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useStyles from '../../styles/not_found';

export default function NotFound(): ReactElement {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <Box className={classes.root}>
      {!isLoading && (
        <>
          <Box>
            <Typography variant="h3">
              Not Found
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5">
              La ruta no existe
            </Typography>
          </Box>
          <Box>
            <Button href="/" className={classes.buttonClass}> Ir al inicio </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
