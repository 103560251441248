import DataTable from 'react-data-table-component';
import { Button } from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import { ReactElement, useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { useCarsLazyQuery, CarsQuery } from '../../types';
import { useStyles } from '../../styles/reservationTable_style';
import { RowCarDataInterface } from '../../interfaces/components.interfaces';

function redirectToCarView(Patente: string) {
  window.location.href = `/admin/autos/${Patente}`;
}

const columns = [
  {
    name: 'ID', selector: 'Auto',
  },
  {
    name: 'Patente', selector: 'Patente',
  },
  {
    name: '',
    cell: (params: { Patente: string }) => {
      const onClick = () => {
        redirectToCarView(params.Patente);
      };
      return <Button onClick={onClick}>Ver</Button>;
    },
  },
];

function formatingData(data: CarsQuery['cars']) {
  const rows: RowCarDataInterface[] = [];
  data.forEach((element) => {
    const car: RowCarDataInterface = {
      id: +element.id,
      Auto: element.id,
      Patente: element.plate,
    };
    rows.push(car);
  });
  return rows;
}

function CarsTable(): ReactElement {
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [executeAllCars] = useCarsLazyQuery({
    onCompleted: (data) => {
      setRowData(formatingData(data.cars));
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlertContent('Falló la solicitud, inténtelo nuevamente');
        setAlert(true);
      }
    },
  });

  useEffect(() => {
    executeAllCars();
  }, []);

  return (
    <div className={classes.root}>
      { alert ? <Alert className={classes.alert} severity="error">{alertContent}</Alert> : <></>}
      <DataTable
        noHeader
        columns={columns}
        data={rowData}
        overflowY
        fixedHeader
        noDataComponent="No se ha encontrado información para mostrar"
      />
    </div>
  );
}

export default CarsTable;
