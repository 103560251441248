import { ReactElement, useEffect, useState } from 'react';
import {
  TextField,
  Box,
} from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import useLoginStyles from '../../styles/login';
import rutFormat from '../../helpers/rutFormat';
import { LoginDataInterface } from '../../interfaces/components.interfaces';
import PASSWORD_LENGTH from '../../helpers/constants';

function LoginForm(props: LoginDataInterface): ReactElement {
  const {
    rut,
    setRut,
    isRutValid,
    password,
    setPassword,
    isPasswordValid,
    setIsPasswordValid,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const classes = useLoginStyles();
  useEffect(() => {
    if (password.length >= PASSWORD_LENGTH) {
      setIsPasswordValid(true);
    } else {
      setIsPasswordValid(false);
    }
  }, [password]);

  return (
    <div>
      <Box className={classes.inputsBox}>
        <TextField
          label="RUT"
          helperText={!isRutValid && rut ? 'Ingrese un RUT válido' : '\u00a0'}
          FormHelperTextProps={{ className: classes.helperText }}
          onChange={(e) => setRut(rutFormat(e.target.value))}
          value={rut}
          className={classes.formInput}
        />
        <FormControl className={classes.formInput}>
          <InputLabel htmlFor="standard-adornment-password">
            CONTRASEÑA
          </InputLabel>
          <Input
            id="password"
            value={password}
            type={showPassword ? 'text' : 'password'}
            aria-describedby="component-helper-text"
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )}
          />
          {(password.length <= PASSWORD_LENGTH && !isPasswordValid) ? (
            <FormHelperText className={classes.alertText} id="password">
              Ingresa tu contraseña
            </FormHelperText>
          ) : ''}
        </FormControl>
      </Box>
    </div>
  );
}

export default LoginForm;
