import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import Table from '@material-ui/core/Table';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { TextField } from '@material-ui/core';
import useWorkOrderShowStyles from '../../styles/work_order_show_style';
import DelayDialog from '../../components/delay_dialog/delay_dialog';
import {
  useAssignTaskMutation,
  useGetAdminWorkOrderByFolioLazyQuery,
  GetAdminWorkOrderByFolioQuery,
  useStartTaskMutation,
  useCompleteTaskMutation,
  useExitCarMutation,
} from '../../types';
import { reducer } from '../../store/reducer';
import Loader from '../../components/loader/loader';

function WorkOrderShow(): ReactElement {
  const fases: { [fase: string]: string } = {
    dent: 'Desabolladura',
    wash: 'Lavado',
    assemble: 'Armado',
    thirdpartyservice: 'Servicio a terceros',
    mechanics: 'Mecánica',
    polish: 'Pulido',
    paint: 'Pintura',
  };

  const roleDict: { [role: string]: string } = {
    worker: 'Trabajador',
    boss: 'Jefe',
  };

  const classes = useWorkOrderShowStyles();
  const { rut, folio }: { rut: string, folio: string } = useParams();
  const [checked, setChecked] = useState<{ [id: string]: boolean }>({});
  const [workerInput, setWorkerInput] = useState('');
  const [selectedWorker, setSelectedWorker] = useState<GetAdminWorkOrderByFolioQuery['workers'][0]>(null);
  const [notAssigned, setNotAssigned] = useState<GetAdminWorkOrderByFolioQuery[
    'getWorkOrderByFolio']['workProcesses'][0]['tasks']>([]);
  const [assigned, setAssigned] = useState<GetAdminWorkOrderByFolioQuery[
    'getWorkOrderByFolio']['workProcesses'][0]['tasks']>([]);
  const [
    currentProcess,
    setCurrentProcess,
  ] = useState<GetAdminWorkOrderByFolioQuery['getWorkOrderByFolio']['workProcesses'][0]>();
  const [workOrder, setWorkOrder] = useState<GetAdminWorkOrderByFolioQuery['getWorkOrderByFolio']>();
  const [workers, setWorkers] = useState<GetAdminWorkOrderByFolioQuery['workers']>();
  const [actualTask, setActualTask] = useState<GetAdminWorkOrderByFolioQuery[
    'getWorkOrderByFolio']['workProcesses'][0]['tasks'][0]>(null);
  const [openDelayDialog, setOpenDelayDialog] = useState(false);
  function getTaskClass(isText: boolean, name: string): string {
    if (name === 'Por iniciar') {
      return isText ? classes.statusNotStarted : classes.notIniciated;
    }
    if (name === 'En proceso') {
      return isText ? classes.statusInProcess : classes.inProcess;
    }
    return isText ? classes.statusFinished : classes.finished;
  }

  function handleWorkersAndWorkOrdeData(data: GetAdminWorkOrderByFolioQuery): void {
    const current = data.getWorkOrderByFolio.workProcesses.find(
      (x) => x.process.id === data.getWorkOrderByFolio.currentProcess.id,
    );
    const notAssignedList: GetAdminWorkOrderByFolioQuery[
      'getWorkOrderByFolio']['workProcesses'][0]['tasks'] = [];
    const assignedList: GetAdminWorkOrderByFolioQuery[
      'getWorkOrderByFolio']['workProcesses'][0]['tasks'] = [];
    const checkedObj: { [id: string]: boolean } = {};
    current.tasks.forEach((task) => {
      if (!task.worker) {
        notAssignedList.push(task);
        checkedObj[task.id] = false;
      } else {
        assignedList.push(task);
      }
    });
    setAssigned(assignedList);
    setNotAssigned(notAssignedList);
    setChecked(checkedObj);
    setWorkOrder(data.getWorkOrderByFolio);
    setCurrentProcess(current);
    setWorkers(data.workers);
  }

  const [executeGetWorkOrder, { refetch, loading }] = useGetAdminWorkOrderByFolioLazyQuery({
    variables: {
      rut,
      folio,
    },
    onCompleted: (data) => {
      handleWorkersAndWorkOrdeData(data);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  const [deliverCar] = useExitCarMutation({
    variables: {
      id: +workOrder?.id,
    },
    onCompleted: () => {
      refetch().then((responseData) => {
        handleWorkersAndWorkOrdeData(responseData.data);
        reducer({ type: 'LOADING' });
      });
    },
  });

  const [startTask] = useStartTaskMutation({
    onCompleted: (data) => {
      setActualTask({
        ...actualTask,
        status: data.startTask.status,
      });
      refetch().then((responseData) => {
        handleWorkersAndWorkOrdeData(responseData.data);
        reducer({ type: 'LOADING' });
      });
    },
  });

  const [completeTask] = useCompleteTaskMutation({
    onCompleted: (data) => {
      setActualTask({
        ...actualTask,
        status: data.completeTask.status,
      });
      refetch().then((responseData) => {
        handleWorkersAndWorkOrdeData(responseData.data);
        reducer({ type: 'LOADING' });
      });
    },
  });

  const [assignTask] = useAssignTaskMutation({
    onCompleted: () => {
      refetch().then((responseData) => {
        handleWorkersAndWorkOrdeData(responseData.data);
        reducer({ type: 'LOADING' });
      });
    },
  });

  function getStageName() {
    if (!workOrder?.inWorkshop) {
      return 'Entregado';
    }
    if (workOrder?.closed) {
      return 'Finalizado';
    }
    return fases[currentProcess?.process.name];
  }

  useEffect(() => {
    executeGetWorkOrder();
  }, []);

  if (actualTask) {
    return (
      <div className={classes.orderContainer}>
        <DelayDialog
          open={openDelayDialog}
          deadline={workOrder.deadline}
          setOpen={setOpenDelayDialog}
          onClose={() => setOpenDelayDialog(false)}
          workOrderId={+workOrder.id}
          setDeadline={(deadline) => {
            setWorkOrder({
              ...workOrder,
              deadline,
            });
          }}
        />
        <div className={classes.backContainer}>
          <div
            className={classes.backButton}
            onClick={() => {
              setActualTask(null);
            }}
          >
            <ArrowBackIosIcon className={classes.backIcon} />
            <div
              className={classes.backText}
            >
              Volver a la orden
            </div>
          </div>
        </div>
        <div className={classes.generalContainer}>
          <div className={classes.backText}>
            {actualTask.piece ? `${actualTask.concept.name} - ${actualTask.piece?.name}` : `${actualTask.concept.name}`}
          </div>
          <div className={classes.backText}>
            {`${fases[currentProcess.process.name]}`}
          </div>
          <div className={classes.taskStats}>
            <div className={classes.statusContainer}>
              <div className={getTaskClass(false, actualTask.status.name)} />
              <div className={getTaskClass(true, actualTask.status.name)}>
                {actualTask.status.name.toUpperCase()}
              </div>
            </div>
            <div className={classes.statusContainer}>
              <div className={classes.taskStatName}>
                Patente:
              </div>
              <div className={classes.taskStatValue}>
                {workOrder.budget.car.plate}
              </div>
            </div>
            <div className={classes.statusContainer}>
              <div className={classes.taskStatName}>
                Marca:
              </div>
              <div className={classes.taskStatValue}>
                {workOrder.budget.car.brand}
              </div>
            </div>
            <div className={classes.statusContainer}>
              <div className={classes.taskStatName}>
                Modelo:
              </div>
              <div className={classes.taskStatValue}>
                {workOrder.budget.car.model}
              </div>
            </div>
            <div className={classes.statusContainer}>
              <div className={classes.taskStatName}>
                Modelo:
              </div>
              <div className={classes.taskStatValue}>
                {workOrder.budget.car.color}
              </div>
            </div>
            <div className={classes.statusContainer}>
              <div className={classes.taskStatName}>
                Fecha de entrega:
              </div>
              <div className={classes.taskStatValue}>
                {workOrder.deadline}
              </div>
            </div>
          </div>
        </div>
        {actualTask.status.name !== 'Completado' && (
          <Button
            className={classes.assignButton}
            variant="outlined"
            onClick={() => {
              setOpenDelayDialog(true);
            }}
          >
            Notificar atraso
          </Button>
        )}
        {actualTask.status.name !== 'Completado' && (
          <Button
            className={classes.assignButton}
            variant="outlined"
            onClick={() => {
              reducer({ type: 'LOADING' });
              if (actualTask.status.name === 'Por iniciar') {
                startTask({
                  variables: {
                    id: +actualTask.id,
                  },
                });
              } else {
                completeTask({
                  variables: {
                    id: +actualTask.id,
                  },
                });
              }
            }}
          >
            {actualTask.status.name === 'Por iniciar' ? 'Empezar tarea' : 'Finalizar tarea'}
          </Button>
        )}
      </div>
    );
  }

  return (
    <div className={classes.orderContainer}>
      {loading && <Loader />}
      <div className={classes.backContainer}>
        <div
          className={classes.backButton}
          onClick={() => {
            window.location.href = '/admin/ordenes-trabajo/';
          }}
        >
          <ArrowBackIosIcon className={classes.backIcon} />
          <div className={classes.backText}>
            Volver
          </div>
        </div>
        {workOrder?.closed && workOrder?.inWorkshop && (
          <Button
            className={classes.deliverButton}
            onClick={() => {
              reducer({ type: 'LOADING' });
              deliverCar();
            }}
          >
            Entregar
          </Button>
        )}
      </div>
      <div className={classes.generalContainer}>
        <div className={classes.generalStatContainer}>
          <div className={classes.generalStatName}>
            Etapa:
          </div>
          <div className={classes.generalStatValue}>
            {getStageName()}
          </div>
        </div>
        <div className={classes.generalStatContainer}>
          <div className={classes.generalStatName}>
            Patente:
          </div>
          <div className={classes.generalStatValue}>
            {workOrder?.budget.car.plate}
          </div>
        </div>
        <div className={classes.generalStatContainer}>
          <div className={classes.generalStatName}>
            Fecha de entrega:
          </div>
          <div className={classes.generalStatValue}>
            {workOrder?.deadline}
          </div>
        </div>
        <div className={classes.generalStatContainer}>
          <div className={classes.generalStatName}>
            Nº de orden:
          </div>
          <div className={classes.generalStatValue}>
            {workOrder?.id}
          </div>
        </div>
        <div className={classes.generalStatContainer}>
          <div className={classes.generalStatName}>
            Contacto:
          </div>
          <div className={classes.generalStatValue}>
            {workOrder?.budget.client.email}
          </div>
        </div>
      </div>
      {!workOrder?.closed && (
        <div className={classes.tablesContainer}>
          <div className={classes.tableContainer}>
            <div className={classes.backText}>
              Tareas no asignadas
            </div>
            <div className={classes.tasksContainer}>
              <TableContainer className={classes.tablePaper} component={Paper}>
                <Table padding="none">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        Concepto
                      </TableCell>
                      <TableCell align="center">
                        Pieza
                      </TableCell>
                      <TableCell align="right">
                        Asignar
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {notAssigned.map((task) => (
                      <TableRow key={task.id}>
                        <TableCell align="left">
                          <div className={classes.tableCell}>
                            {task.concept.name}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            {task.piece?.name}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <Checkbox
                            color="primary"
                            checked={checked[task.id]}
                            onChange={() => {
                              setChecked({
                                ...checked,
                                [task.id]: !checked[task.id],
                              });
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <Autocomplete
              value={selectedWorker}
              className={classes.formInput}
              onChange={(_e, newValue: string | GetAdminWorkOrderByFolioQuery['workers'][0] | null) => {
                setSelectedWorker(newValue as GetAdminWorkOrderByFolioQuery['workers'][0]);
              }}
              getOptionSelected={(option, value) => (value as GetAdminWorkOrderByFolioQuery['workers'][0]).id
              === (option as GetAdminWorkOrderByFolioQuery['workers'][0]).id}
              getOptionLabel={(option) => `${(option as GetAdminWorkOrderByFolioQuery['workers'][0]).name}
              (${roleDict[(option as GetAdminWorkOrderByFolioQuery['workers'][0]).role]})`}
              options={workers || []}
              inputValue={workerInput}
              id="concepts-select"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Trabajador"
                />
              )}
              onInputChange={(_e, value) => {
                setWorkerInput(value);
              }}
            />
            <Button
              variant="outlined"
              className={selectedWorker ? classes.assignButton : classes.assignButtonDisabled}
              onClick={() => {
                if (selectedWorker) {
                  reducer({ type: 'LOADING' });
                  assignTask({
                    variables: {
                      taskIds: Object.keys(checked).filter((id) => checked[id]).map((id) => +id),
                      workerId: +selectedWorker.id,
                    },
                  });
                }
              }}
            >
              Asignar
            </Button>
          </div>
          <div className={classes.tableContainer}>
            <div className={classes.backText}>
              Tareas asignadas
            </div>
            <p className={classes.mobileClick}>(Haz click en una fila para ver detalles)</p>
            <div className={classes.tasksContainer}>
              <TableContainer className={classes.tablePaper} component={Paper}>
                <Table padding="none">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        Concepto
                      </TableCell>
                      <TableCell align="center">
                        Pieza
                      </TableCell>
                      <TableCell align="right">
                        Estado
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {assigned.map((task) => (
                      <TableRow
                        key={task.id}
                        hover
                        onClick={() => {
                          setActualTask(task);
                        }}
                        className={classes.clickableRow}
                      >
                        <TableCell align="left">
                          <div className={classes.tableCell}>
                            {task.concept.name}
                          </div>
                        </TableCell>
                        <TableCell align="center">
                          <div className={classes.tableCell}>
                            {task.piece?.name}
                          </div>
                        </TableCell>
                        <TableCell align="right">
                          <div className={getTaskClass(false, task.status.name)} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default WorkOrderShow;
