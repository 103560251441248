import { ReactElement, useEffect, useState } from 'react';
import {
  TextField,
  Box,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import useLoginStyles from '../../styles/folio_style';
import rutFormat from '../../helpers/rutFormat';
import validateRut from '../../helpers/validateRut';
import { useGetWorkOrderByFolioLazyQuery } from '../../types';
import Tracing from '../../views/tracing/tracing';

function Folio() : ReactElement {
  const ids: { [id: string]: string } = {
    7: '1',
    6: '2',
    5: '3',
    4: '4',
    3: '5',
    2: '6',
    1: '7',
  };
  const classes = useLoginStyles();
  const [rut, setRut] = useState('');
  const [folio, setFolio] = useState('');
  const [isRutValid, setIsRutValid] = useState(false);
  const [isFolioValid, setIsFolioValid] = useState(false);
  const [step, setStep] = useState(0);
  const [deadLine, setDeadLine] = useState('');
  const [plate, setPlate] = useState('');
  const [currentProcess, setCurrentProcess] = useState('');
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const [brand, setBrand] = useState('');
  const [model, setModel] = useState('');
  const [color, setColor] = useState('');
  const [year, setYear] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [chasis, setChasis] = useState('');
  const [processName, setProcessName] = useState<string[]>([]);
  const [executeTracing] = useGetWorkOrderByFolioLazyQuery({
    onCompleted: (data) => {
      setStep(1);
      setPlate(data.getWorkOrderByFolio.budget.car.plate);
      setDeadLine(data.getWorkOrderByFolio.deadline);
      setBrand(data.getWorkOrderByFolio.budget.car.brand);
      setModel(data.getWorkOrderByFolio.budget.car.model);
      setColor(data.getWorkOrderByFolio.budget.car.color);
      setYear(data.getWorkOrderByFolio.budget.car.year);
      setChasis(data.getWorkOrderByFolio.budget.car.chasis);
      setCurrentProcess(data.getWorkOrderByFolio.currentProcess.name);
      const newList:string[] = [];
      for (let i = 0; i < data.getWorkOrderByFolio.workProcesses.length; i += 1) {
        newList.push(data.getWorkOrderByFolio.workProcesses[i].process.name);
      }
      if ((+ids[data.getWorkOrderByFolio.currentProcess.id]) > 1) {
        setActiveStep((+ids[data.getWorkOrderByFolio.currentProcess.id]) - 1);
      }
      setProcessName(newList.reverse());
    },
    onError: () => {
      setAlertContent('No se pudo ingresar. Intente más tarde.');
      setAlert(true);
    },
  });
  useEffect(() => {
    if (folio.length > 0) {
      setIsFolioValid(true);
    } else {
      setIsFolioValid(false);
    }
  }, [folio]);
  useEffect(() => {
    if (rut.length >= 11 && validateRut(rut)) {
      setIsRutValid(true);
    } else {
      setIsRutValid(false);
    }
  }, [rut]);
  function submit() {
    if (isFolioValid && isRutValid) {
      executeTracing({
        variables: {
          rut,
          folio,
        },
      });
    }
  }
  if (step === 0) {
    return (
      <div className={classes.loginContainer}>
        {alert ? <Alert className={classes.alert} severity="error">{alertContent}</Alert> : <></> }
        <Grid
          container
          justify="center"
          alignItems="center"
          direction="column"
          className={classes.folioGrid}
        >
          <Typography className={classes.formTitle}> Seguimiento de mi vehículo </Typography>
          <div>
            <Box className={classes.inputsBox}>
              <TextField
                label="RUT"
                helperText={!isRutValid && rut ? 'Ingrese un RUT válido' : '\u00a0'}
                FormHelperTextProps={{ className: classes.helperText }}
                onChange={(e) => setRut(rutFormat(e.target.value))}
                value={rut}
                className={classes.formInput}
              />
              <TextField
                label="Folio"
                helperText={!isFolioValid && folio.length > 0 ? 'Ingrese folio válido' : '\u00a0'}
                FormHelperTextProps={{ className: classes.helperText }}
                onChange={(e) => setFolio(e.target.value)}
                value={folio}
                className={classes.formInput}
              />
            </Box>
          </div>
          <Button
            variant="contained"
            onClick={() => submit()}
            className={(isRutValid && isFolioValid)
              ? classes.pageButtonActive
              : classes.pageButton}
          >
            Ingresar
          </Button>
        </Grid>
      </div>
    );
  }
  if (step === 1) {
    return (
      <Tracing
        deadLine={deadLine}
        plate={plate}
        currentProcess={currentProcess}
        brand={brand}
        model={model}
        color={color}
        year={year}
        chasis={chasis}
        processName={processName}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
    );
  }
  return (
    <div />
  );
}
export default Folio;
