import { makeStyles, createStyles } from '@material-ui/core/styles';

const usePiecesManagerStyles = makeStyles((theme) => createStyles({
  managerContainer: {
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '10vw',
    [theme.breakpoints.down('md')]: {
      marginBottom: '10vh',
    },
  },
  downloadDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '80vw',
    marginBottom: '3vw',
    [theme.breakpoints.down('md')]: {
      marginBottom: '5vh',
    },
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  downloadButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
  },
  fileInput: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    marginBottom: '1vw',
    width: '30vw',
    [theme.breakpoints.down('md')]: {
      width: '50vw',
      marginBottom: '1.5vh',
    },
  },
  hiddenInput: {
    width: '0.1px',
    height: '0.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  fileName: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    borderRadius: '6px',
    padding: '1vw',
    color: theme.palette.primary.main,
    textAlign: 'center',
    width: '30vw',
    marginBottom: '2vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('md')]: {
      width: '50vw',
      padding: '3vh',
      marginBottom: '3vh',
    },
  },
  title: {
    padding: '20px',
    marginBottom: '5vh',
    color: 'black',
    fontSize: 36,
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
      marginBottom: '2vh',
    },
  },
  infoText: {
    width: '60vw',
  },
  justifiedText: {
    textAlign: 'justify',
    textJustify: 'inter-word',
  },
}));

export default usePiecesManagerStyles;
