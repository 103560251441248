import {
  ReactElement,
  useEffect,
  useState,
} from 'react';
import { Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import useBudgetShowStyles from '../../styles/budget_show';
import ReservationTableShow from '../../components/table/reservationCarShow';
import BudgetTableShow from '../../components/table/budgetCarShow';
import { useGetDataCarShowLazyQuery } from '../../types';

function CarShow(): ReactElement {
  const classes = useBudgetShowStyles();
  const { carPlate }: { carPlate:string } = useParams();
  const [brand, setBrand] = useState('');
  const [chasis, setChasis] = useState('');
  const [model, setModel] = useState('');
  const [year, setYear] = useState(0);

  const [executeData] = useGetDataCarShowLazyQuery({
    variables: {
      plate: carPlate,
    },
    onCompleted: (data) => {
      setBrand(data.getCarByPlate.brand);
      setChasis(data.getCarByPlate.chasis);
      setModel(data.getCarByPlate.model);
      setYear(data.getCarByPlate.year);
    },
  });

  useEffect(() => {
    executeData();
  }, []);

  return (
    <div className={classes.carContainer}>
      <div className={classes.carContainer}>
        <div className={classes.personalDataContainer}>
          <h1>
            <Typography variant="h2">
              Auto
            </Typography>
          </h1>
          <div className={classes.responsiveStatContainer}>
            <p className={classes.responsiveStatName}>Marca:</p>
            <p className={classes.responsiveStatValue}>{` ${brand}`}</p>
          </div>
          <div className={classes.responsiveStatContainer}>
            <p className={classes.responsiveStatName}>Modelo:</p>
            <p className={classes.responsiveStatValue}>{` ${model}`}</p>
          </div>
          <div className={classes.responsiveStatContainer}>
            <p className={classes.responsiveStatName}>Año:</p>
            <p className={classes.responsiveStatValue}>{` ${year}`}</p>
          </div>
          <div className={classes.responsiveStatContainer}>
            <p className={classes.responsiveStatName}>Patente:</p>
            <p className={classes.responsiveStatValue}>{` ${carPlate}`}</p>
          </div>
          <div className={classes.responsiveStatContainer}>
            <p className={classes.responsiveStatName}>Chasis:</p>
            <p className={classes.responsiveStatValue}>{` ${chasis}`}</p>
          </div>
        </div>
      </div>
      <div className={classes.carContainer}>
        <div className={classes.personalDataContainer}>
          <Grid>
            <Typography variant="h2">
              Presupuestos
            </Typography>
          </Grid>
          <Grid className={classes.table}>
            <BudgetTableShow
              plate={carPlate}
            />
          </Grid>

          <Grid>
            <Typography variant="h2">
              Reservas
            </Typography>
          </Grid>
          <Grid className={classes.table}>
            <ReservationTableShow
              plate={carPlate}
            />
          </Grid>
        </div>
      </div>
    </div>
  );
}

export default CarShow;
