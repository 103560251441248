import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '5vh',
    paddingBottom: '20%',
  },
  buttonClass: {
    background: '#115fa6',
    color: '#f5f5f5',
    '&:hover': {
      background: '#f5f5f5',
      color: '#115fa6',
    },
    marginTop: '5vh',
  },
}));

export default useStyles;
