import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const footerStyle = makeStyles((theme: Theme) => createStyles({
  container: {
    paddingTop: 15,
    marginBottom: 15,
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    background: '#343c44',
    padding: theme.spacing(2),
    textAlign: 'center',
    color: 'white',
  },
  subtitle: {
    fontSize: 20,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: 'white',
    marginBottom: 5,
  },
  root: {
    flexGrow: 1,
    background: '#4c545c',
    width: '100vw',
    position: 'relative',
    marginTop: '3vh',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    background: '#4c545c',
    color: 'white',
  },
  field: {
    color: 'white',
  },
  icon: {
    color: '#7bbad5',
    textAlign: 'center',
    marginRight: 5,
  },
  info: {
    textAlign: 'center',
    color: 'white',
  },
  minicontainer: {
    color: 'white',
    [theme.breakpoints.down('md')]: {
      marginBottom: 50,
    },
  },
  link: {
    color: 'white',
    textTransform: 'none',
    fontWeight: 'normal',
    fontSize: 16,
    textDecoration: 'underline',
  },
  cover: {
    color: '#343c44',
    textAlign: 'left',
    fontSize: 24,
    fontWeight: 'bold',
    background: '#343c44',
    padding: theme.spacing(2),
  },
}));

export default footerStyle;
