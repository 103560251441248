import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root:
  {
    padding: '20px',
    marginBottom: '5vh',
    color: 'black',
    fontSize: 36,
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
    },
  },
  grid:
  {
    minHeight: '100vh',
  },
  button: {
    color: '#FFFFFF',
    backgroundColor: '#126da6',
    '&:focus': {
      backgroundColor: '#126da6',
    },
  },
  registrationButton: {
    marginBottom: '20px',
    color: '#FFFFFF',
    backgroundColor: '#126da6',
    '&:focus': {
      backgroundColor: '#126da6',
    },
  },
}));

export default useStyles;
