import { ReactElement } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Carousel from 'react-material-ui-carousel';
import useStyles from '../../styles/carousel';
import firstImage from '../../assets/images/01.png';
import secondImage from '../../assets/images/02.png';
import thirdImage from '../../assets/images/03.png';

function CarouselSlide() : ReactElement {
  const classes = useStyles();
  return (
    <div>
      <Carousel
        NextIcon={<ArrowForwardIosIcon />}
        PrevIcon={<ArrowBackIosIcon />}
        interval={5500}
      >
        <img src={firstImage} className={classes.carouselInner} alt="image1" />
        <img src={secondImage} className={classes.carouselInner} alt="image2" />
        <img src={thirdImage} className={classes.carouselInner} alt="image3" />
      </Carousel>
    </div>
  );
}

export default CarouselSlide;
