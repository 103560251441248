import { ReactElement } from 'react';
import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import ReservedBudget from '../ExportCSV/reservedBudget';
import ExportCars from '../ExportCSV/cars';
import ExportClients from '../ExportCSV/clients';
import ExportReservedEntry from '../ExportCSV/reservedEntry';
import ExportUsers from '../ExportCSV/users';
import ExportBudgets from '../ExportCSV/budgets';
import ExportWorkOrder from '../ExportCSV/workOrders';
import ExportServices from '../ExportCSV/service';
import ExportInsurances from '../ExportCSV/Insurances';
import ExportWorkProcesess from '../ExportCSV/workProcesess';
import ExportTask from '../ExportCSV/task';
import useKPIStyles from '../../styles/kpi_style';

function AllKPIs(): ReactElement {
  const classes = useKPIStyles();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="h2" className={classes.typography}>
        Descarga de datos
      </Typography>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.column}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.row}
        >
          <ReservedBudget />
          <ExportCars />
          <ExportClients />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.row}
        >
          <ExportReservedEntry />
          <ExportUsers />
          <ExportBudgets />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.row}
        >
          <ExportWorkOrder />
          <ExportServices />
          <ExportInsurances />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.row}
        >
          <ExportWorkProcesess />
          <ExportTask />
        </Box>
      </Box>
    </Box>
  );
}

export default AllKPIs;
