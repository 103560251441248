import { ReactElement } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import PlaceIcon from '@material-ui/icons/Place';
import PublicIcon from '@material-ui/icons/Public';
import PhoneIcon from '@material-ui/icons/Phone';
import footerStyle from '../../styles/footer';
import state from '../../store/getGlobalState';

export default function Footer(): ReactElement {
  const classes = footerStyle();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs>
          <Typography className={classes.title}>
            Quelle Servicio Automotriz
          </Typography>
        </Grid>
      </Grid>
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={4} className={classes.minicontainer}>
          <Typography className={classes.paper}> Contáctanos a nuestro WhatsApp! </Typography>
          <Typography className={classes.info}>
            <WhatsAppIcon className={classes.icon} />
            +56 9 7863 9982
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.minicontainer}>
          <Typography className={classes.subtitle}> Contacto </Typography>
          <Typography className={classes.info}>
            <PlaceIcon className={classes.icon} />
            Calle 9 Nte. 1040, Talca
          </Typography>
          <Typography className={classes.info}>
            <PublicIcon className={classes.icon} />
            Región del Maule, Chile
          </Typography>
          <Typography className={classes.info}>
            <PhoneIcon className={classes.icon} />
            71 2225359 / 71 2235754
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.minicontainer}>
          <Typography className={classes.subtitle}> Menú </Typography>
          {!state().token && (
            <>
              <Typography className={classes.info}>
                <Button href="/" className={classes.link}>Nosotros</Button>
              </Typography>
              <Typography className={classes.info}>
                <Button href="/reserva" className={classes.link}>Reservar Hora</Button>
              </Typography>
              <Typography className={classes.info}>
                <Button href="/presupuesto" className={classes.link}>Ver Presupuesto</Button>
              </Typography>
              <Typography className={classes.info}>
                <Button href="/seguimiento" className={classes.link}>Realizar Seguimiento</Button>
              </Typography>
            </>
          )}
          {state().token && (
            <>
              <Typography className={classes.info}>
                <Button href="/admin/presupuestos" className={classes.link}>Presupuestos</Button>
              </Typography>
              <Typography className={classes.info}>
                <Button href="/admin/ordenes-trabajo" className={classes.link}>Órdenes de Trabajo</Button>
              </Typography>
              <Typography className={classes.info}>
                <Button href="/admin/recepciones" className={classes.link}>Recepciones</Button>
              </Typography>
              <Typography className={classes.info}>
                <Button href="/admin/reservas" className={classes.link}>Reservas Clientes</Button>
              </Typography>
              <Typography className={classes.info}>
                <Button href="/admin/reserva-ingreso" className={classes.link}>Recepciones Agendadas</Button>
              </Typography>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs className={classes.cover}>  . </Grid>
      </Grid>
    </div>
  );
}
