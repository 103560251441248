import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useLoginStyles = makeStyles((theme: Theme) => createStyles({
  formInput: {
    width: '60vw',
    marginButton: '2vh',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      width: '30vw',
    },
  },
  inputsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  helperText: {
    color: 'red',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100vw',
    height: '75vh',
  },
  logo: {
    height: '10vh',
    backgroundColor: 'transparent',
    margin: '10vh 0',
  },
  pageButton: {
    color: '#FFFFFF',
    backgroundColor: 'rgba(18, 109, 166, 0.5)',
    '&:focus': {
      backgroundColor: 'rgba(18, 109, 166, 0.5)',
    },
    cursor: 'not-allowed',
  },
  pageButtonActive: {
    color: '#FFFFFF',
    backgroundColor: '#126da6',
    '&:focus': {
      backgroundColor: '#126da6',
    },
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    width: '100vw',
  },
  folioGrid: {
    height: '100vh',
  },
  formTitle: {
    marginBottom: '5vh',
    color: 'black',
    fontSize: 24,
    fontWeight: 'lighter',
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
    },
  },
}));

export default useLoginStyles;
