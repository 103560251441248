import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => createStyles({
  formBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
  },
  appContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  pagesBox: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'center',
    width: '60vw',
    marginBottom: '6vh',
  },
  pageButton: {
    color: '#FFFFFF',
    marginTop: '5vh',
    backgroundColor: 'rgba(18, 109, 166, 0.5)',
    '&:focus': {
      backgroundColor: 'rgba(18, 109, 166, 0.5)',
    },
    cursor: 'not-allowed',
  },
  pageButtonActive: {
    color: '#FFFFFF',
    marginTop: '5vh',
    backgroundColor: '#126da6',
    '&:focus': {
      backgroundColor: '#126da6',
    },
  },
  typography: {
    marginBottom: '40px',
    textAlign: 'center',
  },
  formTitle: {
    marginTop: '5vh',
    marginBottom: '5vh',
    color: 'black',
    fontSize: 24,
    fontWeight: 'lighter',
    textAlign: 'center',
    letterSpacing: 1,
  },
  contentBox: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10vh',
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
}));

export default useStyles;
