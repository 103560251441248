import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({
  root:
  {
    padding: '20px',
    color: '#115fa6',
    fontWeight: 'bold',
    fontSize: 32,
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
    },
  },
  services:
  {
    padding: '20px',
    color: '#115fa6',
    fontWeight: 'bold',
    fontSize: 26,
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
    },
  },
  grid:
  {
    minHeight: '80vh',
    position: 'relative',
  },
  item:
  {
    display: 'flex',
    paddingRight: '20%',
    paddingLeft: '20%',
    marginTop: '5vh',
    flexDirection: 'column',
    minWidth: '100%',
    [theme.breakpoints.down('md')]: {
      paddingRight: '10%',
      paddingLeft: '10%',
    },
  },
  box:
  {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '5vh',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  boxItem:
  {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: '30%',
    paddingLeft: '5vh',
    paddingRight: '5vh',
  },
  text:
  {
    textAlign: 'center',
  },
  icon:
  {
    fontSize: 40,
    color: '#115fa6',
  },
}));
export default useStyles;
