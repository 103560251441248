import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => createStyles({
  scheduler: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4vh',
  },
  buttonActive: {
    marginTop: '4vh',
    height: '4vh',
    backgroundColor: '#126da6',
    color: '#ffffff',
    '&:hover': {
      TransitionEvent: '2s',
      backgroundColor: '#ffffff',
      color: '#3c52b2',
    },
  },
  buttonDisabled: {
    marginTop: '4vh',
    height: '4vh',
    color: '#FFFFFF',
    backgroundColor: 'rgba(18, 109, 166, 0.5)',
    '&:focus': {
      backgroundColor: 'rgba(18, 109, 166, 0.5)',
    },
    cursor: 'not-allowed',
  },
}));

export default useStyles;
