import {
  ReactElement, useEffect,
} from 'react';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { initialState, reducer } from './store/reducer';
import Loader from './components/loader/loader';
import BudgetAppointment from './views/budget_appointment/budget_appointment';
import FwithoutInsurance from './views/budget_appointment/FwithoutInsurance';
import Login from './views/login/Login';
import Footer from './components/footer/footer';
import NotFound from './components/not_found/not_found';

import UseLocalStorage from './helpers/useLocalStorage';

import './styles/App.scss';
import state from './store/getGlobalState';
import Sidebar from './components/header/sidebar';
import Header from './components/header/header';
import Reserve from './components/reserve/reserve';
import LandingPage from './components/landing/landing';
import Index from './views/home/index';
import Reservation from './views/reservation_table/reservation';
import Budget from './views/budget_table/budget';
import WorkersList from './views/workers_table/workers_table';
import AdminsList from './views/admins_table/admins_table';
import AdminRegister from './views/register/admin_register';
import WorkerRegister from './views/register/worker_register';
import BlockDays from './views/block_days/block_days';
import Folio from './components/folio/folio';
import BudgetShow from './views/budget_show/budget_show';
import ReservationData from './views/reservation_show/reservation_show';
import WorkOrder from './views/work_order/work_orders';
import ChangePasswordView from './views/change_password/changePassword';
import BudgetClient from './views/budget_client/budget_client_show';
import Reception from './views/reception/receptionCalendar';
import TracingToken from './views/tracing/tracing_token_show';
import ReceptionTableList from './views/reception_table/reception';
import BudgetTokenClient from './views/budget_client/budget_client_token_show';
import WorkOrderShow from './views/work_order_show/work_orders_show';
import ReceptionShow from './views/reception_show/receptions_show';
import AllKPIs from './components/KPI/allKPIs';
import EntryReservationsTableList from './views/entry_reservations_table/entry_reservations';
import EntryReservationsShow from './views/entry_reservations_show/entry_reservations_show';
import PiecesManager from './views/pieces_manager/pieces_manager';
import CarData from './views/car_data/car_data';
import CarShow from './views/car_show/car_show';

function App(): ReactElement {
  const globalState = useReactiveVar(state);
  const [localState] = UseLocalStorage('state', initialState);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#126da6',
      },
    },
  });

  useEffect(() => {
    reducer({ type: 'INITIALIZE', data: { token: localState.token } });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter forceRefresh>
        {globalState.isLoading && <Loader />}
        {globalState.token && (
          <Sidebar>
            <Switch>
              <Route exact path="/">
                <LandingPage />
              </Route>
              <Route exact path="/admin/reservas">
                <Reservation />
              </Route>
              <Route
                path="/admin/reservas/:id"
                render={({ match }) => <ReservationData id={match.params.id} />}
              />
              <Route exact path="/admin/presupuestos">
                <Budget />
              </Route>
              <Route exact path="/admin/trabajadores">
                <WorkersList />
              </Route>
              <Route exact path="/admin/trabajadores/registro">
                <WorkerRegister />
              </Route>
              <Route exact path="/admin/administradores">
                <AdminsList />
              </Route>
              <Route exact path="/admin/administradores/registro">
                <AdminRegister />
              </Route>
              <Route exact path="/admin/presupuestos/:budgetId">
                <BudgetShow />
              </Route>
              <Route exact path="/admin/bloqueos">
                <BlockDays />
              </Route>
              <Route exact path="/admin/ordenes-trabajo">
                <WorkOrder />
              </Route>
              <Route exact path="/admin/cambio-clave">
                <ChangePasswordView />
              </Route>
              <Route exact path="/admin/agendar-recepcion/:budgetId">
                <Reception />
              </Route>
              <Route exact path="/admin/reserva-ingreso/">
                <EntryReservationsTableList />
              </Route>
              <Route exact path="/admin/reserva-ingreso/:id">
                <EntryReservationsShow />
              </Route>
              <Route exact path="/admin/recepciones">
                <ReceptionTableList />
              </Route>
              <Route exact path="/admin/recepciones/:id">
                <ReceptionShow />
              </Route>
              <Route exact path="/admin/ordenes-trabajo/:rut/:folio">
                <WorkOrderShow />
              </Route>
              <Route exact path="/admin/Datos">
                <AllKPIs />
              </Route>
              <Route exact path="/admin/piezas">
                <PiecesManager />
              </Route>
              <Route exact path="/admin/autos">
                <CarData />
              </Route>
              <Route exact path="/admin/autos/:carPlate">
                <CarShow />
              </Route>
              <Route component={NotFound} />
            </Switch>
          </Sidebar>
        )}
        {!globalState.token && (
          <Header>
            <Switch>
              {globalState.isLoading && <Loader />}
              <Route exact path="/">
                <Index />
              </Route>
              <Route exact path="/admin/login">
                <Login />
              </Route>
              <Route exact path="/tomar-hora">
                <BudgetAppointment />
              </Route>
              <Route exact path="/agendar-s-seguro">
                <FwithoutInsurance />
              </Route>
              <Route exact path="/reserva">
                <Reserve />
              </Route>
              <Route exact path="/seguimiento">
                <Folio />
              </Route>
              <Route exact path="/seguimiento/:token">
                <TracingToken />
              </Route>
              <Route exact path="/presupuesto">
                <BudgetClient />
              </Route>
              <Route exact path="/presupuesto/:token">
                <BudgetTokenClient />
              </Route>
              <Route component={NotFound} />
            </Switch>
          </Header>
        )}
      </BrowserRouter>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
