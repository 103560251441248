import { Button, Dialog, TextField } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ReactElement, useState } from 'react';
import useDelayDialogStyles from '../../styles/delay_dialog';
import { DelayDialogInterface } from '../../interfaces/components.interfaces';
import { useNotifyDelayMutation } from '../../types';
import { reducer } from '../../store/reducer';

function DelayDialog(props: DelayDialogInterface): ReactElement {
  const {
    open,
    onClose,
    deadline,
    setOpen,
    workOrderId,
    setDeadline,
  } = props;
  const classes = useDelayDialogStyles();
  const dateInput = deadline.split('/');
  const dateObj = new Date(+dateInput[2], +dateInput[1] - 1, +dateInput[0]);
  dateObj.setDate(dateObj.getDate() + 1);
  const [date, setDate] = useState('');
  const [comments, setComments] = useState<string>('');
  const [notifyDelay] = useNotifyDelayMutation({
    onCompleted: (data) => {
      reducer({ type: 'LOADING' });
      setDeadline(data.notifyDelay.deadline);
      setOpen(false);
    },
  });
  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      maxWidth={false}
    >
      <div className={classes.closeBox}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => setOpen(false)}
        />
      </div>
      <div className={classes.title}>
        Notificar atraso
      </div>
      <div className={classes.formContainer}>
        <TextField
          value={date}
          label="Nueva fecha"
          className={classes.formInput}
          onChange={(e) => setDate(e.target.value)}
          helperText={date.length > 0 ? '\u00a0' : 'Ingrese un fecha'}
          FormHelperTextProps={{ className: classes.helperText }}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ min: dateObj.toISOString().split('T')[0] }}
        />
        <TextField
          multiline
          rows={4}
          helperText={comments.length > 0 ? '\u00a0' : 'Ingrese un comentario'}
          FormHelperTextProps={{ className: classes.helperText }}
          className={classes.formInput}
          label="Comentarios"
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        <Button
          className={classes.sendButton}
          onClick={() => {
            reducer({ type: 'LOADING' });
            notifyDelay({
              variables: {
                workOrderId,
                comments,
                newDeadline: date.split('-').reverse().join('/'),
              },
            });
          }}
        >
          Notificar
        </Button>
      </div>
    </Dialog>
  );
}

export default DelayDialog;
