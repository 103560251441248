import { ReactElement } from 'react';
import {
  Box,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { useStyles } from '../../styles/workOrderFilter_style';
import { BudgetTableFilterInterface } from '../../interfaces/components.interfaces';

function BudgetTableFilter(props: BudgetTableFilterInterface): ReactElement {
  const {
    name,
    setName,
    status,
    setStatus,
    entryReservation,
    setEntryReservation,
  } = props;
  const classes = useStyles();

  const handleChangeStatus = (event: React.ChangeEvent<{ value: unknown }>) => {
    setStatus(event.target.value as string);
  };
  const hadleChangeReservation = (event: React.ChangeEvent<{ value: unknown }>) => {
    setEntryReservation(event.target.value as string);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.buttonsContainer}
    >
      <FormControl className={classes.formControl}>
        <InputLabel>Trabajador</InputLabel>
        <Input
          id="name"
          value={name}
          aria-describedby="component-helper-text"
          onChange={(e) => setName(e.target.value)}
        />
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel>Estado</InputLabel>
        <Select
          id="status"
          value={status}
          onChange={handleChangeStatus}
        >
          <MenuItem value="">TODOS</MenuItem>
          <MenuItem value="APROBADO">APROBADO</MenuItem>
          <MenuItem value="PENDIENTE">PENDIENTE</MenuItem>
          <MenuItem value="RECHAZADO">RECHAZADO</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.formControl}>
        <InputLabel>Reservado</InputLabel>
        <Select
          id="status"
          value={entryReservation}
          onChange={hadleChangeReservation}
        >
          <MenuItem value="">TODOS</MenuItem>
          <MenuItem value="true">Reservado</MenuItem>
          <MenuItem value="false">No reservado</MenuItem>
        </Select>
      </FormControl>
    </Box>

  );
}
export default BudgetTableFilter;
