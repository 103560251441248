import {
  ReactNode,
  useState,
  ReactElement,
  MouseEvent,
} from 'react';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { Link } from 'react-router-dom';
import { reducer, initialState } from '../../store/reducer';
import useLocalStorage from '../../helpers/useLocalStorage';
import state from '../../store/getGlobalState';
import { useStyles } from '../../styles/sideBar_style';

function Sidebar(props: { children: ReactNode }): ReactElement {
  const { children } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [, setState] = useLocalStorage('state', initialState);
  const [appointment, setAppointment] = useState(null);
  const [register, setRegister] = useState(null);

  const handleClickAppointment = (event: MouseEvent<HTMLButtonElement>) => {
    setAppointment(event.currentTarget);
  };

  const handleCloseAppointment = () => {
    setAppointment(null);
  };

  const handleClickRegister = (event: MouseEvent<HTMLButtonElement>) => {
    setRegister(event.currentTarget);
  };

  const handleCloseRegister = () => {
    setRegister(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logout = () => {
    reducer({ type: 'SIGN_OUT' });
    setState({
      isLoading: false,
      token: '',
      name: '',
    });
    window.location.href = '/admin/login';
    handleDrawerClose();
  };

  const reservationView = () => {
    window.location.href = '/admin/reservas';
  };

  const budgetView = () => {
    window.location.href = '/admin/presupuestos';
  };

  const workersView = () => {
    window.location.href = '/admin/trabajadores';
  };
  const adminsView = () => {
    window.location.href = '/admin/administradores';
  };
  const blockHours = () => {
    window.location.href = '/admin/bloqueos';
  };
  const changePasswordView = () => {
    window.location.href = '/admin/cambio-clave';
  };
  const WorkOrders = () => {
    window.location.href = '/admin/ordenes-trabajo';
  };

  const ReceptionCar = () => {
    window.location.href = '/admin/recepciones';
  };
  const DataView = () => {
    window.location.href = '/admin/Datos';
  };

  const EntryReservationsList = () => {
    window.location.href = '/admin/reserva-ingreso/';
  };

  const piecesView = () => {
    window.location.href = '/admin/piezas/';
  };

  const carsShow = () => {
    window.location.href = '/admin/autos/';
  };

  const editList = [
    {
      text: 'Presupuestos',
      onClick: () => budgetView(),
    },
    {
      text: 'Órdenes de Trabajo',
      onClick: () => WorkOrders(),
    },
    {
      text: 'Bloquear horas',
      onClick: () => blockHours(),
    },
    {
      text: 'Recepción Vehículos',
      onClick: () => ReceptionCar(),
    },
  ];

  const registerList = [
    {
      text: 'Registrar Administrador',
      onClick: () => adminsView(),
    },
    {
      text: 'Registrar Trabajador',
      onClick: () => workersView(),
    },
  ];

  const appointmentList = [
    {
      text: 'Reservas Presupuesto',
      onClick: () => reservationView(),
    },
    {
      text: 'Reservas Ingreso Vehículo',
      onClick: () => EntryReservationsList(),
    },
  ];

  const listItem = [
    {
      text: 'Vehículos',
      onClick: () => carsShow(),
    },
    {
      text: 'Datos',
      onClick: () => DataView(),
    },
    {
      text: 'Piezas',
      onClick: () => piecesView(),
    },
  ];

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar className={classes.topBar}>
          <Typography variant="h6" noWrap className={classes.title}>
            <Link to="/" className={classes.adminLink}>
              QUELLE
            </Link>
          </Typography>
          <Typography>
            <Button href="#" onClick={changePasswordView} className={classes.textTop}>
              Cambiar Contraseña
            </Button>
          </Typography>
          <Typography>
            <Button href="#" onClick={logout} className={classes.textTop}>
              Cerrar Sesión
            </Button>
          </Typography>
        </Toolbar>
        <Toolbar>
          <Grid item sm={10} xs={10} md={10} xl={10} lg={10}>
            <Typography variant="h6" noWrap>
              <Link to="/" className={classes.quelleLink}>
                QUELLE
              </Link>
              {editList.map((item) => {
                const { text, onClick } = item;
                return (
                  <Button key={item.text} href="#" onClick={onClick} className={classes.buttonClass}>
                    {text}
                  </Button>
                );
              })}
              <Button
                onClick={handleClickAppointment}
                className={classes.buttonClass}
              >
                Horas Reservadas
                <ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={appointment}
                keepMounted
                open={Boolean(appointment)}
                onClose={handleCloseAppointment}
                elevation={3}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem className={classes.toggle} onClick={reservationView}>
                  Reserva Presupuesto
                </MenuItem>
                <MenuItem className={classes.toggle} onClick={EntryReservationsList}>
                  Ingreso vehículo
                </MenuItem>
              </Menu>
              <Button
                onClick={handleClickRegister}
                className={classes.buttonClass}
              >
                Registro
                <ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={register}
                keepMounted
                open={Boolean(register)}
                onClose={handleCloseRegister}
                elevation={3}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem className={classes.toggle} onClick={adminsView}> Registrar Administrador </MenuItem>
                <MenuItem className={classes.toggle} onClick={workersView}> Registrar Trabajador </MenuItem>
              </Menu>
            </Typography>
          </Grid>
          <Grid item sm={2} xs={2} md={2} xl={2} lg={3} className={classes.rightSide}>
            <Typography>
              {listItem.map((item) => {
                const { text, onClick } = item;
                return (
                  <Button key={item.text} href="#" onClick={onClick} className={classes.buttonClass}>
                    {text}
                  </Button>
                );
              })}
              {state().token && (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerOpen}
                  className={classes.hide}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Typography>
          </Grid>
        </Toolbar>
      </AppBar>
      {state().token && (
        <div>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="right"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose} className={classes.iconButton}>
                {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider />
            <List className={classes.list}>
              {editList.map((item) => {
                const { text, onClick } = item;
                return (
                  <ListItem button key={text} onClick={onClick}>
                    <ListItemText primary={text} className={classes.listItemText} />
                  </ListItem>
                );
              })}
              <Divider />
              {registerList.map((item) => {
                const { text, onClick } = item;
                return (
                  <ListItem button key={text} onClick={onClick}>
                    <ListItemText primary={text} className={classes.listItemText} />
                  </ListItem>
                );
              })}
              <Divider />
              {appointmentList.map((item) => {
                const { text, onClick } = item;
                return (
                  <ListItem button key={text} onClick={onClick}>
                    <ListItemText primary={text} className={classes.listItemText} />
                  </ListItem>
                );
              })}
              <Divider />
              {listItem.map((item) => {
                const { text, onClick } = item;
                return (
                  <ListItem button key={text} onClick={onClick}>
                    <ListItemText primary={text} className={classes.listItemText} />
                  </ListItem>
                );
              })}
              <Divider />
              <ListItem button onClick={changePasswordView}>
                <ListItemText className={classes.listItemText}>Cambiar Contraseña</ListItemText>
              </ListItem>
              <Divider />
              <div className={classes.signOutContainer}>
                <ListItem button onClick={logout}>
                  <ListItemIcon className={classes.listItemIcon}><ExitToAppIcon /></ListItemIcon>
                  <ListItemText className={classes.listItemText}>Cerrar sesión</ListItemText>
                </ListItem>
              </div>
            </List>
          </Drawer>
        </div>
      )}
      <div className={classes.children}>
        {children}
      </div>
    </div>
  );
}

export default Sidebar;
