import { ReactElement, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
  FormControl,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
} from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import CloseIcon from '@material-ui/icons/Close';
import { ClientDialogInterface } from '../../interfaces/components.interfaces';
import useItemDialogStyles from '../../styles/item_dialog';
import { reducer } from '../../store/reducer';
import { useUpdateBudgetMutation, GetInsurancesQuery } from '../../types';
import rutFormat from '../../helpers/rutFormat';
import validateRut from '../../helpers/validateRut';
import mailRegex from '../../helpers/mailRegex';

function ClientDialog(props: ClientDialogInterface): ReactElement {
  const classes = useItemDialogStyles();
  const {
    open,
    setOpen,
    setClient,
    client,
    onClose,
    budget,
    setBudget,
    insurances,
  } = props;
  const [isRutValid, setIsRutValid] = useState<boolean>(validateRut(client.rut));
  const [isEmailValid, setIsEmailValid] = useState<boolean>(mailRegex.test(client.email));
  const [selectedInsurance, setSelectedInsurance] = useState<string>('');
  const [executeUpdateBudget] = useUpdateBudgetMutation({
    variables: {
      input: {
        budgetID: +budget.id,
        personalData: {
          phoneNumber: client.phone,
          name: client.name,
          lastname: client.lastname,
          rut: client.rut,
          email: client.email,
          plate: budget.car.plate,
          brand: budget.car.brand,
          model: budget.car.model,
          color: budget.car.color,
          year: budget.car.year,
          insurance: selectedInsurance || null,
        },
        sendAuto: false,
      },
    },
    onCompleted: (data) => {
      reducer({ type: 'LOADING' });
      setBudget({
        ...budget,
        ...data.updateBudget,
      });
      setOpen(false);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  useEffect(() => {
    if (client.rut && client.rut.length >= 11 && validateRut(client.rut)) {
      setIsRutValid(true);
    } else {
      setIsRutValid(false);
    }
  }, [client.rut]);

  useEffect(() => {
    if (client.email && mailRegex.test(client.email)) {
      setIsEmailValid(true);
    } else {
      setIsEmailValid(false);
    }
  }, [client.email]);

  useEffect(() => {
    if (budget.insurance) {
      setSelectedInsurance(budget.insurance.name);
    }
  }, [budget.insurance]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setSelectedInsurance(budget.insurance ? budget.insurance.name : '');
        onClose();
      }}
    >
      <div className={classes.closeBox}>
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => {
            setClient(budget.client);
            setSelectedInsurance(budget.insurance ? budget.insurance.name : '');
            setOpen(false);
          }}
        />
      </div>
      <DialogTitle className={classes.dialogTitle}>Editar</DialogTitle>
      <div className={classes.formContainer}>
        <TextField
          label="Nombre"
          className={classes.formInput}
          helperText={client.name ? '\u00a0' : 'Ingrese un nombre'}
          FormHelperTextProps={{ className: classes.helperText }}
          value={client.name}
          onChange={(e) => {
            setClient({
              ...client,
              name: e.target.value.toUpperCase(),
            });
          }}
        />
        <TextField
          label="Apellidos"
          className={classes.formInput}
          helperText={client.lastname ? '\u00a0' : 'Ingrese los apellidos'}
          FormHelperTextProps={{ className: classes.helperText }}
          value={client.lastname}
          onChange={(e) => {
            setClient({
              ...client,
              lastname: e.target.value.toUpperCase(),
            });
          }}
        />
        <TextField
          label="RUT"
          className={classes.formInput}
          helperText={isRutValid ? '\u00a0' : 'Ingrese un rut válido'}
          FormHelperTextProps={{ className: classes.helperText }}
          value={client.rut}
          onChange={(e) => {
            setClient({
              ...client,
              rut: rutFormat(e.target.value),
            });
          }}
        />
        <TextField
          label="Email"
          className={classes.formInput}
          value={client.email}
          helperText={isEmailValid ? '\u00a0' : 'Ingrese un email válido'}
          FormHelperTextProps={{ className: classes.helperText }}
          onChange={(e) => {
            setClient({
              ...client,
              email: e.target.value,
            });
          }}
        />
        <TextField
          label="Número"
          className={classes.formInput}
          helperText={client.phone ? '\u00a0' : 'Ingrese un número'}
          FormHelperTextProps={{ className: classes.helperText }}
          value={client.phone}
          onChange={(e) => {
            setClient({
              ...client,
              phone: e.target.value,
            });
          }}
        />
        <FormControl className={classes.formInput}>
          <InputLabel id="security-select">Seguro</InputLabel>
          <Select
            labelId="security-select"
            onChange={(e) => setSelectedInsurance(e.target.value as string)}
            value={selectedInsurance}
          >
            <MenuItem value="">Sin seguro</MenuItem>
            {insurances.map((insurance: GetInsurancesQuery['insurances'][0]) => (
              <MenuItem
                key={insurance.id}
                value={insurance.name}
              >
                {insurance.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>
            {'\u00a0'}
          </FormHelperText>
        </FormControl>
        <Button
          variant="contained"
          className={(
            isEmailValid
            && isRutValid
            && client.name
            && client.lastname
            && client.phone)
            ? classes.saveButton
            : classes.saveButtonDisabled}
          onClick={() => {
            if (
              isEmailValid
              && isRutValid
              && client.name
              && client.lastname
              && client.phone) {
              reducer({ type: 'LOADING' });
              executeUpdateBudget();
            }
          }}
        >
          Guardar
        </Button>
      </div>
    </Dialog>
  );
}

export default ClientDialog;
