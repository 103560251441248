import { ReactElement, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import state from '../../store/getGlobalState';
import { useStyles } from '../../styles/header_style';

function Header(props : { children: ReactNode }): ReactElement {
  const classes = useStyles();
  const { children } = props;

  return (
    <div className={classes.root}>
      <AppBar className={classes.appBar} elevation={0}>
        <Toolbar className={classes.topBar}>
          <Typography variant="h6" noWrap className={classes.title}>
            <Link to="/" className={classes.quelleLink}>
              QUELLE
            </Link>
          </Typography>
          {!state().token && (
            <div>
              <Button href="/reserva" className={classes.textTop}>Agendar</Button>
            </div>
          )}
        </Toolbar>
        <Toolbar className={classes.bottomBar}>
          {!state().token && (
            <div>
              <Button href="/reserva" className={classes.buttonClassReserve}>Agendar</Button>
              <Button href="/seguimiento" className={classes.buttonClass}>Seguimiento</Button>
              <Button href="/presupuesto" className={classes.buttonClass}>Presupuesto</Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.children}>
        {children}
      </div>
    </div>
  );
}

export default Header;
