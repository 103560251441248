import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useLoginStyles = makeStyles((theme: Theme) => createStyles({
  formInput: {
    width: '60vw',
    marginButton: '2vh',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      width: '30vw',
    },
  },
  inputsBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  helperText: {
    color: 'red',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: '10vh',
    backgroundColor: 'transparent',
    margin: '10vh 0',
  },
  pageButton: {
    color: '#FFFFFF',
    marginTop: '5vh',
    backgroundColor: 'rgba(18, 109, 166, 0.5)',
    '&:focus': {
      backgroundColor: 'rgba(18, 109, 166, 0.5)',
    },
    cursor: 'not-allowed',
  },
  pageButtonActive: {
    color: '#FFFFFF',
    marginTop: '5vh',
    backgroundColor: '#126da6',
    '&:focus': {
      backgroundColor: '#126da6',
    },
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  alertText: {
    color: '#ff0000',
  },
  contentBox: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2vh',
  },
}));

export default useLoginStyles;
