import {
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    overflowX: 'hidden',
  },
  title: {
    flexGrow: 1,
  },
  buttonClass: {
    background: '#115fa6',
    color: '#f5f5f5',
    '&:hover': {
      background: '#f5f5f5',
      color: '#115fa6',
    },
  },
  buttonClassReserve: {
    background: '#115fa6',
    color: '#f5f5f5',
    '&:hover': {
      background: '#f5f5f5',
      color: '#115fa6',
    },
    [theme.breakpoints.down(599)]: {
      display: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: '#ffffff',
  },
  appBar: {
    background: '#115fa6',
    position: 'static',
  },
  children: {
    minHeight: '75vh',
  },
  quelleLink: {
    textDecoration: 'none',
    color: '#115fa6',
    fontWeight: 'bold',
  },
  topBar: {
    background: '#fbfbfb',
    minHeight: 36,
    color: '#115fa6',
  },
  bottomBar: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  textTop: {
    color: '#115fa6',
    textDecoration: 'underline',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

export default useStyles;
