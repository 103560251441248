import DataTable from 'react-data-table-component';
import { Button, Box } from '@material-ui/core';
import { ReactElement, useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import Alert from '@material-ui/lab/Alert';
import { useAllBudgetsLazyQuery, useBudgetsLazyQuery, BudgetsQuery } from '../../types';
import { useStyles } from '../../styles/reservationTable_style';
import { RowBudgetDataInterface } from '../../interfaces/components.interfaces';
import BudgetFilter from '../filters/budgetFilters';

function redirectToReservationView(id: string) {
  window.location.href = `/admin/presupuestos/${id}`;
}

const columns = [
  {
    name: 'Presupuesto', selector: 'Presupuesto',
  },
  {
    name: 'Trabajador', selector: 'Trabajador',
  },
  {
    name: 'Patente', selector: 'Patente',
  },
  {
    name: 'Estado', selector: 'Estado',
  },
  {
    name: 'Reservado', selector: 'Reservado',
  },
  {
    name: '',
    cell: (params: { id: string }) => {
      const onClick = () => {
        redirectToReservationView(params.id);
      };
      return <Button onClick={onClick}>Ver</Button>;
    },
  },
];

function formatingData(data: BudgetsQuery['budgets']) {
  const rows: RowBudgetDataInterface[] = [];
  data.forEach((element) => {
    const reservation: RowBudgetDataInterface = {
      id: +element.id,
      Patente: element.car.plate,
      Presupuesto: element.id.toString(),
      Trabajador: element.user.name,
      Estado: element.status,
      Reservado: element.entryReservation === null ? 'No reservado' : 'Reservado',
    };
    rows.push(reservation);
  });
  return rows;
}

function BudgetTable(): ReactElement {
  const classes = useStyles();
  const [rowData, setRowData] = useState([]);
  const [allRows, setAllRows] = useState(0);
  const [name, setName] = useState('');
  const [plate, setPlate] = useState('');
  const [status, setStatus] = useState('');
  const [entryReservation, setEntryReservation] = useState('');
  const [page, setPage] = useState(1);
  const [alert, setAlert] = useState(false);
  const [alertContent, setAlertContent] = useState('');
  const take = 6;
  const [executeAllBudgets] = useAllBudgetsLazyQuery({
    variables:
    {
      name,
      plate,
      status,
      entry_reservation: entryReservation,
    },
    onCompleted: (data) => {
      setAllRows(data.allBudgets);
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlertContent('Falló la solicitud, inténtelo nuevamente');
        setAlert(true);
      }
    },
  });
  const [executeBudgetsQuery] = useBudgetsLazyQuery({
    variables: {
      page,
      take,
      name,
      plate,
      status,
      entry_reservation: entryReservation,
    },
    onCompleted: (data) => {
      executeAllBudgets();
      setRowData(formatingData(data.budgets));
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      } else {
        setAlertContent('Falló la solicitud, inténtelo nuevamente');
        setAlert(true);
      }
    },
  });
  useEffect(() => {
    executeBudgetsQuery();
  }, [page]);

  return (
    <div className={classes.root}>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={classes.buttonsContainer}
      >
        <BudgetFilter
          name={name}
          setName={setName}
          plate={plate}
          setPlate={setPlate}
          status={status}
          setStatus={setStatus}
          entryReservation={entryReservation}
          setEntryReservation={setEntryReservation}
        />
      </Box>
      { alert ? <Alert className={classes.alert} severity="error">{alertContent}</Alert> : <></>}
      <DataTable
        noHeader
        columns={columns}
        data={rowData}
        noDataComponent="No se ha encontrado información para mostrar"
        pagination
        paginationServer
        paginationTotalRows={allRows}
        paginationPerPage={take}
        paginationComponentOptions={{
          noRowsPerPage: true,
          rangeSeparatorText: 'de',
        }}
        paginationRowsPerPageOptions={[6]}
        onChangePage={(pageNumber) => setPage(pageNumber)}
      />
    </div>
  );
}

export default BudgetTable;
