import {
  ReactElement,
  useState,
} from 'react';
import {
  Box,
  Button,
} from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import useStyles from '../../styles/register_form';
import RegisterData from '../../components/forms/register';
import { reducer } from '../../store/reducer';
import { useSignupAdminMutation } from '../../types';

function AdminRegister() : ReactElement {
  const classes = useStyles();
  const [name, setName] = useState('');
  const [isNameValid, setIsNameValid] = useState(false);
  const [rut, setRut] = useState('');
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isRutValid, setIsRutValid] = useState(false);

  const [execution] = useSignupAdminMutation({
    variables: {
      rut,
      email,
      name,
    },
    onCompleted: () => {
      reducer({ type: 'LOADING' });
      window.location.href = '/admin/administradores';
    },
    onError: (error: ApolloError) => {
      if (error.message === 'Usuario no ha cambiado su contraseña por primera vez') {
        window.location.href = '/admin/cambio-clave';
      }
    },
  });

  function submit() {
    if (isNameValid && isRutValid && isEmailValid) {
      execution();
    }
  }

  return (
    <Box className={classes.appContainer}>
      <Box
        className={classes.formBox}
      >
        <div className={classes.formTitle}>
          Registro de Administrador
        </div>
        <RegisterData
          name={name}
          setName={setName}
          rut={rut}
          setRut={setRut}
          mail={email}
          setMail={setEmail}
          isNameValid={isNameValid}
          setIsNameValid={setIsNameValid}
          isMailValid={isEmailValid}
          setIsMailValid={setIsEmailValid}
          isRutValid={isRutValid}
          setIsRutValid={setIsRutValid}
        />
        <Box className={classes.pagesBox}>
          <Button
            variant="contained"
            onClick={() => submit()}
            className={(isNameValid && isRutValid && isEmailValid)
              ? classes.pageButtonActive
              : classes.pageButton}
          >
            Registrar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AdminRegister;
