import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => createStyles({

  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  dates: {
    maxWidth: '80vw',
    overflow: 'auto',
    '& > *': {
      margin: theme.spacing(0.5),
      height: theme.spacing(8),
    },
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      maxWidth: '50%',

    },
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      textAlign: 'center',
    },
  },
  color: {
    background: '#115fa6',
    color: 'white',
    border: '1px solid black',
    '&:hover': {
      background: '#115fa6',
      color: 'white',
    },
  },
  alert: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  notColor: {
    color: '#115fa6',
    border: '1px solid black',
    background: 'white',
    '&:hover': {
      background: 'white',
      color: '#115fa6',
    },
  },
  hora: {
    border: '1px solid black',
    borderRadius: '15px',
    margin: theme.spacing(0.5),
    background: 'white',
    width: '60vw',
  },
  daysF: {
    marginBottom: theme.spacing(1),
  },
  hours: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  lastButton: {
    color: '#FFFFFF',
    backgroundColor: '#126da6',
    cursor: 'pointer',
    margin: theme.spacing(1),
    float: 'left',
    '&:hover': {
      background: '#126da6',
      color: '#FFFFFF',
    },
  },
  spacing: {
    margin: theme.spacing(0.5),
  },
  hColor: {
    background: '#115fa6',
    textAlign: 'center',
    border: '1px solid black',
    borderRadius: '15px',
    margin: theme.spacing(0.5),
    color: 'white',
    '&:hover': {
      background: '#115fa6',
      color: 'white',
    },
  },
  formInput: {
    width: '60vw',
    margin: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2),
      width: '30vw',
      justifyContent: 'center',
    },
  },
  gridHours: {
    width: '80vw',
    [theme.breakpoints.up('md')]: {
      margin: 'auto',
      width: '40vw',
    },
    [theme.breakpoints.down('md')]: {
      margin: 'auto',
      justifyContent: 'center',
      width: '80vw',
    },
  },
  hNotColor: {
    color: '#115fa6',
    textAlign: 'center',
    border: '1px solid black',
    borderRadius: '15px',
    margin: theme.spacing(0.5),
    background: 'white',
  },
  containerForm: {
    background: '#F6F4F4',
    borderRadius: '20px',
    maxWidth: '100vw',
    border: '1px solid #115fa6',
  },
  pageButton: {
    color: '#FFFFFF',
    backgroundColor: 'rgba(18, 109, 166, 0.5)',
    '&:focus': {
      backgroundColor: 'rgba(18, 109, 166, 0.5)',
    },
    cursor: 'not-allowed',
  },
  pageButtonActive: {
    color: '#FFFFFF',
    backgroundColor: '#126da6',
    '&:focus': {
      backgroundColor: '#126da6',
    },
  },
  reserved: {
    cursor: 'not-allowed',
    color: '#CFCFCF',
    textAlign: 'center',
    border: '1px solid white',
    borderRadius: '15px',
    margin: theme.spacing(0.5),
    '&:hover': {
      background: '#FFFFFF',
      color: '#CFCFCF',
    },
  },
  typography: {
    marginTop: '5vh',
    padding: '5px',
    color: 'black',
    fontSize: 36,
    textAlign: 'center',
    letterSpacing: 1,
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2vh',
      paddingRigth: '2vh',
      marginBottom: '2vh',
      fontSize: 32,
    },
  },
  statsContainer: {
    position: 'relative',
    diplay: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
  },
  statValue: {
    color: 'black',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: 20,
    textAlign: 'center',
    letterSpacing: 1,
    width: '100%',
    whiteSpace: 'pre',
  },
  statName: {
    color: 'black',
    fontSize: 20,
    textAlign: 'center',
    letterSpacing: 1,
  },
  statNameTittle: {
    color: 'black',
    fontSize: 30,
    textAlign: 'center',
    letterSpacing: 1,
  },
  statContainer: {
    position: 'relative',
    display: 'inherit',
    overflow: 'visible',
    flexDirection: 'row',
    paddingRight: '2vh',
    with: '100%',
    padding: 0,
  },
  secondBoxs: {
    marginRight: '3vw',
    marginBottom: '2vh',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    margin: theme.spacing(0.5),
    minWidth: 200,
    [theme.breakpoints.up('md')]: {
      marginTop: '0vh',
    },
  },
  base: {
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  table: {
    width: '90vw',
    padding: theme.spacing(0.5),
    textAlign: 'center',
    marginBottom: '5vh',
    [theme.breakpoints.up('md')]: {
      maxWidth: '45vw',
      maxHeight: '55vh',
      minHeight: '55vh',
      marginBottom: '0vh',
      alignItems: 'center',
    },
  },
  dataTable: {
    width: '90vw',
    padding: theme.spacing(0.5),
    textAlign: 'center',
    marginBottom: '5vh',
    height: 'auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '30vw',
      height: 'auto',
      marginBottom: '0vh',
      alignItems: 'center',
    },
  },
  secondColumn: {
    width: '15vw',
  },
}));
export default useStyles;
