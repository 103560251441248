import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  image:
  {
    height: '80vh',
    justifyContent: 'center',
    alignContent: 'center',
    padding: '5vw',
    background: 'white',
  },
  root: {
    maxWidth: '600px',
  },
  typography: {
    color: '#808080',
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 600,
  },
});

export default useStyles;
