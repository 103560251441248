import {
  ReactElement,
  useState,
  useEffect,
} from 'react';
import { Box, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import useBudgetAppointmentStyles from '../../styles/budget_appointment';
import validatePassword from '../../helpers/passwordVerification';
import { ChangePasswordInterface } from '../../interfaces/components.interfaces';
import PASSWORD_LENGTH from '../../helpers/constants';

function ChangePassword(props: ChangePasswordInterface) : ReactElement {
  const {
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    validationPassword,
    setValidationPassword,
    isNewPasswordValid,
    setIsNewPasswordValid,
    isValidationPasswordValid,
    setIsValidationPasswordValid,
  } = props;
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showValidationPassword, setShowValidationPassword] = useState(false);
  const classes = useBudgetAppointmentStyles();
  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleClickShowValidationPassword = () => {
    setShowValidationPassword(!showValidationPassword);
  };

  useEffect(() => {
    if (validatePassword(newPassword)) {
      setIsNewPasswordValid(true);
    } else {
      setIsNewPasswordValid(false);
    }
  });

  useEffect(() => {
    if (validatePassword(validationPassword) && (validationPassword === newPassword)) {
      setIsValidationPasswordValid(true);
    } else {
      setIsValidationPasswordValid(false);
    }
  });

  return (
    <Box className={classes.inputsBox}>
      <Paper className={classes.infoAlert}>
        <Typography>
          Una contraseña válida cuenta con:
        </Typography>
        <Typography className={classes.rowAlert}>
          - Un largo mínimo de 8 caracteres.
        </Typography>
        <Typography className={classes.rowAlert}>
          - Mínimo una mayúscula.
        </Typography>
        <Typography className={classes.rowAlert}>
          - Mínimo una minúscula.
        </Typography>
        <Typography className={classes.rowAlert}>
          - Mínimo un número.
        </Typography>
      </Paper>
      <FormControl className={classes.formInput}>
        <InputLabel htmlFor="standard-adornment-password">
          Contraseña Actual
        </InputLabel>
        <Input
          id="password"
          type="password"
          value={currentPassword}
          aria-describedby="component-helper-text"
          onChange={(e) => setCurrentPassword(e.target.value)}
        />
        {(currentPassword.length < PASSWORD_LENGTH) ? (
          <FormHelperText className={classes.alertText} id="password">
            Ingresa tu contraseña
          </FormHelperText>
        ) : ''}
      </FormControl>
      <FormControl className={classes.formInput}>
        <InputLabel htmlFor="standard-adornment-password">
          Nueva Contraseña
        </InputLabel>
        <Input
          id="new-password"
          type={showNewPassword ? 'text' : 'password'}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          aria-describedby="component-helper-text"
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowNewPassword}
              >
                {showNewPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
        />
        { !(isNewPasswordValid) ? (
          <FormHelperText className={classes.alertText} id="new-password">
            Ingresa una contraseña válida
          </FormHelperText>
        ) : ''}
      </FormControl>
      <FormControl className={classes.formInput}>
        <InputLabel htmlFor="standard-adornment-password">
          Confirmar Contraseña
        </InputLabel>
        <Input
          id="validation-password"
          type={showValidationPassword ? 'text' : 'password'}
          value={validationPassword}
          onChange={(e) => setValidationPassword(e.target.value)}
          endAdornment={(
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowValidationPassword}
              >
                {showValidationPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )}
        />
        {!(validationPassword === newPassword && isValidationPasswordValid) ? (
          <FormHelperText className={classes.alertText} id="validation-password">
            Las contraseñas no coinciden
          </FormHelperText>
        ) : ''}
      </FormControl>
    </Box>
  );
}
export default ChangePassword;
